import {
  ChangeDetectorRef,
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { AggregatorManagerService } from '../Managers/aggregator-manager.service';
// import * as annyang from 'annyang/dist/annyang.js';
import { VisualizationManagerService } from '../Managers/visualization-manager.service';
import { ErrorService } from '../services/error.service';
import {
  ConfirmationDailogComponent,
  ConfirmDialogModel,
} from '../dailogs/confirmation-dailog/confirmation-dailog.component';
import { MatDialog } from '@angular/material/dialog';
import { DemoService } from '../services/demo.service';
import { environment } from '../../environments/environment';

import * as RecordRTC from 'recordrtc';
import { DomSanitizer } from '@angular/platform-browser';
import { AudiorecorderService } from '../services/audiorecorder.service';

@Component({
  selector: 'app-landing-dashboard',
  templateUrl: './landing-dashboard.component.html',
  styleUrls: ['./landing-dashboard.component.css'],
})
export class LandingDashboardComponent implements OnInit, AfterViewInit {
  showVisualizationMenu = false;
  public transcription;
  public searching: boolean = false;
  record;
  recording = false;
  url;
  error;
  base64Audio;

  @ViewChild('content', { static: false }) public content;

  constructor(
    public router: Router,
    public athenticateService: AuthenticationService,
    public aggrManager: AggregatorManagerService,
    public visualizationManagerService: VisualizationManagerService,
    private ref: ChangeDetectorRef,
    public errorService: ErrorService,
    public dialog: MatDialog,
    public demoService: DemoService,
    private domSanitizer: DomSanitizer,
    private audioservice: AudiorecorderService
  ) {}
  sanitize(url: string) {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }
  isVisualizationLinkActive(): boolean {
    return this.router.url.indexOf('/visualization') >= 0;
  }

  visualizationInnerClickAction(event): void {
    event.stopPropagation();
  }

  switch(): void {
    this.aggrManager.removeSelectedAggregator();
    this.router.navigate(['']);
  }

  confirmExecuteDialog(aggregator: any): void {
    const message =
      `Are you sure you want execute aggregator '` + aggregator.name + `'`;

    const dialogData = new ConfirmDialogModel('Confirm Action', message);

    const dialogRef = this.dialog.open(ConfirmationDailogComponent, {
      maxWidth: '400px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      this.result = dialogResult;

      if (this.result) {
        this.ExecuteAggregator(aggregator);
      }
    });
  }

  ExecuteAggregator(aggregator) {
    this.aggrManager.ExecuteAggregator(aggregator);
  }

  clearSearch(): void {
    this.visualizationManagerService.clearSearch();
    if (this.visualizationManagerService.searchByVoice) {
      this.enableVoiceCommands();
    }
  }

  callSearch(keyword: string): void {
    if (keyword) {
      this.visualizationManagerService.searchKey = keyword;
      this.visualizationManagerService.searchKeyword(keyword);
    }
  }

  disableVoiceCommands(): void {
    this.visualizationManagerService.searchByVoice = false;
    this.visualizationManagerService.searchKey = null;
    this.visualizationManagerService.searchText = '';
    if (this.recording && this.record) {
      this.recording = false;
      this.record.stop(this.processRecording.bind(this));
    }
  }

  enableVoiceCommands(): void {
    this.visualizationManagerService.searchKey = null;
    this.visualizationManagerService.searchText = '';
    this.visualizationManagerService.searchByVoice = true;
    this.url = undefined;
    let mediaConstraints = {
      video: false,
      audio: true,
    };
    navigator.mediaDevices
      .getUserMedia(mediaConstraints)
      .then(this.successCallback.bind(this), this.errorCallback.bind(this));
    this.recording = true;
  }
  successCallback(stream) {
    var options = {
      mimeType: 'audio/wav',
      numberOfAudioChannels: 1,
      sampleRate: 48000,
    };
    var StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
    this.record = new StereoAudioRecorder(stream, options);
    this.record.record();
  }
  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  async processRecording(blob) {
    this.searching = true;
    this.base64Audio = await this.toBase64(blob);
    this.audioservice.uploadaudio(this.base64Audio, 'sample.wav').subscribe(
      (c) => {
        this.transcription = c['text'];
        this.callSearch(this.transcription);
        this.searching = false;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  errorCallback(error) {
    this.error = 'Can not play audio in your browser';
  }
  ngAfterViewInit(): void {
    this.errorService.errorContent = this.content;
  }

  checkInfographActive(): boolean {
    return this.router.url.indexOf('/infograph') > -1;
  }

  checkURLForVisualization(): void {
    this.showVisualizationMenu = false;
    if (this.router.url.indexOf('/visualization/sentiments') > -1) {
      this.visualizationManagerService.searchEnabled = false;
      this.clearSearch();
      this.disableVoiceCommands();
      return;
    }
    if (this.router.url.indexOf('/visualization') > -1) {
      this.visualizationManagerService.searchEnabled = true;
      this.clearSearch();
      this.disableVoiceCommands();
      return;
    }
    this.visualizationManagerService.searchEnabled = false;
    this.clearSearch();
    this.disableVoiceCommands();
    return;
  }
  devMode: boolean = false;
  ngOnInit(): void {
    if (environment.devMode) {
      this.devMode = true;
    }
    this.checkURLForVisualization();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.checkURLForVisualization();
      }
    });
    setInterval(() => {
      this.ref.markForCheck();
    }, 400);
  }

  result: any;
  ConfirmLogout() {
    const message = `Are you sure you want logout?`;

    const dialogData = new ConfirmDialogModel('Confirm Action', message);

    const dialogRef = this.dialog.open(ConfirmationDailogComponent, {
      maxWidth: '400px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      this.result = dialogResult;

      if (this.result) {
        this.router.navigate(['logout']);
      }
    });
  }
}
