<div class="chart-card p-4">

  <h4 class="text-style-p1 text-color-dark font-weight-bold m-2"> Bubble Chart</h4>
  <div
    *ngIf="this.visualizationManagerService.visualizationData != null && this.visualizationManagerService.visualizationData.length == 0"
    class="empty-data">
    <i class="fa fa-solid fa-box-open"></i>
    <strong>Not available!</strong>
  </div>
  <div class="d-flex flex-row chart-visualization-full-size align-items-stretch"
    [style.height]="height ?  height+'px' :  null">

    <div
      class=" p-2 br-1 overflow-auto d-flex flex-column justify-content-center align-items-stretch border-right-light"
      style="min-width: 300px; width:300px" *ngIf="!height">
      <h5 class="text-style-p1 text-color-dark-light">{{ statsData?.aggregatorName || '' }}</h5>
      <button class="btn2" *ngIf="!height" (click)="Add(this.viz_type?.viz_uid)" matTooltip="Add to Dashboard"
        [disabled]="this.viz_type?.isEnabled || this.visualizationManagerService.visualizationData != null && this.visualizationManagerService.visualizationData.length == 0"><i
          class="fa fa-plus"></i></button>
      <p class="mb-4">
        <span class="text-style-h2 text-color-dark font-weight-normal"> {{ statsData?.totalDocuments | number }} </span>
        <span class="text-style-p1 text-color-dark-light">Analyzed</span>
      </p>
      <div class="d-flex justify-content-between align-items-center my-2" *ngFor="let cluster of statsData?.clusters">
        <div class="d-flex align-items-center">
          <i class="fa fa-square-full" [style.color]="colorFunc(cluster.group)"></i>
          <span class="ml-2 text-style-p1 text-color-dark-light">{{ cluster.group }}</span>
        </div>
        <span class="text-style-p1 text-color-dark-light pr-3">{{ cluster.clusterDensity | number }} Analyzed</span>
      </div>

      <br><br><br><br><br><br>

    </div>

    <div class="flex-fill">
      <div #bubbleChart id="bubbleChart" class="w-100 h-100" [style.margin-left]="height ?  '-100px' :  null"></div>
    </div>

  </div>

</div>

<div class="modal fade" id="bubble-chart-details-modal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-xl" role="document">
    <div *ngIf="selectedNode" class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Cluster: <b>{{ selectedNode.group }}</b>
          <i class="fa fa-chevron-right mx-3"></i> Term: <b>{{ selectedNode.name }}</b>
          <i class="fa fa-chevron-right mx-3"></i> Documents({{ selectedNode.documents.length }})
        </h5>
        <button type="button" class="close" (click)="closeDetails()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <table class="table table-striped">
          <tbody>
            <tr *ngFor="let document of selectedNode.documents; let i = index">
              <td class="cursor-pointer" [class.bg-warning]="document.flagged"
                (click)="visualizationManagerService.showDocumentText({ id: document.id, text: document.text })">{{
                document.text }}
              </td>
              <td class="w-10">
                <button (click)="flagItem(document.id, true, i)" data-toggle="tooltip" data-placement="right"
                  title="Flag Document" *ngIf="!document.flagged && !demoService.demoMode" class="btn btn-sm"><i
                    class="fa fa-flag"></i></button>
                <button (click)="flagItem(document.id, false, i)" data-toggle="tooltip" data-placement="right"
                  title="Unflag Document" *ngIf="document.flagged && !demoService.demoMode"
                  class="btn btn-sm text-primary"><i class="fa fa-flag"></i></button>
                <button
                  (click)="documentSourceService.getAndOpenSource({ id: document.id }, aggregatorManagerService.selectedAggregator.id)"
                  data-toggle="tooltip" data-placement="right" title="Open Source" class="btn btn-sm"><i
                    class="fa fa-external-link-alt"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>