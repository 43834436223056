<h1 style="display: contents;">Aggregator History</h1>
<button  class="button float-right secondary-color br-8 font-size14" (click)="ConfirmClearHistories()">Clear History</button>
<div class="mat-elevation-z8 mt-4" style="background-color: white; border-radius: 16px; padding: 20px;">
    <table mat-table [dataSource]="this.historyManager.dataSource" style="width: 100%;">
  
 
      <!-- Position Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>  </th>
        <td mat-cell *matCellDef="let i = index">{{this.paginator.pageIndex == 0 ? i + 1 : 1 + i + this.paginator.pageIndex * this.paginator.pageSize}} </td>
      </ng-container>
  
      <!-- Name Column -->
      <ng-container matColumnDef="execStarted">
        <th mat-header-cell *matHeaderCellDef> Excecution Started </th>
        <td mat-cell *matCellDef="let element"> {{element.execution_started_date}} </td>
      </ng-container>
  
      <!-- Weight Column -->
      <ng-container matColumnDef="execEnded">
        <th mat-header-cell *matHeaderCellDef> Execution Ended</th>
        <td mat-cell *matCellDef="let element"> {{element.execution_ended_date}} </td>
      </ng-container>
  
      <!-- Symbol Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Text </th>
        <td mat-cell *matCellDef="let element"> {{element.status}} </td>
      </ng-container>
  
      <ng-container matColumnDef="comment">
        <th mat-header-cell *matHeaderCellDef> Comment </th>
        <td mat-cell *matCellDef="let element"> {{element.comment}} </td>
      </ng-container>

      <ng-container matColumnDef="score">
        <th mat-header-cell *matHeaderCellDef> Score </th>
        <td mat-cell *matCellDef="let element"> {{element.sentiment_score}} </td>
      </ng-container>

      <ng-container matColumnDef="results">
        <th mat-header-cell *matHeaderCellDef> Results </th>
        <td mat-cell *matCellDef="let element"> {{element.total_results}} </td>
      </ng-container>

      <ng-container matColumnDef="flagged">
        <th mat-header-cell *matHeaderCellDef> Flagged </th>
        <td mat-cell *matCellDef="let element"> {{element.flagged_count}} </td>
      </ng-container>

      <ng-container matColumnDef="conversed">
        <th mat-header-cell *matHeaderCellDef> Conversed </th>
        <td mat-cell *matCellDef="let element"> {{element.conversed_count}} </td>
      </ng-container>

      <ng-container matColumnDef="comments">
        <th mat-header-cell *matHeaderCellDef> Comments </th>
        <td mat-cell *matCellDef="let element"> {{element.comment_count}} </td>
      </ng-container>


      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>  </th>
        <td mat-cell *matCellDef="let element"> 
          <button class="icon" matTooltip="Search Details" (click)="selectSearch(element)" >
            <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M4 8h4V4H4v4zm6 12h4v-4h-4v4zm-6 0h4v-4H4v4zm0-6h4v-4H4v4zm6 0h4v-4h-4v4zm6-10v4h4V4h-4zm-6 4h4V4h-4v4zm6 6h4v-4h-4v4zm0 6h4v-4h-4v4z"/></svg>
          </button> 
        
        <button class="icon ml-3" matTooltip="Delete" (click)="ConfirmRemoveHistory(element)">

          <svg xmlns="http://www.w3.org/2000/svg" width="9.801" height="12" viewBox="0 0 9.801 12">
               
            <g id="trash">
                <path id="Path_4" d="M9.77 2.682l-.265-.794a.739.739 0 0 0-.7-.506H6.576V.657A.658.658 0 0 0 5.92 0H3.88a.658.658 0 0 0-.657.657v.725H1a.739.739 0 0 0-.7.506l-.27.794a.595.595 0 0 0 .564.783h.277L1.48 11a1.09 1.09 0 0 0 1.081 1h4.8a1.09 1.09 0 0 0 1.081-1l.61-7.538h.153a.595.595 0 0 0 .564-.783zM3.927.7h1.946v.679H3.927zm3.815 10.246a.383.383 0 0 1-.38.351h-4.8a.383.383 0 0 1-.38-.351l-.606-7.481h6.771zm-7-8.184l.22-.652A.037.037 0 0 1 1 2.085h7.8a.037.037 0 0 1 .035.025l.217.651zm0 0"    data-name="Path 4"/>
                <path id="Path_5" d="M268.629 172.616h.019a.351.351 0 0 0 .351-.333l.33-6.337a.352.352 0 1 0-.7-.037l-.33 6.337a.352.352 0 0 0 .33.37zm0 0"    data-name="Path 5" transform="translate(-262.008 -161.695)"/>
                <path id="Path_6" d="M106.14 172.287a.352.352 0 0 0 .351.332h.02a.352.352 0 0 0 .332-.37l-.346-6.337a.352.352 0 1 0-.7.038zm0 0"    data-name="Path 6" transform="translate(-103.315 -161.698)"/>
                <path id="Path_7" d="M194.605 172.619a.352.352 0 0 0 .352-.352v-6.337a.352.352 0 0 0-.7 0v6.337a.352.352 0 0 0 .348.352zm0 0"    data-name="Path 7" transform="translate(-189.702 -161.697)"/>
            </g>
        </svg>

        </button> </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  
    <mat-paginator [pageSizeOptions]="[30,60,80,100]" [length]="this.historyManager.total_history_result" (page)="changePagesize($event)"   [pageSize]="limit" showFirstLastButtons></mat-paginator>
  </div>
