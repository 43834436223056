/* tslint:disable */

import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  Input,
} from '@angular/core';
import { addContextMenu, d3Modify, waitInMS } from '../../../services/utils';
import { VisualizationManagerService } from '../../../Managers/visualization-manager.service';
import { DocumentSourceService } from '../../../services/document-source.service';
import { AggregatorManagerService } from '../../../Managers/aggregator-manager.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { DemoService } from '../../../services/demo.service';
import { viz_type } from '../../../models/dashboard';
import { DashboardManagerService } from '../../../Managers/dashboard-manager.service';
const d3: any = addContextMenu();

@Component({
  selector: 'app-force',
  templateUrl: './force.component.html',
  styleUrls: ['./force.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ForceComponent implements OnInit, OnDestroy {
  @ViewChild('forceChartContainer', { static: true })
  private forceChartContainer: ElementRef;
  @Input() height: number = null;
  visualizationSubscription = null;
  loaded = false;
  _serviceSubscription: any;
  constructor(
    public visualizationManagerService: VisualizationManagerService,
    public dashboardManager: DashboardManagerService,
    public aggregatorManagerService: AggregatorManagerService,
    public documentSourceService: DocumentSourceService,
    public demoService: DemoService
  ) {
    this._serviceSubscription = this.dashboardManager.onExport.subscribe(
      (c) => {
        this.export();
      }
    );
  }

  async initChart(): Promise<any> {
    const element = this.forceChartContainer.nativeElement;
    element.innerHTML = '';
    // await waitInMS(50);
    this.createChart();
  }

  createChart(): void {
    if (!this.loaded) return;
    if (
      !this.visualizationManagerService.visualizationInfo ||
      !this.visualizationManagerService.visualizationData
    )
      return;
    if (this.visualizationManagerService.visualizationSearchFailed) return;

    const forceComponent = this;
    const updateNodeStyle = (d, i, nodeMap) => {
      d3.select(nodeMap[i]).style(
        'fill',
        d.data.flagged ? '#e7c575' : '#69b0e7'
      );
    };
    const menu = (nodeD: any, i: any, nodeMap: any) => [
      nodeD.data.flagged
        ? {
            title: 'Unflag',
            action: () => {
              nodeD.data.flagged = false;
              const { documentId } = nodeD.data;
              forceComponent.visualizationManagerService.changeFlagStatus({
                id: documentId,
                status: false,
              });
              updateNodeStyle(nodeD, i, nodeMap);
            },
          }
        : {
            title: 'Flag',
            action: () => {
              nodeD.data.flagged = true;
              const { documentId } = nodeD.data;
              forceComponent.visualizationManagerService.changeFlagStatus({
                id: documentId,
                status: true,
              });
              updateNodeStyle(nodeD, i, nodeMap);
            },
          },
      {
        title: 'Source',
        action: () => {
          const { documentId } = nodeD.data;
          forceComponent.documentSourceService.getAndOpenSource(
            { id: documentId },
            forceComponent.aggregatorManagerService.selectedAggregator.id
          );
        },
      },
    ];
    const onContext = (d, i, allNodes) => {
      d3.event.preventDefault();
      if (d.data.value) {
        if (this.demoService.demoMode) {
          const { documentId } = d.data;
          forceComponent.documentSourceService.getAndOpenSource(
            { id: documentId },
            forceComponent.aggregatorManagerService.selectedAggregator.id
          );
        } else {
          d3.contextMenu(menu(d, i, allNodes))(d, i, allNodes);
        }
      }
    };
    const onClick = (d: any) => {
      if (!d._children) {
        const { documentId, name } = d.data;
        forceComponent.visualizationManagerService.showDocumentText({
          id: documentId,
          text: name,
        });
      }
    };
    const clonedData = JSON.parse(
      JSON.stringify(
        forceComponent.visualizationManagerService.visualizationSearchData ||
          forceComponent.visualizationManagerService.visualizationData
      )
    );
    const aggregatorName =
      forceComponent.visualizationManagerService.visualizationInfo
        .aggregator_visualization_info.name;

    const forceData = d3Modify(clonedData, aggregatorName);
    const element = forceComponent.forceChartContainer.nativeElement;
    element.innerHTML = '';

    const width = element.offsetWidth;
    const height = element.offsetHeight;

    const root = d3.hierarchy(forceData);
    root.eachBefore((itemNode) => {
      if (itemNode.depth > 0 && itemNode.children) {
        itemNode._children = itemNode.children;
        itemNode.children = null;
      }
      if (itemNode.depth >= 1 && !itemNode.data.children) {
        itemNode.id = null;
        itemNode.index = null;
      }
    });

    const svg: any = d3
      .select(element)
      .append('svg')
      .attr('viewBox', [-width / 2, -height / 2, width, height]);

    const g = svg.append('g').attr('class', 'everything');

    const zoomHandler = d3.zoom().on('zoom', zoomActions);

    function zoomActions() {
      g.attr('transform', d3.event.transform);
    }

    zoomHandler(svg);

    const update = () => {
      g.selectAll('*').remove();
      const links = root.links();
      const nodes = root.descendants();

      const simulation = d3
        .forceSimulation(nodes)
        .force(
          'link',
          d3
            .forceLink(links)
            .id((d) => d.id)
            .distance((d) => {
              switch (d.source.depth) {
                case 0:
                  return 200;
                case 1:
                  return 100;
                case 2:
                  return 50;
                case 3:
                  return 25;
                case 4:
                  return 12;
                default:
                  return 3;
              }
            })
            .strength(1)
        )
        .force('charge', d3.forceManyBody().strength(-500))
        .force('x', d3.forceX())
        .force('y', d3.forceY());

      const drag = (simulationInput) => {
        function dragstarted(d) {
          if (!d3.event.active) {
            simulationInput.alphaTarget(0.3).restart();
          }
          d.fx = d.x;
          d.fy = d.y;
        }

        function dragged(d) {
          d.fx = d3.event.x;
          d.fy = d3.event.y;
        }

        function dragended(d) {
          if (!d3.event.active) {
            simulationInput.alphaTarget(0);
          }
          d.fx = null;
          d.fy = null;
        }

        return d3
          .drag()
          .on('start', dragstarted)
          .on('drag', dragged)
          .on('end', dragended);
      };

      const link = g
        .append('g')
        .attr('stroke', '#b6b6b6')
        .attr('stroke-opacity', 0.9)
        .attr('stroke-width', 3)
        .selectAll('line')
        .data(links)
        .join('line')
        .attr('class', 'link');

      const nodeText = g
        .append('g')
        .selectAll('text')
        .data(nodes)
        .join('text')
        .text((d) => (d.depth < 3 ? d.data.name : ''))
        .attr('class', 'node-text')
        .call(drag(simulation));

      const tooltip = d3
        .select(element)
        .append('div')
        .attr('class', 'tooltip')
        .style('opacity', 0);
      const mouseOverMove = (d) => {
        if (d.depth === 3) {
          if (d.data.name != null && d.data.name !== '') {
            tooltip.style('opacity', 0.9);
            tooltip
              .html(d.data.name)
              .style('left', d3.event.pageX - 20 + 'px')
              .style('top', d3.event.pageY - 28 + 'px');
          }
        }
      };
      const mouseOut = (d) => {
        tooltip.style('opacity', 0);
        /*  if (d.parent && d.parent.data.name) {
          tooltip.html(d.parent.data.name)
            .style('left', (d3.event.pageX) + 'px')
            .style('top', (d3.event.pageY - 28) + 'px');
        } else {
          tooltip.transition()
            .duration(500)
            .style('opacity', 0);
        }*/
      };

      const node = g
        .append('g')
        .selectAll('circle')
        .data(nodes)
        .join('circle')
        .attr('fill', (d) => {
          if (d.data.flagged) {
            return '#e7c575';
          }
          switch (d.depth) {
            case 0:
              return '#08169F';
            case 1:
              return '#25CFAD';
            case 2:
              return '#69b0e7';
            case 3:
              return '#abc7eb';
            default:
              return '#6561E7';
          }
        })
        .attr('stroke', (d) => (d.children ? '#6561E7' : '#6561E7'))
        .attr('class', 'node')
        .attr('r', (d) => {
          switch (d.depth) {
            case 0:
              return 18;
            case 1:
              return 10;
            case 2:
              return 8;
            case 3:
              return 14;
            default:
              return 5;
          }
        })
        .call(drag(simulation))
        .on('click', (clickedNode) => {
          if (clickedNode.data.value) {
            return onClick(clickedNode);
          }
          if (!d3.event.defaultPrevented) {
            if (clickedNode.children) {
              clickedNode._children = clickedNode.children;
              clickedNode.children = null;
            } else {
              clickedNode.children = clickedNode._children;
              clickedNode._children = null;
            }
            update();
          }
        })
        .on('contextmenu', onContext)
        .on('mouseover', mouseOverMove)
        .on('mouseout', mouseOut);

      const label = node
        .append('text')
        .text((d) => d.data.name)
        .attr('x', 15)
        .attr('y', 5);

      simulation.on('tick', () => {
        link
          .attr('x1', (d) => d.source.x)
          .attr('y1', (d) => d.source.y)
          .attr('x2', (d) => d.target.x)
          .attr('y2', (d) => d.target.y);

        node.attr('cx', (d) => d.x).attr('cy', (d) => d.y);

        nodeText.attr('x', (d) => d.x + 25).attr('y', (d) => d.y);
      });
    };
    update();
  }

  ngOnDestroy(): void {
    if (this._serviceSubscription) this._serviceSubscription.unsubscribe();
    this.loaded = false;
    this.visualizationSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.dashboardManager.viz_types.forEach((c) => {
      if (c.viz_uid == this.viz_uid) {
        this.viz_type = c;
      }
    });
    this.loaded = true;
    this.visualizationSubscription =
      this.visualizationManagerService.visualizingInit.subscribe(() =>
        this.initChart().then().catch(console.log)
      );
    this.initChart().then().catch(console.log);
  }

  viz_uid = '000000000000000000000004';

  public viz_type: viz_type;
  Add() {
    this.dashboardManager.viz_types.forEach((c) => {
      if (c.viz_uid == this.viz_uid) {
        this.viz_type = c;
      }
    });
    this.viz_type.isEnabled = true;
    this.dashboardManager.AddDashboardItem(
      this.aggregatorManagerService.selectedAggregator.id,
      this.viz_type.viz_uid,
      1
    );
  }

  export() {
    if (this.height) {
      const element = document.querySelector('div#forceChartContainer svg');

      this.dashboardManager.dashboard_items.forEach((c) => {
        if (c.viz_uid == this.viz_uid) {
          c.viz_type = this.viz_type.viz_type;
          c.viz_svg =
            '<svg xmlns="http://www.w3.org/2000/svg" width="750" height="600" viewBox="0 0 580 500" preserveAspectRatio="xMinYMin"   > <g transform="translate(200,200)">' +
            element.innerHTML +
            '</g></svg>';

          //  c.viz_svg=  "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"850\" height=\"800\"    > <g transform=\"translate(50,-100)\"  >"+ element.innerHTML+"</g></svg>";
        }
      });
    }
  }
}
