<div [id]="'viz' + currentVizID" class="chart-card"   [style.height]="height+'px'" >
    <h4 class="text-style-p1 text-color-dark font-weight-bold m-2" style="    position: absolute;
    top: 20px;
    left: 19px;">{{name}}</h4>
 <!-- <button (click)="convertToInline()">ExportSet</button>-->
    <lib-neo-graph [scaleMap]="colorScale"
                   [cipherQuery]="showQuery"
                   [cipherServerURL]="'bolt+s://' + driverURL"
                   [cipherServerUsername]="serverUsername"
                   [cipherServerPassword]="serverPassword"
                   [cipherDatabase]="serverDatabase">
      <div class="text-center chart-visualization-full-size d-flex justify-content-center align-items-center">
        <app-loading-component></app-loading-component>
      </div>
    </lib-neo-graph>
  </div>
