<div class="modal fade" id="source-csv-viewer-modal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title mb-0" id="staticBackdropLabel"><b> Source Details </b></h4>

        <div class="source-details" *ngIf="documentSourceService.sourceObject">
          <span>
            {{ documentSourceService.sourceObject.search_result_csv_record.orignalFileName }}
            &nbsp;|&nbsp;
            Row # {{ documentSourceService.sourceObject.search_result_csv_record.rowNumber }}
<!--            &nbsp;|&nbsp;-->
<!--            {{ documentSourceService.sourceObject.selectedColumn }}-->
          </span>
        </div>

        <button type="button" class="close-btn" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pt-4 pb-4" *ngIf="documentSourceService.sourceObject">

        <div class="csv-table-container">
          <table class="table csv-table">
            <tbody *ngIf="!isRowAList(documentSourceService.sourceObject.search_result_csv_record.row)">
            <tr *ngFor="let colKey of convertToKeys(documentSourceService.sourceObject.search_result_csv_record.row)">
              <th scope="row" [class.active-col]="documentSourceService.sourceObject.selectedColumn === colKey">{{ colKey }}</th>
              <td>
                <div class="details-container" [class.active]="hasMoreCharacters(colKey, documentSourceService.sourceObject.search_result_csv_record.row) && isExpanded(colKey)">
                  {{ trimCharacters(documentSourceService.sourceObject.search_result_csv_record.row[colKey],
                  hasMoreCharacters(colKey, documentSourceService.sourceObject.search_result_csv_record.row) && !isExpanded(colKey)) }}
                  <span *ngIf="hasMoreCharacters(colKey, documentSourceService.sourceObject.search_result_csv_record.row)">
                  <button type="button" *ngIf="!isExpanded(colKey)" (click)="expandToggle(colKey)" class="more-button">show more</button>
                  <button type="button" *ngIf="isExpanded(colKey)" (click)="expandToggle(colKey)" class="less-button">show less</button>
                </span>
                </div>
              </td>
            </tr>
            </tbody>
            <tbody *ngIf="isRowAList(documentSourceService.sourceObject.search_result_csv_record.row)">
            <tr *ngFor="let colItem of documentSourceService.sourceObject.search_result_csv_record.row">
              <th scope="row" [class.active-col]="colItem.index === documentSourceService.sourceObject.selectedIndex">{{ colItem.name }}</th>
              <td>
                <div class="details-container" [class.active]="hasMoreCharactersInText(colItem.value) && isExpanded(colItem.index)">
                  {{ trimCharacters(colItem.value, hasMoreCharactersInText(colItem.value) && !isExpanded(colItem.index)) }}
                  <span *ngIf="hasMoreCharactersInText(colItem.value)">
                  <button type="button" *ngIf="!isExpanded(colItem.index)" (click)="expandToggle(colItem.index)" class="more-button">show more</button>
                  <button type="button" *ngIf="isExpanded(colItem.index)" (click)="expandToggle(colItem.index)" class="less-button">show less</button>
                </span>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

      </div>

      <div class="modal-body pt-4 pb-4 text-center" *ngIf="documentSourceService.sourceLoading">
        <app-loading-component></app-loading-component>
      </div>
    </div>
  </div>
</div>
