import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import { appuser } from '../models/appuser';
import {getRequestId} from './utils';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  loggedUser: appuser = null;
  private authKey = 'loggedUser1';
  private authStatusChange = new Subject<any>();

  constructor(private http: HttpClient) { 
   
  }

  loginAPI(login: string, password: string): Observable<any> {
    const data = new URLSearchParams();
    data.set('login_id', login);
    data.set('login_password', password);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' });
    const options = { withCredentials: true, headers };
    return this.http.post(environment.apiURL + 'Login' , data.toString(), options);
  }
  logoutAPI(): Observable<any> {
    return this.http.get(environment.apiURL + 'Logout', { withCredentials: true });
  }
  resetPasswordAPI(loginId: string): Observable<any> {
    const data = new URLSearchParams();
    data.set('request_id', getRequestId().toString());
    data.set('login_id', loginId);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' });
    const options = { withCredentials: true, headers };
    return this.http.post(environment.apiURL + 'ResetPassword' , data.toString(), options);
  }
  getAuthStatusChange(): Observable<any> {
    return this.authStatusChange.asObservable();
  }
  isLoggedIn(): boolean {
    if (this.loggedUser) {
      return true;
    }
    const storedUser = localStorage.getItem(this.authKey);
    if (storedUser) {
      const obj = JSON.parse(storedUser);
      this.loggedUser = obj;

      if (obj.expTime < (new Date()).getTime()) {
        localStorage.removeItem(this.authKey);
        return false;
      }
      this.setLoggedUser(obj.user);
      return obj.user;
    }
    return false;
  }
  clearLoggedUser(): void {
    this.loggedUser = null;
    this.authStatusChange.next(null);
    localStorage.removeItem(this.authKey);
  }
  setLoggedUser(user: any): void {
    if (user) {
      localStorage.setItem(this.authKey, JSON.stringify({
        user,
        expTime: (new Date()).getTime() + (5 * 60 * 60 * 1000)
      }));
      this.loggedUser = user;
      this.authStatusChange.next(this.loggedUser);
    }
  }


  changePassword(oldPassword: string, newPassword: string, confirmPassword: string): Observable<any> {
    const data = new URLSearchParams();
    data.set('request_id', getRequestId() + '');
    data.set('current_password', oldPassword);
    data.set('new_password', newPassword);
    data.set('confirm_password', confirmPassword);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' });
    const options = {withCredentials: true, headers};
    return this.http.post(environment.apiURL + 'ChangePassword' , data.toString(), options);
  }
}
