
  <div style="    display: block;
  text-align: center;">
  <h1>{{this.transcription}}</h1>
<div style="text-align:center;margin-top: 200px;   display: inline-flex;
width: auto;" >

<div style="display: inline-grid;">
    <button (click)="initiateRecording()" class="btn btn-primary" *ngIf="!recording" style="height: auto; cursor: pointer;background-color: green;color: white;font-size: 40px;"> Start Recording </button>
    <button (click)="stopRecording()" class="btn btn-danger" *ngIf="recording" style=" height: auto; cursor: pointer;background-color: red;color: white;font-size: 40px;"> Stop Recording </button>

  
    <audio controls="" *ngIf="url">
    <source [src]="sanitize(url)" type="audio/wav">
    </audio>

  </div>

  <div (click)="fileInput.click()" style="font-size: 40px;
  margin-left: 10px;
  opacity: 1;

  height: 75px;
  color: white;
  background: #535653;
  padding: 10px;
  padding-top: 12px;"   appDragDrop (onFileDropped)="uploadFile($event)">
   Upload File  <input hidden type="file" #fileInput accept=".mp3,audio/*" (change)="uploadFile($event.target.files)">

    <i class="fa fa-upload"></i>
  </div>
    </div>

  </div>

  <div class="spinner" >
  
  </div> 