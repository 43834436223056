import { Component, OnInit } from '@angular/core';
import { DashboardManagerService } from '../../Managers/dashboard-manager.service';
import { AggregatorManagerService } from '../../Managers/aggregator-manager.service';
import { dashboardItem, vizs, viz_type } from '../../models/dashboard';

import { HttpClient } from '@angular/common/http';
import * as D3 from 'd3';
import { PieArcDatum, VoronoiCell } from 'd3';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  showPopup: boolean = false;
  selecteditem: dashboardItem;

  private host: D3.Selection<any, any, any, any>;
  private svg: D3.Selection<any, any, any, any>;

  constructor(
    public dashboardManager: DashboardManagerService,
    public aggManager: AggregatorManagerService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.host = D3.select('#summaryResult');
    this.dashboardManager.dashboard_items;

    if (this.dashboardManager.viz_types.length == 0) {
      this.dashboardManager.fetchDashboard(
        this.aggManager.selectedAggregator.id
      );
    }
  }

  exportAsPDF() {
    var viz: vizs[] = [];
    this.dashboardManager.onExport.emit();
    let sumViz = this.CreatesummarySvg();
    viz.push(sumViz);

    setTimeout(() => {
      this.dashboardManager.dashboard_items.forEach((c) => {
        if (c.viz_svg && c.viz_type) {
          let vizItem = new vizs();
          vizItem.title = c.viz_type;
          vizItem.is_summary = false;
          vizItem.type = 'svg';
          vizItem.viz_svg = c.viz_svg;
          viz.push(vizItem);
        }
      });

      this.dashboardManager.ExportPdf(
        this.aggManager.selectedAggregator.id,
        this.aggManager.selectedAggregator.name,
        viz
      );
    }, 2000);
  }

  private CreatesummarySvg() {
    this.buildSVG();
    this.createColors();
    this.drawChart();
    const element = document.querySelector('#summaryResult svg');
    let sumViz = new vizs();
    sumViz.type = 'svg';
    sumViz.is_summary = true;
    sumViz.title = 'SummaryReport';
    sumViz.viz_svg =
      '<svg xmlns="http://www.w3.org/2000/svg" width="550" height="600"  viewBox="0 0 450 550"  > <g transform="translate(20,-56)" >' +
      element.innerHTML +
      '</g></svg>';

    return sumViz;
  }

  openPopup(item: dashboardItem) {
    this.selecteditem = item;
    this.showPopup = true;
  }

  closePopup() {
    this.showPopup = false;
  }

  closeInvite(item: dashboardItem, index: number) {
    this.dashboardManager.removeDashboardItem(item.dashboard_item_id);

    this.dashboardManager.viz_types.forEach((viz) => {
      if (viz.viz_uid === item.viz_uid) {
        viz.isEnabled = false;
        item.isEnabled = false;
      }
    });
  }

  private margin = 100;
  width: number = 500;
  height: number = 450;
  // The radius of the pie chart is half the smallest side
  private radius = Math.min(this.width, this.height) / 2 - this.margin;

  private buildSVG(): void {
    this.host.html('');
    this.svg = this.host
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height)
      .append('g')
      .attr(
        'transform',
        'translate(' + this.width / 3.6 + ',' + this.height / 2.5 + ')'
      )
      .style('overflow', 'visible');
  }

  private colors;
  private createColors(): void {
    this.colors = D3.scaleOrdinal()
      .domain(
        this.dashboardManager.summaryReport.map((d) => d.percent.toString())
      )
      .range(D3.schemePaired);
  }

  private drawChart(): void {
    const pie = D3.pie<any>().value((d: any) => Number(d.percent));

    var arcGenerator = D3.arc().innerRadius(0).outerRadius(this.radius);

    // Build the pie chart
    this.svg
      .selectAll('pieces')
      .data(pie(this.dashboardManager.summaryReport))
      .enter()
      .append('path')

      .attr(
        'd',
        D3.arc<PieArcDatum<any>>().innerRadius(0).outerRadius(this.radius)
      )

      .attr('fill', (d, i) => this.colors(i))
      .attr('stroke', '#9e9e9e')
      .style('stroke-width', '0.5px');

    // Add labels
    const labelLocation = D3.arc().innerRadius(100).outerRadius(this.radius);

    var legendG = this.svg
      .selectAll('.legend')
      .data(pie(this.dashboardManager.summaryReport))
      .enter()
      .append('g')
      .attr('transform', function (d, i) {
        return 'translate(' + (i * 95 - 185) + ',' + 150 + ')';
      })
      .attr('class', 'legend');

    legendG
      .append('rect')
      .attr('width', 10)
      .attr('height', 10)
      .attr('fill', (d, i) => this.colors(i));

    legendG
      .append('text')
      .text(function (d) {
        return (
          '(' +
          d.data.percent.toFixed(2) +
          '%) ' +
          d.data.source_name.substring(0, 12)
        );
      })
      .style('font-size', 10)
      .attr('y', 10)
      .attr('x', 12);
  }
}
