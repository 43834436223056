import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { UserManagerService } from '../../Managers/user-manager.service';
import { user } from '../../models/user';

import { AuthenticationService } from './../../services/authentication.service';
import {
  ConfirmDialogModel,
  ConfirmationDailogComponent,
} from '../../dailogs/confirmation-dailog/confirmation-dailog.component';
import { MatDialog } from '@angular/material/dialog';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css'],
})
export class UserListComponent implements OnInit {
  public displayedColumns: string[] = [
    'id',
    'name',
    'email',
    'state',
    'type',
    'actions',
  ];

  public showPasswordPopup: boolean = false;

  public passwordForm: FormGroup;
  public newpassword: FormControl;

  public passwordPattern: any;

  public showNewPassword: boolean = true;
  public dataSource: any;

  public offset: number = 0;
  public limit: number = 30;

  public length: number = 1;

  constructor(
    public router: Router,
    private FormBuild: FormBuilder,
    public userManager: UserManagerService,
    public dialog: MatDialog,
    public athenticateService: AuthenticationService
  ) {
    this.passwordPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@\-#\$%\^&\*])(?=.{10,})/;
  }

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit() {
    this.createFormControls();
    this.createForm();
    this.getUsers();
  }

  createForm() {
    this.passwordForm = this.FormBuild.group({
      newpassword: this.newpassword,
    });
  }

  createFormControls() {
    this.newpassword = new FormControl('', [
      Validators.required,
      Validators.pattern(this.passwordPattern),
    ]);
  }

  editUser(user: user) {
    this.userManager.selectedUser = user;
    this.router.navigate(['users/edituser']);
  }

  AddUser() {
    this.userManager.selectedUser = null;
    this.router.navigate(['users/edituser']);
  }

  changePagesize(event?: PageEvent) {
    this.limit = event.pageSize;
    this.offset = event.pageIndex * event.pageSize;

    this.getUsers();
  }

  changePassword() {
    this.userManager.resetUser(this.selecteduser.email, this.newpassword.value);
    this.newpassword.setValue('');
    this.showPasswordPopup = false;
  }

  closepopup() {
    this.showPasswordPopup = false;
  }

  getUsers() {
    this.userManager.getUsers(this.offset, this.limit);
    /*   setTimeout(() => {

      this.length = this.userManager.total_user_result;
      this.dataSource = new MatTableDataSource<user>(this.userManager.users);


    }, 1000);*/
  }

  result: any;
  selecteduser: user;
  confirmResetPassword(user: user) {
    const message =
      `Are you sure you want reset password for ` + user.name + ` ?`;

    const dialogData = new ConfirmDialogModel('Confirm Action', message);

    const dialogRef = this.dialog.open(ConfirmationDailogComponent, {
      maxWidth: '400px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      this.result = dialogResult;

      if (this.result) {
        this.selecteduser = user;
        this.resetPassword(user.email);
      }
    });
  }

  resetPassword(email) {
    this.showPasswordPopup = true; // this.userManager.resetUser(id);
  }

  confirmDeleteDialog(user: user) {
    const message = `Are you sure you want delete ` + user.name + ` ?`;

    const dialogData = new ConfirmDialogModel('Confirm Action', message);

    const dialogRef = this.dialog.open(ConfirmationDailogComponent, {
      maxWidth: '400px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      this.result = dialogResult;

      if (this.result) {
        this.removeUser(user.id);
      }
    });
  }

  removeUser(id) {
    this.userManager.removeUser(id, this.offset, this.limit);
  }
}
