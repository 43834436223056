import { Component, enableProdMode, Inject } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { DOCUMENT } from '@angular/common';
import { isDevMode } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'info-fe';
  private CONF_PATH = 'assets/conf/';
  constructor(public http: HttpClient, @Inject(DOCUMENT) private document) {
    //console.log('Dev Mode: ' + isDevMode());
    console.log('devMode: ', environment.devMode);
    let request: any = null;
    if (isDevMode()) {
      // environment.devMode = true;
      request = this.http.get(this.CONF_PATH + 'conf.development.json');

      request.subscribe((c) => {
        console.log(c);
        environment.apiURL = c.apiURL;
        // environment.courpus_API = c.courpus_API;
      });
    } else {
      /* Bellow code was use to get configuration information of APIURL from configuration file in asset folder*/

      //request = this.http.get(this.CONF_PATH + 'conf.pproduction.json');

      //this code is useed to hardcorde that server is devmode server

      const baseUrl = this.document.location;
      console.log(baseUrl.origin);

      environment.apiURL = baseUrl.origin + '/InfosiphonWS/';

      if (baseUrl.hostname == 'dev.infosiphon.com') {
        environment.devMode = true;
      } else {
        environment.devMode = false;
      }

      request = this.http.get(this.CONF_PATH + 'conf.production.json');

      request.subscribe((c) => {
        console.log(c);

        environment.courpus_API = c.courpus_API;

        console.log(environment);
      });
    }

    /* request.subscribe(c=>{
 
    
 
       environment.apiURL = c.apiURL;
       environment.courpus_API =c.courpus_API;
           }); */
  }
}
