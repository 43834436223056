<div class="d-flex h-100">
  <div
    class="theme-shadow-vertical nav-vertical h-100 nav-vertical d-flex flex-column justify-content-start align-items-center">

    <div class="mb-5 mt-3">


      <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" style="max-height: 25px;" max-height="25px;"
        viewBox="0 0 128 96">
        <defs>
          <style>
            .cls-1header {
              fill: url(#infosiphon_gradient);
            }
          </style>
          <linearGradient id="infosiphon_gradient" x1="-0.87" y1="14.58" x2="120.2" y2="56.14"
            gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#6214f4" />
            <stop offset="1" stop-color="#082caf" />
          </linearGradient>
        </defs>
        <title>infosiphon_logo</title>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_5" data-name="Layer 5">
            <polygon class="cls-1header"
              points="64.95 96 42.68 62.83 75.73 11.55 25.43 12 43.85 38.33 35.26 51.77 0 0.07 97.9 0 58.63 61.58 66.1 72.59 112.31 0 128 0 64.95 96" />
          </g>
        </g>
      </svg>
    </div>

    <a class="mb-4 icon" matTooltip="Home" [routerLink]="['']" routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }">
      <i class="fa fa-home"></i>
    </a>



    <a class="mb-4 icon" matTooltip="Users" [routerLink]="['users']"
      *ngIf="(this.aggrManager?.selectedAggregator == null) && !demoService.demoMode" routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }">
      <i class="fa fa-user"></i>
    </a>
    <!-- <a class="mb-4 icon"
    matTooltip="voice recorder"
    [routerLink]="['audiorecorder']" *ngIf="!demoService.demoMode && devMode"
    routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
  
   <i class="fas fa-microphone-alt"></i>
 </a> -->



    <a class="mb-4 icon" matTooltip="Dashboard" [routerLink]="['dashboard']"
      *ngIf="this.aggrManager?.selectedAggregator != null" routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }">
      <i class="fa fa-desktop" aria-hidden="true"></i>
    </a>

    <a class="mb-4 icon cursor-link" matTooltip="Visualization" *ngIf="this.aggrManager?.selectedAggregator != null"
      (click)="showVisualizationMenu = true" [class.active]="isVisualizationLinkActive() || showVisualizationMenu">
      <i class="fa fa-chart-bar"></i>
    </a>


    <a class="mb-4 icon " matTooltip="Structured Analytics" *ngIf="this.aggrManager?.selectedAggregator != null"
      [routerLink]="['/csvVisualization']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <i class="fa fa-file-archive"></i>
    </a>


    <a class="mb-4 icon" matTooltip="Sentiment Analysis" *ngIf="this.aggrManager?.selectedAggregator != null"
      [routerLink]="['sentiment_analysis']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <i class="fa fa-smile-o"></i>
    </a>

    <a class="mb-4 icon" matTooltip="Info Graph" [routerLink]="['infograph']"
      *ngIf="this.aggrManager?.selectedAggregator != null" routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }">
      <i class="fa fa-database"></i>
    </a>


    <a class="mb-4 icon" matTooltip="Image Analytics" *ngIf="this.aggrManager?.selectedAggregator != null"
      [routerLink]="['/visualization/cvPipeline']" routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }">

      <i class="fas fa-images"></i>
    </a>




    <a class="mb-4 icon" matTooltip="Speech Analytics" [routerLink]="['speechrecognition']"
      *ngIf="!demoService.demoMode && this.aggrManager?.selectedAggregator != null" routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }">

      <i class="fas fa-file-audio"></i>
    </a>







    <a class="mb-4 icon" matTooltip="Details"
      *ngIf="(this.aggrManager?.selectedAggregator != null) && !demoService.demoMode" [routerLink]="['search']"
      routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <i class="fas fa-th-large"></i>
    </a>



    <a class="mb-4 icon" matTooltip="History"
      *ngIf="(this.aggrManager?.selectedAggregator != null) && !demoService.demoMode" [routerLink]="['history']"
      routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <i class="fa fa-history"></i>
    </a>

    <a class="mb-4 icon" matTooltip="Settings"
      *ngIf="(this.aggrManager?.selectedAggregator != null) && !demoService.demoMode" [routerLink]="['setting']"
      routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <i class="fa fa-cog"></i>
    </a>

    <a class="mb-4 icon" *ngIf="!demoService.demoMode" matTooltip="Change Password" [routerLink]="['/changepassword']"
      routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <i class="fas fa-key"></i>
    </a>

    <a class="mb-4 icon" *ngIf="!demoService.autoLogin" matTooltip="Logout" (click)="ConfirmLogout()"
      routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <i class="fa fa-sign-out-alt"></i>
    </a>
  </div>

  <div class="w-100 bg-light position-relative">
    <div
      class="d-flex justify-content-between align-items-center px-2 theme-shadow-horizontal bg-white w-100 nav-horizontal">

      <h3 class="ml-2 mt-2 ml-3 text-style-h3 text-color-dark"
        [style.color]="this.athenticateService.loggedUser.themeColor">{{this.athenticateService.loggedUser.title}}</h3>

      <div class="d-flex flex-row justify-content-center align-items-center">

        <form (ngSubmit)="callSearch(visualizationManagerService.searchText)" class="form-inline my-2 my-lg-0 mr-auto">
          <input type="search" name="search" [(ngModel)]="visualizationManagerService.searchText"
            class="form-control form-control-sm mr-2" placeholder="Search" #ctrl="ngModel" required>
          <button class="btn btn-primary btn-sm rounded-pill px-3" [disabled]="ctrl.invalid" type="submit"><i
              class="fa fa-search"></i></button>
        </form>

        <div class="nav-item" *ngIf="!visualizationManagerService.searchKey">
          <button data-toggle="tooltip" data-placement="bottom" (mousedown)="enableVoiceCommands()"
            (mouseup)="disableVoiceCommands()" (mouseleave)="disableVoiceCommands()"
            [title]="visualizationManagerService.searchByVoice ? 'Search By Text' : 'Search By Voice'"
            class="btn btn-sm mx-2 px-2 py-2 btn-no-edges">
            <i class="fa same-width" [class.mic-blinking]="visualizationManagerService.searchByVoice"
              [ngClass]="visualizationManagerService.searchByVoice ? 'fa-microphone': 'fa-microphone-slash'"></i>
          </button>
        </div>
        <div class="nav-item position-relative">
          <div class="search-actions"
            *ngIf="visualizationManagerService.searchEnabled && visualizationManagerService.searchKey">
            <button data-toggle="tooltip" data-placement="bottom" (click)="clearSearch()" title="Clear Search"
              class="nav-link btn"><i class="fa fa-times"></i></button>

            <small class="nav-link" [style.color]="visualizationManagerService.searchKey? 'Orange' : 'Blue'">{{
              visualizationManagerService.searchKey ? 'Search Term: ' + visualizationManagerService.searchKey :''
              }}</small>
          </div>
          <!-- <div class="seraching" *ngIf="searching">Searching...</div> -->
        </div>



      </div>


      <div style="margin-right: 300px;">
        <span class="p1-bolder" *ngIf=" this.aggrManager.selectedAggregator">{{ this.aggrManager.selectedAggregator.name
          }}
          ({{this.aggrManager.selectedAggregator.total_results}})</span>
        <a class=" ml-2 switch  w-auto mr-1" *ngIf="(this.aggrManager.selectedAggregator) && !demoService.demoMode"
          (click)="confirmExecuteDialog(this.aggrManager.selectedAggregator)">Execute</a>

      </div>
      <div class="mr-5 d-flex align-items-center">
        <button class="  switch" *ngIf=" this.aggrManager.selectedAggregator" (click)="switch()">Switch</button>
        <p *ngIf="!demoService.demoMode" class="mb-0">{{this.athenticateService.loggedUser.userName}}</p>
      </div>

    </div>

    <div class="scrolldiv" [style.padding]="checkInfographActive() ? '1px 1px': '32px 52px'"
      style="height: 100%;overflow: auto;">
      <router-outlet></router-outlet>
    </div>
  </div>

  <div class="visualization-container" *ngIf="showVisualizationMenu" (click)="showVisualizationMenu = false">
    <div class="visualization-menu-box bg-white theme-shadow-visualization"
      (click)="visualizationInnerClickAction($event)">
      <h3 class="text-style-h3 mt-5 ml-4 text-color-dark">Visualizations</h3>
      <hr>
      <div class="d-flex flex-column">
        <!--  <a class="visualization-link" [routerLink]="['/visualization/sentiments']" routerLinkActive="active">
            <span class="text-style-p1 text-color-dark ml-4">Sentiments</span></a> -->

        <a class="visualization-link" [routerLink]="['/visualization/pack']" routerLinkActive="active">
          <span class="text-style-p1 text-color-dark ml-4">Packed Layout</span></a>

        <a class="visualization-link" [routerLink]="['/visualization/sunburst']" routerLinkActive="active">
          <span class="text-style-p1 text-color-dark ml-4">Sunburst</span></a>

        <a class="visualization-link" [routerLink]="['/visualization/tree-map']" routerLinkActive="active">
          <span class="text-style-p1 text-color-dark ml-4">Tree Map</span></a>

        <a class="visualization-link" [routerLink]="['/visualization/force']" routerLinkActive="active">
          <span class="text-style-p1 text-color-dark ml-4">Directed Force</span></a>

        <a class="visualization-link" [routerLink]="['/visualization/tree']" routerLinkActive="active">
          <span class="text-style-p1 text-color-dark ml-4">Collapsible Tree</span></a>

        <a class="visualization-link" [routerLink]="['/visualization/bubble-chart']" routerLinkActive="active">
          <span class="text-style-p1 text-color-dark ml-4">Bubble Chart</span></a>
        <a class="visualization-link" [routerLink]="['/visualization/indent-tree']" routerLinkActive="active">
          <span class="text-style-p1 text-color-dark ml-4">Indent Tree</span></a>










      </div>
    </div>
  </div>
</div>

<ngx-spinner bdColor="rgba(255,255,255,0.99)" size="medium" color="#5273ff" type="timer" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>


<ng-template #content let-modal style=" z-index: 999999999999999999;">
  <div class="modal-header">
    <h4 class="modal-title">Error</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{this.errorService.Error}}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
  </div>
</ng-template>