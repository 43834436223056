import {Component, OnDestroy, OnInit} from '@angular/core';
import * as c3 from 'c3/c3';
import { VisualizationManagerService } from '../../../Managers/visualization-manager.service';
import { addContextMenu, waitInMS } from '../../../services/utils';

const d3: any = addContextMenu();

@Component({
  selector: 'app-sentiments',
  templateUrl: './sentiments.component.html',
  styleUrls: ['./sentiments.component.css']
})
export class SentimentsComponent implements OnInit, OnDestroy {
  private hook = null;
  sentimentByScore: any = null;
  colorFunc = null;
  constructor(public visualizationManagerService: VisualizationManagerService) { }
  async ngOnInit(): Promise<any> {
    if (this.visualizationManagerService.sentimentsData) {
    //  await waitInMS(150);
      this.createChart();
    }
    this.hook = this.visualizationManagerService.visualizingInit.subscribe(async (event) => {
      await waitInMS(150);
      this.createChart();
    });
  }
  ngOnDestroy(): void {
    if (this.hook) {
      this.hook.unsubscribe();
    }
  }
  sortByAverage(sources: [any]): [any] {
    return sources.sort((a, b) => b.sentimentAverage - a.sentimentAverage);
  }
  createChart(): void {
    if (this.visualizationManagerService.isLoading) { return; }
    const sentimentDivisionCHart = c3.generate({
      bindto: '#sentimentPieChart',
      pie: {
        expand: true
      },
      // title: {
      //   text: 'Sentiment Division',
      //   position: 'top-left',   // top-left, top-center and top-right
      // },
      data: {
        // iris data from R
        columns: this.visualizationManagerService.sentimentsData[0],
        type : 'pie',
        colors: {
          Positive: '#1ed6bb',
          Negative: '#d61e57',
          Neutral: '#6214f4'
        }
      }
    });
    const sentimentCountCHart = c3.generate({
      bindto: '#sentimentCountValueChart',
      // title: {
      //   text: 'Sentiment Count / Value',
      //   position: 'top-left',   // top-left, top-center and top-right
      // },
      data: {
        // iris data from R
        columns: this.visualizationManagerService.sentimentsData[1],
        type : 'bar',
        color: (color, d) => {
          const id = d.id ? d.id : d;
          switch (id) {
            case '-9':
              return '#B7005A';
            case '-8':
              return '#AA0369';
            case '-7':
              return '#9D0678';
            case '-6':
              return '#900888';
            case '-5':
              return '#830B97';
            case '-4':
              return '#750EA6';
            case '-3':
              return '#6811B5';
            case '-2':
              return '#5B13C5';
            case '-1':
              return '#4E16D4';
            case '0':
              return '#4119E3';
            case '1':
              return '#3D2EDF';
            case '2':
              return '#3943DA';
            case '3':
              return '#3558D6';
            case '4':
              return '#316DD1';
            case '5':
              return '#2E82CD';
            case '6':
              return '#2A97C8';
            case '7':
              return '#26ACC4';
            case '8':
              return '#22C1BF';
            case '9':
              return '#1ED6BB';
          }
          return '#fff55a';
        }
      },
      labels: true,
      axis: {
        rotated: false,
        x: {
          label: {
            text: 'Score',
            position: 'outer-left',
          },
          type: 'indexed',
          tick: {
            centered: true
          }
        },
        y: {
          show: true,
          label: {
            text: 'Count',
            position: 'outer-middle'
          },
          tick: {
            format: d3.format('.1s')
          }
        }
      },
      grid: {
        x: {
          show: false
        },
        y: {
          show: false,
          lines: [
            {value: 0, text: '', position: 'end'}
          ]
        }
      },
      bar: {
        space: 0.45,
        width: {
          ratio: 0.9
        }
      }
    });


    const averageSentimentScore = c3.generate({
      bindto: '#sentimentAverageScoreChart',
      // title: {
      //   text: 'Average Sentiment Score / History',
      //   position: 'top-right',   // top-left, top-center and top-right
      // },
      point: { r: 3 },
      data: {
        x: 'x',
        columns: [
          ['x', ...this.visualizationManagerService.sentimentsData[2].categories],
          this.visualizationManagerService.sentimentsData[2].columns,
          this.visualizationManagerService.sentimentsData[3].columns
        ],
        labels: false,
        type: 'bar',
        types: {
          'Smoothened Score': 'spline'
        },
        empty: {
          label: {
            text: 'No Data'
          }
        },
        selection: {
          enabled: true,
          draggable: true
        },
        colors: {
          'Smoothened Score': '#082caf',
          'Average Score': '#1ed6bb'
        }
      },zoom:{
        enabled:true
      },
      subchart: {
        show: true,
        axis: {
          x: {
            show: false
          }
        }
    },
      axis: {
        x: {
          label: {
            text: 'Executions',
            position: 'inner-center'
          },
          type: 'category',
          tick: {
            format: '%Y-%m-%d'
          }
        },
        y: {
          label: {
            text: 'Score',
            position: 'outer-middle'
          },
          tick: {
            format: d3.format('.1f')
          }
        },
        y2: {
          show: false,
          label: 'Score'
        }
      },
      grid: {
        x: {
          show: false
        },
        y: {
          show: false,
          lines: [
            {value: 0, text: '', position: 'end'}
          ]
        }
      },
      bar: {
        space: 0.1,
        width: {
          ratio: 0.85 // this makes bar width 50% of length between ticks
        }
      }
    });

    averageSentimentScore.zoom([94, 100]);


    this.sentimentByScore = this.visualizationManagerService.sentimentsData[4];
    if (this.sentimentByScore.sources) {
      this.colorFunc = d3.scaleOrdinal(this.sentimentByScore.sources.map(d => d.source))
        .range(['#354FB0', '#fabf1b', '#1ED6BB',
          '#6214f4', '#d61e57', '#1464f4',
          '#0093d5', '#949bff', '#ab6dff', '#ff0e72',
          '#0b80b0',
          '#00ac59', '#488f31', '#00ac59', '#83af70',
          '#c4a0ec',
          '#082CAF',
          '#98af8f', '#778271', '#8e8e8e', '#9f8888',
          '#f0b8b8',
          '#ec9c9d', '#e67f83', '#de6069', '#de425b',
          '#a47b80']);
    }

    const colors = {};
    for (const source of this.visualizationManagerService.sentimentsData[4].sources) {
      colors[source.source] = this.colorFunc(source.source);
    }

    const sentimentBySourceChart = c3.generate({
      bindto: '#sentimentBySourceChart',
      // title: {
      //   text: 'Average Sentiment Score / History',
      //   position: 'top-right',   // top-left, top-center and top-right
      // },
      point: {
        r: 3
      },
      data: {
        x: 'x',
        columns: [ ['x', ...this.visualizationManagerService.sentimentsData[4].sources[0]
          .sentimentHistory.map(i => (new Date(i.time)).toISOString().substring(0, 10))],
          ...this.visualizationManagerService.sentimentsData[4]
          .sources.map(i => ([i.source, ...i.sentimentHistory.map(ini => ini.average)]))],
        labels: false,
        type: 'spline',
        // types: {
        //   'Smoothened Score': 'spline'
        // },
        empty: {
          label: {
            text: 'No Data'
          }
        },
        selection: {
          enabled: true,
          draggable: true
        },
        colors
      },zoom:{
        enabled:true
      },
      subchart: {
        show: true,
        axis: {
          x: {
            show: false
          }
        }
    },
      axis: {
        x: {
          label: {
            text: 'Executions',
            position: 'inner-center'
          },
          // type: 'category',
          type: 'category',
          tick: {
            format: '%Y-%m-%d'
          },
          // categories: this.visualizationManagerService.sentimentsData[4]
          //   .sources[0].sentimentHistory.map(i => (new Date(i.time)).toISOString().substring(0, 10)),
          // tick: {
          //   count: 4
          // }
        },
        y: {
          label: {
            text: 'Sentiment',
            position: 'outer-middle'
          },
          // tick: {
          //   format: d3.format('.1f')
          // }
        },
        y2: {
          show: false,
          label: 'Score'
        }
      },
      grid: {
        x: {
          show: false
        },
        y: {
          show: false,
          lines: [
            {value: 0, text: '', position: 'end'}
          ]
        }
      },
      // bar: {
      //   space: 0.1,
      //   width: {
      //     ratio: 0.85 // this makes bar width 50% of length between ticks
      //   }
      // }
    });

    sentimentBySourceChart.zoom([94, 100]);

  }

}
