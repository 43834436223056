<div class="container-fluid" *ngIf="!visualizationManagerService.isLoading">
  <div class="row">

    <div class="col-8"><div class="chart-card p-3">
      <h4 class="text-style-p1 text-color-dark font-weight-bold m-2">Sentiment Count/Value</h4>
      <div class="chart-dimensions" id="sentimentCountValueChart"></div> </div>
    </div>

    <div class="col-4"><div class="chart-card p-3">
      <h4 class="text-style-p1 text-color-dark font-weight-bold m-2">Sentiment Division</h4>
      <div class="chart-dimensions" id="sentimentPieChart"></div></div>
    </div>

    <div class="col-4">
      <div class="chart-card p-3" *ngIf="sentimentByScore">
        <h4 class="text-style-p1 text-color-dark font-weight-bold m-2">Sentiment by Source</h4>
        <div class="chart-dimensions d-flex flex-column align-items-stretch m-2" >

          <div class="d-flex flex-row mt-3">
            <div class="w-50" *ngIf="sentimentByScore.mostPositiveSource">
              <div class="text-style-p1 text-color-dark-light">Most Positive</div>
              <div class="text-style-h2 text-color-dark mt-2 mb-1">
                {{ sentimentByScore.mostPositiveSource.source }}</div>
              <span class="source-header-bottom" [style.borderTopColor]="colorFunc(sentimentByScore.mostPositiveSource.source)">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </div>
            <div class="w-50" *ngIf="sentimentByScore.mostNegativeSource">
              <div class="text-style-p1 text-color-dark-light">Most Negative</div>
              <div class="text-style-h2 text-color-dark mt-2 mb-1">
                {{ sentimentByScore.mostNegativeSource.source }}</div>
              <span class="source-header-bottom" [style.borderTopColor]="colorFunc(sentimentByScore.mostNegativeSource.source)">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </div>
          </div>

          <div class="d-flex flex-fill overflow-auto flex-column mt-3">

            <div class="d-flex flex-row my-2 align-items-center" *ngFor="let source of sortByAverage(sentimentByScore.sources)">
              <div class="w-50 d-flex align-items-center">
                <i class="fa fa-square-full" [style.color]="colorFunc(source.source)"></i>
                <span class="ml-2 text-style-p1 text-color-dark-light">{{ source.source }}</span>
              </div>
              <div class="w-50 d-flex flex-row justify-content-between align-items-center">
                <span class="text-style-p1 text-color-dark-light pr-3">{{ source.analysed | number: '1.0-0' }} Analyzed</span>
                <span class="text-style-p1 text-color-dark-light pr-3">{{ source.sentimentAverage | number: '1.1-1' }}</span>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>

    <div class="col-8"><div class="chart-card p-3">
      <h4 class="text-style-p1 text-color-dark font-weight-bold m-2">Sentiment by Source/History</h4>
      <div class="chart-dimensions" id="sentimentBySourceChart"></div></div>
    </div>

    <div class="col-12"><div class="chart-card p-3">
      <h4 class="text-style-p1 text-color-dark font-weight-bold m-2">Smoothened vs Average Sentiments Score/History</h4>
      <div class="chart-dimensions" id="sentimentAverageScoreChart"></div></div>
    </div>



    <br>
    <br>
    <br>
    <p>.</p>

  </div>
</div>
