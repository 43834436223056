import { Injectable } from '@angular/core';
import { AggregatorsService } from '../services/aggregators.service';
import { CloudData } from 'angular-tag-cloud-module';
import {
  aggregatorSummary,
  search,
  aggregator,
  aggregator_search_term,
  website_aggregator,
  document_aggregator,
  aggregator_exclude_filters,
  document,
  schedule_types,
  aggregator_confluence_text,
  spaces,
} from '../models/aggregator';
import { file, object_ids } from '../models/speachrecognition';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorService } from '../services/error.service';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { csvVisualizer, tag } from '../models/csvVisualizer';
import { HttpClient } from '@angular/common/http';
import { text } from 'd3';
import { ResetManagerService } from './reset-manager.service';
import { VisualizationManagerService } from './visualization-manager.service';
import { DashboardManagerService } from './dashboard-manager.service';
import { DataMangerService } from './data-manger.service';
import { of } from 'rxjs';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable({
  providedIn: 'root',
})
export class AggregatorManagerService {
  public speechAnalyticsObjectIds: object_ids[] = [];
  public filesSpeech: file[] = [];
  // tslint:disable-next-line:max-line-length
  constructor(
    public router: Router,
    private resetManager: ResetManagerService,
    private dataManager: DataMangerService,
    private aggregatorsService: AggregatorsService,
    private spinner: NgxSpinnerService,
    private errorService: ErrorService,
    private httpClient: HttpClient,
    private notification: NzNotificationService
  ) {
    const storedAggregator = localStorage.getItem(this.selectedAggregatorKey);

    if (storedAggregator) {
      this.selectedAggregator = JSON.parse(storedAggregator);
      setTimeout(() => {
        this.dataManager.fetchSelectedAggregatorData(
          this.selectedAggregator.id
        );
      }, 1000);

      this.getCSVStatisiticAggregator();
    }
  }

  public selectedAggregator: aggregatorSummary = null;
  public dataSource: any;

  public schedule_types: schedule_types[];

  public offset = 0;
  public limit = 25;
  public aggregators: aggregatorSummary[] = [];
  public aggregatorSetting: aggregator;
  // tslint:disable-next-line:variable-name
  public total_aggregator_result: number;

  // tslint:disable-next-line:variable-name
  aggregator_interval_list = new Map<number, any>();

  public websites: website_aggregator[] = [];
  public documentURLs: document_aggregator[] = [];

  public confluenceterms: spaces[] = [];
  public searchterms: aggregator_search_term[] = [];
  public searchtermsSummary: aggregator_search_term[] = [];

  public filters: aggregator_exclude_filters[] = [];

  public documents: document[] = [];

  private selectedAggregatorKey = 'selectedAggregator';
  public selectedObjectId = null;
  public summaryReport: any;

  reset(): void {
    this.aggregators = [];
    this.websites = [];
    this.documentURLs = [];
    this.searchterms = [];
    this.filters = [];
    this.documents = [];
    this.confluenceterms = [];
    this.selectedAggregator = null;
    this.clearAllInterval();
  }

  getSelectedAggregatorInfo(id): void {
    this.aggregatorsService.fetchAggregatorInfo(id).subscribe((c) => {
      this.selectedAggregator = c.aggregator;
    });
  }

  public getAggregators(refresh?: boolean): void {
    this.spinner.show();
    this.aggregatorsService
      .getAggregatorList(this.offset, this.limit)
      .subscribe((c: any) => {
        this.total_aggregator_result = c.total_aggregators;
        c.aggregators.forEach((element) => {
          const filter = this.aggregators.filter((cIn, index) => {
            // tslint:disable-next-line:triple-equals
            return cIn.id == element.id;
          });
          // tslint:disable-next-line:triple-equals
          if (filter.length == 0) {
            this.aggregators.push(element);
          }
        });

        this.aggregators.sort(
          (a, b) =>
            new Date(b.created).getTime() - new Date(a.created).getTime()
        );

        this.dataSource = new MatTableDataSource<aggregatorSummary>(
          this.aggregators
        );

        this.spinner.hide();
      });
  }

  public ExecuteAggregator(currAggregator): void {
    this.aggregatorsService.ExecuteAggregator(currAggregator.id).subscribe(
      (c) => {
        this.executeAggregator(currAggregator);
      },
      (err) => {
        this.notification.create('error', err.error.message, '');
      }
    );
  }

  executeAggregator(agg: aggregatorSummary): void {
    const id = setInterval(() => this.fetchAggregatorInfo(agg, this), 1000);
    this.aggregator_interval_list.set(agg.id, id);
  }

  // tslint:disable-next-line:variable-name
  private fetchAggregatorInfo(agg: any, _this): void {
    this.aggregatorsService.fetchAggregatorInfo(agg.id).subscribe((c) => {
      agg = c.aggregator;

      this.aggregators.forEach((element) => {
        // tslint:disable-next-line:triple-equals
        if (element.id == c.aggregator.id) {
          //  element.comment_count = c.aggregator.comment_count;
          // element.conversed_count = c.aggregator.conversed_count;
          element.enable_aggregator_processed_alert =
            c.aggregator.enable_aggregator_processed_alert;
          element.flagged_count = c.aggregator.flagged_count;
          element.last_executed = c.aggregator.last_executed;

          element.name = c.aggregator.name;
          element.schedule_type = c.aggregator.schedule_type;
          element.scheduled_for = c.aggregator.scheduled_for;
          element.state = c.aggregator.state;
          element.total_results = c.aggregator.total_results;
          if (
            this.selectedAggregator != undefined &&
            this.selectedAggregator.id == element.id
          )
            this.selectedAggregator.total_results = c.aggregator.total_results;
        }
      });

      if (
        !(
          c.aggregator.state !== 'Done' &&
          c.aggregator.state !== 'Scheduled' &&
          c.aggregator.state !== 'Awaiting'
        )
      ) {
        clearInterval(this.aggregator_interval_list.get(c.aggregator.id));
      }
    });
    return agg;
  }

  clearAllInterval(): void {
    this.aggregator_interval_list.forEach((value, key) => {
      clearInterval(value);
    });
  }

  getOrganizationInfo() {
    this.aggregatorsService.getOrganizationInformation().subscribe((c) => {
      this.schedule_types = c['organization_info'][
        'schedule_types'
      ] as schedule_types[];
    });
  }

  // tslint:disable-next-line:variable-name
  public DeleteAggregator(aggregator_id): void {
    this.spinner.show();
    this.aggregatorsService.DeleteAggregator(aggregator_id).subscribe(
      (c) => {
        this.aggregators.forEach((cd, index) => {
          // tslint:disable-next-line:triple-equals
          if (cd.id == aggregator_id) {
            this.aggregators.splice(index, 1);
            this.notification.create('success', 'Deleted Successfully', '');
          }
        });
        this.spinner.hide();
      },
      (err) => {
        this.notification.create('error', err.error.message, '');
      }
    );
  }

  // tslint:disable-next-line:variable-name
  public SetAggregatorUserAlert(
    aggregator_id,
    enable_aggregator_processed_alert
  ): void {
    this.spinner.show();
    this.aggregatorsService
      .SetAggregatorUserAlert(aggregator_id, enable_aggregator_processed_alert)
      .subscribe((c) => {
        this.spinner.hide();
      });
  }

  // tslint:disable-next-line:variable-name
  public AddAggregator(aggregator_name): void {
    this.spinner.show();
    this.aggregatorsService.AddAggregator(aggregator_name).subscribe(
      (c: any) => {
        this.getAggregators();

        this.selectedAggregator = new aggregatorSummary();
        this.selectedAggregator.id = c.aggregator_id;
        this.selectedAggregator.name = aggregator_name;

        this.router.navigate(['setting']);
        this.spinner.hide();
      },
      (err) => {
        this.notification.create('error', err.error.message, '');
      }
    );
  }

  public getAggregatorSetting(): any {
    this.spinner.show();
    this.aggregatorSetting = null;
    return this.aggregatorsService
      .getAggregator(this.selectedAggregator.id)
      .subscribe(
        (c) => {
          this.aggregatorSetting = c.aggregator;
          //  this.document_aggregator_id = c.aggregator.document_aggregator_id;
          this.websites =
            this.aggregatorSetting.website_aggregator.website_aggregator_links;
          this.documentURLs =
            this.aggregatorSetting.document_aggregator.document_aggregator_links;
          this.filters = this.aggregatorSetting.aggregator_exclude_filters;
          this.searchterms = this.aggregatorSetting.aggregator_search_terms;
          this.documents =
            this.aggregatorSetting.document_aggregator.document_collections;
          this.confluenceterms =
            this.aggregatorSetting.confluence_aggregator.confluence_aggregator_spaces;
        },
        (error) => {
          this.spinner.hide();
          this.errorService.showError(error.error.message);
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  public getAggregatorSearchTerms(id): any {
    return this.aggregatorsService.getAggregator(id).subscribe(
      (c) => {
        this.searchtermsSummary = c.aggregator.aggregator_search_terms;
        // console.log(c.aggregator.aggregator_search_terms);
      },
      (error) => {
        this.spinner.hide();
        this.errorService.showError(error.error.message);
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  public async AggregatorSpeechAnalyticsObjectIds(): Promise<any> {
    this.spinner.show();
    return this.aggregatorsService
      .getAggregatorSpeechAnalyticsObjectIds(this.selectedAggregator.id)
      .subscribe(
        (c) => {
          this.speechAnalyticsObjectIds = c['object_ids'];
          if (c['object_ids'].length > 0) {
            this.selectedObjectId = c['object_ids'][0]['_id'];
          }
        },
        (error) => {
          this.spinner.hide();
          this.errorService.showError(error.error.message);
        },
        () => {
          this.spinner.hide();
        }
      );
  }
  public GetAggregatorSpeechAnalytics(object_id): any {
    this.spinner.show();
    return this.aggregatorsService
      .GetAggregatorSpeechAnalytics(object_id, this.selectedAggregator.id)
      .subscribe(
        (c) => {
          this.filesSpeech.push(Object(c)['analytics']);
        },
        (error) => {
          this.spinner.hide();
          this.errorService.showError(error.error.message);
        },
        () => {
          this.spinner.hide();
        }
      );
  }
  getFiles() {
    return of(this.filesSpeech);
  }

  public update_sentence(aggregatorName, _id, type, start, innerText): any {
    this.spinner.show();
    return this.aggregatorsService
      .update_sentence(aggregatorName, _id, type, start, innerText)
      .subscribe(
        (c) => {
          this.spinner.hide();
        },
        (err) => {
          this.notification.create('error', err.error.message, '');
        }
      );
  }
  public update_speaker(aggregatorName, _id, speaker, speakerNewName): any {
    this.spinner.show();
    return this.aggregatorsService
      .update_speaker(aggregatorName, _id, speaker, speakerNewName)
      .subscribe(
        (c) => {
          this.spinner.hide();
        },
        (err) => {
          this.notification.create('error', err.error.message, '');
        }
      );
  }

  public EditAggregator(name, type, year, month, day): void {
    this.spinner.show();
    this.aggregatorsService
      .EditAggregator(this.selectedAggregator.id, name, type, year, month, day)
      .subscribe(
        (c) => {
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
        }
      );
  }

  public UploadFile(document_aggregator_id, files: File[]): void {
    this.aggregatorsService
      .addDocumentCollection(document_aggregator_id, files)
      .subscribe(
        (c) => {
          this.documents.push(
            new document(c.document_collection_id, files[0].name)
          );
        },
        (err) => {
          this.notification.create('error', err.error.message, '');
        }
      );
  }

  public RemoveFile(document_id) {
    this.aggregatorsService.removeDocument(document_id).subscribe(
      (c) => {
        this.documents.forEach((d, index) => {
          if (d.id == document_id) {
            this.documents.splice(index, 1);
          }
        });
      },
      (error) => {
        this.errorService.showError(error.error.message);
      }
    );
  }

  public RemoveSearchTerm(aggregator_search_term_id) {
    this.spinner.show();
    this.aggregatorsService
      .RemoveSearchTerm(aggregator_search_term_id)
      .subscribe((c) => {
        this.searchterms.forEach(
          (st, index) => {
            if (aggregator_search_term_id == st.id) {
              this.searchterms.splice(index, 1);
            }
          },
          (error) => {
            this.errorService.showError(error.error.message);
          }
        );

        this.spinner.hide();
      });
  }

  // tslint:disable-next-line:variable-name
  public AddSearchTerm(aggregator_id, search_term, search_term_type) {
    this.spinner.show();
    this.aggregatorsService
      .AddSearchTerm(aggregator_id, search_term, search_term_type)
      .subscribe(
        (c: any) => {
          this.searchterms.push(
            c.aggregator_search_term as aggregator_search_term
          );
          this.spinner.hide();
        },
        (err) => {
          this.notification.create('error', err.error.message, '');
        }
      );
  }

  // tslint:disable-next-line:variable-name
  public AddFilters(aggregator_id, exclude_filter, exclude_filter_type) {
    this.spinner.show();
    this.aggregatorsService
      .AddFilter(aggregator_id, exclude_filter, exclude_filter_type)
      .subscribe(
        (c: any) => {
          this.filters.push(c.aggregator_exclude_filter);

          this.spinner.hide();
        },
        (err) => {
          this.notification.create('error', err.error.message, '');
        }
      );
  }

  // tslint:disable-next-line:variable-name
  public Addwebsite(website_aggregator_id, website_aggregator_url) {
    this.spinner.show();
    this.aggregatorsService
      .AddWebsite(website_aggregator_id, website_aggregator_url)
      .subscribe(
        (c: any) => {
          this.websites.push(c.website_aggregator_link);
          this.spinner.hide();
        },
        (err) => {
          this.notification.create('error', err.error.message, '');
        }
      );
  }
  public AddDocumentURL(url_aggregator_id, type, document_aggregator_url) {
    this.spinner.show();
    this.aggregatorsService
      .AddDocumentURL(url_aggregator_id, type, document_aggregator_url)
      .subscribe(
        (c: any) => {
          this.documentURLs.push(c.document_aggregator_link);
          this.spinner.hide();
        },
        (err) => {
          this.notification.create('error', err.error.message, '');
        }
      );
  }
  public Addconfluence(aggregator_id, confluence_text) {
    this.spinner.show();
    this.aggregatorsService
      .AddConfulenceText(aggregator_id, confluence_text)
      .subscribe((c: any) => {
        let space = new spaces();
        space.id = c['confluence_aggregator_space']['id'];
        space.space_key = c['confluence_aggregator_space']['url'];
        this.confluenceterms.push(space);

        this.spinner.hide();
      });
  }

  Removefilter(aggregator_filter_id) {
    this.spinner.show();
    this.aggregatorsService.Removefilter(aggregator_filter_id).subscribe(
      (c) => {
        this.filters.forEach((st, index) => {
          if (aggregator_filter_id == st.id) {
            this.filters.splice(index, 1);
          }
        });

        this.spinner.hide();
      },
      (error) => {
        this.errorService.showError(error.message);
      }
    );
  }

  Removewebsite(website_id) {
    this.spinner.show();
    this.aggregatorsService.Removewebsite(website_id).subscribe(
      (c) => {
        this.websites.forEach((st, index) => {
          if (website_id == st.id) {
            this.websites.splice(index, 1);
          }
        });

        this.spinner.hide();
      },
      (error) => {
        this.errorService.showError(error.message);
      }
    );
  }
  RemoveDocumentURL(document_id) {
    this.spinner.show();
    this.aggregatorsService.RemoveDocumentURL(document_id).subscribe(
      (c) => {
        this.documentURLs.forEach((st, index) => {
          if (document_id == st.id) {
            this.documentURLs.splice(index, 1);
          }
        });

        this.spinner.hide();
      },
      (error) => {
        this.errorService.showError(error.message);
      }
    );
  }
  RemoveConfluence(id) {
    this.spinner.show();
    this.aggregatorsService.Removeconfluence(id).subscribe(
      (c) => {
        this.confluenceterms.forEach((st, index) => {
          if (id == st.id) {
            this.confluenceterms.splice(index, 1);
          }
        });

        this.spinner.hide();
      },
      (error) => {
        this.errorService.showError(error.message);
      }
    );
  }

  public setSelectedAggregator(agg: aggregatorSummary): void {
    setTimeout(() => {
      this.dataManager.fetchSelectedAggregatorData(agg.id);
    }, 9000);

    const aggregatorJSON = JSON.stringify(agg);
    this.selectedAggregator = JSON.parse(aggregatorJSON);
    localStorage.setItem(this.selectedAggregatorKey, aggregatorJSON);
    this.getCSVStatisiticAggregator();
  }

  public removeSelectedAggregator(): void {
    this.selectedAggregator = null;
    this.dataManager.resetSelectedAggregator();
    this.csv = null;
    localStorage.removeItem(this.selectedAggregatorKey);
  }

  public editYoutubeAggregator() {
    this.aggregatorsService
      .edit_youtube_aggregator(
        this.aggregatorSetting.youtube_aggregator.id,
        this.aggregatorSetting.youtube_aggregator.enabled
      )
      .subscribe((c) => {});
  }

  public editConfulanceAggregator() {
    this.aggregatorsService
      .edit_confulance_aggregator(
        this.aggregatorSetting.confluence_aggregator.id,
        this.aggregatorSetting.confluence_aggregator.enabled
      )
      .subscribe((c) => {});
  }

  public editfxNewsAggregator() {
    this.aggregatorsService
      .edit_fxNews_aggregator(
        this.aggregatorSetting.fxnews_aggregator.id,
        this.aggregatorSetting.fxnews_aggregator.enabled
      )
      .subscribe((c) => {});
  }

  public editDocumentAggregator() {
    this.aggregatorsService
      .edit_document_aggregator(
        this.aggregatorSetting.document_aggregator.id,
        this.aggregatorSetting.document_aggregator.enabled
      )
      .subscribe((c) => {});
  }

  public editGoogleAggregator() {
    this.aggregatorsService
      .edit_google_aggregator(
        this.aggregatorSetting.google_aggregator.id,
        this.aggregatorSetting.google_aggregator.enabled,
        this.aggregatorSetting.google_aggregator.crawl_level,
        this.aggregatorSetting.google_aggregator.follow_external_links
      )
      .subscribe((c) => {});
  }

  public editWebAggregator() {
    this.aggregatorsService
      .edit_website_aggregator(
        this.aggregatorSetting.website_aggregator.id,
        this.aggregatorSetting.website_aggregator.enabled,
        this.aggregatorSetting.website_aggregator.crawl_level,
        this.aggregatorSetting.website_aggregator.follow_external_links
      )
      .subscribe((c) => {});
  }

  public editFacebookAggregator() {
    this.aggregatorsService
      .edit_facebook_aggregator(
        this.aggregatorSetting.facebook_aggregator.id,
        this.aggregatorSetting.facebook_aggregator.enabled
      )
      .subscribe((c) => {});
  }

  public editInstagramAggregator() {
    this.aggregatorsService
      .edit_instagram_aggregator(
        this.aggregatorSetting.instagram_aggregator.id,
        this.aggregatorSetting.instagram_aggregator.enabled
      )
      .subscribe((c) => {});
  }

  public editTwitterAggregator() {
    this.aggregatorsService
      .edit_twitter_aggregator(
        this.aggregatorSetting.twitter_aggregator.id,
        this.aggregatorSetting.twitter_aggregator.enabled
      )
      .subscribe((c) => {});
  }

  public editRedditAggregator() {
    this.aggregatorsService
      .edit_reddit_aggregator(
        this.aggregatorSetting.reddit_aggregator.id,
        this.aggregatorSetting.reddit_aggregator.enabled
      )
      .subscribe((c) => {});
  }

  public csv: csvVisualizer[];
  public selectedFile: csvVisualizer;

  public getCSVStatisiticAggregator() {
    this.selectedFile = undefined;
    this.spinner.show();
    this.aggregatorsService
      .GetAggregatorCSVStatisic(this.selectedAggregator.id)
      .subscribe((c) => {
        this.csv = c['statistics'] as csvVisualizer[];

        this.csv.forEach((c, index) => {
          if (index == 0) {
            c.isSelected = true;
            this.selectedFile = c;
          } else {
            c.isSelected = false;
          }
          c.data.forEach((e) => {
            e.show = true;

            if (e.top_tags) {
              e.tags_cloud = [];
              e.top_tags.forEach((tg) => {
                e.tags_cloud.push({ text: tg.tag, weight: tg.idf * 2 });
              });
            }
          });

          this.spinner.hide();
        });
      });
  }

  public GetTestCSVFile() {
    this.httpClient.get('assets/profiles_500.json').subscribe((data: any) => {
      this.csv = data['statistics'];

      this.csv.forEach((c) => {
        c.data.forEach((e) => {
          e.show = true;
        });
      });
    });

    setTimeout(function () {
      if (this.csv.length > 0) {
        this.csv[0].isSelected = true;
        this.selectedFile = this.csv[0];
      }
    }, 3000);
  }
}
