import { Component, OnInit } from '@angular/core';
import { SearchManagerService } from '../../Managers/search-manager.service';
import { comment } from '../../models/aggregator';
import { AggregatorManagerService } from '../../Managers/aggregator-manager.service';
import { Router } from '@angular/router';
import {
  ConfirmationDailogComponent,
  ConfirmDialogModel,
} from '../../dailogs/confirmation-dailog/confirmation-dailog.component';
import { MatDialog } from '@angular/material/dialog';
import { Validators, FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-comments-conversation',
  templateUrl: './comments-conversation.component.html',
  styleUrls: ['./comments-conversation.component.css'],
})
export class CommentsConversationComponent implements OnInit {
  public offset: number = 0;

  public limit: number = 3;
  constructor(
    public router: Router,
    public searchManger: SearchManagerService,
    public aggManager: AggregatorManagerService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.createAddCommentControls();
    this.createAddcomment();

    if (this.searchManger.selectedSearch == undefined) {
      this.router.navigate(['search']);
    }
    this.searchManger.getCommentConversation(
      this.searchManger.selectedSearch.id
    );
    this.searchManger.getcomments(
      this.searchManger.selectedSearch.id,
      this.offset,
      this.limit
    );
  }

  public PostcommentForm: FormGroup;

  // Form Controls
  public comment: FormControl;

  createAddCommentControls() {
    this.comment = new FormControl('', [Validators.required]);
  }
  AddComment(comment: string) {
    this.searchManger.addComment(this.searchManger.selectedSearch.id, comment);
  }
  createAddcomment() {
    this.PostcommentForm = new FormGroup({
      comment: this.comment,
    });
  }

  confirmFlagChange() {
    const message = `Are you sure you want change flag?`;

    const dialogData = new ConfirmDialogModel('Confirm Action', message);

    const dialogRef = this.dialog.open(ConfirmationDailogComponent, {
      maxWidth: '400px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      this.result = dialogResult;

      if (this.result) {
        this.setFlag();
      }
    });
  }

  setFlag() {
    this.searchManger.SetSearchResultFlagged(
      this.searchManger.selectedSearch.id,
      false
    );
    this.router.navigate(['search']);
  }

  navigate() {
    window.open(this.searchManger.comment_conversation.link, '_blank');
  }

  result: any;
  confirmRemoveSearch() {
    const message = `Are you sure you want to remove this entry?`;

    const dialogData = new ConfirmDialogModel('Confirm Action', message);

    const dialogRef = this.dialog.open(ConfirmationDailogComponent, {
      maxWidth: '400px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      this.result = dialogResult;

      if (this.result) {
        this.removeSearch();
      }
    });
  }

  removeSearch() {
    this.searchManger.RemoveAggregatorSearchResult(
      this.searchManger.selectedSearch.id
    );
    this.router.navigate(['search']);
  }

  confirmDeleteComment(comment: comment) {
    const message = `Are you sure you want to remove this entry?`;

    const dialogData = new ConfirmDialogModel('Confirm Action', message);

    const dialogRef = this.dialog.open(ConfirmationDailogComponent, {
      maxWidth: '400px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      this.result = dialogResult;

      if (this.result) {
        this.deleteComment(comment);
      }
    });
  }

  deleteComment(comment: comment) {
    this.searchManger.removeComment(comment.id);
  }

  GoBack() {
    this.router.navigate(['search']);
  }
}
