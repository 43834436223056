<div class="d-flex justify-content-between align-items-center mb-2">
  <h1>Details</h1>
  <button class="button secondary-color br-8 ml-2 font-size14" (click)="openFilterTerms()"
    *ngIf="!this.history_id">Export PDF</button>
</div>

<div class="row custom-responsive">
  <div class="col-lg-3">
    <form [formGroup]="filterForm" (ngSubmit)="onSubmitFilter(filterForm.value)">
      <div class="filter-panel">
        <div class="filter-header">
          <span class="title">Filters&nbsp; <nz-switch matTooltip="Boolean Operator" formControlName="boolean_operator"
              nzCheckedChildren="AND" nzUnCheckedChildren="OR"></nz-switch></span>
          <div class="d-flex placeholder-black">
            <nz-select formControlName="orderField" nzPlaceHolder="Sort By" nzBorderless style="width: 130px">
              <nz-option nzValue="date" nzLabel="Publish Date"></nz-option>
              <nz-option nzValue="source" nzLabel="Source"></nz-option>
              <nz-option nzValue="sourceName" nzLabel="Author"></nz-option>
              <nz-option nzValue="sentimentScore" nzLabel="Sentiment"></nz-option>
              <nz-option nzValue="created" nzLabel="Search Date"></nz-option>
            </nz-select>

            <nz-select formControlName="orderType" nzPlaceHolder="Order By" nzBorderless>
              <nz-option nzValue="ASC" nzLabel="ASC"></nz-option>
              <nz-option nzValue="DESC" nzLabel="DESC"></nz-option>
            </nz-select>
            <div>
            </div>
          </div>
        </div>
        <div class="filter-body">
          <ul>
            <li>
              <b class="inline-label">Publish Date</b> <br>
              <div class="row sm-padding">
                <div class="col-lg-6">
                  <nz-date-picker nzPlaceHolder="From" formControlName="publish_date_from"
                    [nzDisabledDate]="disablePublishDateFrom"></nz-date-picker>
                </div>
                <div class="col-lg-6">
                  <nz-date-picker nzPlaceHolder="To" formControlName="publish_date_to"
                    [nzDisabledDate]="disabledPublishDateTo"></nz-date-picker>
                </div>
              </div>
              <b class="inline-label">Search Date</b> <br>
              <div class="row sm-padding">
                <div class="col-lg-6 ">
                  <nz-date-picker nzPlaceHolder="From" formControlName="search_date_from"
                    [nzDisabledDate]="disableSearchDateFrom"></nz-date-picker>
                </div>
                <div class="col-lg-6">
                  <nz-date-picker nzPlaceHolder="To" formControlName="search_date_to"
                    [nzDisabledDate]="disabledSearchDateTo"></nz-date-picker>
                </div>
              </div>

              <div class="row sm-padding">
                <div class="col-lg-12">
                  <b class="inline-label">Titles</b> <br>
                  <nz-select nzMode="tags" nzAllowClear class="fullwidth" formControlName="source_names">
                    <nz-option *ngFor="let option of listSourceNames" [nzLabel]="option.label" [nzValue]="option.value">
                    </nz-option>
                  </nz-select>

                </div>
                <div class="col-lg-12">
                  <b class="inline-label">Select Sentiments</b> <br>
                  <nz-select nzMode="multiple" nzAllowClear class="fullwidth" formControlName="sentiments">
                    <nz-option nzValue="positive" nzLabel="positive"></nz-option>
                    <nz-option nzValue="negative" nzLabel="negative"></nz-option>
                    <nz-option nzValue="neutral" nzLabel="neutral"></nz-option>
                  </nz-select>
                </div>
              </div>
            </li>

            <li>
              <b class="inline-label">Select Sources</b> <br>
              <nz-select nzMode="multiple" nzAllowClear class="fullwidth" formControlName="sources">
                <nz-option nzValue="100" nzLabel="document"></nz-option>
                <nz-option nzValue="3" nzLabel="google"></nz-option>
                <nz-option nzValue="5" nzLabel="reddit"></nz-option>
                <nz-option nzValue="2" nzLabel="twitter"></nz-option>
                <nz-option nzValue="1" nzLabel="website"></nz-option>
                <nz-option nzValue="4" nzLabel="youtube"></nz-option>
                <nz-option nzValue="99" nzLabel="FXOption"></nz-option>
              </nz-select>
            </li>
            <li>
              <b class="inline-label">Flagged</b> <br>
              <nz-radio-group formControlName="flagged">
                <label nz-radio nzValue="1">Yes</label>
                <label nz-radio nzValue="0">No</label>
                <label nz-radio nzValue="-1">Both</label>
              </nz-radio-group><br>
              <b class="inline-label">Conversed</b> <br>
              <nz-radio-group formControlName="conversed">
                <label nz-radio nzValue="1">Yes</label>
                <label nz-radio nzValue="0">No</label>
                <label nz-radio nzValue="-1">Both</label>
              </nz-radio-group>
            </li>
          </ul>
        </div>
        <div class="filter-footer">
          <div class="d-flex">
            <button type="submit" class="button primary-color  br-8">Submit</button>
            <button (click)="cancelFilter()" type="button" class="button secondary-color  br-8  ml-2">Clear</button>
          </div>
        </div>
      </div>

    </form>
  </div>

  <div class="col-lg-9">
    <div class="pb-90" *ngIf="this.searchManager.searchs && this.searchManager.searchs.length > 0">
      <div class="row">
        <div class="col-lg-4" *ngFor="let element of this.searchManager.searchs">
          <div class="tile">
            <div class="tile-header">
              <div>
                <span class="title">{{element.source_name}}</span>
                <span class="date font11"><b>Publish:</b>{{element.date}}</span>&nbsp;|&nbsp; <span
                  class="date font11"><b>Search:</b>{{element.created}}</span>

              </div>
              <div class="d-flex align-items-center justify-content-between">

                <div class="action-icons">
                  <button class="ml-1" matTooltip="Flagged" (click)="setFlag(element)" [ngClass]="{
                                                      'selected': element.flagged,
                                                      'not-selected':!element.flagged
                                                   }">

                    <i class="fa fa-solid fa-flag"></i>
                  </button>
                  <button class="ml-1" matTooltip="comments" [disabled]="!element.flagged"
                    (click)="selectComment(element)">
                    <i class="fa fa-solid fa-comment"></i>
                  </button>
                  <button class="ml-1" matTooltip="Delete" (click)="confirmRemveSearch(element)">
                    <i class="fa fa-solid fa-trash"></i>
                  </button>
                </div>
              </div>

            </div>
            <div class="tile-body">
              <div class="mb-2">
                <a *ngIf="!isCSVSource(element.link)" href="{{element.link}}" title="{{element.text}}" target="_blank">
                  {{element.text | slice:0:90}}
                  <span *ngIf="element.text.length > 90">...</span>
                </a>

                <a *ngIf="isCSVSource(element.link)" role="button"
                  (click)="documentSourceService.getAndOpenSource(null, aggrManager.selectedAggregator.id, element.link)"
                  title="{{element.text}}">
                  {{element.text | slice:0:90}} </a>

                <a *ngIf="element.text.length > 90" role="button" (click)="showText(element.text)" class="readmore">Read
                  more</a>
              </div>
              <div class="action-emoji">
                <i *ngIf="element.score >= 5" class="fas fa-solid fa-smile" [matTooltip]="element.score"></i>
                <i *ngIf="element.score <= -5 " class="fas fa-solid fa-angry" [matTooltip]="element.score"></i>
                <i *ngIf="element.score < 5 && element.score > -5" class="fas fa-solid fa-meh"
                  [matTooltip]="element.score"></i>
              </div>
              <div class="source-icons">
                <i class="fab fa-brands"
                  [ngClass]="{'fa-reddit': element.source == 'Reddit', 'fa-youtube': element.source == 'YouTube', 'fa-twitter': element.source ==  'Twitter', 'fa fa-file': element.source ==  'Document', 'fa-facebook': element.source ==  'Facebook', 'fa-google': element.source ==  'Google', 'fa-fax': element.source ==  'FXOption', 'fa-internet-explorer': element.source ==  'Website'}"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="this.searchManager.searchs && this.searchManager.searchs.length == 0" class="no-data">
      <i class="fa fa-solid fa-box-open"></i>
      <strong>Not Result Found!</strong>
    </div>

  </div>
  <div class="sticky-bottom">
    <mat-paginator [pageSizeOptions]="[15,30,60,80,100]" [length]=" this.searchManager.total_search_result"
      (page)="changePagesize($event)" [pageSize]="this.searchManager.limit" showFirstLastButtons></mat-paginator>
  </div>
  <app-csv-table-viewer></app-csv-table-viewer>


  <div *ngIf="showFilterTerms"
    style="position: absolute; top:0px; left:0px; background-color: #0303039c;z-index: 99999; width: 100%; height: 100%;">
    <div style="    background-color: white;
      display: inline-block;
      width: 30%;
      top: 15%;
      LEFT: 36%;
      POSITION: absolute;">

      <div class="modal-header">
        <h4 class="modal-title">Clusters </h4>

        <button type="button" class="close" aria-label="Close" (click)="closepopup()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">


        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Search Terms</mat-label>

          <input matInput placeholder="Search terms" (input)="filterChanged($event.target.value)">
          <mat-icon matSuffix>filter_list</mat-icon>

        </mat-form-field>
        <button mat-stroked-button class="w-50" (click)="selectAll()"> Select All</button>
        <button mat-stroked-button class="w-50" (click)="unSelectAll()"> Unselect All</button>

        <mat-tree class="mt-5" [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
          <!-- This is the tree node template for leaf nodes -->
          <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
            <li class="mat-tree-node">
              <!-- use a disabled button to provide padding for tree leaf -->
              <button mat-icon-button disabled></button>
              <mat-checkbox class="checklist-leaf-node" (change)="ItemSelectionToggle($event.checked,node)"
                [checked]="node.selected">{{node.name}}</mat-checkbox>


            </li>
          </mat-tree-node>
          <!-- This is the tree node template for expandable nodes -->
          <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
            <li>
              <div class="mat-tree-node">
                <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
                  <mat-icon class="mat-icon-rtl-mirror">
                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                  </mat-icon>
                </button>
                <mat-checkbox [checked]="node.selected" [indeterminate]="node.indeterminate && !node.selected"
                  (change)="ItemSelectionToggle($event.checked,node)">{{node.name}}</mat-checkbox>
              </div>
              <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
                <ng-container matTreeNodeOutlet></ng-container>
              </ul>
            </li>
          </mat-nested-tree-node>
        </mat-tree>


      </div>
      <div class="modal-footer">

        <button type="submit" [disabled]="(this.selectedTerms.length == 0)" class="btn btn-info "
          (click)="export()">Submit</button>

        <button type="button" class="btn btn-outline-dark" (click)="closepopup()">Close</button>
      </div>

    </div>
  </div>
  <nz-drawer [nzWidth]="900" [nzClosable]="false" [nzVisible]="visible" nzPlacement="right" nzTitle="Conversation"
    (nzOnClose)="close()">
    <ng-container *nzDrawerContent>
      <div class="position-relative" *ngIf="isCommentRender">
        <app-comments-conversation></app-comments-conversation>
      </div>
    </ng-container>
  </nz-drawer>
  <nz-modal [(nzVisible)]="isVisibleText" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
    <ng-container *nzModalContent>
      <div *ngIf="moreText" class="more-text">
        {{moreText}}
      </div>
    </ng-container>
  </nz-modal>