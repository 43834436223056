<lib-neo-graph *ngIf="showQuery"
               (canvasMouseClick)="canvasMouseClick($event)"
               (canvasMouseOver)="canvasMouseOver($event)"
               (nodeMouseClick)="nodeMouseClick($event)"
               (nodeMouseOver)="nodeMouseOver($event)"
               (nodeMouseDoubleClick)="nodeMouseDoubleClick($event)"
               (edgeMouseClick)="edgeMouseClick($event)"
               (edgeMouseOver)="edgeMouseOver($event)"
               (nodesAndRelationshipsUpdate)="nodesAndRelationshipsUpdate($event)"
               [scaleMap]="colorScale"
               [cipherQuery]="showQuery"
               [cipherServerURL]="'bolt+s://' + driverURL"
               [cipherServerUsername]="serverUsername"
               [cipherServerPassword]="serverPassword"
               [cipherDatabase]="serverDatabase">
  <div class="text-center chart-visualization-full-size d-flex justify-content-center align-items-center">
    <app-loading-component></app-loading-component>
  </div>
</lib-neo-graph>

<div *ngIf="showNode || showEdge" class="pl-4 pr-4 theme-shadow-vertical-left-low-tail"
     style="width: 25vw; z-index: 1; position:absolute; right:0px; top:1px; background:rgba(255, 255, 255, 0.9); height: calc(100vh - 100px); padding: 10px; overflow-y: auto; overflow-x: hidden">

  <button class="ml-2 closebutton" style="position: absolute; right: 10px; top: 20px;"
          (click)="showNode = null; showEdge = null;"><i class="fas fa-times"></i></button>

  <div *ngIf="showNode">
    <h3 class="mt-2"><b>Node Details</b></h3>
    <hr>
    <table class="table table-borderless">
      <tbody>
      <tr>
        <th scope="row">CLASSIFICATION</th>
        <td>{{ showNode.labels[0] }}</td>
      </tr>
      <tr *ngFor="let property of filterNodeProperties(showNode.properties)">
        <th scope="row">{{ property.key | uppercase }}</th>
        <td>{{ property.value }}</td>
      </tr>
      </tbody>
    </table>

    <div *ngIf="showNode && showNode.relations">
      <hr>
      <h3 class="mt-3"><b>Relations</b></h3>
      <hr>
      <table class="table table-borderless">
        <tbody>
        <tr *ngFor="let relation of showNode.relations">
          <th scope="row">{{ relation.leftNode.properties.name }}
            <small>({{ relation.leftNode.labels[0] }})</small></th>
          <td>
            <i class="fa fa-long-arrow-alt-left" *ngIf="!relation.isRight"></i>
          </td>
          <td class="text-center d-flex justify-content-center align-items-center">
            <small>
              {{ relation.relationship.type }}
            </small>
          </td>
          <td>
            <i class="fa fa-long-arrow-alt-right" *ngIf="relation.isRight"></i>
          </td>
          <th scope="row" class="text-right">{{ relation.rightNode.properties.name }}
            <small>({{ relation.rightNode.labels[0] }})</small></th>
        </tr>
        </tbody>
      </table>
    </div>

  </div>

  <div *ngIf="showEdge">
    <h3 class="mt-2"><b>Relationship Details</b></h3>
    <hr>
    <table class="table table-borderless">
      <tbody>
      <tr *ngFor="let property of filterEdgeProperties(showEdge.properties)">
        <th scope="row">{{ property.key | uppercase }}</th>
        <td>{{ property.value }}</td>
      </tr>
      </tbody>
    </table>

    <div *ngIf="showEdge && showEdge.relations">
      <hr>
      <h3 class="mt-3"><b>Nodes</b></h3>
      <hr>
      <table class="table table-borderless">
        <tbody>
        <tr *ngFor="let relation of showEdge.relations">
          <th scope="row">{{ relation.startingNode.properties.name }}
            <small>({{ relation.startingNode.labels[0] }})</small></th>
          <td>
            <i class="fa fa-long-arrow-alt-left" *ngIf="false"></i>
          </td>
          <td class="text-center d-flex justify-content-center align-items-center">
            <small>
              {{ relation.relationship.type }}
            </small>
          </td>
          <td>
            <i class="fa fa-long-arrow-alt-right" *ngIf="true"></i>
          </td>
          <th scope="row" class="text-right">{{ relation.endingNode.properties.name }}
            <small>({{ relation.endingNode.labels[0] }})</small>
          </th>
        </tr>
        </tbody>
      </table>
    </div>

    <hr>

    <div class="text-center">
      <button (click)="getAndOpenSource(showEdge.infosiphon_id)" class="btn btn-primary">Go to source</button>
    </div>

    <div *ngIf="showEdge.documentText">
      <h4><b>CONTEXT</b></h4>
      <p>{{ showEdge.documentText }}</p>
    </div>

  </div>

</div>
