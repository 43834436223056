import { CloudData } from 'angular-tag-cloud-module';



export class nominal
{
    public nominal: string;
    public counts:number;
    public percentage:string;
 
}
export class textual
{
    public term: string;
    public counts: number;
    public idf: number;
}

export class  csvVisualizer
{
    data: column[];
    documentId: number;
    orignalFileName: string;
    givenFileName: string;
    aggregatorId:number;
    id: number;

    isSelected:boolean=true;
}


export class column
{
    viz_uid :string;
    column_name:string;
    column_marker:string;
    top_nominals: nominal[];
    
    histogram: histogram[];
    statistics:statistics[];
    top_tags: tag[];
    numerics:numerics[];
    tags_cloud: CloudData[];
    linechart: linechart;
    show:boolean = true;
}

export class histogram
{
    interval:string;
    value_counts:number;

}

export  class linechart
{
    col1:string;
    col2:string;
    data: line_point[]=[];
}

export class line_point
{
    x:number;
    y:number;
}

export class statistics
{
    name: string;
    value: Number
}


export class tag
{
    count:number;
    idf:number;
    tag:string;
}


 export class numerics
{
    index: number;
value: number;
}
/*
export class numericalSummary
{
    statistics: statistics;
    numerical: numerical[];
}
*/