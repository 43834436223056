import { Component, OnInit, ElementRef, Input } from '@angular/core';
import * as d3 from 'd3';
import { line_point } from '../../../../models/csvVisualizer';

@Component({
  selector: 'app-scatterplots',
  templateUrl: './scatterplots.component.html',
  styleUrls: ['./scatterplots.component.css'],
})
export class ScatterplotsComponent implements OnInit {
  uniqueId: any;
  @Input() id: string;
  @Input() public data: line_point[] = [];

  private svg;
  private margin = 50;
  private width = 1600 - this.margin * 2;
  private height = 800 - this.margin * 2;

  constructor() {}

  ngOnInit(): void {
    this.uniqueId = 'canvas' + this.id;
  }

  public ngOnChanges(changes): void {
    if (changes.hasOwnProperty('data') && this.data) {
      this.createSvg();
      this.drawPlot();

      window.addEventListener('resize', () => this.drawPlot());
    }
  }

  private createSvg(): void {
    if (this.svg) {
      d3.select('figure#' + this.uniqueId)
        .selectAll('*')
        .remove();
    }
    this.svg = d3
      .select('figure#' + this.uniqueId)
      .append('svg')
      .attr('width', this.width + this.margin * 2)
      .attr('height', this.height + this.margin * 2)
      .append('g')
      .attr('transform', 'translate(' + this.margin + ',' + this.margin + ')');
  }

  private drawPlot(): void {
    // Add X axis
    const x = d3
      .scaleLinear()
      .domain([d3.min(this.data, (d) => d.x), d3.max(this.data, (d) => d.x)])
      .range([0, this.width]);
    this.svg
      .append('g')
      .attr('transform', 'translate(0,' + this.height + ')')
      .call(d3.axisBottom(x).tickFormat(d3.format('d')));

    // Add Y axis
    const y = d3
      .scaleLinear()
      .domain([d3.min(this.data, (d) => d.y), d3.max(this.data, (d) => d.y)])
      .range([this.height, 0]);
    this.svg.append('g').call(d3.axisLeft(y));

    // Add dots
    const dots = this.svg.append('g');
    dots
      .selectAll('dot')
      .data(this.data)
      .enter()
      .append('circle')
      .attr('cx', (d) => x(d.x))
      .attr('cy', (d) => y(d.y))
      .attr('r', 7)
      .style('opacity', 0.5)
      .style('fill', '#69b3a2')
      .on('mouseover', (d) => {
        div.transition().duration(200).style('opacity', 0.9);
        div
          .html('a tooltip <br/>' + d.x + '<br/>' + d.y)
          .style('left', d3.event.pageX + 'px')
          .style('top', d3.event.pageY - 28 + 'px');
      })
      .on('mouseout', (d) => {
        div.transition().duration(500).style('opacity', 0);
      });

    const div = this.svg
      .append('div')
      .attr('class', 'tooltip')
      .style('opacity', 0);

    // Add labels
    dots
      .selectAll('text')
      .data(this.data)
      .enter()
      .append('text')
      .text((d) => '')
      .attr('x', (d) => x(d.x))
      .attr('y', (d) => y(d.y));
  }
}
