import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  Input,
  OnDestroy,
} from '@angular/core';

import { AggregatorManagerService } from '../../../Managers/aggregator-manager.service';
import { VisualizationManagerService } from '../../../Managers/visualization-manager.service';
import { Router } from '@angular/router';
import { addContextMenu, waitInMS } from '../../../services/utils';
import { viz_type } from '../../../models/dashboard';
import { DashboardManagerService } from '../../../Managers/dashboard-manager.service';

import * as c3 from 'c3/c3';
const d3: any = addContextMenu();

@Component({
  selector: 'app-sentimentdevision',
  templateUrl: './sentimentdevision.component.html',
  styleUrls: ['./sentimentdevision.component.css'],
})
export class SentimentdevisionComponent implements OnInit {
  private hook = null;
  sentimentByScore: any = null;
  colorFunc = null;
  formatN = d3.format('.2s');
  uniqueId: any;

  @Input() height: number = null;
  _serviceSubscription: any;
  constructor(
    private router: Router,
    public dashboardManager: DashboardManagerService,
    public visualizationManagerService: VisualizationManagerService,
    public aggManager: AggregatorManagerService
  ) {
    this._serviceSubscription = this.dashboardManager.onExport.subscribe(
      (c) => {
        this.export();
      }
    );
  }

  ngOnInit(): void {
    this.uniqueId = 'sentimentDivisin' + this.getRandomInt(1, 2000);

    //this.createChart();
  }

  viz_uid = '00000000000000000000000a';
  ngAfterViewInit(): void {
    this.dashboardManager.viz_types.forEach((c) => {
      if (c.viz_uid == this.viz_uid) {
        this.viz_type = c;
      }
    });
    this.createChart();
    this.hook = this.visualizationManagerService.visualizingInit.subscribe(
      (event) => {
        this.createChart();
      }
    );
  }
  public viz_type: viz_type;
  Add() {
    this.dashboardManager.viz_types.forEach((c) => {
      if (c.viz_uid == this.viz_uid) {
        this.viz_type = c;
      }
    });

    this.viz_type.isEnabled = true;
    this.dashboardManager.AddDashboardItem(
      this.aggManager.selectedAggregator.id,
      this.viz_type.viz_uid,
      1
    );
  }

  getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  public createChart() {
    globalThis.d3 = d3;
    if (this.visualizationManagerService.sentimentsData != undefined) {
      const sentimentDivisionCHart = c3.generate({
        bindto: '#' + this.uniqueId,
        pie: {
          expand: true,
        },
        // title: {
        //   text: 'Sentiment Division',
        //   position: 'top-left',   // top-left, top-center and top-right
        // },
        data: {
          columns: this.visualizationManagerService.sentimentsData[0],
          type: 'pie',
          colors: {
            Positive: '#1ed6bb',
            Negative: '#d61e57',
            Neutral: '#6214f4',
          },
        },
      });
    }
  }

  ngOnDestroy() {
    if (this._serviceSubscription) this._serviceSubscription.unsubscribe();
    if (this.hook) {
      this.hook.unsubscribe();
    }
  }

  export() {
    if (this.height) {
      const element = document.querySelector('#' + this.uniqueId + ' svg');

      this.dashboardManager.dashboard_items.forEach((c) => {
        if (c.viz_uid == this.viz_uid) {
          //c.viz_svg=  "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"600\" height=\"600\" viewBox=\"0 0 600 300\" preserveAspectRatio=\"xMinYMin\" style=\"display: block; margin: 0vh; background: rgb(255, 255, 255); cursor: pointer;\" > <g transform=\"translate(50,50)\">"+ element.innerHTML.replace(/clip-path=\".*?\"/g,"")+"</g></svg>";
          c.viz_svg =
            '<svg xmlns="http://www.w3.org/2000/svg" width="850" height="800"    > <g transform="translate(0,0)"  >' +
            element.innerHTML.replace(/clip-path=\".*?\"/g, '') +
            '</g></svg>';

          c.viz_type = this.viz_type.viz_type;
        }
      });
    }
  }
}
