<div class="d-flex justify-content-between align-items-center mb-4">
  <h1>Aggregators</h1>

  <div>
    <button class="transparent" matTooltip="Table View" (click)="tableView()" [ngClass]="{active: !isTabView}">
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
        <defs>

        </defs>
        <g id="tab_icon_14px" transform="translate(-149.297)">
          <path id="Path_181" d="M149.3 0h14v2.918h-14zm0 0" />
          <path id="Path_183" d="M149.3 147.008h14v2.916h-14zm0 0" transform="translate(0 -141.221)" />
          <path id="Path_185" d="M149.3 294.012h14v2.917h-14zm0 0" transform="translate(0 -282.928)" />
        </g>
      </svg>
    </button>

    <button class="transparent" (click)="tabView()" matTooltip="Tab View" [ngClass]="{active: isTabView}">

      <svg xmlns="http://www.w3.org/2000/svg" id="grid_icon_14px" width="14" height="14" viewBox="0 0 14 14">
        <defs>

        </defs>
        <g id="Group_804">
          <g id="Group_803">
            <path id="Rectangle_529" d="M0 0H3.5V3.5H0z" transform="translate(5.25 5.25)" />
            <path id="Rectangle_530" d="M0 0H3.5V3.5H0z" />
            <path id="Rectangle_531" d="M0 0H3.5V3.5H0z" transform="translate(5.25 10.5)" />
            <path id="Rectangle_532" d="M0 0H3.5V3.5H0z" transform="translate(0 5.25)" />
            <path id="Rectangle_533" d="M0 0H3.5V3.5H0z" transform="translate(0 10.5)" />
            <path id="Rectangle_534" d="M0 0H3.5V3.5H0z" transform="translate(10.5)" />
            <path id="Rectangle_535" d="M0 0H3.5V3.5H0z" transform="translate(5.25)" />
            <path id="Rectangle_536" d="M0 0H3.5V3.5H0z" transform="translate(10.5 5.25)" />
            <path id="Rectangle_537" d="M0 0H3.5V3.5H0z" transform="translate(10.5 10.5)" />
          </g>
        </g>
      </svg>
    </button>
    <button *ngIf="!demoService.demoMode" class="button secondary-color  ml-2 font-size14" (click)="ShowAddPopup()">
      Add Aggregator
    </button>
  </div>
</div>
<div [hidden]="!isTabView" class="row custom-grid pb-3">

  <div class="col-lg-3" *ngFor="let agg of this.aggManager.aggregators">

    <div class="card mb-4 aggregator-shadow">

      <div class="sm-title">
        <span (click)="dashboardclick(agg)" class="cursor-pointer"> {{agg.name|slice:0:40}} </span>
        <span (click)="openSummary(agg,agg.id)" class="cursor-pointer"><i class="fas fa-ellipsis-v"></i></span>
      </div>


      <div class="inline pl-2 pr-2">
        <div class="progree-bar">
          <nz-progress *ngIf="agg.state == 'Done'" [nzPercent]="100" nzSize="small"></nz-progress>
          <nz-progress *ngIf="agg.state == 'Scheduled'" [nzPercent]="99" nzSize="small"></nz-progress>
          <nz-progress *ngIf="agg.state == 'Visualization'" [nzPercent]="95" nzSize="small"></nz-progress>
          <nz-progress *ngIf="agg.state == 'Processed'" [nzPercent]="90" nzSize="small"></nz-progress>
          <nz-progress *ngIf="agg.state == 'Visualize Queue'" [nzPercent]="85" nzSize="small"></nz-progress>
          <nz-progress *ngIf="agg.state == 'Analyze Queue'" [nzPercent]="80" nzSize="small"></nz-progress>
          <nz-progress *ngIf="agg.state == 'Searching'" [nzPercent]="20" nzSize="small"></nz-progress>
          <nz-progress *ngIf="agg.state == 'Search Queue'" [nzPercent]="5" nzSize="small"></nz-progress>
          <nz-progress *ngIf="agg.state == 'Awaiting'" [nzPercent]="0" nzSize="small"></nz-progress>

        </div>

        <span class="p2 ml-2">
          <svg xmlns="http://www.w3.org/2000/svg" widht="12" height="12" viewBox="0 0 539.31 464">
            <title>Last Executed</title>
            <g id="Layer_2" data-name="Layer 2">
              <g id="Capa_1" data-name="Capa 1">
                <path
                  d="M381.29,311,354.42,337.9l-82.2-82.19a18.84,18.84,0,0,1-5.57-13.43l.05-114.94h38l-.05,107.07Z" />
                <path
                  d="M307.31,0h0A232.17,232.17,0,0,0,79.05,192H0l13.66,13.66,80,80,5.65,5.65,5.66-5.65,80-80L198.63,192H127.92A184.67,184.67,0,0,1,242.68,59.74,182.39,182.39,0,0,1,307.31,48c101.46,0,184,82.54,184,184s-82.54,184-184,184a183.07,183.07,0,0,1-145.1-70.83l-4.92-6.3L151,343.79l-25.21,19.69-6.31,4.92,4.93,6.31A230.77,230.77,0,0,0,307.31,464c127.93,0,232-104.07,232-232S435.24,0,307.31,0Z" />
              </g>
            </g>
          </svg>

          {{ agg.last_executed }}

        </span>
        <span *ngIf="!demoService.demoMode" class="p2">
          <svg xmlns="http://www.w3.org/2000/svg" widht="12" height="12" viewBox="0 0 520 520"
            *ngIf="agg.scheduled_for">
            <title>Scheduled For</title>
            <g id="Layer_2" data-name="Layer 2">
              <g id="Capa_1" data-name="Capa 1">
                <path
                  d="M456,44H432V4H392V44H128V4H88V44H64A60.07,60.07,0,0,0,4,104V456a60.07,60.07,0,0,0,60,60H269.76a176.19,176.19,0,0,1-35.66-40H64a20,20,0,0,1-20-20V192H476v42.1a176.19,176.19,0,0,1,40,35.66V104A60.07,60.07,0,0,0,456,44Zm20,108H44V104A20,20,0,0,1,64,84H88v40h40V84H392v40h40V84h24a20,20,0,0,1,20,20Z" />
                <path
                  d="M432,4V44h24a60.07,60.07,0,0,1,60,60V269.76a176.19,176.19,0,0,0-40-35.66V192H44V456a20,20,0,0,0,20,20H234.1a176.19,176.19,0,0,0,35.66,40H64A60.07,60.07,0,0,1,4,456V104A60.07,60.07,0,0,1,64,44H88V4h40V44H392V4h40M392,124V84H128v40H88V84H64a20,20,0,0,0-20,20v48H476V104a20,20,0,0,0-20-20H432v40H392M432,0H392a4,4,0,0,0-4,4V40H132V4a4,4,0,0,0-4-4H88a4,4,0,0,0-4,4V40H64A64.07,64.07,0,0,0,0,104V456a64.07,64.07,0,0,0,64,64H269.76a4,4,0,0,0,2.55-7.08,172.29,172.29,0,0,1-34.86-39.1A4,4,0,0,0,234.1,472H64a16,16,0,0,1-16-16V196H472v38.1a4,4,0,0,0,1.82,3.35,172.29,172.29,0,0,1,39.1,34.86,4,4,0,0,0,7.08-2.55V104a64.07,64.07,0,0,0-64-64H436V4a4,4,0,0,0-4-4Zm4,88h20a16,16,0,0,1,16,16v44H48V104A16,16,0,0,1,64,88H84v36a4,4,0,0,0,4,4h40a4,4,0,0,0,4-4V88H388v36a4,4,0,0,0,4,4h40a4,4,0,0,0,4-4V88Z" />
                <path
                  d="M381,246c-74.44,0-135,60.56-135,135s60.56,135,135,135,135-60.56,135-135S455.44,246,381,246Zm0,230a95,95,0,1,1,95-95A95.11,95.11,0,0,1,381,476Z" />
                <path
                  d="M381,246c74.44,0,135,60.56,135,135S455.44,516,381,516,246,455.44,246,381s60.56-135,135-135m0,230a95,95,0,1,0-95-95,95.11,95.11,0,0,0,95,95m0-234c-76.64,0-139,62.36-139,139s62.36,139,139,139,139-62.36,139-139S457.64,242,381,242Zm0,230a91,91,0,1,1,91-91,91.1,91.1,0,0,1-91,91Z" />
                <polygon points="400 314 360 314 360 401 434 401 434 361 400 361 400 314" />
                <path
                  d="M400,314v47h34v40H360V314h40m0-4H360a4,4,0,0,0-4,4v87a4,4,0,0,0,4,4h74a4,4,0,0,0,4-4V361a4,4,0,0,0-4-4H404V314a4,4,0,0,0-4-4Z" />
              </g>
            </g>
          </svg>
          {{ agg.scheduled_for}}
        </span>
        <div class="d-flex justify-content-between mt-20">
          <div *ngIf="!demoService.demoMode" class="mt-2">
            <div class="d-flex">
              <div class="border-bottom-right  w-60px p-1 text-center"><span class="p1">Results</span></div>
              <div class="border-bottom w-60px p-1 text-center"><span class="p1">Alert</span></div>
              <!-- <div class="border-bottom w-60px p-1 text-center"><span class="p1">Comments</span></div> -->
            </div>
            <div class="d-flex">
              <div class="border-right w-60px p-1 text-center"><span class="p1">
                  {{agg.total_results | number}}

                </span></div>
              <div class="w-60px p-1 text-center"><label *ngIf="!demoService.demoMode" class=" chkboxcontainer"
                  matTooltip="Alert">
                  <input type="checkbox" [(ngModel)]="agg.enable_aggregator_processed_alert"
                    (change)="changeAlert(agg,agg.enable_aggregator_processed_alert)">
                  <span class="checkmark"></span>
                </label></div>
              <!-- <div class="w-80px p-1 text-center">{{agg.comment_count}}</div> -->
            </div>
          </div>

          <div class="float-right">
            <button class="icon ml-2" matTooltip="Visualization" (click)="VisualizationClick(agg)">

              <svg xmlns="http://www.w3.org/2000/svg" id="_004-report" width="17" height="17" data-name="004-report"
                viewBox="0 0 14 14">

                <path id="Path_14"
                  d="M32.23 6.563a1.224 1.224 0 0 0 .852-.346l1.214.607a1.205 1.205 0 0 0-.015.149 1.23 1.23 0 0 0 2.461 0 1.216 1.216 0 0 0-.123-.527L38.266 4.8a1.216 1.216 0 0 0 .527.123 1.232 1.232 0 0 0 1.23-1.23 1.212 1.212 0 0 0-.061-.364l1.431-1.073a1.23 1.23 0 1 0-.549-1.023 1.212 1.212 0 0 0 .061.364l-1.431 1.071a1.225 1.225 0 0 0-1.788 1.551l-1.647 1.647a1.209 1.209 0 0 0-1.379.223l-1.214-.607a1.205 1.205 0 0 0 .015-.149 1.23 1.23 0 1 0-1.23 1.23zm0 0"
                  data-name="Path 14" transform="translate(-30.152)" />
                <path id="Path_15"
                  d="M13.59 159.078h-.437v-8.668a.41.41 0 0 0-.41-.41H11.1a.41.41 0 0 0-.41.41v8.668h-.82v-6.207a.41.41 0 0 0-.41-.41H7.82a.41.41 0 0 0-.41.41v6.207h-.82v-2.926a.41.41 0 0 0-.41-.41H4.539a.41.41 0 0 0-.41.41v2.926h-.82v-4.566a.41.41 0 0 0-.41-.41H1.258a.41.41 0 0 0-.41.41v4.566H.41a.41.41 0 0 0 0 .82h13.18a.41.41 0 0 0 0-.82zm0 0"
                  data-name="Path 15" transform="translate(0 -145.898)" />
              </svg>
            </button>

            <button class="icon ml-2" matTooltip="Sentiment Analysis" (click)="sentimentclick(agg)">

              <i class="fa fa-smile-o" aria-hidden="true"></i>

            </button>

            <button class="icon ml-2" matTooltip="Speech Analytics" (click)="speechclick(agg)">

              <i class="fas fa-file-audio" aria-hidden="true"></i>

            </button>

            <button class="icon ml-2" matTooltip="Image Analytics" (click)="imageclick(agg)">

              <i class="fas fa-images" aria-hidden="true"></i>

            </button>

            <button class="icon ml-2" matTooltip="Info Graph" (click)="infoGraphClick(agg)">

              <i class="fa fa-database"></i>

            </button>

            <br>

            <button *ngIf="!demoService.demoMode" class="icon ml-2 mt-2" matTooltip="Execute Aggregator"
              (click)="confirmExecuteDialog(agg)">
              <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" width="10" height="13" x="0px" y="0px"
                viewBox="0 0 477.886 477.886" style="enable-background:new 0 0 477.886 477.886;" xml:space="preserve">
                <g>
                  <g>
                    <path
                      d="M476.091,231.332c-1.654-3.318-4.343-6.008-7.662-7.662L24.695,1.804C16.264-2.41,6.013,1.01,1.8,9.442
                           c-1.185,2.371-1.801,4.986-1.8,7.637v443.733c-0.004,9.426,7.633,17.07,17.059,17.075c2.651,0.001,5.266-0.615,7.637-1.8
                           L468.429,254.22C476.865,250.015,480.295,239.768,476.091,231.332z M34.133,433.198V44.692l388.506,194.253L34.133,433.198z" />
                  </g>
                </g>
              </svg>

            </button>

            <button *ngIf="!demoService.demoMode" class="icon ml-2 mt-2" matTooltip="History"
              (click)="historyclick(agg)">
              <i class="fa fa-history" aria-hidden="true"></i>

            </button>



            <button *ngIf="!demoService.demoMode" class="icon ml-2" matTooltip="Details" (click)="detailsClick(agg)">

              <i class="fas fa-th-large"></i>

            </button>
            <button *ngIf="!demoService.demoMode" class="icon ml-2 mt-2" matTooltip="Setting"
              (click)="settingClick(agg)">
              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 18.6 18.6">

                <g id="gear" transform="translate(.3 .3)">
                  <g id="Group_31" data-name="Group 31">
                    <g id="Group_30" data-name="Group 30">
                      <path id="Path_14"
                        d="M18 10.488V7.512l-2.441-.481a6.808 6.808 0 0 0-.53-1.276l1.361-2.042-2.1-2.1-2.044 1.358a6.805 6.805 0 0 0-1.277-.53L10.488 0H7.512l-.481 2.441a6.808 6.808 0 0 0-1.277.53L3.712 1.609l-2.1 2.1 1.359 2.045a6.808 6.808 0 0 0-.53 1.276L0 7.512v2.977l2.441.481a6.809 6.809 0 0 0 .53 1.276l-1.362 2.042 2.1 2.1 2.042-1.361a6.807 6.807 0 0 0 1.277.53L7.512 18h2.977l.481-2.441a6.807 6.807 0 0 0 1.277-.53l2.042 1.361 2.1-2.1-1.361-2.042a6.808 6.808 0 0 0 .53-1.276zm-2.967 3.665l-.88.88-1.873-1.248a6.143 6.143 0 0 1-2.218.921l-.441 2.24H8.379l-.441-2.24a6.143 6.143 0 0 1-2.218-.921l-1.873 1.248-.88-.88 1.248-1.873a6.143 6.143 0 0 1-.921-2.218l-2.24-.441V8.379l2.239-.441a6.148 6.148 0 0 1 .922-2.218L2.967 3.847l.88-.88L5.72 4.215a6.143 6.143 0 0 1 2.218-.921l.441-2.24h1.242l.441 2.24a6.142 6.142 0 0 1 2.218.921l1.873-1.249.88.88-1.248 1.874a6.145 6.145 0 0 1 .921 2.218l2.24.441v1.242l-2.239.441a6.141 6.141 0 0 1-.921 2.218z"
                        data-name="Path 14" />
                    </g>
                  </g>
                  <g id="Group_33" data-name="Group 33" transform="translate(5.309 5.309)">
                    <g id="Group_32" data-name="Group 32">
                      <path id="Path_15"
                        d="M154.691 151a3.691 3.691 0 1 0 3.691 3.691 3.7 3.7 0 0 0-3.691-3.691zm0 6.328a2.637 2.637 0 1 1 2.637-2.637 2.64 2.64 0 0 1-2.637 2.637z"
                        data-name="Path 15" transform="translate(-151 -151)" />
                    </g>
                  </g>
                </g>
              </svg>


            </button>
            <button *ngIf="!demoService.demoMode" class="icon ml-2 mt-2" matTooltip="Delete"
              (click)="confirmDeleteDialog(agg)">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 10.009 12.2">
                <g id="trash" transform="translate(.104 .1)">
                  <path id="Path_4" stroke-width="0.2px"
                    d="M9.77 2.682l-.265-.794a.739.739 0 0 0-.7-.506H6.576V.657A.658.658 0 0 0 5.92 0H3.88a.658.658 0 0 0-.657.657v.725H1a.739.739 0 0 0-.7.506l-.27.794a.595.595 0 0 0 .564.783h.277L1.48 11a1.09 1.09 0 0 0 1.081 1h4.8a1.09 1.09 0 0 0 1.081-1l.61-7.538h.153a.595.595 0 0 0 .564-.783zM3.927.7h1.946v.679H3.927zm3.815 10.246a.383.383 0 0 1-.38.351h-4.8a.383.383 0 0 1-.38-.351l-.606-7.481h6.771zm-7-8.184l.22-.652A.037.037 0 0 1 1 2.085h7.8a.037.037 0 0 1 .035.025l.217.651zm0 0"
                    data-name="Path 4" transform="translate(.001)" />
                </g>
              </svg>
            </button>


          </div>

        </div>


      </div>

      <div>

      </div>
    </div>
  </div>
</div>

<div [hidden]="isTabView" class="mat-elevation-z8 mt-4"
  style="background-color: white; border-radius: 16px; padding: 20px;">
  <table mat-table [dataSource]="this.aggManager.dataSource" style="width: 100%;">


    <!-- Position Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let i = index">{{this.paginator.pageIndex == 0 ? i + 1 : 1 + i +
        this.paginator.pageIndex * this.paginator.pageSize}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name</th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="state">
      <th mat-header-cell *matHeaderCellDef class="text-center"> State</th>
      <td mat-cell *matCellDef="let element" class="text-center primary-color" style="color: white;"
        [ngClass]="{'secondary-color': element.state == 'Scheduled', 'search-color': element.state == 'Searching', 'search-queue-color': element.state ==  'Search Queue' }">
        {{element.state}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="schedule_type">
      <th mat-header-cell *matHeaderCellDef class="text-center"> Frequency</th>
      <td mat-cell *matCellDef="let element" class="text-center"> {{element.schedule_type}} </td>
    </ng-container>


    <ng-container *ngIf="!demoService.demoMode" matColumnDef="scheduled_for">
      <th mat-header-cell *matHeaderCellDef class="text-center"> Scheduled For</th>
      <td mat-cell *matCellDef="let element" class="text-center"> {{element.scheduled_for}} </td>
    </ng-container>

    <ng-container matColumnDef="last_executed">
      <th mat-header-cell *matHeaderCellDef class="text-center"> Last Executed</th>
      <td mat-cell *matCellDef="let element" class="text-center"> {{element.last_executed}} </td>
    </ng-container>

    <ng-container matColumnDef="results">
      <th mat-header-cell *matHeaderCellDef class="text-center"> Results</th>
      <td mat-cell *matCellDef="let element" class="text-center"> {{element.total_results}} </td>
    </ng-container>

    <ng-container *ngIf="!demoService.demoMode" matColumnDef="flagged">
      <th mat-header-cell *matHeaderCellDef class="text-center"> Flagged</th>
      <td mat-cell *matCellDef="let element" class="text-center"> {{element.flagged_count}} </td>
    </ng-container>

    <ng-container *ngIf="!demoService.demoMode" matColumnDef="conversed">
      <th mat-header-cell *matHeaderCellDef class="text-center"> Conversed</th>
      <td mat-cell *matCellDef="let element" class="text-center"> {{element.conversed_count}} </td>
    </ng-container>

    <ng-container *ngIf="!demoService.demoMode" matColumnDef="comments">
      <th mat-header-cell *matHeaderCellDef class="text-center"> Comments</th>
      <td mat-cell *matCellDef="let element" class="text-center"> {{element.comment_count}} </td>
    </ng-container>


    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">


        <label *ngIf="!demoService.demoMode" class=" chkboxcontainer ml-2 mr-4" matTooltip="Schedule">
          <input type="checkbox" [(ngModel)]="element.enable_aggregator_processed_alert"
            (change)="changeAlert(element,element.enable_aggregator_processed_alert)">
          <span class="checkmark" style="left: 0px !important;"></span>
        </label>

        <button class="icon ml-3 mt-2" matTooltip="Visualization" (click)="VisualizationClick(element)">

          <svg xmlns="http://www.w3.org/2000/svg" id="_004-report" width="14" height="14" data-name="004-report"
            viewBox="0 0 14 14">

            <path id="Path_14"
              d="M32.23 6.563a1.224 1.224 0 0 0 .852-.346l1.214.607a1.205 1.205 0 0 0-.015.149 1.23 1.23 0 0 0 2.461 0 1.216 1.216 0 0 0-.123-.527L38.266 4.8a1.216 1.216 0 0 0 .527.123 1.232 1.232 0 0 0 1.23-1.23 1.212 1.212 0 0 0-.061-.364l1.431-1.073a1.23 1.23 0 1 0-.549-1.023 1.212 1.212 0 0 0 .061.364l-1.431 1.071a1.225 1.225 0 0 0-1.788 1.551l-1.647 1.647a1.209 1.209 0 0 0-1.379.223l-1.214-.607a1.205 1.205 0 0 0 .015-.149 1.23 1.23 0 1 0-1.23 1.23zm0 0"
              data-name="Path 14" transform="translate(-30.152)" />
            <path id="Path_15"
              d="M13.59 159.078h-.437v-8.668a.41.41 0 0 0-.41-.41H11.1a.41.41 0 0 0-.41.41v8.668h-.82v-6.207a.41.41 0 0 0-.41-.41H7.82a.41.41 0 0 0-.41.41v6.207h-.82v-2.926a.41.41 0 0 0-.41-.41H4.539a.41.41 0 0 0-.41.41v2.926h-.82v-4.566a.41.41 0 0 0-.41-.41H1.258a.41.41 0 0 0-.41.41v4.566H.41a.41.41 0 0 0 0 .82h13.18a.41.41 0 0 0 0-.82zm0 0"
              data-name="Path 15" transform="translate(0 -145.898)" />
          </svg>
        </button>

        <button class="icon ml-3 mt-2" matTooltip="Sentiment Analysis" (click)="sentimentclick(element)">
          <i class="fa fa-smile-o" aria-hidden="true"></i>
        </button>

        <button *ngIf="!demoService.demoMode" class="icon ml-2 mt-2" matTooltip="Details" (click)="detailsClick(agg)">
          <i class="fas fa-th-large"></i>
        </button>

        <button class="icon ml-2 mt-2" matTooltip="Info Graph" (click)="infoGraphClick(agg)">
          <i class="fa fa-database"></i>
        </button>

        <button *ngIf="!demoService.demoMode" class="icon ml-3 mt-2" matTooltip="Dashboard"
          (click)="dashboardclick(agg)">
          <i class="fa fa-desktop" aria-hidden="true"></i>
        </button>

        <button *ngIf="!demoService.demoMode" class="icon ml-3 mt-2" matTooltip="Execute Aggregator"
          (click)="confirmExecuteDialog(element)">
          <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            width="7.5" height="10" x="0px" y="0px" viewBox="0 0 477.886 477.886"
            style="enable-background:new 0 0 477.886 477.886;" xml:space="preserve">
            <g>
              <g>
                <path
                  d="M476.091,231.332c-1.654-3.318-4.343-6.008-7.662-7.662L24.695,1.804C16.264-2.41,6.013,1.01,1.8,9.442
                       c-1.185,2.371-1.801,4.986-1.8,7.637v443.733c-0.004,9.426,7.633,17.07,17.059,17.075c2.651,0.001,5.266-0.615,7.637-1.8
                       L468.429,254.22C476.865,250.015,480.295,239.768,476.091,231.332z M34.133,433.198V44.692l388.506,194.253L34.133,433.198z" />
              </g>
            </g>
          </svg>
        </button>

        <button *ngIf="!demoService.demoMode" class="icon ml-3 mt-2" matTooltip="Setting"
          (click)="settingClick(element)">
          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 18.6 18.6">

            <g id="gear" transform="translate(.3 .3)">
              <g id="Group_31" data-name="Group 31">
                <g id="Group_30" data-name="Group 30">
                  <path id="Path_14"
                    d="M18 10.488V7.512l-2.441-.481a6.808 6.808 0 0 0-.53-1.276l1.361-2.042-2.1-2.1-2.044 1.358a6.805 6.805 0 0 0-1.277-.53L10.488 0H7.512l-.481 2.441a6.808 6.808 0 0 0-1.277.53L3.712 1.609l-2.1 2.1 1.359 2.045a6.808 6.808 0 0 0-.53 1.276L0 7.512v2.977l2.441.481a6.809 6.809 0 0 0 .53 1.276l-1.362 2.042 2.1 2.1 2.042-1.361a6.807 6.807 0 0 0 1.277.53L7.512 18h2.977l.481-2.441a6.807 6.807 0 0 0 1.277-.53l2.042 1.361 2.1-2.1-1.361-2.042a6.808 6.808 0 0 0 .53-1.276zm-2.967 3.665l-.88.88-1.873-1.248a6.143 6.143 0 0 1-2.218.921l-.441 2.24H8.379l-.441-2.24a6.143 6.143 0 0 1-2.218-.921l-1.873 1.248-.88-.88 1.248-1.873a6.143 6.143 0 0 1-.921-2.218l-2.24-.441V8.379l2.239-.441a6.148 6.148 0 0 1 .922-2.218L2.967 3.847l.88-.88L5.72 4.215a6.143 6.143 0 0 1 2.218-.921l.441-2.24h1.242l.441 2.24a6.142 6.142 0 0 1 2.218.921l1.873-1.249.88.88-1.248 1.874a6.145 6.145 0 0 1 .921 2.218l2.24.441v1.242l-2.239.441a6.141 6.141 0 0 1-.921 2.218z"
                    data-name="Path 14" />
                </g>
              </g>
              <g id="Group_33" data-name="Group 33" transform="translate(5.309 5.309)">
                <g id="Group_32" data-name="Group 32">
                  <path id="Path_15"
                    d="M154.691 151a3.691 3.691 0 1 0 3.691 3.691 3.7 3.7 0 0 0-3.691-3.691zm0 6.328a2.637 2.637 0 1 1 2.637-2.637 2.64 2.64 0 0 1-2.637 2.637z"
                    data-name="Path 15" transform="translate(-151 -151)" />
                </g>
              </g>
            </g>
          </svg>
        </button>

        <button *ngIf="!demoService.demoMode" class="icon ml-3 mt-2" matTooltip="Delete"
          (click)="confirmDeleteDialog(element)">
          <svg xmlns="http://www.w3.org/2000/svg" width="11" height="13" viewBox="0 0 10.009 12.2">
            <g id="trash" transform="translate(.104 .1)">
              <path id="Path_4" stroke-width="0.2px"
                d="M9.77 2.682l-.265-.794a.739.739 0 0 0-.7-.506H6.576V.657A.658.658 0 0 0 5.92 0H3.88a.658.658 0 0 0-.657.657v.725H1a.739.739 0 0 0-.7.506l-.27.794a.595.595 0 0 0 .564.783h.277L1.48 11a1.09 1.09 0 0 0 1.081 1h4.8a1.09 1.09 0 0 0 1.081-1l.61-7.538h.153a.595.595 0 0 0 .564-.783zM3.927.7h1.946v.679H3.927zm3.815 10.246a.383.383 0 0 1-.38.351h-4.8a.383.383 0 0 1-.38-.351l-.606-7.481h6.771zm-7-8.184l.22-.652A.037.037 0 0 1 1 2.085h7.8a.037.037 0 0 1 .035.025l.217.651zm0 0"
                data-name="Path 4" transform="translate(.001)" />
            </g>
          </svg>
        </button>

      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[30,60,80,100]" [length]="this.aggManager.total_aggregator_result"
    (page)="changePagesize($event)" [pageSize]="this.aggManager.limit" showFirstLastButtons></mat-paginator>
</div>


<div *ngIf="showAddPopup"
  style="position: absolute; top:0px; left:0px; background-color: #0303039c;z-index: 9; width: 100%; height: 100%;">
  <div style="    background-color: white;
    display: inline-block;
    width: 30%;
    top: 30%;
    LEFT: 36%;
    POSITION: absolute;">
    <form name="myForm" [formGroup]="AddAggregatorForm" (submit)="AddAggregator()">
      <div class="modal-header">
        <h4 class="modal-title">Add Aggregator</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeInvite()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <small *ngIf="!name.valid && name.dirty" class="form-text text-danger">Please enter name.</small><br>


        <input type="email" class="form-control" (keypress)="omit_special_char($event)"
          placeholder="Please enter aggregator name" formControlName="name" [ngClass]="{
        'border-danger': !name.valid && name.dirty,
        'border-success': name.valid && name.dirty
       }">


      </div>
      <div class="modal-footer">

        <button type="submit" [disabled]="name.invalid" class="btn btn-info ">Add</button>

        <button type="button" class="btn btn-outline-dark" (click)="closeInvite()">Close</button>
      </div>
    </form>
  </div>
</div>
<nz-drawer *ngIf="this.aggSummary" [nzWidth]="900" [nzClosable]="false" [nzVisible]="visible" nzPlacement="right"
  nzTitle="{{ this.aggSummary.name}}" (nzOnClose)="close()">
  <ng-container *nzDrawerContent>

    <span class="summary-state">{{ this.aggSummary.state}}</span>
    <div class="row drawer-widgets" *ngIf="visibleSummary">
      <div class="col-lg-12"
        *ngIf="this.aggManager.searchtermsSummary && this.aggManager.searchtermsSummary.length > 0">
        <div class="chart-card pt-3 pb-3 px-4">
          <div class="d-flex align-items-center">
            <h4 class="text-color-dark font-weight-bold mb-2">Search Terms</h4>
          </div>
          <div>
            <nz-tag *ngFor="let st of this.aggManager.searchtermsSummary">{{st.searchTerm}}
            </nz-tag>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="chart-card pt-3 pb-3 px-4">
          <div class="d-flex align-items-center">
            <h4 class="text-color-dark font-weight-bold mb-2">Results</h4>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="d-flex flex-column flex-fill">
                <div class="text-style-p1 text-color-dark-light">Total </div>
                <div class="text-style-h3 text-color-dark">{{
                  visualizationManagerService.sentimentsData[4]?.totalExecutedResults | number }} </div>
                <!-- <img class="dashboard-icon" src="./assets/images/totalExecutionResults.png"
                  alt="Total Execution Results"> -->
              </div>

            </div>
            <div class="col-lg-6">
              <div class="d-flex flex-column flex-fill">
                <div class="text-style-p1 text-color-dark-light">Last Execution</div>
                <div class="text-style-h3 text-color-dark">{{
                  visualizationManagerService.sentimentsData[4]?.lastExecutedResults | number }} </div>
                <!-- <img class="dashboard-icon" src="./assets/images/lastExecutionIcon.png" alt="Last Execution"> -->
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-6">
        <div class="chart-card d-flex flex-row align-items-center py-4 px-4 dashboard-top-card">
          <div class="d-flex flex-column flex-fill">
            <div class="text-style-p1 text-color-dark-light"> Total Results </div>
            <div class="text-style-h3 text-color-dark">{{
              visualizationManagerService.sentimentsData[4]?.totalExecutedResults | number }} </div>
          </div>
          <img class="dashboard-icon" src="./assets/images/totalExecutionResults.png" alt="Total Execution Results">
        </div>
      </div>
      <div class="col-6">
        <div class="chart-card d-flex flex-row align-items-center py-4 px-4 dashboard-top-card">
          <div class="d-flex flex-column flex-fill">
            <div class="text-style-p1 text-color-dark-light"> Last Execution Results</div>
            <div class="text-style-h3 text-color-dark">{{
              visualizationManagerService.sentimentsData[4]?.lastExecutedResults | number }} </div>
          </div>
          <img class="dashboard-icon" src="./assets/images/lastExecutionIcon.png" alt="Last Execution">
        </div>
      </div> -->



      <div class="col-12">
        <div class="chart-card p-3" *ngIf="sentimentByScore">
          <div class="d-flex align-items-center mb-2">
            <h4 class="text-style-p1 text-color-dark font-weight-bold m-2"> Source Counts</h4>
          </div>
          <div class="d-flex flex-column align-items-stretch m-2">
            <div class="progress">
              <div class="progress-bar" *ngFor="let source of sortByAnalysed(sentimentByScore?.sources)"
                data-toggle="tooltip" data-placement="bottom"
                [title]=" source?.source + ' ' + source?.analysedPercentage + '%'"
                [style.backgroundColor]="colorFunc(source?.source)" [style.width]="source?.analysedPercentage + '%'"
                role="progressbar"></div>
            </div>


            <div class="d-flex flex-fill overflow-auto flex-column mt-3">

              <div class="d-flex flex-row my-2 align-items-center"
                *ngFor="let source of sortByAnalysed(sentimentByScore.sources)">
                <div class="w-50 d-flex align-items-center">
                  <img class="mini-icons" [src]="resolveIcon(source?.source)" [alt]="source?.source">
                  <span class="text-style-p1 min-w70 pr-2 text-color-dark-light">{{ source?.source }}</span>
                  <i class="fa fa-square-full" [style.color]="colorFunc(source?.source)"></i>
                </div>
                <div class="w-50 d-flex flex-row justify-content-between align-items-center">
                  <span class="text-style-p1 text-color-dark-light pr-3">{{ source?.analysed | number }}</span>
                  <span class="text-style-p1 text-color-dark-light pr-3">{{ source?.analysedPercentage | number:
                    '1.1-1'
                    }}%</span>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
      <div class="col-12">
        <app-source-count-chart #sourcecount></app-source-count-chart>
      </div>
    </div>
    <nz-spin class="pt-4" [nzSpinning]="this.visualizationManagerService.loadSummary">
    </nz-spin>
    <div *ngIf="noDataFound">
      <div class="empty-data">
        <i class="fa fa-solid fa-box-open"></i>
        <strong>Not available!</strong>
      </div>
    </div>

  </ng-container>
</nz-drawer>