import { Component, OnInit,AfterViewInit, ViewChild } from '@angular/core';
import { FormGroup,FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { ErrorService } from '../../services/error.service';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})
export class ChangepasswordComponent implements OnInit,AfterViewInit {


  @ViewChild('content', { static: false }) public content;

  public error = '';
  showPassword = false;
  showNewPassword = false;
  showconfirmPassword=false;
  isLoading = false;
  public passwordForm: FormGroup;
  public newpassword: FormControl;
  public password: FormControl;
  public confirmpassword: FormControl;
  public passwordPattern: any;
  constructor(public route:Router,private FormBuild: FormBuilder,public authService:AuthenticationService,  public errorService: ErrorService) {
    this.passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@\-#\$%\^&\*])(?=.{10,})/;
   }

  ngOnInit(): void {
    this.createFormControls();
    this.createForm();
  }


  ngAfterViewInit(): void
  {
    this.errorService.errorContent =this.content;
  }

  createForm() {
    this.passwordForm = this.FormBuild.group({
      newpassword: this.newpassword,
      password: this.password,
      confirmpassword: this.confirmpassword
      
    }, { validator: this.matchPasswords } );
  }

  createFormControls() {
    this.password = new FormControl('', [
      Validators.required
    ]);

    this.newpassword = new FormControl('', [
      Validators.required,
      Validators.pattern(this.passwordPattern)
    ]);

    this.confirmpassword = new FormControl('', [
      Validators.required,
      Validators.pattern(this.passwordPattern)
    ]);

   
  }

 
  matchPasswords(formGroup: FormGroup) {
    return (
      formGroup.controls.newpassword.value === formGroup.controls.confirmpassword.value
    ) ? null : formGroup.controls.confirmpassword .setErrors({NotSame: true});
  }

  changePassword()
  {
    this.authService.changePassword(this.password.value,this.newpassword.value,this.confirmpassword.value).subscribe(c=>{
      this.route.navigate(['']);
    });

  }

  cancel()
 {
  this.route.navigate(['']);
 }

}
