/* tslint:disable */

import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  Input,
  AfterViewInit,
} from '@angular/core';
import { addContextMenu, d3Modify, waitInMS } from '../../../services/utils';
import { VisualizationManagerService } from '../../../Managers/visualization-manager.service';
import { DocumentSourceService } from '../../../services/document-source.service';
import { AggregatorManagerService } from '../../../Managers/aggregator-manager.service';
import { DemoService } from '../../../services/demo.service';
import { viz_type } from '../../../models/dashboard';
import { DashboardManagerService } from '../../../Managers/dashboard-manager.service';

const d3: any = addContextMenu();

@Component({
  selector: 'app-pack',
  templateUrl: './pack.component.html',
  styleUrls: ['./pack.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class PackComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('packContainer', { static: true })
  private packContainer: ElementRef;

  visualizationSubscription = null;
  loaded = false;

  @Input() height: number = null;
  constructor(
    public visualizationManagerService: VisualizationManagerService,
    public dashboardManager: DashboardManagerService,
    public aggregatorManagerService: AggregatorManagerService,
    public documentSourceService: DocumentSourceService,
    public demoService: DemoService
  ) {
    this._serviceSubscription = this.dashboardManager.onExport.subscribe(
      (c) => {
        this.export();
      }
    );
  }

  async initChart(): Promise<any> {
    const element = this.packContainer.nativeElement;
    element.innerHTML = '';
    // await waitInMS(50);
    this.createChart();
  }

  createChart(): void {
    if (!this.loaded) {
      return;
    }
    if (
      !this.visualizationManagerService.visualizationInfo ||
      !this.visualizationManagerService.visualizationData
    ) {
      return;
    }
    if (this.visualizationManagerService.visualizationSearchFailed) {
      return;
    }

    const thisComponent = this;
    const updateNodeStyle = (d, i, nodeMap) => {
      d3.select(nodeMap[i]).style(
        'fill',
        d.data.flagged ? '#ffca00' : '#eff5ff'
      );
    };
    const menu = (nodeD: any, i: any, nodeMap: any) => [
      nodeD.data.flagged
        ? {
            title: 'Unflag',
            action: () => {
              nodeD.data.flagged = false;
              const { documentId } = nodeD.data;
              thisComponent.visualizationManagerService.changeFlagStatus({
                id: documentId,
                status: false,
              });
              updateNodeStyle(nodeD, i, nodeMap);
            },
          }
        : {
            title: 'Flag',
            action: () => {
              nodeD.data.flagged = true;
              const { documentId } = nodeD.data;
              thisComponent.visualizationManagerService.changeFlagStatus({
                id: documentId,
                status: true,
              });
              updateNodeStyle(nodeD, i, nodeMap);
            },
          },
      {
        title: 'Source',
        action: () => {
          const { documentId } = nodeD.data;
          thisComponent.documentSourceService.getAndOpenSource(
            { id: documentId },
            thisComponent.aggregatorManagerService.selectedAggregator.id
          );
        },
      },
    ];
    const onContext = (d, i, allNodes) => {
      d3.event.stopPropagation();
      d3.event.preventDefault();
      if (!d.data.children) {
        if (this.demoService.demoMode) {
          const { documentId } = d.data;
          thisComponent.documentSourceService.getAndOpenSource(
            { id: documentId },
            thisComponent.aggregatorManagerService.selectedAggregator.id
          );
        } else {
          d3.contextMenu(menu(d, i, allNodes))(d, i, allNodes);
        }
      }
    };

    // let color = d3.scaleLinear()
    //   .domain([-1, 5])
    //   .range(['hsl(244.8,62%,19.6%)','hsl(241.1,74.4%,55.5%)'])
    //   .interpolate(d3.interpolateHcl);

    const color = d3
      .scaleLinear()
      .domain([-1, 5])
      .range(['hsl(244.8,62%,19.6%)', 'hsl(241.1,74.4%,55.5%)'])
      .interpolate(d3.interpolateHcl);

    const format = d3.format(',d');

    const element = this.packContainer.nativeElement;
    element.innerHTML = '';
    const width = element.offsetWidth;
    var height = element.offsetHeight;
    if (this.height) {
      height = this.height;
    }
    const pack = (data) =>
      d3
        .pack()
        .size([height / 2, height / 2])
        .padding(3)(
        d3
          .hierarchy(data)
          .sum((d) => d.value)
          .sort((a, b) => b.value - a.value)
      );

    const clonedData = JSON.parse(
      JSON.stringify(
        thisComponent.visualizationManagerService.visualizationSearchData ||
          thisComponent.visualizationManagerService.visualizationData
      )
    );
    const aggregatorName =
      thisComponent.visualizationManagerService.visualizationInfo
        .aggregator_visualization_info.name;

    const packData: any = d3Modify(clonedData, aggregatorName);

    const root = pack(packData);
    let focus = root;
    let view;

    const tooltip = d3
      .select(element)
      .append('div')
      .attr('class', 'tooltip')
      .style('opacity', 0);
    const mouseOverMove = (d) => {
      if (d.data.name != null && d.data.name !== '') {
        tooltip.transition().duration(200).style('opacity', 0.9);
        tooltip
          .html(d.data.name)
          .style('left', d3.event.pageX + 'px')
          .style('top', d3.event.pageY - 28 + 'px');
      }
    };
    const mouseOut = (d) => {
      if (d.parent && d.parent.data.name) {
        tooltip
          .html(d.parent.data.name)
          .style('left', d3.event.pageX + 'px')
          .style('top', d3.event.pageY - 28 + 'px');
      } else {
        tooltip.transition().duration(500).style('opacity', 0);
      }
    };
    const svg = d3
      .select(element)
      .append('svg')
      .attr('viewBox', `-${width / 2} -${height / 2} ${width} ${height}`)
      .style('display', 'block')
      .style('margin', '0vh 0vh')
      .style('background', '#FFF')
      .style('cursor', 'pointer')
      .on('click', () => zoom(root));

    const node = svg
      .append('g')
      .selectAll('circle')
      .data(root.descendants().slice(0))
      .join('circle')
      .attr('class', function (d) {
        return d.parent
          ? d.children
            ? 'node'
            : 'node node--leaf'
          : 'node node--root';
      })
      .attr('fill', (d) =>
        d.children ? color(d.depth) : d.data.flagged ? '#ffca00' : '#f7fffc'
      )
      .attr('pointer-events', (d) => (!d.children ? null : null))
      .on('mouseover', function () {
        d3.select(this).attr('stroke', '#000');
      })
      .on('mouseout', function () {
        d3.select(this).attr('stroke', null);
      })
      .on('click', (d) => {
        d3.event.stopPropagation();
        if (!d.data.children) {
          const { documentId, name } = d.data;
          thisComponent.visualizationManagerService.showDocumentText({
            id: documentId,
            text: name,
          });
          return;
        }
        return focus !== d && zoom(d);
      })
      .on('contextmenu', onContext)
      .on('mouseover', mouseOverMove)
      .on('mouseout', mouseOut);

    const label = svg
      .append('g')
      .attr('class', 'label')
      .attr('pointer-events', 'none')
      .attr('text-anchor', 'middle')
      .selectAll('text')
      .data(root.descendants())
      .join('text')
      .style('font-size', (d) => (d.data.children ? '13px' : '5px'))
      .style('font-weight', (d) => (d.data.children ? '200' : '100'))
      .style('fill', (d) => (d.data.children ? 'white' : 'transparent'))
      .style('fill-opacity', (d) => (d.parent === root ? 1 : 0))
      .style('display', (d) => (d.parent === root ? 'inline' : 'none'))
      .text((d) =>
        d.parent === root
          ? d.data.name.length > 20
            ? d.data.name.substr(0, 19)
            : d.data.name
          : this.height
          ? ''
          : d.data.name.length > 20
          ? d.data.name.substr(0, 19)
          : d.data.name
      )
      .on('click', (d) => console.log(d, 'text'));

    zoomTo([root.x, root.y, root.r * 2]);

    function zoomTo(v) {
      const k = height / v[2];

      view = v;

      label.attr(
        'transform',
        (d) => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`
      );
      node.attr(
        'transform',
        (d) => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`
      );
      node.attr('r', (d) => d.r * k);
    }

    function zoom(d) {
      const focus0 = focus;

      focus = d;

      const transition = svg
        .transition()
        .duration(d3.event.altKey ? 7500 : 750)
        .tween('zoom', (inD) => {
          const i = d3.interpolateZoom(view, [focus.x, focus.y, focus.r * 2]);
          return (t) => zoomTo(i(t));
        });

      label
        .filter(function (inD) {
          return inD.parent === focus || this.style.display === 'inline';
        })
        .transition(transition)
        .style('fill-opacity', (inD) => (inD.parent === focus ? 1 : 0))
        // tslint:disable-next-line:curly
        .on('start', function (inD) {
          if (inD.parent === focus) {
            this.style.display = 'inline';
          }
        })
        // tslint:disable-next-line:curly
        .on('end', function (inD) {
          if (inD.parent !== focus) {
            this.style.display = 'none';
          }
        });
    }
  }

  ngAfterViewInit() {}

  ngOnDestroy(): void {
    if (this._serviceSubscription) this._serviceSubscription.unsubscribe();
    this.loaded = false;
    this.visualizationSubscription.unsubscribe();
  }

  _serviceSubscription: any;
  ngOnInit(): void {
    this.dashboardManager.viz_types.forEach((c) => {
      if (c.viz_uid == this.viz_uid) {
        this.viz_type = c;
      }
    });
    this.loaded = true;
    this.visualizationSubscription =
      this.visualizationManagerService.visualizingInit.subscribe(() =>
        this.initChart().then().catch(console.log)
      );
    this.initChart().then().catch(console.log);
  }

  viz_uid = '000000000000000000000001';

  public viz_type: viz_type;
  Add() {
    this.dashboardManager.viz_types.forEach((c) => {
      if (c.viz_uid == this.viz_uid) {
        this.viz_type = c;
      }
    });
    this.viz_type.isEnabled = true;
    this.dashboardManager.AddDashboardItem(
      this.aggregatorManagerService.selectedAggregator.id,
      this.viz_type.viz_uid,
      1
    );
  }

  export() {
    if (this.height) {
      const element = document.querySelector('div#packContainer svg');

      this.dashboardManager.dashboard_items.forEach((c) => {
        if (c.viz_uid == this.viz_uid) {
          c.viz_type = this.viz_type.viz_type;
          c.viz_svg =
            '<svg xmlns="http://www.w3.org/2000/svg" width="600" height="600" viewBox="0 0 600 600" preserveAspectRatio="xMinYMin" style="display: block; margin: 0vh; background: rgb(255, 255, 255); cursor: pointer;" > <g transform="translate(250,250)">' +
            element.innerHTML +
            '</g></svg>';
        }
      });
    }
  }
}
