import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  ViewChild,
  OnChanges,
} from '@angular/core';
import { general_Image } from '../../../../models/dashboard';

@Component({
  selector: 'app-cv-pipline-sideview',
  templateUrl: './cv-pipline-sideview.component.html',
  styleUrls: ['./cv-pipline-sideview.component.css'],
})
export class CvPiplineSideviewComponent
  implements OnInit, AfterViewInit, OnChanges
{
  @ViewChild('layout22', { static: true }) canvasRef;
  @Input() image: general_Image;
  @Input() parent: string;
  @Input() width: number;

  @Input() isFullScreen: boolean = false;

  @Input() height: number;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.width = document.getElementById(this.parent).offsetWidth;
    this.height = document.getElementById(this.parent).offsetHeight;

    this.drawRectangle('--');
  }

  drawRectangle(location): void {
    let canvas = this.canvasRef.nativeElement;
    let context = canvas.getContext('2d');
    // context.clearRect(0, 0, canvas.width, canvas.height);

    context.clearRect(0, 0, this.width, this.height);

    let source = new Image();
    //source.setAttribute('loading','lazy');
    //source.setAttribute('crossorigin', 'anonymous');

    console.log(this.image.image_url);
    source.src = this.image.image_url;

    source.onload = () => {
      console.log(source);

      this.DrawImage(source, canvas, context);
    };

    source.onerror = (c) => {};
  }

  private DrawImage(source: HTMLImageElement, canvas: any, context: any) {
    if (this.isFullScreen) {
      if (this.width > source.width) {
        this.width = source.width;
      }
    }

    var scale = Math.max(
      this.width / source.width,
      this.height / source.height
    );

    var scalex = this.width / source.width;
    var scaley = this.height / source.height;
    // get the top left position of the image
    var x = canvas.width / 2 - (source.width / 2) * scalex;
    var y = canvas.height / 2 - (source.height / 2) * scaley;

    // this.width = source.width * scale;
    // this.height = source.height * scale;
    try {
      context.drawImage(
        source,
        x,
        y,
        Math.round(source.width * scalex),
        Math.round(source.height * scaley)
      );

      if (this.image.object_detection) {
        //general image detection
        this.image.object_detection.forEach((c) => {
          context.beginPath();
          context.rect(
            c.bbox.x1 * scalex,
            c.bbox.y1 * scaley,
            (c.bbox.x2 - c.bbox.x1) * scalex,
            (c.bbox.y2 - c.bbox.y1) * scaley
          );
          context.strokeStyle = '#FF0000';
          context.lineWidth = 3;
          context.stroke();
        });
      }
      if (this.image.face_detection) {
        this.image.face_detection.forEach((c) => {
          context.beginPath();
          //let region = { x: c.bbox.x1 * (scalex) , y: c.bbox.y1 * ( scaley) , w: c.bbox.x2 * (scalex), h: c.bbox.y2 * (scaley) };
          context.rect(
            c.bbox.x1 * scalex,
            c.bbox.y1 * scaley,
            (c.bbox.x2 - c.bbox.x1) * scalex,
            (c.bbox.y2 - c.bbox.y1) * scaley
          );
          context.strokeStyle = '#FF0000';
          context.lineWidth = 3;
          context.stroke();
        });
      }
    } catch (ex) {}
  }

  ngOnChanges() {
    //if (this.isScrolledIntoView(this.canvasRef.nativeElement)) {

    this.drawRectangle('Change');

    // }
    //console.log("This is: "+ Math.max.apply(Math, this.tooltip.map(function(o) { return o.score; })))
  }
}
