export class aggregatorSummary {
  comment_count: number;
  conversed_count: number;
  enable_aggregator_processed_alert: boolean;
  flagged_count: number;
  id: number;
  last_executed: Date;
  name: string;
  schedule_type: string;
  scheduled_for: shedule_date;
  state: string;
  total_results: number;
  created: Date;
}

export class schedule_types {
  schedule_type_id: number;
  schedule_type: string;
}
export class shedule_date {
  date: number;
  month: number;
  year: number;
}

export class history {
  comment: string;
  comment_count: number;
  conversed_count: number;
  execution_ended_date: string;
  execution_ended_time: string;
  execution_started_date: string;
  execution_started_time: string;
  flagged_count: number;
  id: number;
  sentiment_score: number;
  status: string;
  total_results: number;
}

export class search {
  comment_count: number;
  conversed: boolean;
  date: string;
  flagged: boolean;
  id: number;
  link: string;
  score: number;
  source: string;
  source_name: string;
  text: string;
  time: string;
}
export class date {
  date: number;
  month: number;
  year: number;
}
export class aggregator_search_term {
  searchTerm: string;
  id: number;
  type: string;
}

export class aggregator_confluence_text {
  text: string;
  id: number;
  type: string;
}

export class aggregator_exclude_filters {
  excludeFilter: string;
  id: number;
  type: number;
}

export class aggregator {
  schedule_type: string;
  instagram_aggregator: source_aggregator;
  scheduled_for: date;
  total_results: number;
  google_aggregator: source_aggregator;
  facebook_aggregator: source_aggregator;
  fxnews_aggregator: source_aggregator;
  total_aggregator_search_terms: number;
  aggregator_exclude_filters: aggregator_exclude_filters[];
  name: string;
  reddit_aggregator: source_aggregator;

  aggregator_search_terms: aggregator_search_term[];
  id: number;
  state: string;
  website_aggregator: source_aggregator;

  total_aggregator_exclude_filters: number;

  last_executed: Date;

  document_aggregator: source_aggregator;
  twitter_aggregator: source_aggregator;
  youtube_aggregator: source_aggregator;
  confluence_aggregator: source_aggregator;
}

export class source_aggregator {
  crawl_level: number;
  follow_external_links: boolean;
  total_website_aggregator_links: number;
  id: number;
  website_aggregator_links: website_aggregator[];
  document_aggregator_links: document_aggregator[];
  enabled: boolean;

  confluence_aggregator_spaces: spaces[];
  document_collections: document[];
}

export class spaces {
  id: number;
  space_key: string;
}
export class document {
  constructor(id, name) {
    this.id = id;
    this.name = name;
  }
  id: number;
  name: string;
  state: string;
  total_documents: number;
  type: string;
}

export class website_aggregator {
  id: number;
  url: string;
}
export class document_aggregator {
  id: number;
  url: string;
}

export class comment {
  date: string;
  search_result_id: number;
  user_name: string;
  comment: string;
  id: number;
  time: string;
  own_comment: boolean;
}

export class search_result_conversation {
  comment_count: number;
  date: string;
  aggregator_id: number;
  search_result_id: number;
  user_name: string;
  link: string;
  aggregator_name: string;
  source: string;
  text: string;
  time: string;
  source_name: string;
}

export class cluster {
  cluster: string;
  cluster_id: number;
  selected?: boolean;
  indeterminate?: boolean;
  cluster_terms: cluster_terms[];
}
export class cluster_terms {
  term: string;
  term_id: number;
  selected?: boolean;
  indeterminate?: boolean;
}

export class terms {
  name: string;
  id?: number;
  selected?: boolean;
  indeterminate?: boolean;
  children?: terms[];
}
