import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { getRequestId } from './utils';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class AudiorecorderService {
  constructor(private http: HttpClient) {}

  uploadaudio(base64Audio, name) {
    const data = {
      base64_audio: base64Audio,
    };
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    const options = { withCredentials: false, header };
    //
    return this.http.post(
      environment.speechAnalytics + 'search',
      data,
      options
    );
  }

  uploadYoutubeLink(link, url_type) {
    const data: FormData = new FormData();

    data.append('link', link);
    data.append('url_type', url_type);
    data.append('diarization', 'true');
    //
    const headers = new HttpHeaders();
    const options = { headers };
    return this.http.post(
      environment.speechURL +
        '/predict_file?link=' +
        link +
        '&url_type=' +
        url_type +
        '&diarization=true',
      data,
      options
    );
  }
}
