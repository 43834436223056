 

<div class="mt-4  text-center ">

  <mat-button-toggle-group name="Analysis_selected" [(ngModel)]="Analysis_selected" aria-label="Font Style">
    <mat-button-toggle value="Image Analysis" class="selection" mat-stroked-button (click)="AnalysisChange()" > Image Analysis
    </mat-button-toggle>

    <mat-button-toggle value="Text Analysis" class="selection" mat-stroked-button (click)="AnalysisChange()" > Text
      Analysis</mat-button-toggle>
    <mat-button-toggle value="Person Analysis" class="selection" (click)="AnalysisChange()" mat-stroked-button> Person Analysis</mat-button-toggle>
  </mat-button-toggle-group>
</div>



  <div class="d-flex mt-1">


    <div *ngIf="Analysis_selected === 'Image Analysis'" class="w-15">
      <mat-button-toggle-group aria-label="Font Style" style="width: 100%;">


        <mat-button-toggle value="Object Detection" checked="true" class="selection2" mat-stroked-button
          (click)="selectTexonomy(true)">Object Detection</mat-button-toggle>
        <mat-button-toggle value="Classification" class="selection2" mat-stroked-button (click)="selectTexonomy(false)">
          Classification</mat-button-toggle>
      </mat-button-toggle-group>

      <div  style="background-color: white; overflow: auto;
      height: calc(100vh - 200px);">

      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
        <!-- This is the tree node template for leaf nodes -->
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle  [ngClass]="{ 'background-highlight': activeNode === node }">
          <li class="mat-tree-node" (click)="onClick(node)">
            <!-- use a disabled button to provide padding for tree leaf -->
            <button mat-icon-button ></button>
            {{node.name}} ({{node.count}})
          </li>
        </mat-tree-node>
        <!-- This is the tree node template for expandable nodes -->
        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" >
          <li (click)="onClick(node)">
            <div class="mat-tree-node" [ngClass]="{ 'background-highlight': activeNode === node }">
              <button mat-icon-button matTreeNodeToggle click="onClick(node)" [attr.aria-label]="'Toggle ' + node.name ">
                <mat-icon class="mat-icon-rtl-mirror">
                  {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
              </button>
              {{node.name}} ({{node.count}})
            </div>
            <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
              <ng-container matTreeNodeOutlet>

              </ng-container>
            </ul>
          </li>
        </mat-nested-tree-node>
      </mat-tree>
    </div>
    </div>


    <div *ngIf="(Analysis_selected === 'Text Analysis')" class="w-15"  style="background-color: white;">

      <mat-form-field appearance="outline" class="mt-1 ml-1" style="width: 95%;"
        *ngIf="Analysis_selected == 'Text Analysis'">
        <mat-label>Search Word</mat-label>

        <input matInput placeholder="Search Word" [formControl]="myControl">

        <button mat-button *ngIf="myControl.value" matSuffix mat-icon-button aria-label="Clear"
          (click)="myControl.value=''">
          <mat-icon>close</mat-icon>
        </button>

      </mat-form-field>

      <div  style="background-color: white; overflow: auto;
      height: calc(100vh - 250px);">
      <div class="ml-1 d-grid" style="margin-top: 0px;">


        <button mat-stroked-button class=" mt-1 ml-2 mr-2 text-left" [ngClass]="{'active': selectedword === option.word}" *ngFor="let option of filteredOptions | async"
          (click)="filterText(option.word)"> {{option.word + ' ( ' + option.freqeuncy +' )'}}</button>


      </div>
      </div>


    </div>


    <div *ngIf="(Analysis_selected === 'Person Analysis')" class="w-15 pl-1 pr-1" style="background-color: white; overflow: auto;
height: calc(100vh - 150px);">

     
      <h3 class="mt-3">Gender</h3>



      <mat-selection-list [(ngModel)]="selectedGender">
        <mat-list-option *ngFor="let gender of genderlist" (click)="ApplyFilter()"  [value]="gender" [selected]="selectedGender?.includes(gender)">
          {{gender}}
        </mat-list-option>
      </mat-selection-list>



      <h3 class="mt-1">Age</h3>
      <div class="d-inline-flex ml-3 mt-2">
        <div class="age-label-container">
          <label id="age-name-label" class="age-name-label">Minimum</label>
          <mat-slider [max]="120" [min]="-1" [step]="1" (ngModelChange)="ApplyFilter()" [thumbLabel]="true" [(ngModel)]="minAge"
            aria-labelledby="age-name-label">
          </mat-slider>

          <input type="number" class="age-value-label age-input" [(ngModel)]="minAge" (ngModelChange)="ApplyFilter()">
        </div>


      </div>
      <div class="ml-5" class="d-inline-flex ml-3">
        <div class="age-label-container">
          <label id="age-name-label" class="age-name-label">Maximum</label>
          <mat-slider [max]="120" [min]="-1" [step]="1" (ngModelChange)="ApplyFilter()"  [thumbLabel]="true" [(ngModel)]="maxAge"
            aria-labelledby="age-name-label">
          </mat-slider>
          <input type="number" class="age-value-label age-input" [(ngModel)]="maxAge" (ngModelChange)="ApplyFilter()">
        </div>


      </div>
      <div style="position: relative;">
      <h3 class="mt-1">Emotions  </h3> 
    
  <mat-checkbox 
  style="position: absolute; top: 0; right: 17px;"
         [color]="accent"
        (change)="setAllEmotion($event.checked)"
        [checked]="selectedEmotion?.length == 7"> </mat-checkbox>

      </div>
 
      <mat-selection-list [(ngModel)]="selectedEmotion" >
        <mat-list-option *ngFor="let emotion of typesOfEmotion" (click)="ApplyFilter()"   [value]="emotion" [selected]="selectedEmotion?.includes(emotion)" >
          {{emotion}}
        </mat-list-option>
      </mat-selection-list>


    <!--  <button  style="  width: 100%;" (click)="ApplyFilter()" class="mt-2"  mat-raised-button
      color="accent" aria-label="update">
      Apply Filter
    </button> -->

    </div>


    <div class="w-85" [ngClass]="{'w-45': selectedImage != undefined}" *ngIf="(Analysis_selected === 'Image Analysis')"    style=" overflow: auto;
    height: calc(100vh - 150px);">

      <div  id="loadedimages"  class="images-wrapper mt-2"   >
        <div id="imageplacehoder{{i}}"   class="placehoder ml-2 mt-2 d-inline-block"  [ngClass]="{'active': selectedImage === img}"  *ngFor="let img of this.vManager.generalImages; let i = index">
 
          <app-cv-pipeline-image [parentId]="i" imgUrl="{{img.image_url}}" image="{{img}}" (onClick)="SelectImage(img)" [object_detection]="img.object_detection" >
          </app-cv-pipeline-image>
        </div>
      </div>
    </div>

    
    <div class="w-85" *ngIf="(Analysis_selected === 'Text Analysis')" >

      <div style=" overflow: auto;
    height: calc(100vh - 150px);">
        <div class=" ml-2 mt-2 d-inline-block" *ngFor="let img of this.filterImgaes">
 
          <app-cv-pipleline-text-detection imgUrl="{{img.image_url}}" isTextDetection="true"   [tooltip]="img.text_recognition">
          </app-cv-pipleline-text-detection>
        </div>
      </div>
    </div>


    <div class="w-85" [ngClass]="{'w-45': selectedImage != undefined}" *ngIf="(Analysis_selected === 'Person Analysis')"   style=" overflow: auto;
    height: calc(100vh - 150px);" >

      <div  id="loadedimages"  class="images-wrapper mt-2" [ngClass]="{'detailview': selectedImage != undefined}"    >
        <div  id="imageplacehoder{{i}}" class="placehoder ml-2 mt-2 d-inline-block"     [ngClass]="{'active': selectedImage === img}" *ngFor="let img of this.vManager.personImageAnalytics; let i = index">
 
          <app-cv-pipeline-image  [parentId]="i" (onClick)="SelectImage(img)"  imgUrl="{{img.image_url}}" [face_detection]="img.face_detection"   >
          </app-cv-pipeline-image>
        </div>
      </div>
    </div>


    <div class="w-45" id="cvsidelayout"   *ngIf="selectedImage" style="align-items: center; height: calc(100vh - 150px); position: relative;" >
<!--<img src="{{selectedImage.image_url}}" style="width: 100%; height: 100%;">  -->
<app-cv-pipline-sideview parent="cvsidelayout" [image]="selectedImage" ></app-cv-pipline-sideview>

<button class="ml-2 closebutton" style="position: absolute; right: 20px; top: 1px;" (click)="isFullscreen = true;"><i
  class="fas fa-arrows-alt"></i></button>
<button class="ml-2 closebutton" style="position: absolute; right: 0px; top: 1px;" (click)="closeDetail()"><i
  class="fas fa-times"></i></button>
    </div>

  </div>

  <div id="cvFullscreen" *ngIf="isFullscreen" style="position: absolute;background-color: #ffffffe0; top:0px; left:0px; text-align: center; width: 100%; height: 100%; ">
    <button class="ml-2 closebutton" style="position: absolute; right: 0px; top: 1px;" (click)="isFullscreen = false;"><i
      class="fas fa-times"></i></button>
    <app-cv-pipline-sideview parent="cvFullscreen" style="margin: 20px;" [image]="selectedImage" [isFullScreen]="true" ></app-cv-pipline-sideview>
  </div>

 