import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormGroupDirective,
  NgForm,
} from '@angular/forms';
import { first } from 'rxjs/operators';
import { ErrorStateMatcher } from '@angular/material/core';
import { UserManagerService } from '../../../Managers/user-manager.service';
import { user } from '../../../models/user';

@Component({
  selector: 'app-edituser',
  templateUrl: './edituser.component.html',
  styleUrls: ['./edituser.component.css'],
})
export class EdituserComponent implements OnInit {
  registerForm: FormGroup;
  isEdit: boolean = true;
  constructor(
    public route: Router,
    private formBuilder: FormBuilder,
    public userManager: UserManagerService
  ) {}

  ngOnInit(): void {
    if (this.userManager.selectedUser) {
      this.isEdit = true;
    } else {
      this.isEdit = false;
    }

    if (this.isEdit) {
      this.registerForm = this.formBuilder.group({
        name: ['', [Validators.required]],
        email: [
          { value: '', disabled: true },
          [Validators.required, Validators.email],
        ],
        type: [''],
        state: [''],
      });

      this.registerForm.controls['name'].setValue(
        this.userManager.selectedUser.name
      );
      this.registerForm.controls['email'].setValue(
        this.userManager.selectedUser.email
      );

      if (this.userManager.selectedUser.type == 'Analyst')
        this.registerForm.controls['type'].setValue('Administrator');
      else if (this.userManager.selectedUser.type == 'Viewer') {
        this.registerForm.controls['type'].setValue('Regular');
      } else {
        this.registerForm.controls['type'].setValue(
          this.userManager.selectedUser.type
        );
      }
      this.registerForm.controls['state'].setValue(
        this.userManager.selectedUser.state
      );
    } else {
      this.registerForm = this.formBuilder.group({
        name: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
        type: [''],
        state: [''],
      });
    }
  }

  onSubmit(value: any) {
    if (this.registerForm.valid) {
      if (this.isEdit) {
        this.userManager.selectedUser.name =
          this.registerForm.controls['name'].value;
        this.userManager.selectedUser.type =
          this.registerForm.controls['type'].value;
        this.userManager.selectedUser.state =
          this.registerForm.controls['state'].value;

        this.userManager.updateUsers();
      } else {
        this.userManager.AddUsers(
          this.registerForm.controls['name'].value,
          this.registerForm.controls['email'].value,
          this.registerForm.controls['type'].value,
          this.registerForm.controls['state'].value
        );

        //   this.isEdit = true;
      }
    }
  }

  cancel() {
    this.route.navigate(['users']);
  }
}
