<div class=" justify-content-between align-items-center px-2  ">


  <div class="inline ml-2">
    <h2 class="ml-2">Files</h2>
    <div *ngIf="this.aggrManager.csv != null && this.aggrManager.csv.length == 0">
      <div class="alert alert-warning ml-1">Upload Excel/CSV files in Aggregator Settings</div>
    </div>
    <button class="ml-2 btn" [ngClass]="{'active ': st.isSelected}" (click)="ChangeFile(st)"
      *ngFor="let st of this.aggrManager.csv; let f = index">{{st.givenFileName}}</button>
  </div>

  <div class="inline mt-2" *ngIf="this.aggrManager.csv != null && this.aggrManager.csv.length > 0">
    <!--<h2 class="ml-2">Columns</h2>
    <button class="ml-2 btn2" [ngClass]="{'active2 ': column.show}" (click)="ShowhideColumn(column)" *ngFor="let column of this.aggrManager.selectedFile?.data">{{column.column_name}} </button>-->

    <button class="ml-4 btn2" [ngClass]="{'active2 ': isNominalSelected}" (click)="nominalSelected()">Nominal</button>

    <button class="ml-2 btn2" [ngClass]="{'active2 ': isNumericSelected}"
      (click)="numericalSelected()">Statistical</button>

    <button class="ml-2 btn2" [ngClass]="{'active2 ': isCombineSelected}"
      (click)="combineSelected()">Correlation</button>
  </div>

</div>


<div style="display: flex; flex-wrap: wrap;  " [hidden]="!isNominalSelected">
  <div class="ml-3 mt-4  col-4" style=" min-width: 600px; max-width: 600px;"
    [ngStyle]="{'display': (column.column_marker.toLowerCase() === 'numeric') ? 'none': 'inline'  }"
    *ngFor="let column of this.aggrManager.selectedFile?.data; let i = index">
    <div class="pl-3 pt-2 pr-2 " *ngIf="isNominalSelected">

      <app-piechart [id]="column.viz_uid " *ngIf="column.column_marker.toLowerCase() === 'nominal'" class="mt-2"
        style="display: block;"></app-piechart>

      <app-text-cloud [id]="column.viz_uid " *ngIf="column.column_marker.toLowerCase() === 'text'"></app-text-cloud>

    </div>
  </div>


</div>
<div style="display: flex; flex-wrap: wrap;   " [hidden]="!isNumericSelected">

  <div class="ml-3 mt-4  col-4" style=" min-width: 600px; max-width: 600px;"
    [ngStyle]="{'display': (column.column_marker.toLowerCase() != 'numeric') ? 'none': 'inline'  }"
    *ngFor="let column of this.aggrManager.selectedFile?.data; let i = index">


    <div class="pl-3 pt-2 pr-2 " *ngIf="column.column_marker.toLowerCase() === 'numeric'">

      <app-barchart [id]="column.viz_uid " *ngIf="column.column_marker.toLowerCase() === 'numeric'" class="mt-2"
        style="display: block;"></app-barchart>

    </div>
  </div>
</div>
<div class="text-center mt-4 " style="background-color: white; border-radius: 10px; height: 100%; position: relative; "
  [hidden]="!isCombineSelected">
  <div>
    <mat-form-field appearance="outline" class="m-2" style="font-weight: bolder;
width: 236px;">
      <mat-label>Select X-axis</mat-label>
      <mat-select>

        <div *ngFor="let cl of this.aggrManager.selectedFile?.data; let i = index">
          <mat-option value="cl.column_name" (click)="selectFirstNumericColumn(cl)"
            *ngIf="cl.column_marker.toLowerCase() === 'numeric'">{{cl.column_name}}</mat-option>
        </div>

      </mat-select>
    </mat-form-field>



    <mat-form-field class="m-2" appearance="outline" style="font-weight: bolder;
width: 236px;">
      <mat-label>Select Y-axis</mat-label>
      <mat-select>

        <div *ngFor="let cl of this.aggrManager.selectedFile?.data; let i = index">
          <mat-option value="cl.column_name" (click)="selectSecondNumericColumn(cl)"
            *ngIf="cl.column_marker.toLowerCase() === 'numeric'">{{cl.column_name}}</mat-option>
        </div>

      </mat-select>
    </mat-form-field>

  </div>
  <app-scatterplots [id]="this.aggrManager.selectedFile?.documentId" class="mt-2"
    [data]="this.firstcolumn?.linechart?.data" style="display: block;"> </app-scatterplots>


</div>
<!--
<div  style="display: flex; flex-wrap: wrap;  ">


 

  
    <div class="card ml-3 mt-4  pl-3 pt-2 pr-2 col-6" [hidden]="!column.show" *ngFor="let column of this.aggrManager.selectedFile?.data; let i = index"   >

        <div  style="display: block;">
<h2>{{column.column_name}}</h2>

<mat-form-field *ngIf="column.column_marker === 'numeric'" appearance="fill" style="float: right; margin-top:-40px">
  <mat-label>Select an numeric column</mat-label>
  <mat-select   >
    <mat-option (click)="selectNumericColumn(undefined,column)" value="column.column_name" selected="true" >{{column.column_name}}</mat-option>
    <div *ngFor="let cl of this.aggrManager.selectedFile?.data; let i = index">
  <mat-option value="cl.column_name" (click)="selectNumericColumn(cl,column)"  *ngIf="cl.column_marker === 'numeric' && cl.column_name != column.column_name">{{cl.column_name}}</mat-option>
    </div>
   
  </mat-select>
</mat-form-field>

<div *ngIf="column.column_marker === 'numeric'" >
 <app-barchart  [id]="this.aggrManager.selectedFile?.documentId + i "  [hidden]="!(column.linechart === undefined)"   class="mt-2" [data]="column.histogram" [statistics]="column.statistics"  style="display: block;" ></app-barchart>
 <app-scatterplots [id]="this.aggrManager.selectedFile?.documentId + i "  [hidden]="!(column.linechart !== undefined)"   class="mt-2" [data]="column?.linechart?.data"   style="display: block;" >   </app-scatterplots>

</div>
 <app-piechart [id]="this.aggrManager.selectedFile?.documentId + (i+1)*23"   *ngIf="column.column_marker === 'nominal'"   class="mt-2" [data]="column.top_nominals"  style="display: block;"></app-piechart>
 
 <app-text-cloud [id]="this.aggrManager.selectedFile?.documentId + (i+1)*23"   *ngIf="column.column_marker === 'text'"  [data]="column.tags_cloud" ></app-text-cloud>

    </div>
 
    
</div>


</div>-->