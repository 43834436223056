import { Component, OnInit, Input, HostListener } from '@angular/core';
import { CloudData, CloudOptions } from 'angular-tag-cloud-module';
import { AggregatorManagerService } from '../../../../Managers/aggregator-manager.service';
import { viz_type } from '../../../../models/dashboard';
import { DashboardManagerService } from '../../../../Managers/dashboard-manager.service';
@Component({
  selector: 'app-text-cloud',
  templateUrl: './text-cloud.component.html',
  styleUrls: ['./text-cloud.component.css']
})
export class TextCloudComponent implements OnInit {


  public options: CloudOptions = {
    // if width is between 0 and 1 it will be set to the width of the upper element multiplied by the value
    width: 1,
    // if height is between 0 and 1 it will be set to the height of the upper element multiplied by the value
    height: 450,
    overflow: false,
     
  };

  @Input() showAddButton : boolean=true;
  @Input() id:string;
   data:CloudData[]=[];
 
   columnName:string="";
  constructor(public aggrManager: AggregatorManagerService,public dashboardManager:DashboardManagerService) { }

  ngOnInit(): void {

    this.DrawCloud();
  }


  public viz_type: viz_type =  new viz_type();
  private DrawCloud() {
    this.aggrManager.csv.forEach(csv => {
      csv.data.forEach(column => {
        if (column.viz_uid == this.id) {
          this.data = column.tags_cloud;
          this.columnName = column.column_name;
        }
      });
    });
  
  }

  Add()
  {
     this.dashboardManager.viz_types.forEach(c=>{
     if(c.viz_uid ==  this.id)
     {
       this.viz_type = c;
     } });
     this.viz_type.isEnabled = true;
    this.dashboardManager.AddDashboardItem(this.aggrManager.selectedAggregator.id,this.id,2);
  }



  @HostListener('window:resize', ['$event'])
  onResize(event) {
 
    this.DrawCloud();
}

}
