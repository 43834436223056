 <div class="chart-card pl-4 pt-4 pr-2 pb-3">
  <h2 >  {{this.columnName}}</h2>
 <!-- <button class="btn2" *ngIf="showAddButton"   (click)="Add(this.viz_type?.viz_uid)" matTooltip="Add to Dashboard"  [disabled]="this.viz_type?.isEnabled"><i class="fa fa-plus"></i></button>-->
 <div class="m-1">   
 <angular-tag-cloud 
        [data]="data"
        [width]="options.width"
        [height]="options.height"
        [overflow]="options.overflow"
         
        [zoomOnHover]="options.zoomOnHover"
        [realignOnResize]="options.realignOnResize"
        >
      </angular-tag-cloud>
    </div></div>  
