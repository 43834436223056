import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorService } from '../services/error.service';
import { VisualizationService } from '../services/visualization.service';
import { AggregatorsService } from '../services/aggregators.service';
import { InfoGraphManagerService } from '../Managers/info-graph-manager.service';
import { AggregatorManagerService } from '../Managers/aggregator-manager.service';
import { CipherQueryService, NeoColorsService } from 'neo4j-graph';
import { MatDialog } from '@angular/material/dialog';
import { infographColors } from '../info-graph/infograph-colors';

@Component({
  selector: 'app-graph-test',
  templateUrl: './graph-test.component.html',
  styleUrls: ['./graph-test.component.css'],
})
export class GraphTestComponent implements OnInit {
  constructor(
    public router: Router,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    public errorService: ErrorService,
    public visualizationService: VisualizationService,
    public aggregatorsService: AggregatorsService,
    public infoGraphManager: InfoGraphManagerService,
    public aggManager: AggregatorManagerService,
    public cipherQueryService: CipherQueryService,
    public dialog: MatDialog,
    public neoColorsService: NeoColorsService
  ) {}
  public responseItems = [
    { mappedName: 'startingNode', key: 'n' },
    { mappedName: 'endingNode', key: 'o' },
    { mappedName: 'relationship', key: 'r' },
  ];
  public colorScale: any = null;
  public toggleFilterSearch = true;
  public isBrowse = true;
  public searchForm: FormGroup;
  public classificationForm: FormGroup;
  public alertMessage;

  viz = null;
  driverURL = 'app.infosiphon.com:7687';
  serverDatabase = 'windtechnicalissues';
  serverUsername = 'neo4j';
  serverPassword = '14081947';
  searchTerm: any;
  searchInput = '';
  limit = 120;
  showNode: any = null;
  showEdge: any = null;

  filteredItems = [];
  showQuery: string = null;
  queryResults: any = {};

  ngOnInit(): void {
    this.cipherQueryService.setDriver(
      'bolt+s://' + this.driverURL,
      this.serverUsername,
      this.serverPassword
    );
    this.fetchClassifications().then();
  }

  filterEdgeProperties(properties: any[]): any[] {
    const restricted = ['infosiphon_id', 'subject_1', 'subject_2'];
    return properties.filter((i) => restricted.indexOf(i.key) < 0);
  }

  filterNodeProperties(properties: any[]): any[] {
    const restricted = ['subject_1', 'subject_2'];
    return properties.filter((i) => restricted.indexOf(i.key) < 0);
  }

  async fetchClassifications(): Promise<any> {
    const runQuery = `MATCH (n) RETURN DISTINCT LABELS(n)`;
    const records = await this.cipherQueryService.runQuery(
      runQuery,
      null,
      this.serverDatabase
    );

    const classifications = records
      .map((i, index) => ({
        isSelected: index < 6,
        name: i.get('LABELS(n)')[0],
        relationCount: 0,
        type: 'label',
      }))
      .filter((i) => i.name !== 'other');

    this.colorScale = this.neoColorsService.generateScale(infographColors, [
      'other',
      ...classifications.map((i) => i.name),
    ]);
    this.showQuery = 'MATCH (n:cardinal)-[r]->(o) RETURN n, r, o LIMIT 25';
  }

  nodeMouseOver = (item: any) => {};
  nodeMouseClick = (item: any) => {
    this.showNodeModal(item);
  };
  edgeMouseOver = (item: any) => {};
  edgeMouseClick = (item: any) => {
    this.showEdgeModal(item);
  };
  canvasMouseOver = (item: any) => {};
  canvasMouseClick = (item: any) => {
    this.showEdge = null;
    this.showNode = null;
  };
  onGraphModelChange = (stats: any) => {};
  nodeMouseDoubleClick = ({ node, currentNeighbours, callback }) => {
    if (currentNeighbours.length > this.limit) {
      callback(null, { nodes: [], relationships: [] });
    }
    this.getNeighbours(node.id, currentNeighbours).then(
      (result) => {
        callback(null, {
          nodes: result.nodes,
          relationships: result.relationships,
        });
      },
      () => {
        callback(null, { nodes: [], relationships: [] });
      }
    );
  };
  nodesAndRelationshipsUpdate = (ev) => {
    this.queryResults = ev;
  };

  async getNeighbours(id: any, currentNeighbourIds = []): Promise<any> {
    const queryToRun = `MATCH path = (a)--(o)
                   WHERE id(a) = ${id}
                   AND NOT (id(o) IN[${currentNeighbourIds.join(',')}])
                   RETURN path, size((a)--()) as c
                   ORDER BY id(o)
                   LIMIT ${this.limit - currentNeighbourIds.length}`;
    const records = await this.cipherQueryService.runQuery(
      queryToRun,
      null,
      this.serverDatabase
    );
    // tslint:disable-next-line:radix
    const count =
      records.length > 0 ? parseInt(records[0].get('c').toString()) : 0;
    const unmappedNodesAndRelations = records.map((i) => ({
      startingNode: i.get('path').segments[0].start,
      endingNode: i.get('path').segments[0].end,
      relationship: i.get('path').segments[0].relationship,
    }));
    const nodeAndRelations = this.cipherQueryService.mapNodesAndRelationships(
      unmappedNodesAndRelations
    );
    return { ...nodeAndRelations, count };
  }

  getAndOpenSource(id): void {
    this.visualizationService
      .fetchAggregatorDocumentLink(id)
      .subscribe((data) => window.open(data.search_result_link, '_blank'));
  }

  showNodeModal(node: any): void {
    this.showNode = node;
    this.showEdge = null;
    const fetDetailsQuery = `MATCH (n)-[r]->(o) WHERE ID(n)=${node.id} OR ID(o)=${node.id} RETURN n, r, o`;
    this.cipherQueryService
      .runQuery(fetDetailsQuery, this.responseItems, this.serverDatabase)
      .then((results) => {
        this.showNode = {
          ...this.showNode,
          relations: results.map((i) =>
            i.startingNode.identity.toString() === node.id
              ? {
                  ...i,
                  leftNode: i.startingNode,
                  isRight: true,
                  rightNode: i.endingNode,
                }
              : {
                  ...i,
                  leftNode: i.endingNode,
                  isRight: false,
                  rightNode: i.startingNode,
                }
          ),
        };
      });
  }

  showEdgeModal(edge: any): void {
    const properties = edge.properties.reduce(
      (acc, item) => ({ ...acc, [item.key]: item.value }),
      {}
    );
    this.showEdge = edge;
    this.showNode = null;
    const fetDetailsQuery = `MATCH (n)-[r]->(o) WHERE ID(r)=${edge.id} RETURN n, r, o`;
    this.cipherQueryService
      .runQuery(fetDetailsQuery, this.responseItems, this.serverDatabase)
      .then((results) => {
        this.showEdge = {
          ...this.showEdge,
          relations: results,
        };
      });
    if (properties.infosiphon_id) {
      this.visualizationService
        .fetchAggregatorDocumentText(properties.infosiphon_id)
        .subscribe((data) =>
          this.showEdge
            ? (this.showEdge = {
                ...this.showEdge,
                documentText: data.search_result_text,
                infosiphon_id: properties.infosiphon_id,
              })
            : null
        );
    }
  }
}
