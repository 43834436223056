import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  csvVisualizer,
  column,
  line_point,
  linechart,
} from '../../../models/csvVisualizer';
import { AggregatorManagerService } from '../../../Managers/aggregator-manager.service';
import { line } from 'd3';

@Component({
  selector: 'app-csvfile-visualizer',
  templateUrl: './csvfile-visualizer.component.html',
  styleUrls: ['./csvfile-visualizer.component.css'],
})
export class CsvfileVisualizerComponent implements OnInit {
  isNominalSelected: boolean = true;
  isNumericSelected: boolean = false;
  isCombineSelected: boolean = false;
  public csv: csvVisualizer[] = [];
  public selectedFile: csvVisualizer;

  constructor(
    private httpClient: HttpClient,
    public aggrManager: AggregatorManagerService
  ) {}

  ngOnInit(): void {
    //this.aggrManager.getCSVStatisiticAggregator();
  }

  ChangeFile(selectedFile: csvVisualizer) {
    this.aggrManager.csv.forEach((c) => {
      c.isSelected = false;
    });
    selectedFile.isSelected = true;
    this.aggrManager.selectedFile = selectedFile;
  }

  ShowhideColumn(item: column) {
    item.show = !item.show;
  }

  public firstcolumn: column;
  public secondcolumn: column;
  pchart = new linechart();
  selectFirstNumericColumn(firstColumn: column) {
    this.firstcolumn = firstColumn;
    this.DrawCombineChart(this.firstcolumn, this.secondcolumn);
  }
  selectSecondNumericColumn(secondColumn: column) {
    this.secondcolumn = secondColumn;
    this.DrawCombineChart(this.firstcolumn, secondColumn);
  }

  private DrawCombineChart(firstColumn: column, secondColumn: column) {
    if (firstColumn && secondColumn) {
      this.pchart.col1 = this.firstcolumn.column_name;
      this.pchart.col2 = this.secondcolumn.column_name;
      this.firstcolumn.numerics = this.firstcolumn.numerics.sort(
        (n1, n2) => n1.value - n2.value
      );
      this.secondcolumn.numerics = this.secondcolumn.numerics.sort(
        (n1, n2) => n1.value - n2.value
      );

      this.firstcolumn.linechart = new linechart();
      this.firstcolumn.numerics.forEach((c) => {
        this.secondcolumn.numerics.forEach((s) => {
          if (c.index == s.index) {
            let point = new line_point();
            point.x = c.value;
            point.y = s.value;

            this.firstcolumn.linechart.data.push(point);
          }
        });
      });
    } else {
      this.firstcolumn.linechart = undefined;
    }
  }

  nominalSelected() {
    this.isNominalSelected = true;
    this.isCombineSelected = false;
    this.isNumericSelected = false;
  }

  numericalSelected() {
    this.isNominalSelected = false;
    this.isCombineSelected = false;
    this.isNumericSelected = true;
  }

  combineSelected() {
    this.isNominalSelected = false;
    this.isCombineSelected = true;
    this.isNumericSelected = false;
  }

  public show: boolean = false;
  change(event: any) {
    this.show = true;
  }
  public charttype: string = 'histogram';
  selectChange(value: string) {
    this.charttype = value;
  }
}
