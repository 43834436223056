<div>
  <div *ngIf="this.aggManager.speechAnalyticsObjectIds.length == 0" class="empty-data">
    <i class="fa fa-solid fa-box-open"></i>
    <strong>Not available!</strong>
  </div>
  <div class="content" *ngIf="this.aggManager.speechAnalyticsObjectIds.length > 0">
    <!-- <pre>ddd{{this.aggManager.speechAnalyticsObjectIds | json}}</pre> -->
    <div class="row">
      <div class="col-2">
        <mat-list color="primary">
          <h3 mat-subheader class="header">Files</h3>
          <ul class="speech-ids">
            <li *ngFor="
                let file of this.aggManager.speechAnalyticsObjectIds;
                let i = index
              ">
              <span (click)="getFileAnalytics(file._id)" title="{{ file.name }}"><i class="fa fa-solid fa-file"></i>{{
                file.name }}</span>
            </li>
          </ul>
        </mat-list>
      </div>

      <div class="col-4" *ngIf="currentFile?.file?.sentences && !currentFile?.file?.conversation">
        <h3 mat-subheader class="header">Sentences</h3>
        <mat-list color="primary" style="overflow: auto; height: calc(100vh - 200px)">
          <mat-list-item *ngFor="let file of currentFile?.file?.sentences; let i = index"
            (click)="this.audioService.seekTo(file.start)" style="
              height: auto;
              padding-top: 5px;
              padding-left: 5px;
              padding-right: 5px;
            " [ngStyle]="
              state?.currentTime >= file.start && state?.currentTime <= file.end
                ? { 'background-color': '#867dd87a' }
                : { 'background-color': 'Transparent' }
            ">
            <span (click)="editSentence(i)" class="edit-icon">
              <mat-icon color="primary" mat-list-icon>edit</mat-icon>
            </span>
            <span (click)="editCancelSentence()" *ngIf="!isEdit && isEditValue === i" class="edit-icon">
              <mat-icon color="primary" mat-list-icon>cancel</mat-icon>
            </span>

            <div (blur)="
                updateSentence(
                  this.files[0]._id['$oid'],
                  file.sentence,
                  file.start,
                  $event.target.innerHTML,
                  'sentence'
                )
              " [attr.contenteditable]="!isEdit && isEditValue === i" spellcheck="false"
              [ngClass]="{ 'editable-mode': !isEdit && isEditValue === i }">
              {{ file.sentence }}
            </div>

            <mat-icon color="primary" *ngIf="
                state?.currentTime >= file.start &&
                state?.currentTime <= file.end
              ">
              beenhere</mat-icon>
            <mat-divider></mat-divider>
          </mat-list-item>
        </mat-list>
      </div>


      <div class="col-4" *ngIf="currentFile?.file?.conversation">
        <h3 mat-subheader class="header">Conversation</h3>
        <mat-list id="conversation" color="primary"
          style="overflow: auto; height: calc(100vh - 200px);display: flex;flex-direction: column;">
          <mat-list-item *ngFor="let file of currentFile?.file?.conversation.utterances; let i = index"
            (click)="this.audioService.seekTo(file.start_time)" style="
                  height: auto;
                  padding-top: 5px;
                  padding-left: 5px;
                  padding-right: 5px;
                  order: 1;
                " [ngStyle]="
                  state?.currentTime >= file.start_time && state?.currentTime <= file.end_time
                    ? { 'background-color': '#867dd87a' }
                    : { 'background-color': 'Transparent' } && this.urlFragment == file.start_time
                      ? { 'order': '0', 'background-color': '#89ff89' }
                      : { 'order': '1' }
                ">
            <span (click)="editSentence(i)" class="edit-icon">
              <mat-icon color="primary" mat-list-icon>edit</mat-icon>
            </span>
            <span (click)="editCancelSentence()" *ngIf="!isEdit && isEditValue === i" class="edit-icon">
              <mat-icon color="primary" mat-list-icon>cancel</mat-icon>
            </span>
            <div>
              <b>{{file.speaker}}: </b>
              <div id={{file.start_time}} (blur)="updateSentence(
                              this.files[0]._id['$oid'],
                              file.utterance,
                              file.start_time,
                              $event.target.innerText,
                              'conversation'
                            )
                          " [attr.contenteditable]="!isEdit && isEditValue === i" spellcheck="false"
                [ngClass]="{ 'editable-mode': !isEdit && isEditValue === i }">
                {{ file.utterance }}
              </div>

            </div>



            <mat-icon color="primary" *ngIf="
                    state?.currentTime >= file.start &&
                    state?.currentTime <= file.end
                  ">
              beenhere</mat-icon>
            <mat-divider></mat-divider>
          </mat-list-item>
        </mat-list>

      </div>


      <div class="col-6" [hidden]="!currentFile.file">
        <h3 mat-subheader class="header">Statistics</h3>
        <div style="
            overflow: auto;
            height: calc(100vh - 200px);
            display: flex;
            flex-wrap: wrap;
          ">
          <div>
            <h3>Utterances</h3>
            <figure #utterances id="utterances"></figure>
          </div>
          <div>
            <h3>Time(sec)</h3>
            <figure #time id="time"></figure>
          </div>
          <div>
            <h3>Words</h3>
            <figure #word id="word"></figure>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="media-footer" *ngIf="currentFile?.file?.sentences">
    <div class="media-container">
      <div class="slider-bar">
        {{ state?.readableCurrentTime }}
        <!-- <mat-slider class="time-slider" min="0" [max]="state?.duration" step="1" [value]="state?.currentTime" (input)="onSliderChangeEnd($event)" [disabled]="state?.error || currentFile.index === undefined"></mat-slider> -->
        <nz-slider [nzMin]="0" style="width: 100%" [nzTipFormatter]="formatter" [nzMax]="state.duration"
          [nzMarks]="marks" [nzIncluded]="true" [(ngModel)]="state.currentTime"
          (ngModelChange)="onSliderChangeEnd($event)" [disabled]="state.error || currentFile.index === undefined">
        </nz-slider>

        {{ state?.readableDuration }}
      </div>
      <div class="speaker-info">
        <div class="btngroup-person">
          <div *ngFor="let file of this.speakers; let i = index" class="d-flex align-items-center">
            <button class="tagbutton mr-2" [ngClass]="selectedSpeaker == file ? 'active' : ''"
              (click)="selectSpeaker(file)">
              <div (blur)="updateSpeaker( this.files[0]._id['$oid'],file,$event.target.innerText)"
                [attr.contenteditable]="!isEditSpeaker && isEditSpeakerValue === i">
                {{ file }}
              </div>

            </button>
            <span (click)="editSpeaker(i)" class="edit-icon">
              <mat-icon color="white" mat-list-icon>edit</mat-icon>
            </span>
            <span (click)="editCancelSpeaker()" *ngIf="!isEditSpeaker && isEditSpeakerValue === i" class="edit-icon">
              <mat-icon color="white" mat-list-icon>cancel</mat-icon>
            </span>
          </div>
        </div>
        <!-- <div class="speaker-line" *ngFor="
            let file of currentFile?.file?.conversation?.utterances;
            let i = index
          ">
          <div *ngIf="
              state?.currentTime >= file.start_time &&
              state?.currentTime <= file.end_time
            ">
            <span mat-line>Speaker {{ file.speaker }} : {{ file.utterance }}
            </span>
          </div>
        </div> -->
      </div>

      <div class="media-action-bar">
        <div>
          <button mat-button [disabled]="isFirstPlaying()" (click)="previous()">
            <mat-icon mat-list-icon>skip_previous</mat-icon>
          </button>
          <button mat-button (click)="play()" [disabled]="state?.error" *ngIf="!state?.playing">
            <mat-icon mat-list-icon>play_circle_filled</mat-icon>
          </button>
          <button mat-button (click)="pause()" *ngIf="state?.playing">
            <mat-icon mat-list-icon>pause</mat-icon>
          </button>
          <button mat-button [disabled]="isLastPlaying()" (click)="next()">
            <mat-icon mat-list-icon>skip_next</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>