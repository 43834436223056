import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { InfoGraphManagerService } from '../../Managers/info-graph-manager.service';
import { infoGraph, query, infoGraphResponse } from '../../models/infograph';
import { AggregatorManagerService } from '../../Managers/aggregator-manager.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorService } from '../../services/error.service';
import { VisualizationService } from '../../services/visualization.service';
import { AggregatorsService } from '../../services/aggregators.service';
import { environment } from '../../../environments/environment';

import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { CipherQueryService, NeoColorsService } from 'neo4j-graph';
import { infographColors } from '../infograph-colors';

import {
  ConfirmationDailogComponent,
  ConfirmDialogModel,
} from '../../dailogs/confirmation-dailog/confirmation-dailog.component';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DocumentSourceService } from '../../services/document-source.service';
import { AuthenticationService } from '../../services/authentication.service';
import { DemoService } from '../../services/demo.service';
import { DashboardManagerService } from '../../Managers/dashboard-manager.service';
import { dashboardItem } from '../../models/dashboard';

declare const $: any;

@Component({
  selector: 'app-infograph',
  templateUrl: './infograph.component.html',
  styleUrls: ['./infograph.component.css'],
})
export class InfographComponent implements OnInit, OnDestroy {
  constructor(
    public router: Router,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    public errorService: ErrorService,
    public visualizationService: VisualizationService,
    public aggregatorsService: AggregatorsService,
    public dashboardManager: DashboardManagerService,
    public infoGraphManager: InfoGraphManagerService,
    public aggManager: AggregatorManagerService,
    public cipherQueryService: CipherQueryService,
    public dialog: MatDialog,
    public documentSourceService: DocumentSourceService,
    public demoService: DemoService,
    public neoColorsService: NeoColorsService
  ) {}

  @ViewChild(MatPaginator) paginator: MatPaginator;

  public AddFavForm: FormGroup;
  public showAddPopup = false;
  public name: FormControl;
  public selectedQueryid;
  filteredSearch: Observable<infoGraph[]>;
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  queryCtrl = new FormControl();

  public responseItems = [
    { mappedName: 'startingNode', key: 'n' },
    { mappedName: 'endingNode', key: 'o' },
    { mappedName: 'relationship', key: 'r' },
  ];

  public toggleFilterSearch = true;
  public isBrowse = true;
  public searchForm: FormGroup;
  public classificationForm: FormGroup;
  public alertMessage;

  viz = null;
  driverURL = 'app.infosiphon.com:7687';
  serverDatabase: string = null;
  serverUsername = 'neo4j';
  serverPassword = '14081947';
  searchTerm: any;
  searchInput = '';
  limit = 120;
  showNode: any = null;
  showEdge: any = null;
  hoverNode: any = null;
  hoverEdge: any = null;

  filteredItems = [];

  public colorScale: any = null;
  showQuery: any = null;

  curPage = 1;
  pageSize = 50;
  queryResults: any = {};

  isEntitySelected = true;
  isRelationshipSelected = false;
  isStarredSelected = false;

  public totalRelations = 0;
  public corpusStatus = false;
  public clustering_of_relations = null;
  public dbpedia_entities_mention_extraction = null;
  public saving_texts_to_db_preprocessing = null;
  public stanford_nlp_triplet_extraction = null;

  result: any;

  lastFilterSearch = '';

  updatesearchFilter(): void {
    this.infoGraphManager.updatesearhlist();
    // this.filteredSearch =   of(this.filterSearch(this.lastFilterSearch));
  }

  filterSearch(filter: string): infoGraph[] {
    this.lastFilterSearch = filter;
    if (filter) {
      return this.infoGraphManager.searchList.filter((option, index) => {
        // This is sample         return ((option.name?.toLowerCase().indexOf(filter?.toLowerCase()) >= 0) &&
        // (this.searchinquery(option.name, option.type)));
        return (
          option.name?.toLowerCase().indexOf(filter?.toLowerCase()) >= 0 &&
          this.searchinquery(option.name, option.type)
        );
      });
    } else {
      return this.infoGraphManager.searchList.slice();
    }
  }

  searchinquery(value: string, type: string): any {
    let isfound = true;
    this.infoGraphManager.query.forEach((c) => {
      if (c.name === value && c.type === type) {
        isfound = false;
      }
    });

    return isfound;
  }

  createAddFavControls(): void {
    this.name = new FormControl('', [Validators.required]);
  }

  createAddfav(): void {
    this.AddFavForm = new FormGroup({
      name: this.name,
    });
  }

  AddFav(): void {
    this.showAddPopup = false;

    const q = new query();
    q.name = this.AddFavForm.controls.name.value;
    q.condition = this.infoGraphManager.condition;
    q.query = this.infoGraphManager.query;

    if (this.selectedQueryid) {
      this.infoGraphManager.removeFav(this.selectedQueryid);
      this.selectedQueryid = null;
    }

    this.infoGraphManager.saveFav(this.aggManager.selectedAggregator.id, q);
    setTimeout(() => {
      this.infoGraphManager.getFavList(this.aggManager.selectedAggregator.id);
    }, 1500);
  }

  EditFav(event, queryCurr: infoGraphResponse): void {
    event.stopPropagation();
    this.showAddPopup = true;
    this.selectedQueryid = queryCurr.id;
    this.AddFavForm.controls.name.setValue(queryCurr.query.name);
  }

  public dashboardItem: dashboardItem = new dashboardItem();
  AddtoDashboard(event, queryCurr: infoGraphResponse): void {
    queryCurr.isEnabled = true;
    event.stopPropagation();

    this.dashboardManager.AddDashboardItem(
      this.aggManager.selectedAggregator.id,
      queryCurr.id,
      3
    );
  }
  closeInvite(): void {
    this.showAddPopup = false;
  }

  ShowAddPopup(): void {
    this.AddFavForm.controls.name.setValue('');
    this.showAddPopup = true;
  }

  omit_special_char(event): any {
    let k;
    k = event.charCode; //         k = event.keyCode;  (Both can be used)
    // tslint:disable-next-line:triple-equals
    return (
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      k == 8 ||
      k == 32 ||
      (k >= 48 && k <= 57)
    );
  }

  ngOnInit(): void {
    this.filteredSearch = this.queryCtrl.valueChanges.pipe(
      startWith<string | infoGraph[]>(''),
      map((value) =>
        typeof value === 'string' ? value : this.lastFilterSearch
      ),
      map((filter) => this.filterSearch(filter))
    );

    this.spinner.show();
    this.createAddFavControls();
    this.createAddfav();
    if (!this.aggManager.selectedAggregator) {
      this.router.navigate(['']).then();
      return;
    }
    this.infoGraphManager.getFavList(this.aggManager.selectedAggregator.id);
    this.serverDatabase = this.aggManager.selectedAggregator.name
      .split(' ')
      .join('')
      .toLowerCase();

    if (environment.devMode) {
      this.serverDatabase = 'dev' + this.serverDatabase;
    }

    this.searchForm = this.formBuilder.group({
      searchTerm: ['', [Validators.required]],
    });
    this.infoGraphManager.query = [];
    this.infoGraphManager.clusters = [];
    this.infoGraphManager.classifications = [];
    this.infoGraphManager.relationships = [];
    this.infoGraphManager.searchlabel = [];

    (async () => {
      try {
        this.cipherQueryService.setDriver(
          'bolt+s://' + this.driverURL,
          this.serverUsername,
          this.serverPassword
        );
        const actionStatus = await this.checkNeoDBStatus();
        if (actionStatus) {
          const actionRequired = await this.checkStatusOfKG();
          if (actionRequired) {
            this.spinner.hide();
            this.alertMessage = actionRequired;
            return;
          } else {
            this.spinner.hide();
            this.alertMessage = actionStatus;
            return;
          }
        }
        await this.fetchClassifications();
        if (!this.infoGraphManager.classifications.length) {
          const actionRequired = await this.checkStatusOfKG();
          if (actionRequired) {
            this.spinner.hide();
            this.alertMessage = actionRequired;
            return;
          } else {
            this.spinner.hide();
            this.alertMessage =
              'No Classification Data available against this Aggregator.';
            return;
          }
        }
        this.spinner.hide();
        this.clearSearch();
      } catch (err) {
        this.spinner.hide();
        this.errorService.showError(err.toString());
      }
    })();
  }

  async onClassificationSubmit(value: any): Promise<any> {
    await this.fetchClassifications();
  }

  async checkNeoDBStatus(): Promise<any> {
    try {
      const runQuery = `MATCH (n) RETURN DISTINCT LABELS(n)`;
      await this.cipherQueryService.runQuery(
        runQuery,
        null,
        this.serverDatabase
      );
      return null;
    } catch (e) {
      return e.toString();
    }
  }

  async checkStatusOfKG(): Promise<any> {
    try {
      const result = await this.aggregatorsService.getAggregatorKGStatus(
        environment.courpus_API +
          `${this.aggManager.selectedAggregator.id}/${this.aggManager.selectedAggregator.name}/`
      );
      if (
        !result ||
        result.clustering_of_relations !== 'process completed' ||
        result.dbpedia_entities_mention_extraction !== 'process completed' ||
        result.saving_texts_to_db_preprocessing !== 'process completed' ||
        result.stanford_nlp_triplet_extraction !== 'process completed'
      ) {
        return 'This Database has pending processes. Please recheck after completion.';
      }
      return null;
    } catch (e) {
      if (e.status === 0) {
        throw Error('Cross origin requests are not allowed.');
      } else {
        return 'This database has not been processed yet. No Information available.';
      }
    }
  }

  nodeMouseOver = (item: any) => {
    this.hoverNode = item;
  };
  nodeMouseClick = (item: any) => {
    this.showNodeModal(item);
  };
  edgeMouseOver = (item: any) => {
    this.hoverEdge = item;
  };
  edgeMouseClick = (item: any) => {
    this.showEdgeModal(item);
  };
  canvasMouseOver = (item: any) => {
    this.hoverEdge = null;
    this.hoverNode = null;
  };
  canvasMouseClick = (item: any) => {
    this.showEdge = null;
    this.showNode = null;
  };

  onGraphModelChange = (stats: any) => {};
  nodeMouseDoubleClick = ({ node, currentNeighbours, callback }) => {
    if (currentNeighbours.length > this.limit) {
      callback(null, { nodes: [], relationships: [] });
    }
    this.getNeighbours(node.id, currentNeighbours).then(
      (result) => {
        callback(null, {
          nodes: result.nodes,
          relationships: result.relationships,
        });
      },
      () => {
        callback(null, { nodes: [], relationships: [] });
      }
    );
  };
  nodesAndRelationshipsUpdate = (ev) => {
    this.queryResults = ev;
  };

  async getNeighbours(id: any, currentNeighbourIds = []): Promise<any> {
    const queryToRun = `MATCH path = (a)--(o)
                   WHERE id(a) = ${id}
                   AND NOT (id(o) IN[${currentNeighbourIds.join(',')}])
                   RETURN path, size((a)--()) as c
                   ORDER BY id(o)
                   LIMIT ${this.limit - currentNeighbourIds.length}`;
    const records = await this.cipherQueryService.runQuery(
      queryToRun,
      null,
      this.serverDatabase
    );
    // tslint:disable-next-line:radix
    const count =
      records.length > 0 ? parseInt(records[0].get('c').toString()) : 0;
    const unmappedNodesAndRelations = records.map((i) => ({
      startingNode: i.get('path').segments[0].start,
      endingNode: i.get('path').segments[0].end,
      relationship: i.get('path').segments[0].relationship,
    }));
    const nodeAndRelations = this.cipherQueryService.mapNodesAndRelationships(
      unmappedNodesAndRelations
    );
    return { ...nodeAndRelations, count };
  }

  getAndOpenSource(id): void {
    this.documentSourceService.getAndOpenSource(
      { id },
      this.aggManager.selectedAggregator.id
    );
    // this.visualizationService.fetchAggregatorDocumentLink(id).subscribe(data => window.open(data.search_result_link, '_blank'));
  }

  showNodeModal(node: any): void {
    this.showNode = node;
    this.showEdge = null;
    const fetDetailsQuery = `MATCH (n)-[r]->(o) WHERE ID(n)=${node.id} OR ID(o)=${node.id} RETURN n, r, o`;
    this.cipherQueryService
      .runQuery(fetDetailsQuery, this.responseItems, this.serverDatabase)
      .then((results) => {
        this.showNode = {
          ...this.showNode,
          relations: results.map((i) =>
            i.startingNode.identity.toString() === node.id
              ? {
                  ...i,
                  leftNode: i.startingNode,
                  isRight: true,
                  rightNode: i.endingNode,
                }
              : {
                  ...i,
                  leftNode: i.endingNode,
                  isRight: false,
                  rightNode: i.startingNode,
                }
          ),
        };
      });
  }

  showEdgeModal(edge: any): void {
    const properties = edge.properties.reduce(
      (acc, item) => ({ ...acc, [item.key]: item.value }),
      {}
    );
    this.showEdge = edge;
    this.showNode = null;
    const fetDetailsQuery = `MATCH (n)-[r]->(o) WHERE ID(r)=${edge.id} RETURN n, r, o`;
    this.cipherQueryService
      .runQuery(fetDetailsQuery, this.responseItems, this.serverDatabase)
      .then((results) => {
        this.showEdge = {
          ...this.showEdge,
          relations: results,
        };
      });
    if (properties.infosiphon_id) {
      this.visualizationService
        .fetchAggregatorDocumentText(properties.infosiphon_id)
        .subscribe((data) =>
          this.showEdge
            ? (this.showEdge = {
                ...this.showEdge,
                documentText: data.search_result_text,
                infosiphon_id: properties.infosiphon_id,
              })
            : null
        );
    }
  }

  filterEdgeProperties(properties: any[]): any[] {
    if (properties && properties.length) {
      const restricted = ['infosiphon_id', 'subject_1', 'subject_2'];
      return properties.filter((i) => restricted.indexOf(i.key) < 0);
    } else {
      return [];
    }
  }

  filterNodeProperties(properties: any[]): any[] {
    if (properties && properties.length) {
      const restricted = ['subject_1', 'subject_2'];
      return properties.filter((i) => restricted.indexOf(i.key) < 0);
    } else {
      return [];
    }
  }

  truncate(str: string, n: number): string {
    return str.length > n ? str.substr(0, n - 1) + '...' : str;
  }

  onSearchSubmit(event: MatChipInputEvent): void {
    const input = event.input;
    const value = this.queryCtrl.value;

    // tslint:disable-next-line:triple-equals
    if (value != '') {
      this.buildQuery(new infoGraph(value, 'label-name', false));
      this.infoGraphManager.searchlabel.push(
        new infoGraph(value, 'label-name', true)
      );

      // Reset the input value
      if (input) {
        input.value = '';
      }
    }

    this.queryCtrl.setValue(null);
    this.clearSearch();
  }

  selectEntity(): void {
    this.isEntitySelected = true;
    this.isRelationshipSelected = false;
    this.isStarredSelected = false;
  }

  selectRelationship(): void {
    this.isEntitySelected = false;
    this.isRelationshipSelected = true;
    this.isStarredSelected = false;
  }

  selectStarred(): void {
    this.isEntitySelected = false;
    this.isRelationshipSelected = false;
    this.isStarredSelected = true;
  }

  selectStarredQuery(currQuery: infoGraphResponse): void {
    this.infoGraphManager.setquery(currQuery.query.query);
    this.infoGraphManager.condition = currQuery.query.condition;
    this.clearSearch();
  }

  ToggleFilterSearch(): void {
    this.toggleFilterSearch = !this.toggleFilterSearch;
  }

  closeBrowse(): void {
    this.isBrowse = false;
  }

  browse(): void {
    this.isBrowse = true;
  }

  ToggleRelationships(item: infoGraph): void {
    item.isSelected = !item.isSelected;
    if (item.isSelected) {
      this.infoGraphManager.AddToQuery(item);
    } else {
      this.infoGraphManager.RemoveFromQuery(item);
    }
  }

  UpdateCondition(condition: string): void {
    this.infoGraphManager.condition = condition;
    this.clearSearch();
  }

  ToggleCluster(item: infoGraph): void {
    item.isSelected = !item.isSelected;
    if (item.isSelected) {
      this.infoGraphManager.AddToQuery(item);
    } else {
      this.infoGraphManager.RemoveFromQuery(item);
    }
  }

  async buildQuery(item: infoGraph, selected?: boolean): Promise<any> {
    if (selected) {
      item.isSelected = selected;
    } else {
      item.isSelected = !item.isSelected;
    }
    if (item.isSelected) {
      this.infoGraphManager.AddToQuery(item);
    } else {
      this.infoGraphManager.RemoveFromQuery(item);
    }
    if (item.type === 'label') {
      for (const currCLass of this.infoGraphManager.classifications) {
        if (currCLass.name === item.name) {
          currCLass.isSelected = item.isSelected;
        }
      }
    }
    this.clearSearch();
  }

  public getChipColor(type): string {
    if (type === 'cluster') {
      return '#c3f5ed';
    } else if (type === 'relationship') {
      return '#e1e1e6';
    } else {
      return '#6214f4';
    }
  }

  clearSearch(): void {
    this.searchInput = '';
    const labelQuerySyntax = this.infoGraphManager.getLabelQuerySyntax();

    const relationshipQuery =
      this.infoGraphManager.getRelationshipQuerySyntax();
    const runQuery =
      labelQuerySyntax && relationshipQuery
        ? `MATCH (n)-[r${relationshipQuery}]-(o)
    WHERE ${labelQuerySyntax}
    RETURN n, r, o limit ${this.limit}`
        : labelQuerySyntax
        ? `MATCH (n)-[r]-(o)
    WHERE ${labelQuerySyntax}
    RETURN n, r, o limit ${this.limit}`
        : relationshipQuery
        ? `MATCH (n)-[r${relationshipQuery}]-(o)
    RETURN n, r, o limit ${this.limit}`
        : `MATCH (n)-[r]-(o) WHERE ${this.infoGraphManager.getAllLabelQuerySyntax()} RETURN n, r, o limit ${
            this.limit
          }`;

    this.drawQuery(runQuery);
  }

  drawQuery(currQuery: string): void {
    this.showQuery = currQuery;
    return;
  }

  ngOnDestroy(): void {}

  async fetchClassifications(): Promise<any> {
    const runQuery = `MATCH (n) RETURN DISTINCT LABELS(n)`;
    const records = await this.cipherQueryService.runQuery(
      runQuery,
      null,
      this.serverDatabase
    );

    let classifications = records
      .map((i, index) => ({
        isSelected: index < 6,
        name: i.get('LABELS(n)')[0],
        relationCount: 0,
        type: 'label',
      }))
      .filter((i) => i.name !== 'other');

    this.colorScale = this.neoColorsService.generateScale(infographColors, [
      'other',
      ...classifications.map((i) => i.name),
    ]);

    this.infoGraphManager.classifications = classifications;

    const query2 = `MATCH (n) WHERE ${this.infoGraphManager.getAllLabelQuerySyntax()} RETURN DISTINCT LABELS(n), COLLECT(n.name)`;
    const records2 = await this.cipherQueryService.runQuery(
      query2,
      null,
      this.serverDatabase
    );

    classifications = records2
      .sort(
        (a, b) =>
          b.get('COLLECT(n.name)').length - a.get('COLLECT(n.name)').length
      )
      .map((i, index) => ({
        name: i.get('LABELS(n)')[0],
        nodeCount: i.get('COLLECT(n.name)').length,
        isSelected: index < 5 && i.get('LABELS(n)')[0] !== 'none',
        relationCount: 0,
        type: 'label',
      }));

    this.infoGraphManager.classifications = classifications;

    this.infoGraphManager.classifications.forEach((c) => {
      if (c.isSelected) {
        this.infoGraphManager.query.push(c);
        this.infoGraphManager.isClassSelected = true;
      }
    });

    await this.fetchClusters();
    this.fetchRelationships().then();

    this.updatesearchFilter();
  }

  async fetchClusters(): Promise<any> {
    const runQuery = `MATCH ()-[r]-() RETURN DISTINCT r.cluster`;
    const records = await this.cipherQueryService.runQuery(
      runQuery,
      null,
      this.serverDatabase
    );
    this.infoGraphManager.clusters = records
      .map((i) => new infoGraph(i.get('r.cluster'), 'cluster', false))
      .sort((a, b) => +a.name - +b.name);

    this.updatesearchFilter();
  }

  changePagesize(event?: PageEvent): void {
    this.curPage = event.pageIndex + 1;
  }

  async fetchRelationships(): Promise<any> {
    this.totalRelations = 0;
    this.infoGraphManager.relationships = [];

    for (
      let index = 0;
      index < this.infoGraphManager.classifications.length;
      index++
    ) {
      const label = this.infoGraphManager.classifications[index];
      if (label.name !== 'none') {
        const runQuery = `MATCH (n)-[r]->() WHERE n:${label.name} RETURN DISTINCT TYPE(r), r.cluster`;
        const records = await this.cipherQueryService.runQuery(
          runQuery,
          null,
          this.serverDatabase
        );

        this.infoGraphManager.relationships = [
          ...this.infoGraphManager.relationships,
          ...records.map((i) => ({
            labelIndex: index,
            selected: label.isSelected,
            name: i._fields[0],
            cluster: i.get('r.cluster'),
          })),
        ];
        this.infoGraphManager.classifications[index].relationCount =
          records?.length;
        this.totalRelations += records?.length;
        this.updatesearchFilter();
      }
    }
    this.assignCopy();
  }

  assignCopy(): void {
    this.filteredItems = Object.assign([], this.infoGraphManager.relationships);
  }

  filteredRelationships(): any {
    let selectedClusters: any = this.infoGraphManager.query.filter(
      (i: infoGraph) => i.type === 'cluster'
    );
    if (!selectedClusters.length) {
      return this.filteredItems;
    }
    selectedClusters = selectedClusters.reduce(
      (pr, curr: infoGraph) => ({ ...pr, [curr.name]: curr }),
      {}
    );
    return this.filteredItems.filter((i) => selectedClusters[i.cluster]);
  }

  filterItem(value): void {
    if (!value) {
      this.assignCopy();
    } // when nothing has typed
    this.filteredItems = Object.assign(
      [],
      this.infoGraphManager.relationships
    ).filter(
      (item) => item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
    );

    this.curPage = 1;
    this.totalRelations = this.filteredItems.length;
    this.paginator.pageIndex = 0;
  }

  ConfirmDeleteFav(event, id): void {
    event.stopPropagation();
    const message = `Are you sure you want delete query?`;

    const dialogData = new ConfirmDialogModel('Confirm Action', message);

    const dialogRef = this.dialog.open(ConfirmationDailogComponent, {
      maxWidth: '400px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      this.result = dialogResult;

      if (this.result) {
        this.infoGraphManager.removeFav(id);
        this.infoGraphManager.starredQueries.forEach((c, index) => {
          // tslint:disable-next-line:triple-equals
          if (c.id == id) {
            this.infoGraphManager.starredQueries.splice(index, 1);
          }
        });
      }
    });
  }
}
