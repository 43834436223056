import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { HistoryManagerService } from '../../../Managers/history-manager.service';
import { AggregatorManagerService } from '../../../Managers/aggregator-manager.service';
import { history } from '../../../models/aggregator';
import {
  ConfirmDialogModel,
  ConfirmationDailogComponent,
} from '../../../dailogs/confirmation-dailog/confirmation-dailog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css'],
})
export class HistoryComponent implements OnInit {
  public displayedColumns: string[] = [
    'id',
    'execStarted',
    'execEnded',
    'status',
    'comment',
    'score',
    'results',
    'flagged',
    'conversed',
    'comments',
    'actions',
  ];

  public offset: number = 0;
  public limit: number = 30;

  public length: number = 1;

  constructor(
    public router: Router,
    public historyManager: HistoryManagerService,
    public aggrManager: AggregatorManagerService,
    public dialog: MatDialog
  ) {}

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit() {
    this.getHistory();
  }

  selectSearch(hist: history) {
    this.router.navigate(['/search'], { queryParams: { history_id: hist.id } });
  }

  changePagesize(event?: PageEvent) {
    this.limit = event.pageSize;
    this.offset = event.pageIndex * event.pageSize;

    this.getHistory();
  }

  getHistory() {
    this.historyManager.getHistory(
      this.aggrManager.selectedAggregator.id,
      this.offset,
      this.limit
    );
  }
  result: any;
  ConfirmRemoveHistory(hist: history) {
    const message = `Are you sure you want remove history?`;

    const dialogData = new ConfirmDialogModel('Confirm Action', message);

    const dialogRef = this.dialog.open(ConfirmationDailogComponent, {
      maxWidth: '400px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      this.result = dialogResult;

      if (this.result) {
        this.removeHistory(hist.id);
      }
    });
  }

  removeHistory(id) {
    this.historyManager.RemoveAggregatorHistory(id);
  }

  ConfirmClearHistories() {
    const message = `Are you sure you want clear all history?`;

    const dialogData = new ConfirmDialogModel('Confirm Action', message);

    const dialogRef = this.dialog.open(ConfirmationDailogComponent, {
      maxWidth: '400px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      this.result = dialogResult;

      if (this.result) {
        this.clearHistory();
      }
    });
  }

  clearHistory() {
    this.historyManager.clearHistory(this.aggrManager.selectedAggregator.id);
  }
}
