import { Component, OnInit, Input,ViewChild,AfterViewInit, OnDestroy} from '@angular/core';
import * as d3 from 'd3';
import { nominal,  histogram, statistics } from '../../../../models/csvVisualizer';
import { AggregatorManagerService } from '../../../../Managers/aggregator-manager.service';
import { viz_type, dashboardItem } from '../../../../models/dashboard';
import { DashboardManagerService } from '../../../../Managers/dashboard-manager.service';

@Component({
  selector: 'app-barchart',
  templateUrl: './barchart.component.html',
  styleUrls: ['./barchart.component.css']
})
export class BarchartComponent implements OnInit,AfterViewInit,OnDestroy {

  uniqueId: any;
  _serviceSubscription:any;
  constructor(public aggrManager: AggregatorManagerService,public dashboardManager:DashboardManagerService) {
    this._serviceSubscription = this.dashboardManager.onExport.subscribe(c=>{
      this.export();
       
  });
   }
  
   data : histogram[];
  statistics: statistics[];
   
  @Input() id:string;

  @Input() showAddButton:boolean=true;
 
  private svg;
  private margin = 50;
  @Input() width:number = 600 ;
  @Input() height:number = 450 ;
  public columnName:string ="";

  ngOnInit() : void {

    this.uniqueId = 'canvas'+this.id+this.getRandomInt(1,2000);

setTimeout(() => {
    this.aggrManager.csv.forEach(csv => {

      csv.data.forEach(column => {
        if (column.viz_uid == this.id) {
          this.data = column.histogram;
          this.statistics = column.statistics;
    this.columnName = column.column_name;
        }
      });

    });


    this.dashboardManager.dashboard_items.forEach(c=>{
      if(c.viz_uid ==  this.id)
      {
        this.dashboardItem = c;
        this.dashboardItem.isEnabled = true;
      } });
},1000);

   

  
 
    // Parse data from a CSV
    // d3.csv("/assets/frameworks.csv").then(data => this.drawBars(data));

    // Fetch JSON from an external endpoint
    // d3.json('https://api.jsonbin.io/b/5eee6a5397cb753b4d149343').then(data => this.drawBars(data));
  }

ngOnDestroy()
{
  if(this._serviceSubscription)
  this._serviceSubscription.unsubscribe();
}
   getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  ngAfterViewInit():void
  {
    this.height = this.height - (this.margin * 2);;
    this.width = this.width - (this.margin * 2);
     setTimeout(() => { 
       
      this.aggrManager.csv.forEach(csv => {
   
      csv.data.forEach(column => {
        if (column.viz_uid == this.id) {
          this.data = column.histogram;
          this.statistics = column.statistics;
           
        }
      });

    });


    this.dashboardManager.dashboard_items.forEach(c=>{
      if(c.viz_uid ==  this.id)
      {
        this.dashboardItem = c;
        this.dashboardItem.isEnabled = true;
      } });  
      
      this.createSvg();
    this.drawBars(this.data);},1000);
    
  
   
 

  }

  private createSvg(): void {
    

 
    this.svg = d3.select("figure#"+this.uniqueId)
    .append("svg")
    .attr("width", this.width  )
    .attr("height", this.height + (this.margin * 3))
   
    .append("g")
  
    .attr("transform", "translate(" + this.margin + "," + this.margin + ")");

   /* .call(d3.zoom().scaleExtent([1, Infinity]).on("zoom", function () {
      this.svg.attr("transform", d3.event.transform)
  }))*/
     var zoom = d3.zoom()
    .scaleExtent([1, Infinity])
    .translateExtent([
      [0, 0],
      [this.width, this.height]
    ])
    .extent([
      [0, 0],
      [this.width, this.height]
    ])
    .on("zoom", zoom);

  
  this.svg.call(zoom);  

 

  }
  
  private drawBars(data: histogram[]): void {
    // Add X axis

 
   
 

    const x = d3.scaleBand()
    .range([0, this.width])
    .domain(data.map(d => d.interval))
    .padding(0.1);

    this.svg.append("g")
    .attr("transform", "translate(0," + this.height + ")")
    .call(d3.axisBottom(x))
    .selectAll("text")
    .attr("transform", "translate(-10,0)rotate(-45)")
    .style("text-anchor", "end");

    // Add Y axis
    const y = d3.scaleLinear()
    .domain([0, Math.max.apply(Math, data.map(function(o) { return o.value_counts; }))])
    
    .range([this.height, 0]);

    this.svg.append("g")
    .call(d3.axisLeft(y));

    // Create and fill the bars
    this.svg.selectAll("bars")
    .data(data)
    .enter()
    
    .append("rect")
    .attr("x", d => x(d.interval))
    .attr("y", d => y(d.value_counts))
    .attr("width", x.bandwidth())
    .attr("height", (d) => this.height - y(d.value_counts))
    .attr("fill", "#79bbf2");
  }



 

  public dashboardItem: dashboardItem=new dashboardItem();
  Add()
  {
     this.dashboardManager.dashboard_items.forEach(c=>{
     if(c.viz_uid ==  this.id)
     {
       this.dashboardItem = c;
     } });
     this.dashboardItem.isEnabled = true;
    this.dashboardManager.AddDashboardItem(this.aggrManager.selectedAggregator.id,this.id,2);
  }

  export()
  {
    if(this.height)
    {
  
      const element = document.querySelector("figure#"+this.uniqueId +" svg");
      this.dashboardManager.dashboard_items.forEach(c=>{
        if(c.viz_uid ==  this.id)
        {
         
           c.viz_type = this.columnName;
         //  c.viz_svg=  "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"600\" height=\"600\" viewBox=\"-50 0 300 800\" preserveAspectRatio=\"xMinYMin\" style=\"display: block; margin: 0vh; background: rgb(255, 255, 255); cursor: pointer;\" > <g transform=\"translate(0,0)\">"+ element.innerHTML+"</g> </svg>";
         c.viz_svg=  "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"850\" height=\"800\"    > <g transform=\"translate(50,-50)\"  >"+ element.innerHTML+"</g></svg>";
    
        } });
      }
    }
   

 

}
