import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  ElementRef,
  ViewChild,
  OnDestroy,
  HostListener,
} from '@angular/core';
import * as D3 from 'd3';
import { PieArcDatum, VoronoiCell } from 'd3';
import {
  csvVisualizer,
  column,
  textual,
  nominal,
} from '../../../../models/csvVisualizer';

import { AggregatorManagerService } from '../../../../Managers/aggregator-manager.service';
import { viz_type, dashboardItem } from '../../../../models/dashboard';
import { DashboardManagerService } from '../../../../Managers/dashboard-manager.service';

@Component({
  selector: 'app-piechart',
  templateUrl: './piechart.component.html',
  styleUrls: ['./piechart.component.css'],
})
export class PiechartComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('sentimentPieChart') element: ElementRef;
  uniqueId: any;
  @Input() id: string;

  private host: D3.Selection<any, any, any, any>;
  private svg: D3.Selection<any, any, any, any>;

  private htmlElement: HTMLElement;
  private pieData: nominal[] = [];

  columnName: string = '';
  @Input() data: nominal[] = [];
  @Input() showAddButton: boolean = true;
  _serviceSubscription: any;
  constructor(
    public aggrManager: AggregatorManagerService,
    public dashboardManager: DashboardManagerService
  ) {
    this._serviceSubscription = this.dashboardManager.onExport.subscribe(
      (c) => {
        this.export();
      }
    );
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.DrawCharts();
    }, 1000);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.DrawCharts();
  }

  private DrawCharts() {
    this.aggrManager.csv.forEach((csv) => {
      csv.data.forEach((column) => {
        if (column.viz_uid === this.id) {
          this.pieData = column.top_nominals;
        }
      });
    });
    this.dashboardManager.dashboard_items.forEach((c) => {
      if (c.viz_uid == this.id) {
        this.dashboardItem = c;
        this.dashboardItem.isEnabled = true;
      }
    });
    this.host = D3.select('figure#' + this.uniqueId);
    this.width = document.getElementById(this.uniqueId).offsetWidth;
    this.buildSVG();
    this.createColors();
    this.drawChart();
  }

  ngOnDestroy() {
    if (this._serviceSubscription) this._serviceSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.uniqueId = 'canvas' + this.id + this.getRandomInt(1, 2000);

    setTimeout(() => {
      this.aggrManager.csv.forEach((csv) => {
        csv.data.forEach((column) => {
          if (column.viz_uid == this.id) {
            this.columnName = column.column_name;
          }
        });
      });

      this.dashboardManager.dashboard_items.forEach((c) => {
        if (c.viz_uid == this.id) {
          this.dashboardItem = c;
          this.dashboardItem.isEnabled = true;
        }
      });
    }, 1000);
  }
  getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  private margin = 50;
  @Input() width: number = 700;
  @Input() height: number = 450;
  // The radius of the pie chart is half the smallest side
  private radius = Math.min(this.width, this.height) / 2 - this.margin;

  private buildSVG(): void {
    this.host.html('');
    this.svg = this.host
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height)
      .append('g')
      .attr(
        'transform',
        'translate(' + this.width / 2.9 + ',' + this.height / 2 + ')'
      );
  }

  private colors;
  private createColors(): void {
    this.colors = D3.scaleOrdinal()
      .domain(this.pieData.map((d) => d.percentage.toString()))
      .range(D3.schemeSet3);
  }

  private drawChart(): void {
    const pie = D3.pie<any>().value((d: any) => Number(d.percentage));

    // Build the pie chart
    this.svg
      .selectAll('pieces')
      .data(pie(this.pieData))
      .enter()
      .append('path')
      .attr(
        'd',
        D3.arc<PieArcDatum<any>>().innerRadius(0).outerRadius(this.radius)
      )
      .attr('fill', (d, i) => this.colors(i))
      .attr('stroke', '#9e9e9e')
      .style('stroke-width', '0.5px');

    // Add labels
    const labelLocation = D3.arc().innerRadius(100).outerRadius(this.radius);

    var legendG = this.svg
      .selectAll('.legend')
      .data(pie(this.pieData))
      .enter()
      .append('g')
      .attr('transform', function (d, i) {
        return 'translate(' + 180 + ',' + (i * 20 - 150) + ')';
      })
      .attr('class', 'legend');

    legendG
      .append('rect')
      .attr('width', 15)
      .attr('height', 15)
      .attr('fill', (d, i) => this.colors(i));

    legendG
      .append('text')
      .text(function (d) {
        return (
          '(' +
          d.data.percentage.toFixed(3) +
          '%) ' +
          d.data.nominal.substring(0, 10)
        );
      })
      .style('font-size', 12)
      .attr('y', 12)
      .attr('x', 20);
  }

  private buildPie(): void {
    let pie = D3.pie();
    let values = this.getvalues();
    let arcSelection = this.svg
      .selectAll('.arc')
      .data(pie(values))
      .enter()
      .append('g')
      .attr('class', 'arc');

    this.populatePie(arcSelection);
  }

  getvalues(): number[] {
    let values = [];

    if (this.pieData != undefined) {
      this.pieData.forEach((c) => {
        values.push(c.counts);
      });
    }

    return values;
  }
  private populatePie(arcSelection: any): void {
    let innerRadius = this.radius - 50;
    let outerRadius = this.radius - 10;

    var color = D3.scaleOrdinal()
      .domain(this.pieData.map((d) => d.percentage.toString()))
      .range(D3.schemeSet2);

    let arc = D3.arc<D3.PieArcDatum<number>>().outerRadius(outerRadius);
    arcSelection
      .append('path')
      .attr('d', arc)
      .attr('fill', (datum, index) => {
        return color(this.pieData[index].percentage);
      });

    arcSelection
      .append('text')
      .attr('transform', (datum: any) => {
        datum.innerRadius = 0;
        datum.outerRadius = outerRadius;
        return 'translate(' + arc.centroid(datum) + ')';
      })
      .text((datum, index) => this.pieData[index].nominal)
      .style('text-anchor', 'middle');
  }

  public dashboardItem: dashboardItem = new dashboardItem();
  Add() {
    this.dashboardManager.dashboard_items.forEach((c) => {
      if (c.viz_uid == this.id) {
        this.dashboardItem = c;
      }
    });
    this.dashboardItem.isEnabled = true;
    this.dashboardManager.AddDashboardItem(
      this.aggrManager.selectedAggregator.id,
      this.id,
      2
    );
  }
  export() {
    if (this.height) {
      const element = document.querySelector(
        'figure#' + this.uniqueId + ' svg'
      );
      this.dashboardManager.dashboard_items.forEach((c) => {
        if (c.viz_uid == this.id) {
          c.viz_type = this.columnName;
          //   c.viz_svg=  "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"600\" height=\"600\" viewBox=\"-50 0 300 800\" preserveAspectRatio=\"xMinYMin\" style=\"display: block; margin: 0vh; background: rgb(255, 255, 255); cursor: pointer;\" > <g transform=\"translate(0,0)\">"+ element.innerHTML+"</g> </svg>";
          c.viz_svg =
            '<svg xmlns="http://www.w3.org/2000/svg" width="850" height="800"    > <g transform="translate(50,-100)"  >' +
            element.innerHTML +
            '</g></svg>';
        }
      });
    }
  }
}
