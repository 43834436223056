import { Injectable } from '@angular/core';
import { ErrorService } from '../services/error.service';
import { Router } from '@angular/router';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { NgxSpinnerService } from 'ngx-spinner';
import { color } from 'd3';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(
    public errorService: ErrorService,
    public router: Router,
    public spinner: NgxSpinnerService
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    /*  const token: string = localStorage.getItem('token');

          if (token) {
              request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
          }

          if (!request.headers.has('Content-Type')) {
              request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
          }*/

    if (request.headers.has('Content-Type')) {
      request = request.clone({
        headers: request.headers.set('Accept', 'application/json'),
      });

      return next.handle(request).pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
          }
          return event;
        }),
        catchError((error: HttpErrorResponse) => {
          this.spinner.hide();
          if (error['status'] == 403) {
            this.router.navigate(['logout']);
          } else if (error.url.includes('corpus')) {
            return throwError(error);
          } else if (error['status'] == 500) {
            return throwError(error);
          } else {
            this.errorService.showError(error['error']['message']);
          }
          return throwError(error);
        })
      );
    } else {
      request = request.clone({
        headers: request.headers.set('Accept', '*/*'),
      });

      request = request.clone({
        headers: request.headers.set('responseType', 'blob'),
      });
      return next.handle(request);
    }
  }
}
