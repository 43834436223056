import { Component, OnInit, ViewChild, Input, OnChanges } from '@angular/core';
import { detected_object, general_Image, face_detection, object_detection, text_detection_results, text } from '../../../../models/dashboard';


@Component({
  selector: 'app-cv-pipleline-text-detection',
  templateUrl: './cv-pipleline-text-detection.component.html',
  styleUrls: ['./cv-pipleline-text-detection.component.css']
})
export class CvPiplelineTextDetectionComponent implements OnInit {

  constructor() { }
  @ViewChild('layout', { static: true }) canvasRef;
 

  @Input() imgUrl: string;

  @Input() tooltip:text[];

 

  @Input() isTextDetection:boolean=false
 
  loading:boolean = true;
  ngOnInit(): void {
    // this.drawRectangle();
  }

  ngOnChanges() {

    //console.log("This is: "+ Math.max.apply(Math, this.tooltip.map(function(o) { return o.score; })))

   // this.drawRectangle();
  }
  //other code here

  drawRectangle(): void {
  //  let canvas = this.canvasRef.nativeElement;
  //  let context = canvas.getContext('2d');

   /* let source = new Image();
   

    source.onload = () => {

      this.loading = false;

      var scale = Math.max(canvas.width / source.width, canvas.height / source.height);
      // get the top left position of the image
      var x = (canvas.width / 2) - (source.width / 2) * scale;
      var y = (canvas.height / 2) - (source.height / 2) * scale;
      context.drawImage(source, x, y, source.width * scale, source.height * scale);

      

      
      

    };

     source.src = this.imgUrl;*/




  }

  onload()
  { 
 
    this.loading = false;
  }

  show;

// The Tool-Tip instance:
 ToolTip(canvas, region, text, width, timeout) {

  var me = this,                                // self-reference for event handlers
      div = document.createElement("div"),      // the tool-tip div
      parent = canvas.parentNode,               // parent node for canvas
      visible = false;                          // current status
  
  // set some initial styles, can be replaced by class-name etc.
  div.style.cssText = "position:fixed;padding:7px;z-index:2;background:gold;pointer-events:none;width:" + width + "px";
  div.innerHTML = text;
  
  // show the tool-tip
  this.show = function(pos) {
    if (!visible) {                             // ignore if already shown (or reset time)
      visible = true;                           // lock so it's only shown once
      setDivPos(pos);                           // set position
      parent.appendChild(div);                  // add to parent of canvas
      setTimeout(hide, timeout);                // timeout for hide
    }
  }
  
  // hide the tool-tip
  function hide() {
    visible = false;                            // hide it after timeout
    parent.removeChild(div);                    // remove from DOM
  }

  // check mouse position, add limits as wanted... just for example:
  function check(e) {
    var pos = getPos(e),
        posAbs = {x: e.clientX, y: e.clientY};  // div is fixed, so use clientX/Y
    if (!visible &&
        pos.x >= region.x && pos.x < region.x + region.w &&
        pos.y >= region.y && pos.y < region.y + region.h) {
      me.show(posAbs);                          // show tool-tip at this pos
    }
    else setDivPos(posAbs);                     // otherwise, update position
  }
  
  // get mouse position relative to canvas
  function getPos(e) {
    var r = canvas.getBoundingClientRect();
    return {x: e.clientX - r.left, y: e.clientY - r.top}
  }
  
  // update and adjust div position if needed (anchor to a different corner etc.)
  function setDivPos(pos) {
    if (visible){
      if (pos.x < 0) pos.x = 0;
      if (pos.y < 0) pos.y = 0;
      // other bound checks here
      div.style.left = pos.x + "px";
      div.style.top = pos.y + "px";
    }
  }
  
  // we need to use shared event handlers:
  canvas.addEventListener("mousemove", check);
  canvas.addEventListener("click", check);
  
}


}
