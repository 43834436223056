import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { InfoGraphManagerService } from '../../Managers/info-graph-manager.service';
import { DashboardManagerService } from '../../Managers/dashboard-manager.service';
import { AggregatorManagerService } from '../../Managers/aggregator-manager.service';
import { CipherQueryService, NeoColorsService } from 'neo4j-graph';
import { infographColors } from '../infograph-colors';
import { convertGraphToHTML } from '../../services/graph-html-converteer';

@Component({
  selector: 'app-neo-graph-widget',
  templateUrl: './neo-graph-widget.component.html',
  styleUrls: ['./neo-graph-widget.component.css'],
})
export class NeoGraphWidgetComponent implements OnInit, OnDestroy {
  currentVizID = Math.floor(Math.random() * 1000);
  driverURL = 'ai.infosiphon.com:7687';
  serverDatabase: string = null;
  serverUsername = 'neo4j';
  serverPassword = 'nimda';
  limit = 120;
  @Input() height: number = 582;
  public colorScale: any = null;
  showQuery: any = null;
  _serviceSubscription: any;
  @Input() id: string;

  constructor(
    private infoGraphManager: InfoGraphManagerService,
    public neoColorsService: NeoColorsService,
    public cipherQueryService: CipherQueryService,
    public aggManager: AggregatorManagerService,
    public dashboardManager: DashboardManagerService
  ) {
    this._serviceSubscription = this.dashboardManager.onExport.subscribe(
      (c) => {
        this.export();
      }
    );
  }

  public name: string = '';

  ngOnInit(): void {
    this.currentVizID = Math.floor(Math.random() * 1000);
    this.infoGraphManager.starredQueries.forEach((c) => {
      if (c.id.toString() == this.id) {
        this.name = c.query.query.map((o) => o.name).join('-');
      }
    });
    this.serverDatabase = this.aggManager.selectedAggregator.name
      .split(' ')
      .join('')
      .toLowerCase();
    this.GetClassifications();
  }

  ngOnDestroy() {
    if (this._serviceSubscription) this._serviceSubscription.unsubscribe();
  }
  // @TODO: Check this function for converting an SVG to HTML with inline CSS
  convertToInline(): void {
    const element = document.querySelector(
      `div#viz${this.currentVizID} svg#neoGraphElement`
    );
    if (element) {
    }
  }

  private async GetClassifications() {
    const runQuery = `MATCH (n) RETURN DISTINCT LABELS(n)`;
    const records = await this.cipherQueryService.runQuery(
      runQuery,
      null,
      this.serverDatabase
    );
    let classifications = records
      .map((i, index) => ({
        isSelected: index < 6,
        name: i.get('LABELS(n)')[0],
        relationCount: 0,
        type: 'label',
      }))
      .filter((i) => i.name !== 'other');
    this.colorScale = this.neoColorsService.generateScale(infographColors, [
      'other',
      ...classifications.map((i) => i.name),
    ]);

    this.search();
  }

  drawQuery(currQuery: string): void {
    this.showQuery = currQuery;
    return;
  }

  search(): void {
    const labelQuerySyntax = this.infoGraphManager.getLabelsofFavByid(this.id);

    const relationshipQuery = this.infoGraphManager.getRelationshipofFavByid(
      this.id
    );

    const runQuery =
      labelQuerySyntax && relationshipQuery
        ? `MATCH (n)-[r${relationshipQuery}]-(o)
    WHERE ${labelQuerySyntax}
    RETURN n, r, o limit ${this.limit}`
        : labelQuerySyntax
        ? `MATCH (n)-[r]-(o)
    WHERE ${labelQuerySyntax}
    RETURN n, r, o limit ${this.limit}`
        : relationshipQuery
        ? `MATCH (n)-[r${relationshipQuery}]-(o)
  RETURN n, r, o limit ${this.limit}`
        : `MATCH (n)-[r]-(o) WHERE ${this.infoGraphManager.getAlllabelFavById(
            this.id
          )} RETURN n, r, o limit ${this.limit}`;

    this.drawQuery(runQuery);
  }

  export() {
    const element = document.querySelector(
      `div#viz${this.currentVizID} svg#neoGraphElement`
    );
    if (element) {
      var svg = convertGraphToHTML(
        element.clientWidth,
        element.clientHeight,
        element.innerHTML
      );
      this.dashboardManager.dashboard_items.forEach((c) => {
        if (c.viz_uid == this.id) {
          c.viz_svg = svg;
          c.viz_type = this.name;
        }
      });
    }
  }
}
