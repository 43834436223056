<div class="d-flex justify-content-between align-items-center px-2">
<h1>Users</h1>


  <button class="button secondary-color br-8 ml-2 font-size14" *ngIf="this.athenticateService.loggedUser?.userType == 0"  (click)="AddUser()">Add User</button>
  </div>

<div class="mat-elevation-z8 mt-4" style="background-color: white; border-radius: 16px; padding: 20px;">
    <table mat-table [dataSource]="this.userManager.dataSource" style="width: 100%;">


      <!-- Position Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>  </th>
        <td mat-cell *matCellDef="let i = index">{{this.paginator.pageIndex == 0 ? i + 1 : 1 + i + this.paginator.pageIndex * this.paginator.pageSize}} </td>

      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef> Email </th>
        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef> State </th>
        <td mat-cell *matCellDef="let element"> {{element.state}} </td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef> Type </th>
        <td mat-cell *matCellDef="let element"> {{element.type}} </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>  </th>
        <td mat-cell *matCellDef="let element">
          <button class="icon" *ngIf="(this.athenticateService.loggedUser.userType == 0  || this.athenticateService.loggedUser.email == element.email)  "  matTooltip="Edit user"  (click)="editUser(element)"><svg xmlns="http://www.w3.org/2000/svg" id="edit" width="12" height="12" viewBox="0 0 12 12">

            <g id="Group_547" data-name="Group 547" transform="translate(0 .857)">
                <g id="Group_546" data-name="Group 546">
                    <path id="Path_67" d="M9.857 39.28a.429.429 0 0 0-.429.429v4.286a.429.429 0 0 1-.428.428H1.286a.429.429 0 0 1-.429-.429v-8.571a.429.429 0 0 1 .429-.429h5.143a.429.429 0 1 0 0-.857H1.286A1.286 1.286 0 0 0 0 35.423v8.571a1.286 1.286 0 0 0 1.286 1.286H9a1.286 1.286 0 0 0 1.286-1.286v-4.286a.429.429 0 0 0-.429-.428z"    data-name="Path 67" transform="translate(0 -34.137)"/>
                </g>
            </g>
            <g id="Group_549" data-name="Group 549" transform="translate(2.572)">
                <g id="Group_548" data-name="Group 548">
                    <path id="Path_68" d="M111.357.484a1.64 1.64 0 0 0-2.32 0l-5.645 5.645a.433.433 0 0 0-.1.168l-.857 2.571a.429.429 0 0 0 .406.564.435.435 0 0 0 .135-.022l2.571-.857a.429.429 0 0 0 .168-.1l5.642-5.653a1.641 1.641 0 0 0 0-2.316z"    data-name="Path 68" transform="translate(-102.409 -.004)"/>
                </g>
            </g>
        </svg></button>

        <button class="icon ml-3"  *ngIf="(this.athenticateService.loggedUser.userType == 0  || this.athenticateService.loggedUser.email == element.email)  "  matTooltip="Reset password"  (click)='confirmResetPassword(element)'>

            <svg xmlns="http://www.w3.org/2000/svg" width="11.985" height="12" viewBox="0 0 11.985 12">
                <path id="key"   d="M11.894 9.81L6.569 4.485a3.212 3.212 0 0 0 .246-1.235A3.25 3.25 0 1 0 3.566 6.5 3.212 3.212 0 0 0 4.8 6.253l1.83 1.828-.741.741a.25.25 0 0 0 0 .353l1 1a.25.25 0 0 0 .353 0l.823-.823.4.4-.823.823a.25.25 0 0 0 0 .353l1 1a.25.25 0 0 0 .353 0l.741-.741.45.45a1.25 1.25 0 0 0 1.886-.136 1.3 1.3 0 0 0-.178-1.691zM3.566 5a1.75 1.75 0 1 1 1.75-1.75A1.75 1.75 0 0 1 3.566 5z" transform="translate(-.316)"/>
            </svg>

        </button>


        <button class="icon ml-3 mt-2"  matTooltip="Delete"  *ngIf="(this.athenticateService.loggedUser.userType == 0 && element.type != 'Owner')"   matTooltip="Delete" (click)="confirmDeleteDialog(element)">
          <svg xmlns="http://www.w3.org/2000/svg" width="11" height="13" viewBox="0 0 10.009 12.2">
              <g id="trash" transform="translate(.104 .1)">
                  <path id="Path_4"    stroke-width="0.2px" d="M9.77 2.682l-.265-.794a.739.739 0 0 0-.7-.506H6.576V.657A.658.658 0 0 0 5.92 0H3.88a.658.658 0 0 0-.657.657v.725H1a.739.739 0 0 0-.7.506l-.27.794a.595.595 0 0 0 .564.783h.277L1.48 11a1.09 1.09 0 0 0 1.081 1h4.8a1.09 1.09 0 0 0 1.081-1l.61-7.538h.153a.595.595 0 0 0 .564-.783zM3.927.7h1.946v.679H3.927zm3.815 10.246a.383.383 0 0 1-.38.351h-4.8a.383.383 0 0 1-.38-.351l-.606-7.481h6.771zm-7-8.184l.22-.652A.037.037 0 0 1 1 2.085h7.8a.037.037 0 0 1 .035.025l.217.651zm0 0" data-name="Path 4" transform="translate(.001)"/>
              </g>
          </svg>
      </button>

      </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[30,60,80,100]" [length]="this.userManager.total_user_result" (page)="changePagesize($event)"   [pageSize]="limit" showFirstLastButtons></mat-paginator>

  </div>


  <div *ngIf="showPasswordPopup" style="position: absolute; top:0px; left:0px; background-color: #0303039c;z-index: 99999; width: 100%; height: 100%;">
    <div style="    background-color: white;
    display: inline-block;
    width: 30%;
    top: 30%;
    LEFT: 36%;
    POSITION: absolute;">

      <div class="modal-header">
        <h4 class="modal-title">Reset Password</h4>
        <button type="button" class="close" aria-label="Close" (click)="closepopup()" >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <form  [formGroup]="passwordForm" (submit)="changePassword()">

          <br>
          <small *ngIf="error" class="form-text text-danger">{{ error }}</small>


          <div class="input-group">
            <input [type]="showNewPassword? 'text': 'password' "

            tabindex="2"
                   aria-label="Password" aria-describedby="password-addon1"
                   [ngClass]="{
                      'border-danger': !newpassword.valid && newpassword.dirty,
                      'border-success': newpassword.valid && newpassword.dirty
                     }" placeholder="NewPassword"  formControlName="newpassword" class="form-control">
            <div class="input-group-append">
              <button type="button" tabindex="-1" (click)="showNewPassword = !showNewPassword" class="btn btn-outline-secondary">
                <img class="input-icon" [src]="showNewPassword? './assets/icons/hide.png' : './assets/icons/eye.png' " alt="close"></button>
            </div>
            <small *ngIf="!newpassword.valid && newpassword.dirty" class="form-text text-danger">Password must contain at least one Uppercase, Lowercase, special character, number. And it must be 10 characters long.</small>
          </div>
<br>


          <br>



          <input  tabindex="4"  type="submit" [disabled]="passwordForm.invalid" value="Change Password" class="btn btn-primary btn-block mt-2 mb-4">
          <input  tabindex="5"   (click)="closepopup()"   value="Cancel" class="btn btn-secondary btn-block mt-2 mb-4">
        </form>





      </div>


  </div>
  </div>
