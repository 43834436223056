<!--<div *ngIf="visualizationManagerService.isLoading" class="d-flex justify-content-center align-items-center visualization-loading-container">-->
<!--  <app-loading-component></app-loading-component>-->
<!--</div>-->
<div *ngIf="visualizationManagerService.visualizationSearchFailed" class="d-flex justify-content-center align-items-center visualization-loading-container">
  <div class="alert alert-warning" role="alert">The term "{{ visualizationManagerService.visualizationSearchFailed }}" is not found in any Document.</div>
</div>

<router-outlet></router-outlet>

<div class="modal fade" id="document-text-modal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title mb-0" id="staticBackdropLabel"><b> Source Text </b></h4>

        <div class="alert alert-warning mb-0">
          <small>Use Right Click instead of Left to open Source</small>
        </div>

        <button type="button" class="close-btn" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pt-4 pb-4">
        {{ visualizationManagerService.documentText }}
      </div>

    </div>
  </div>
</div>

<app-csv-table-viewer></app-csv-table-viewer>
