import { Component, OnInit, ViewChild, Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SearchManagerService } from '../../../Managers/search-manager.service';
import { AggregatorManagerService } from '../../../Managers/aggregator-manager.service';
import { MatRadioChange } from '@angular/material/radio';
import { MatSelectChange } from '@angular/material/select';

import { search, terms } from '../../../models/aggregator';
import {
  ConfirmationDailogComponent,
  ConfirmDialogModel,
} from '../../../dailogs/confirmation-dailog/confirmation-dailog.component';
import { MatDialog } from '@angular/material/dialog';

import { filter } from 'rxjs/operators';
import { DocumentSourceService } from '../../../services/document-source.service';

import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormGroupDirective,
  NgForm,
} from '@angular/forms';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
})
export class SearchComponent implements OnInit {
  filterForm: FormGroup;
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    public documentSourceService: DocumentSourceService,
    public searchManager: SearchManagerService,
    public aggrManager: AggregatorManagerService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder
  ) {}
  history_id: any;
  public labelPosition: any;
  public value1: any;
  public visible = false;
  public isCommentRender = false;
  public isVisibleText = false;
  public moreText = null;

  public length = 1;
  public displayedColumns: string[] = [
    'id',
    'source',
    'srcname',
    'text',
    'score',
    'date',
    'actions',
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  result: any;

  disablePublishDateFrom = (startValue: Date): boolean => {
    if (!startValue || !this.filterForm.controls['publish_date_to'].value) {
      return false;
    }
    return (
      startValue.getTime() >
      this.filterForm.controls['publish_date_to'].value.getTime()
    );
  };

  disabledPublishDateTo = (endValue: Date): boolean => {
    if (!endValue || !this.filterForm.controls['publish_date_from'].value) {
      return false;
    }
    return (
      endValue.getTime() <=
      this.filterForm.controls['publish_date_from'].value.getTime()
    );
  };

  disableSearchDateFrom = (startValue: Date): boolean => {
    if (!startValue || !this.filterForm.controls['search_date_to'].value) {
      return false;
    }
    return (
      startValue.getTime() >
      this.filterForm.controls['search_date_to'].value.getTime()
    );
  };

  disabledSearchDateTo = (endValue: Date): boolean => {
    if (!endValue || !this.filterForm.controls['search_date_from'].value) {
      return false;
    }
    return (
      endValue.getTime() <=
      this.filterForm.controls['search_date_from'].value.getTime()
    );
  };

  public listSourceNames: Array<{ label: string; value: string }> = [];

  ngOnInit() {
    this.history_id = undefined;
    this.route.queryParams.subscribe((params) => {
      this.history_id = params.history_id;
    });
    this.search();
    this.searchManager.getClusters();

    this.filterForm = this.formBuilder.group({
      publish_date_from: [''],
      publish_date_to: [''],
      search_date_from: [''],
      search_date_to: [''],
      flagged: ['-1'],
      conversed: ['-1'],
      document: [''],
      facebook: [''],
      google: [''],
      reddit: [''],
      twitter: [''],
      website: [''],
      youtube: [''],
      FXOption: [''],
      boolean_operator: ['AND'],
      orderField: [''],
      orderType: [''],
      source_names: [],
      sentiments: [],
      sources: [],
    });
  }
  ngOnDestroy() {
    this.searchManager.source_names = [];
    this.searchManager.sources = [];
    this.searchManager.sentiments = [];
    this.searchManager.publish_date_from = '';
    this.searchManager.publish_date_to = '';
    this.searchManager.search_date_from = '';
    this.searchManager.search_date_to = '';
    this.searchManager.boolean_operator = 'AND';
    this.searchManager.orderField = '';
    this.searchManager.orderType = '';
  }
  onChangeSources(value) {
    console.log('state', this.searchManager.sources);
    console.log('current', value);
  }

  onSubmitFilter(value: any) {
    //console.log('here', value);

    if (this.filterForm.valid) {
      const boolean_operator =
        this.filterForm.controls['boolean_operator'].value;
      if (boolean_operator) {
        this.searchManager.boolean_operator = 'AND';
      } else {
        this.searchManager.boolean_operator = 'OR';
      }
      this.searchManager.orderField =
        this.filterForm.controls['orderField'].value;
      this.searchManager.orderType =
        this.filterForm.controls['orderType'].value;

      this.searchManager.publish_date_from = this.filterForm.controls[
        'publish_date_from'
      ].value
        ? this.filterForm.controls['publish_date_from'].value.getTime()
        : '';

      this.searchManager.publish_date_to = this.filterForm.controls[
        'publish_date_to'
      ].value
        ? this.filterForm.controls['publish_date_to'].value.getTime()
        : '';

      this.searchManager.search_date_from = this.filterForm.controls[
        'search_date_from'
      ].value
        ? this.filterForm.controls['search_date_from'].value.getTime()
        : '';

      this.searchManager.search_date_to = this.filterForm.controls[
        'search_date_to'
      ].value
        ? this.filterForm.controls['search_date_to'].value.getTime()
        : '';

      this.searchManager.source_names =
        this.filterForm.controls['source_names'].value;

      this.searchManager.sentiments =
        this.filterForm.controls['sentiments'].value;
      this.searchManager.sources = this.filterForm.controls['sources'].value;

      this.searchManager.flag_only = this.filterForm.controls['flagged'].value;
      this.searchManager.conversed_only =
        this.filterForm.controls['conversed'].value;

      this.searchManager.offset = 0;
      this.paginator.firstPage();
      this.search();
    }
  }
  cancelFilter() {
    //this.filterForm.reset();
    this.filterForm.controls['orderField'].reset();
    this.filterForm.controls['orderType'].reset();
    this.filterForm.controls['publish_date_from'].reset();
    this.filterForm.controls['publish_date_to'].reset();
    this.filterForm.controls['search_date_from'].reset();
    this.filterForm.controls['search_date_to'].reset();
    this.filterForm.controls['source_names'].reset();
    this.filterForm.controls['sentiments'].reset();
    this.filterForm.controls['sources'].reset();

    this.searchManager.source_names = [];
    this.searchManager.sources = [];
    this.searchManager.sentiments = [];
    this.searchManager.publish_date_from = '';
    this.searchManager.publish_date_to = '';
    this.searchManager.search_date_from = '';
    this.searchManager.search_date_to = '';
    this.searchManager.boolean_operator = 'AND';
    this.searchManager.orderField = '';
    this.searchManager.orderType = '';
    this.searchManager.flag_only = -1;
    this.searchManager.conversed_only = -1;

    this.searchManager.offset = 0;
    this.paginator.firstPage();
    this.search();
  }
  isCSVSource(link: string): boolean {
    if (link !== undefined) {
      return link.indexOf('csv://') === 0;
    } else {
      return false;
    }
  }

  private search() {
    this.searchManager.getSearch(this.history_id);
  }

  public export() {
    this.searchManager.export(this.selectedTerms);
  }
  removehistory() {
    this.history_id = undefined;
    this.search();
  }

  selectChange(event: MatSelectChange) {
    this.searchManager.source = event.value;
    this.searchManager.offset = 0;
    this.paginator.firstPage();
    this.search();
  }

  dateChange(event: MatSelectChange) {}

  changePagesize(event?: PageEvent) {
    this.searchManager.limit = event.pageSize;
    this.searchManager.offset = event.pageIndex * event.pageSize;

    this.search();
  }

  setFlag(search: search) {
    search.flagged = !search.flagged;

    this.searchManager.SetSearchResultFlagged(
      search.id,
      search.flagged,
      this.history_id
    );
  }
  close(): void {
    this.visible = false;
    this.isCommentRender = false;
  }

  showText(text) {
    this.moreText = text;
    this.isVisibleText = true;
  }

  handleOk() {
    this.moreText = null;
    this.isVisibleText = false;
  }

  handleCancel(): void {
    this.moreText = null;
    this.isVisibleText = false;
  }

  selectComment(search: search) {
    this.searchManager.selectedSearch = search;
    this.visible = true;
    this.isCommentRender = true;
    //this.router.navigate(['comments']);
  }
  confirmRemveSearch(search: search) {
    const message = `Are you sure you want to remove this entry?`;

    const dialogData = new ConfirmDialogModel('Confirm Action', message);

    const dialogRef = this.dialog.open(ConfirmationDailogComponent, {
      maxWidth: '400px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      this.result = dialogResult;

      if (this.result) {
        this.removeSearch(search);
      }
    });
  }

  removeSearch(search: search) {
    this.searchManager.RemoveAggregatorSearchResult(search.id, this.history_id);
  }

  showFilterTerms: boolean = false;
  treeControl = new NestedTreeControl<terms>((node) => node.children);
  dataSource = new MatTreeNestedDataSource<terms>();
  selectedTerms = [];

  closepopup() {
    this.showFilterTerms = false;
  }
  openFilterTerms() {
    this.dataSource.data = this.searchManager.TreeCluster;
    this.treeControl.dataNodes = this.searchManager.TreeCluster;
    Object.keys(this.dataSource.data).forEach((x) => {
      this.setParent(this.dataSource.data[x], null);
    });

    this.showFilterTerms = true;
  }

  public filterChanged(filterText: string) {
    let filteredTreeData;
    if (filterText) {
      filteredTreeData = this.searchManager.TreeCluster.filter(
        (d) =>
          d.name.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) >
          -1
      );
    } else {
      filteredTreeData = this.searchManager.TreeCluster;
    }

    // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
    // file node as children.
    this.dataSource.data = filteredTreeData;
    this.treeControl.dataNodes = filteredTreeData;
    Object.keys(this.dataSource.data).forEach((x) => {
      this.setParent(this.dataSource.data[x], null);
    });

    if (filterText) {
      this.treeControl.expandAll();
    } else {
      this.treeControl.collapseAll();
      // this.treeControl.expandAll();
    }
  }

  hasChild = (_: number, node: terms) =>
    !!node.children && node.children.length > 0;
  setParent(data, parent) {
    data.parent = parent;
    if (data.children) {
      data.children.forEach((x) => {
        this.setParent(x, data);
      });
    }
  }

  checkAllParents(node) {
    if (node.parent) {
      const descendants = this.treeControl.getDescendants(node.parent);
      node.parent.selected = descendants.every((child) => child.selected);
      node.parent.indeterminate = descendants.some((child) => child.selected);
      this.checkAllParents(node.parent);
    }
  }
  ItemSelectionToggle(checked, node) {
    node.selected = checked;
    if (node.children) {
      node.children.forEach((x) => {
        this.ItemSelectionToggle(checked, x);
      });
    }
    this.checkAllParents(node);

    this.selectedTerms = [];
    this.dataSource.data.forEach((node) => {
      this.selectedTerms = this.selectedTerms.concat(
        this.treeControl
          .getDescendants(node)
          .filter((x) => x.selected && x.id)
          .map((obj) => ({ term: obj.name, id: obj.id }))
      );
    });
  }

  selectAll() {
    for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
      this.ItemSelectionToggle(true, this.treeControl.dataNodes[i]);
      this.treeControl.expand(this.treeControl.dataNodes[i]);
    }
  }

  unSelectAll() {
    for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
      this.ItemSelectionToggle(false, this.treeControl.dataNodes[i]);
      this.treeControl.collapse(this.treeControl.dataNodes[i]);
    }
  }
}
