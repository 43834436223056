import { Injectable } from '@angular/core';
 
import { environment } from '../../environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import {getRequestId} from './utils';
import {Observable} from 'rxjs';
import { searchResponse, historyResponse } from '../Response/responce';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(private http: HttpClient) {}
 
  getUsers(offset:string,limit:string): Observable<any> {
    const data = new URLSearchParams();
    data.set('offset', offset);
    data.set('request_id', getRequestId().toString());
    data.set('limit', limit);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' });
    const options = {withCredentials: true, headers};
    return this.http.get(environment.apiURL + 'Users?offset='+offset+'&limit='+limit+"&request_id="+getRequestId().toString() , options);
  }


  updateUser(user_id,name,type,state)
  {
    const data = new URLSearchParams();
    data.set('user_id', user_id);
    data.set('request_id', getRequestId().toString());
    data.set('name', name);
    data.set('type', type);
    data.set('state', state);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' });
    const options = {withCredentials: true, headers};
    return this.http.post(environment.apiURL + 'EditUser',data.toString() , options);
 

    


  }



  removeUser(user_id ){
   
   
    const data = new URLSearchParams();
    data.set('user_id', user_id);
    data.set('request_id', getRequestId().toString());
    
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' });
    const options = {withCredentials: true, headers};
    return this.http.post(environment.apiURL + 'RemoveUser',data.toString() , options);
 
  }


  addUser(userName: string, userEmail: string, userType: string): Observable<any> {
    const data = new URLSearchParams();
    data.set('request_id', '' + getRequestId().toString());
    data.set('name', userName);
    data.set('email', userEmail);
    data.set('type', userType);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' });
    const options = {withCredentials: true, headers};
    return this.http.post(environment.apiURL + 'AddUser' , data.toString(), options);
  }
  startPretending(userId: number): Observable<any> {
    const data = new URLSearchParams();
    data.set('request_id',  getRequestId() + '');
    data.set('user_id', userId + '');
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' });
    const options = {withCredentials: true, headers};
    return this.http.post(environment.apiURL + 'StartPretending' , data.toString(), options);
  }

  stopPretending(): Observable<any> {
    const data = new URLSearchParams();
    data.set('request_id', getRequestId() + '');
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' });
    const options = {withCredentials: true, headers};
    return this.http.post(environment.apiURL + 'StopPretending' , data.toString(), options);
  }

   

  resetPasswordByAdmin(email: string,new_password:string): Observable<any> {
    const data = new URLSearchParams();
    data.set('request_id',  getRequestId() + '');
    data.set('email', email);
    data.set('new_password', new_password);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' });
    const options = {withCredentials: true, headers};
    return this.http.post(environment.apiURL + 'RenewPasswordByAdmin' , data.toString(), options);
  }


  resetPassword(loginId: string): Observable<any> {
    const data = new URLSearchParams();
    data.set('request_id', getRequestId() + '');
    data.set('login_id', loginId);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' });
    const options = {withCredentials: true, headers};
    return this.http.post(environment.apiURL + 'ResetPassword' , data.toString(), options);
  }

  changePassword(oldPassword: string, newPassword: string, confirmPassword: string): Observable<any> {
    const data = new URLSearchParams();
    data.set('request_id', getRequestId() + '');
    data.set('current_password', oldPassword);
    data.set('new_password', newPassword);
    data.set('confirm_password', confirmPassword);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' });
    const options = {withCredentials: true, headers};
    return this.http.post(environment.apiURL + 'ChangePassword' , data.toString(), options);
  }
}
