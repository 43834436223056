import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { VisualizationManagerService } from '../../../Managers/visualization-manager.service';
import { AggregatorManagerService } from '../../../Managers/aggregator-manager.service';
import { Router } from '@angular/router';
import * as c3 from 'c3/c3';
import { addContextMenu, waitInMS } from '../../../services/utils';
import { SourceCountChartComponent } from '../source-count-chart/source-count-chart.component';
import { SentimentAverageScoreChartComponent } from '../sentiment-average-score-chart/sentiment-average-score-chart.component';
import { SentimentdevisionComponent } from '../sentimentdevision/sentimentdevision.component';
import { SentimentSourceHistoryComponent } from '../sentiment-source-history/sentiment-source-history.component';
import { AccumulatedChangeComponent } from '../accumulated-change/accumulated-change.component';

const d3: any = addContextMenu();

@Component({
  selector: 'app-system_analysis',
  templateUrl: './system_analysis.component.html',
  styleUrls: ['./system_analysis.component.css'],
})
export class system_analysisComponent implements OnInit, OnDestroy {
  @ViewChild('sourcecount') sourcecount: SourceCountChartComponent;
  @ViewChild('sentimentavgscorecount')
  sentimentavgscorecount: SentimentAverageScoreChartComponent;

  @ViewChild('sentimentPieChart') sentimentPieChart: SentimentdevisionComponent;
  @ViewChild('sentimentBySourceChart')
  sentimentBySourceChart: SentimentSourceHistoryComponent;

  @ViewChild('accumulatedChange') accumulatedChange: AccumulatedChangeComponent;

  private hook = null;
  sentimentByScore: any = null;
  colorFunc = null;
  formatN = d3.format('.2s');

  constructor(
    private router: Router,
    public visualizationManagerService: VisualizationManagerService,
    public aggManager: AggregatorManagerService
  ) {}

  resolveIcon(sourceName): string {
    switch (sourceName) {
      case 'Website':
        return './assets/images/website.png';
      case 'Twitter':
        return './assets/images/twitter.png';
      case 'Google':
        return './assets/images/google.png';
      case 'YouTube':
        return './assets/images/youtube.png';
      case 'Reddit':
        return './assets/images/reddit.jpeg';
      case 'Facebook':
        return './assets/images/facebook.jpeg';
      case 'Instagram':
        return './assets/images/instagram.png';
      case 'Document':
        return './assets/images/document.png';
      case 'Confluence':
        return './assets/images/confluence.png';
      default:
        return './assets/images/default.png';
    }
  }

  async initChart(): Promise<any> {
    await waitInMS(150);
    this.createChart();
  }

  ngOnInit(): void {
    console.log('system analysis component');

    if (!this.aggManager.selectedAggregator) {
      this.router.navigate(['']).then();
      return;
    }
    if (this.visualizationManagerService.sentimentsData) {
      this.initChart().then();
    }
    this.visualizationManagerService
      .assertSentimentsData(this.aggManager.selectedAggregator.id)
      .then((res) =>
        console.log('hhhh', this.visualizationManagerService.sentimentsData[4])
      );
    this.hook = this.visualizationManagerService.visualizingInit.subscribe(
      async (event) => this.initChart().then()
    );
  }

  sortByAverage(sources: [any]): [any] {
    return sources.sort((a, b) => b.sentimentAverage - a.sentimentAverage);
  }

  sortByAnalysed(sources: [any]): [any] {
    return sources.sort((a, b) => b.analysed - a.analysed);
  }

  createChart(): void {
    if (this.visualizationManagerService.isLoading) {
      return;
    }

    globalThis.d3 = d3;

    this.sentimentByScore = this.visualizationManagerService.sentimentsData[4];
    if (this.sentimentByScore.sources) {
      this.colorFunc = d3
        .scaleOrdinal(this.sentimentByScore.sources.map((d) => d.source))
        .range([
          '#354FB0',
          '#fabf1b',
          '#1ED6BB',
          '#6214f4',
          '#d61e57',
          '#1464f4',
          '#0093d5',
          '#949bff',
          '#ab6dff',
          '#ff0e72',
          '#0b80b0',
          '#00ac59',
          '#488f31',
          '#00ac59',
          '#83af70',
          '#c4a0ec',
          '#082CAF',
          '#98af8f',
          '#778271',
          '#8e8e8e',
          '#9f8888',
          '#f0b8b8',
          '#ec9c9d',
          '#e67f83',
          '#de6069',
          '#de425b',
          '#a47b80',
        ]);

      const colors = {};
      for (const source of this.visualizationManagerService.sentimentsData[4]
        .sources) {
        colors[source.source] = this.colorFunc(source.source);
      }
      if (this.sourcecount) {
        this.sourcecount.createChart();
      }
      this.sentimentavgscorecount.createChart();
      this.sentimentPieChart.createChart();
      this.sentimentBySourceChart.createChart();
      this.accumulatedChange.createChart();
      /*
      const sourceCountChart = c3.generate({
        bindto: '#sourceCountChart',
        point: {
          r: 3
        },
        data: {
          x: 'x',
          columns: [['x', ...this.visualizationManagerService.sentimentsData[4].sources[0]
            .sourceHistory.map(i => (new Date(i.executionTime)).toISOString().substring(0, 10))],
            ...this.visualizationManagerService.sentimentsData[4]
              .sources.map(i => ([i.source, ...i.sourceHistory.map(ini => ini.sourceCount)]))],
          labels: false,
          type: 'spline',
          empty: {
            label: {
              text: 'No Data'
            }
          },
          zoom: {

            type: 'drag'
          },
          selection: {
            enabled: true,
            draggable: true
          },
          colors
        }, zoom: {
          enabled: true
        },
        subchart: {
          show: true,
          axis: {
            x: {
              show: false
            }
          }
        },
        axis: {
          x: {
            label: {
              text: 'Executions',
              position: 'inner-center'
            },
            // type: 'category',
            type: 'category',
            tick: {
              format: '%Y-%m-%d'
            },
            // categories: this.visualizationManagerService.sentimentsData[4]
            //   .sources[0].sentimentHistory.map(i => (new Date(i.time)).toISOString().substring(0, 10)),
            // tick: {
            //   count: 4
            // }
          },
          y: {
            label: {
              text: 'Count',
              position: 'outer-middle'
            },
            tick: {
              format: d3.format('.1s')
            }
          },
          y2: {
            show: false,
            label: 'Score'
          }
        },
        grid: {
          x: {
            show: false
          },
          y: {
            show: false,

          }
        },
        // bar: {
        //   space: 0.1,
        //   width: {
        //     ratio: 0.85 // this makes bar width 50% of length between ticks
        //   }
        // }
      });

      sourceCountChart.zoom([94, 100]);


      const sentimentDivisionCHart = c3.generate({
        bindto: '#sentimentPieChart',
        pie: {
          expand: true
        },
        // title: {
        //   text: 'Sentiment Division',
        //   position: 'top-left',   // top-left, top-center and top-right
        // },
        data: {
          // iris data from R
          columns: this.visualizationManagerService.sentimentsData[0],
          type: 'pie',
          colors: {
            Positive: '#1ed6bb',
            Negative: '#d61e57',
            Neutral: '#6214f4'
          }
        }
      });



      */

      /* const averageSentimentScore = c3.generate({
        bindto: '#sentimentAverageScoreChart',
        // title: {
        //   text: 'Average Sentiment Score / History',
        //   position: 'top-right',   // top-left, top-center and top-right
        // },
        point: {r: 3}, zoom: {
          enabled: true
        },
        subchart: {
          show: true,
          axis: {
            x: {
              show: false
            }
          }
        },
        data: {
          x: 'x',
          columns: [
            ['x', ...this.visualizationManagerService.sentimentsData[2].categories],
            this.visualizationManagerService.sentimentsData[2].columns,
            this.visualizationManagerService.sentimentsData[3].columns
          ],
          labels: false,
          type: 'bar',
          types: {
            'Smoothened Score': 'spline'
          },
          empty: {
            label: {
              text: 'No Data'
            }
          },
          selection: {
            enabled: true,
            draggable: true
          },
          colors: {
            'Smoothened Score': '#082caf',
            'Average Score': '#1ed6bb'
          }
        },
        axis: {
          x: {
            label: {
              text: 'Executions',
              position: 'inner-center'
            },
            type: 'category',
            tick: {
              format: '%Y-%m-%d'
            }
          },
          y: {
            label: {
              text: 'Score',
              position: 'outer-middle'
            },
            tick: {
              format: d3.format('.1f')
            }
          },
          y2: {
            show: false,
            label: 'Score'
          }
        },
        grid: {
          x: {
            show: false
          },
          y: {
            show: false,
            lines: [
              {value: 0, text: '', position: 'end'}
            ]
          }
        },
        bar: {
          space: 0.1,
          width: {
            ratio: 0.85 // this makes bar width 50% of length between ticks
          }
        }
      });

      averageSentimentScore.zoom([25, 30]); 

      const sentimentBySourceChart = c3.generate({
        bindto: '#sentimentBySourceChart',
        // title: {
        //   text: 'Average Sentiment Score / History',
        //   position: 'top-right',   // top-left, top-center and top-right
        // },
        point: {
          r: 3
        },
        data: {
          x: 'x',
          columns: [['x', ...this.visualizationManagerService.sentimentsData[4].sources[0]
            .sentimentHistory.map(i => (new Date(i.time)).toISOString().substring(0, 10))],
            ...this.visualizationManagerService.sentimentsData[4]
              .sources.map(i => ([i.source, ...i.sentimentHistory.map(ini => ini.average)]))],
          labels: false,
          type: 'spline',
          // types: {
          //   'Smoothened Score': 'spline'
          // },
          empty: {
            label: {
              text: 'No Data'
            }
          },
          selection: {
            enabled: true,
            draggable: true
          },
          colors
        },
        axis: {
          x: {
            label: {
              text: 'Executions',
              position: 'inner-center'
            },
            // type: 'category',
            type: 'category',
            tick: {
              format: '%Y-%m-%d'
            },
            // categories: this.visualizationManagerService.sentimentsData[4]
            //   .sources[0].sentimentHistory.map(i => (new Date(i.time)).toISOString().substring(0, 10)),
            // tick: {
            //   count: 4
            // }
          },
          y: {
            label: {
              text: 'Sentiment',
              position: 'outer-middle'
            },
            // tick: {
            //   format: d3.format('.1f')
            // }
          },
          y2: {
            show: false,
            label: 'Score'
          }
        },
        grid: {
          x: {
            show: false
          },
          y: {
            show: false,
            lines: [
              {value: 0, text: '', position: 'end'}
            ]
          }
        },
        zoom: {
          enabled: true
        },
        subchart: {
          show: true,
          axis: {
            x: {
              show: false
            }
          }
        },
        // bar: {
        //   space: 0.1,
        //   width: {
        //     ratio: 0.85 // this makes bar width 50% of length between ticks
        //   }
        // }
      });
      sentimentBySourceChart.zoom([94, 100]);  


      const accumulatedChangeInSourceChart = c3.generate({
        bindto: '#accumulatedChangeInSourceChart',
        // title: {
        //   text: 'Average Sentiment Score / History',
        //   position: 'top-right',   // top-left, top-center and top-right
        // },
        point: {
          r: 3
        },
        zoom: {
          enabled: true
        },
        subchart: {
          show: true,
          axis: {
            x: {
              show: false
            }
          }
        },
        data: {
          x: 'x',
          columns: [['x', ...this.visualizationManagerService.sentimentsData[4].sources[0]
            .sourceHistory.map(i => (new Date(i.executionTime)).toISOString().substring(0, 10))],
            ...this.visualizationManagerService.sentimentsData[4]
              .sources.map(i => ([i.source, ...i.sourceHistory.map(ini => ini.accumulatedChange)]))],
          labels: false,
          type: 'area',
          // types: {
          //   'Smoothened Score': 'spline'
          // },
          empty: {
            label: {
              text: 'No Data'
            }
          },
          selection: {
            enabled: true,
            draggable: true
          },
          colors
        },
        axis: {
          x: {
            label: {
              text: 'Executions',
              position: 'inner-center'
            },
            // type: 'category',
            type: 'category',
            tick: {
              format: '%Y-%m-%d'
            },
            // categories: this.visualizationManagerService.sentimentsData[4]
            //   .sources[0].sentimentHistory.map(i => (new Date(i.time)).toISOString().substring(0, 10)),
            // tick: {
            //   count: 4
            // }
          },
          y: {
            label: {
              text: 'Accumulated Change',
              position: 'outer-middle'
            },
            tick: {
              format: d3.format('.1s')
            }
          },


          y2: {
            show: false,
            label: 'Score'
          }
        },
        grid: {
          x: {
            show: false
          },
          y: {
            show: false,
            lines: [
              {value: 0, text: '', position: 'end'}
            ]
          }
        },
        // bar: {
        //   space: 0.1,
        //   width: {
        //     ratio: 0.85 // this makes bar width 50% of length between ticks
        //   }
        // }
      });
      accumulatedChangeInSourceChart.zoom([94, 100]); */
    }
  }

  ngOnDestroy(): void {
    if (this.hook) {
      this.hook.unsubscribe();
    }
  }
}
