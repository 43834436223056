export const convertGraphToHTML = (width, height, content) => {
  return `
  <svg xmlns="http://www.w3.org/2000/svg" width="750" height="600"     >  
   

 <g transform="translate(50,250)">
${content.replace(/<rect.*>.*?<\/rect>/ig,'') }
  </g>
</svg>
  `;
};

/*<defs>
  .node {
  cursor: pointer;
  pointer-events: all;
}

 .node:hover  {
  stroke: white;
  stroke-width: 1.5px;
}

  .node--leaf {
  fill: #201a5f;
  color: transparent !important;
}

 .label {
  font-family: Roboto;
  font-size: 9px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.22;
  letter-spacing: normal;
  text-align: center;
  fill: white;

}

 .label,
  .node--root,
  .node--leaf {
  pointer-events: none;
}

svg .label, svg .node--root, svg .node--leaf {
  pointer-events: visible !important;
}

 
 

 
  .node {
  cursor: pointer;
}
 .node > .ring {
  fill: none;
  opacity: 0;
  stroke: #6ac6ff;
}
 .node.selected > .ring {
  stroke: #fdcc59;
  opacity: 0.3;
}
  .node:hover > .ring {
  stroke: #6ac6ff;
  opacity: 0.3;
}
  .relationship > text {
  fill: #333;
}
  .relationship > .overlay {
  opacity: 0;
  fill: #6ac6ff;
}
  
  .remove_node .expand_node:hover {
  border: 2px #000 solid;
}
  .outline {
  cursor: pointer;
}
 
  text {
  line-height: normal;
}
 
 
 
</defs>*/