import { NgModule } from '@angular/core';
import { AggregatorsComponent } from './aggregators/aggregators.component';
import { LoginComponent } from './login/login.component';
import { SigninComponent } from './login/signin/signin.component';
import { Routes, RouterModule } from '@angular/router';
import { AggregatorListComponent } from './aggregators/aggregator-list/aggregator-list/aggregator-list.component';
import { VisualizationComponent } from './aggregators/Visualization/visualization/visualization.component';
import { system_analysisComponent } from './aggregators/system_analysis/system_analysis/system_analysis.component';
import { HistoryComponent } from './aggregators/history/history/history.component';
import { SearchComponent } from './aggregators/search/search/search.component';
import { SettingComponent } from './aggregators/setting/setting/setting.component';
import { UsersComponent } from './users/users.component';
import { UserListComponent } from './users/user-list/user-list.component';
import { EdituserComponent } from './users/edituser/edituser/edituser.component';
import { LandingDashboardComponent } from './landing-dashboard/landing-dashboard.component';
import { AlreadyLoggedIn } from './gaurd/already-login';
import { LogoutComponent } from './login/logout/logout.component';
import { ForgotpasswordComponent } from './login/forgotpassword/forgotpassword.component';
import { SentimentsComponent } from './aggregators/Visualization/sentiments/sentiments.component';
import { BubbleChartComponent } from './aggregators/Visualization/bubble-chart/bubble-chart.component';
import { IndentTreeComponent } from './aggregators/Visualization/indent-tree/indent-tree.component';
import { TreeMapComponent } from './aggregators/Visualization/tree-map/tree-map.component';
import { ForceComponent } from './aggregators/Visualization/force/force.component';
import { PackComponent } from './aggregators/Visualization/pack/pack.component';
import { TreeComponent } from './aggregators/Visualization/tree/tree.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { InfographComponent } from './info-graph/infograph/infograph.component';
import { CommentsConversationComponent } from './aggregators/comments-conversation/comments-conversation.component';
import { ChangepasswordComponent } from './login/changepassword/changepassword.component';
import { RoundBurstChartComponent } from './aggregators/Visualization/round-burst-chart/round-burst-chart.component';
import { CsvfileVisualizerComponent } from './aggregators/Visualization/csvfile-visualizer/csvfile-visualizer.component';
import { GraphTestComponent } from './graph-test/graph-test.component';
import { DashboardComponent } from './aggregators/dashboard/dashboard.component';
import { CvPipelineComponent } from './aggregators/Visualization/cv-pipeline/cv-pipeline.component';
import { SpeechrecognitionComponent } from './aggregators/speechrecognition/speechrecognition.component';
import { AudiorecorderComponent } from './aggregators/audiorecorder/audiorecorder.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    children: [
      { path: '', component: SigninComponent },
      { path: 'forgot-password', component: ForgotpasswordComponent },
    ],
  },
  { path: 'graph', component: GraphTestComponent },
  {
    path: 'changepassword',
    component: ChangepasswordComponent,
    canActivate: [AlreadyLoggedIn],
  },

  { path: 'logout', component: LogoutComponent },
  {
    path: '',
    component: LandingDashboardComponent,
    canActivate: [AlreadyLoggedIn],
    children: [
      {
        path: '',
        component: AggregatorsComponent,
        children: [
          { path: '', component: AggregatorListComponent },
          { path: 'sentiment_analysis', component: system_analysisComponent },
          { path: 'dashboard', component: DashboardComponent },
          {
            path: 'visualization',
            component: VisualizationComponent,
            children: [
              { path: 'sentiments', component: SentimentsComponent },
              { path: 'bubble-chart', component: BubbleChartComponent },
              { path: 'indent-tree', component: IndentTreeComponent },
              { path: 'tree-map', component: TreeMapComponent },
              { path: 'sunburst', component: RoundBurstChartComponent },
              { path: 'force', component: ForceComponent },
              { path: 'pack', component: PackComponent },
              { path: 'tree', component: TreeComponent },
              { path: 'cvPipeline', component: CvPipelineComponent },
            ],
          },
          { path: 'infograph', component: InfographComponent },
          { path: 'speechrecognition', component: SpeechrecognitionComponent },
          { path: 'csvVisualization', component: CsvfileVisualizerComponent },

          { path: 'history', component: HistoryComponent },
          { path: 'search', component: SearchComponent },
          { path: 'setting', component: SettingComponent },
          { path: 'comments', component: CommentsConversationComponent },
          { path: 'audiorecorder', component: AudiorecorderComponent },
        ],
      },
      {
        path: 'users',
        component: UsersComponent,
        children: [
          { path: '', component: UserListComponent },
          { path: 'edituser', component: EdituserComponent },
        ],
      },
    ],
  },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
