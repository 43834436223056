export class infoGraph {
  constructor(name, type, isSelected) {
    this.name = name;
    this.isSelected = isSelected;
    this.type = type;
  }

  public name = '';
  public type: string; // three type classification, relationship , cluster
  public isSelected: boolean;

  relationCount: number;
  nodeCount: number;
}

export class query {
  public name: string;
  public condition: string;
  public query: infoGraph[];
}

export class infoGraphResponse {
  public aggregator_id: number;
  public created_time: number;
  public user_id: number;
  query: query;
  id: number;
  isEnabled: boolean = false;
}
