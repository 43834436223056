/* tslint:disable */

import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  Input,
} from '@angular/core';
import { addContextMenu, d3Modify, waitInMS } from '../../../services/utils';
import { VisualizationManagerService } from '../../../Managers/visualization-manager.service';
import { DocumentSourceService } from '../../../services/document-source.service';
import { AggregatorManagerService } from '../../../Managers/aggregator-manager.service';
import { DemoService } from '../../../services/demo.service';
import { viz_type } from '../../../models/dashboard';
import { DashboardManagerService } from '../../../Managers/dashboard-manager.service';
const d3: any = addContextMenu();

@Component({
  selector: 'app-indent-tree',
  templateUrl: './indent-tree.component.html',
  styleUrls: ['./indent-tree.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class IndentTreeComponent implements OnInit, OnDestroy {
  @ViewChild('indentchart', { static: true })
  private indentchartContainer: ElementRef;
  @Input() height: number = null;
  visualizationSubscription = null;
  _serviceSubscription: any;
  loaded = false;
  constructor(
    public visualizationManagerService: VisualizationManagerService,
    public dashboardManager: DashboardManagerService,
    public aggregatorManagerService: AggregatorManagerService,
    public documentSourceService: DocumentSourceService,
    public demoService: DemoService
  ) {
    this._serviceSubscription = this.dashboardManager.onExport.subscribe(
      (c) => {
        // this.export();
      }
    );
  }

  async initChart(): Promise<any> {
    const element = this.indentchartContainer.nativeElement;
    element.innerHTML = '';
    //  await waitInMS(30);
    this.createChart();
  }

  createChart(): void {
    if (!this.loaded) return;
    if (
      !this.visualizationManagerService.visualizationInfo ||
      !this.visualizationManagerService.visualizationData
    )
      return;
    if (this.visualizationManagerService.visualizationSearchFailed) return;

    const indentTreeComp = this;
    const updateNodeStyle = (d, i, nodeMap) => {
      if (d.data.flagged) {
        d.data.oldColor = d3.select(nodeMap[i]).style('fill');
        d3.select(nodeMap[i]).style('fill', '#ffe00b');
      } else {
        d3.select(nodeMap[i]).style('fill', '#fd8d3c');
      }
    };
    const menu = (nodeD: any, i: any, nodeMap: any) => [
      nodeD.data.flagged
        ? {
            title: 'Unflag',
            action: () => {
              nodeD.data.flagged = false;
              const { documentId } = nodeD.data;
              indentTreeComp.visualizationManagerService.changeFlagStatus({
                id: documentId,
                status: false,
              });
              updateNodeStyle(nodeD, i, nodeMap);
            },
          }
        : {
            title: 'Flag',
            action: () => {
              nodeD.data.flagged = true;
              const { documentId } = nodeD.data;
              indentTreeComp.visualizationManagerService.changeFlagStatus({
                id: documentId,
                status: true,
              });
              updateNodeStyle(nodeD, i, nodeMap);
            },
          },
      {
        title: 'Source',
        action: () => {
          const { documentId } = nodeD.data;
          indentTreeComp.documentSourceService.getAndOpenSource(
            { id: documentId },
            indentTreeComp.aggregatorManagerService.selectedAggregator.id
          );
        },
      },
    ];
    const onContext = (d, i, allNodes) => {
      d3.event.preventDefault();
      if (!d.data.children) {
        if (this.demoService.demoMode) {
          const { documentId } = d.data;
          indentTreeComp.documentSourceService.getAndOpenSource(
            { id: documentId },
            indentTreeComp.aggregatorManagerService.selectedAggregator.id
          );
        } else {
          d3.contextMenu(menu(d, i, allNodes))(d, i, allNodes);
        }
      }
    };
    const clonedData = JSON.parse(
      JSON.stringify(
        indentTreeComp.visualizationManagerService.visualizationSearchData ||
          indentTreeComp.visualizationManagerService.visualizationData
      )
    );
    const aggregatorName =
      indentTreeComp.visualizationManagerService.visualizationInfo
        .aggregator_visualization_info.name;

    const data = d3Modify(clonedData, aggregatorName);
    const chartElement = indentTreeComp.indentchartContainer.nativeElement;
    chartElement.innerHTML = '';

    const width = chartElement.offsetWidth;
    const elHeight = chartElement.offsetHeight;

    const barHeight = 40;
    const barWidth = Math.round(width * 0.8);
    let i = 0;
    const duration = 400;
    const root = d3.hierarchy(data);
    root.x0 = 0;
    root.y0 = 0;

    const diagonal = d3
      .linkHorizontal()
      .x(function (d) {
        return d.y;
      })
      .y(function (d) {
        return d.x;
      });

    const svg = d3
      .select(chartElement)
      .append('svg')
      .attr('id', 'indent-tree-svg-01')
      .attr('width', width)
      .attr('height', elHeight)
      .append('g')
      .attr('transform', 'translate(' + '30' + ',' + '20' + ')');

    root.eachBefore(function (n) {
      if (n.depth >= 1 && n.data.children) {
        n._children = n.children;
        n.children = null;
      }
      if (n.depth >= 1 && !n.data.children) {
        n.id = null;
      }
    });
    update(root);
    function update(source) {
      // Compute the flattened node list.
      const nodes = root.descendants();

      const height = Math.max(500, nodes.length * (barHeight * 1.1) + 50);

      d3.select('svg#indent-tree-svg-01')
        .transition()
        .duration(duration)
        .attr('height', height + 'px');

      d3.select(self.frameElement)
        .transition()
        .duration(duration)
        .style('height', height + 'px');

      let index = -1;
      root.eachBefore(function (n) {
        n.x = ++index * (barHeight * 1.1);
        n.y = n.depth * barHeight * 2;
      });

      // Update the nodes…
      const node = svg.selectAll('.node').data(nodes, function (d) {
        return d.id || (d.id = ++i);
      });

      const nodeEnter = node
        .enter()
        .append('g')
        .attr('class', 'node')
        .attr('transform', function (d) {
          return 'translate(' + source.y0 + ',' + source.x0 + ')';
        })
        .style('opacity', 0);

      // Enter any new nodes at the parent's previous position.
      nodeEnter
        .append('rect')
        .attr('y', -barHeight / 2)
        .attr('height', barHeight)
        .attr('width', barWidth)
        .attr('rx', barHeight / 4)
        .style('fill', color)
        .on('click', click)
        .on('contextmenu', onContext);

      nodeEnter
        .append('text')
        .attr('dy', 3.5)
        .attr('dx', 20)
        .text(function (d) {
          return d.data.name;
        });

      // Transition nodes to their new position.
      nodeEnter
        .transition()
        .duration(duration)
        .attr('transform', function (d) {
          return 'translate(' + d.y + ',' + d.x + ')';
        })
        .style('opacity', 1);

      node
        .transition()
        .duration(duration)
        .attr('transform', function (d) {
          return 'translate(' + d.y + ',' + d.x + ')';
        })
        .style('opacity', 1)
        .select('rect')
        .style('fill', color);

      // Transition exiting nodes to the parent's new position.
      node
        .exit()
        .transition()
        .duration(duration)
        .attr('transform', function (d) {
          return 'translate(' + source.y + ',' + source.x + ')';
        })
        .style('opacity', 0)
        .remove();

      // Update the links…
      const link = svg.selectAll('.link').data(root.links(), function (d) {
        return d.target.id;
      });

      // Enter any new links at the parent's previous position.
      link
        .enter()
        .insert('path', 'g')
        .attr('class', 'link')
        .attr('d', function (d) {
          const o = { x: source.x0, y: source.y0 };
          return diagonal({ source: o, target: o });
        })
        .transition()
        .duration(duration)
        .attr('d', diagonal);

      // Transition links to their new position.
      link.transition().duration(duration).attr('d', diagonal);

      // Transition exiting nodes to the parent's new position.
      link
        .exit()
        .transition()
        .duration(duration)
        .attr('d', function (d) {
          const o = { x: source.x, y: source.y };
          return diagonal({ source: o, target: o });
        })
        .remove();

      // Stash the old positions for transition.
      root.each(function (d) {
        d.x0 = d.x;
        d.y0 = d.y;
      });
    }

    // Toggle children on click.
    function click(d) {
      if (!d.data.children) {
        indentTreeComp.visualizationManagerService.showDocumentText({
          id: d.data.documentId,
          text: d.data.name,
        });
      }
      if (d.children) {
        d._children = d.children;
        d.children = null;
      } else {
        d.children = d._children;
        d._children = null;
      }
      update(d);
    }

    function color(d) {
      switch (d.data.dataLevel) {
        case 0:
          return '#2c49b0';
        case 1:
          return '#d64b77';
        case 2:
          return '#7d3df5';
        case 3:
          return d.data.flagged ? '#fabf1b' : '#36d6be';
      }
    }
  }

  ngOnDestroy(): void {
    if (this._serviceSubscription) this._serviceSubscription.unsubscribe();
    this.loaded = false;
    this.visualizationSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.dashboardManager.viz_types.forEach((c) => {
      if (c.viz_uid == this.viz_uid) {
        this.viz_type = c;
      }
    });
    this.loaded = true;
    this.visualizationSubscription =
      this.visualizationManagerService.visualizingInit.subscribe(() =>
        this.initChart().then().catch(console.log)
      );
    this.initChart().then().catch(console.log);
  }

  viz_uid = '000000000000000000000007';

  public viz_type: viz_type;
  Add() {
    this.dashboardManager.viz_types.forEach((c) => {
      if (c.viz_uid == this.viz_uid) {
        this.viz_type = c;
      }
    });
    this.viz_type.isEnabled = true;
    this.dashboardManager.AddDashboardItem(
      this.aggregatorManagerService.selectedAggregator.id,
      this.viz_type.viz_uid,
      1
    );
  }

  export() {
    if (this.height) {
      const element = document.querySelector('div#indentchart svg');

      this.dashboardManager.dashboard_items.forEach((c) => {
        if (c.viz_uid == this.viz_uid) {
          c.viz_type = this.viz_type.viz_type;
          //   c.viz_svg=  "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"600\" height=\"600\" viewBox=\"0 0 200 1200\"  style=\"display: block; margin: 0vh; background: rgb(255, 255, 255); cursor: pointer;\" > <g transform=\"translate(15,50)\">"+ element.innerHTML+"</g></svg>";
          c.viz_svg =
            '<svg xmlns="http://www.w3.org/2000/svg" width="850" height="900"  viewBox="0 0 200 1600"   > <g transform="translate(0,10)"  >' +
            element.innerHTML +
            '</g></svg>';
        }
      });
    }
  }
}
