import { Pipe, PipeTransform } from '@angular/core';
import { InfoGraphManagerService } from '../Managers/info-graph-manager.service';
import { infoGraph } from '../models/infograph';

@Pipe({
  name: 'isExist',
})
export class IsExistPipe implements PipeTransform {
  constructor(public infoManager: InfoGraphManagerService) {}

  transform(value: infoGraph): boolean {
    let found = true;

    this.infoManager.query.forEach((q) => {
      if (q.name === value.name && q.type === value.type) {
        found = false;
      }
    });
    return found;
  }
}
