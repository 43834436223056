import { Component, OnInit, ElementRef, Input } from '@angular/core';
import * as d3 from 'd3';
import { line_point } from '../../../../models/csvVisualizer';

@Component({
  selector: 'app-linechart',
  templateUrl: './linechart.component.html',
  styleUrls: ['./linechart.component.css'],
})
export class LinechartComponent implements OnInit {
  uniqueId: any;
  @Input() id: string;

  ngOnInit() {
    this.uniqueId = 'linechart' + this.id;
  }

  @Input() public data: line_point[] = [];

  private margin = 50;
  private width = 950;
  private height = 750;

  public svg;
  public svgInner;
  public yScale;
  public xScale;
  public xAxis;
  public yAxis;
  public lineGroup;

  constructor(public chartElem: ElementRef) {}

  public ngOnChanges(changes): void {
    if (changes.hasOwnProperty('data') && this.data) {
      this.initializeChart();
      this.drawChart();

      window.addEventListener('resize', () => this.drawChart());
    }
  }

  private initializeChart(): void {
    if (this.svg) {
      d3.select('figure#' + this.uniqueId)
        .selectAll('*')
        .remove();
    }
    this.svg = d3
      .select('figure#' + this.uniqueId)
      .append('svg')
      .attr('height', this.height);

    this.svgInner = this.svg
      .append('g')
      .style(
        'transform',
        'translate(' + this.margin + 'px, ' + this.margin + 'px)'
      );

    this.yScale = d3
      .scaleLinear()
      .domain([d3.max(this.data, (d) => d.y), d3.min(this.data, (d) => d.y)])
      .range([0, this.height - 2 * this.margin]);

    this.yAxis = this.svgInner
      .append('g')
      .attr('id', 'y-axis')
      .style('transform', 'translate(' + this.margin + 'px,  0)');

    this.xScale = d3.scaleTime().domain(d3.extent(this.data, (d) => d.x));

    this.xAxis = this.svgInner
      .append('g')
      .attr('id', 'x-axis')
      .style(
        'transform',
        'translate(0, ' + (this.height - 2 * this.margin) + 'px)'
      );

    this.lineGroup = this.svgInner
      .append('g')
      .append('path')
      .attr('id', 'line')
      .style('fill', 'none')
      .style('stroke', 'red')
      .style('stroke-width', '2px');
  }

  private drawChart(): void {
    //this.width = this.chartElem.nativeElement.getBoundingClientRect().width;

    this.svg.attr('width', this.width);

    this.xScale.range([this.margin, this.width - 2 * this.margin]);

    const xAxis = d3.axisBottom(this.xScale);

    this.xAxis.call(xAxis);

    const yAxis = d3.axisLeft(this.yScale);

    this.yAxis.call(yAxis);

    const line = d3
      .line()
      .x((d) => d[0])
      .y((d) => d[1])
      .curve(d3.curveMonotoneX);

    const points: [number, number][] = this.data.map((d) => [
      this.xScale(d.x),
      this.yScale(d.y),
    ]);

    this.lineGroup.attr('d', line(points));
  }
}
