import {
  Component,
  OnInit,
  ViewChild,
  Input,
  OnChanges,
  OnDestroy,
  AfterViewInit,
  ElementRef,
  ChangeDetectorRef,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  detected_object,
  general_Image,
  face_detection,
  object_detection,
  text_detection_results,
  text,
} from '../../../../models/dashboard';
import { VisualizationManagerService } from '../../../../Managers/visualization-manager.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-cv-pipeline-image',
  templateUrl: './cv-pipeline-image.component.html',
  styleUrls: ['./cv-pipeline-image.component.css'],
})
export class CvPipelineImageComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit
{
  constructor(
    public vManger: VisualizationManagerService,
    private cdRef: ChangeDetectorRef
  ) {}
  @ViewChild('layout', { static: true }) canvasRef;

  @Input() width: number = 350;

  @Input() height: number = 350;

  @Input() imgUrl: string;
  @Input() image: general_Image;
  @Input() parentId: string;
  @Input() tooltip: text[];

  @Input() face_detection: face_detection[];

  @Input() object_detection: object_detection[];
  loading: boolean = true;

  @Output() onClick = new EventEmitter<general_Image>();

  imageloadSubscriber;
  ngOnInit(): void {
    if (this.vManger.loadImageonscroll) {
      this.imageloadSubscriber = this.vManger.loadImageonscroll.subscribe(
        (c) => {
          //     if (this.isScrolledIntoView(this.canvasRef.nativeElement)) {

          if (this.loading) this.drawRectangle('emit');
          //   }
        }
      );
    }
  }

  ngAfterViewInit() {
    // this.width = document.getElementById("imageplacehoder" + this.parentId).offsetWidth;
    //  if (this.isScrolledIntoView(this.canvasRef.nativeElement)) {

    this.drawRectangle('Firstload');
    //   }
  }
  ngOnDestroy() {
    if (this.imageloadSubscriber) this.imageloadSubscriber.unsubscribe();
  }

  ngOnChanges() {
    //if (this.isScrolledIntoView(this.canvasRef.nativeElement)) {

    this.drawRectangle('Change');

    // }
    //console.log("This is: "+ Math.max.apply(Math, this.tooltip.map(function(o) { return o.score; })))
  }

  isScrolledIntoView(el) {
    let rect = el.getBoundingClientRect();
    let elemTop = rect.top;
    let elemBottom = rect.bottom;

    // Only completely visible elements return true:
    let isVisible =
      elemTop >= 100 && elemBottom - 50 <= window.innerHeight - 150;
    // Partially visible elements return true:
    //isVisible = elemTop < window.innerHeight && elemBottom >= 0;

    return isVisible;
  }
  //other code here

  drawRectangle(location): void {
    let canvas = this.canvasRef.nativeElement;
    let context = canvas.getContext('2d');
    // context.clearRect(0, 0, canvas.width, canvas.height);

    let source = new Image();

    //source.setAttribute('crossorigin', 'anonymous');
    source.src = this.imgUrl;

    source.onload = () => {
      this.loading = false;

      this.DrawImage(source, canvas, location, context);
    };

    source.onerror = (c) => {};

    context.restore();
  }

  onImageClick() {
    console.log('Image clicked');
    this.onClick.emit();
  }

  show;

  private DrawImage(
    source: HTMLImageElement,
    canvas: any,
    location: any,
    context: any
  ) {
    const scalex = this.width / source.width;
    const scaley = this.height / source.height;
    const scale = Math.min(scalex, scaley);
    const x = (canvas.width - source.width * scale) / 2;
    const y = (canvas.height - source.height * scale) / 2;
    this.width = source.width * scale;
    this.height = source.height * scale;

    try {
      context.drawImage(
        source,
        x,
        y,
        Math.round(source.width * scalex),
        Math.round(source.height * scaley)
      );
      if (this.object_detection) {
        //general image detection
        this.object_detection.forEach((c) => {
          context.beginPath();
          context.rect(
            c.bbox.x1 * scalex,
            c.bbox.y1 * scaley,
            (c.bbox.x2 - c.bbox.x1) * scalex,
            (c.bbox.y2 - c.bbox.y1) * scaley
          );
          context.strokeStyle = '#FF0000';
          context.lineWidth = 3;
          context.stroke();
        });
      }
      if (this.face_detection) {
        this.face_detection.forEach((c) => {
          context.beginPath();
          let region = {
            x: c.bbox.x1 * scalex + x,
            y: c.bbox.y1 * scaley + y,
            w: (c.bbox.x2 - c.bbox.x1) * scalex,
            h: (c.bbox.y2 - c.bbox.y1) * scaley,
          };
          context.rect(region.x, region.y, region.w, region.h);
          context.strokeStyle = '#FF0000';
          context.lineWidth = 3;
          context.stroke();
          this.ToolTip(
            canvas,
            region,
            c.facial_analysis.emotion +
              '<br/>' +
              c.facial_analysis.gender +
              '<br/> min age:' +
              c.facial_analysis.age_min +
              '<br/> max age:' +
              c.facial_analysis.age_max,
            100,
            1200
          );
        });
      }

      this.loading = false;
    } catch (ex) {}
  }

  // The Tool-Tip instance:
  ToolTip(canvas, region, text, width, timeout) {
    var me = this, // self-reference for event handlers
      div = document.createElement('div'), // the tool-tip div
      parent = canvas.parentNode, // parent node for canvas
      visible = false; // current status

    // set some initial styles, can be replaced by class-name etc.
    div.style.cssText =
      'position:fixed;padding:7px;z-index:2;background:rgba(255, 255, 0, 0.759);color:black; pointer-events:none; line-height: 15px; width:' +
      width +
      'px; height:' +
      80 +
      'px';
    div.innerHTML = text;

    // show the tool-tip
    this.show = function (pos) {
      if (!visible) {
        // ignore if already shown (or reset time)
        visible = true; // lock so it's only shown once
        setDivPos(pos); // set position
        parent.appendChild(div); // add to parent of canvas
        setTimeout(hide, timeout); // timeout for hide
      }
    };

    // hide the tool-tip
    function hide() {
      visible = false; // hide it after timeout
      parent.removeChild(div); // remove from DOM
    }

    // check mouse position, add limits as wanted... just for example:
    function check(e) {
      var pos = getPos(e),
        posAbs = { x: e.clientX, y: e.clientY }; // div is fixed, so use clientX/Y
      if (
        !visible &&
        pos.x >= region.x &&
        pos.x < region.x + region.w &&
        pos.y >= region.y &&
        pos.y < region.y + region.h
      ) {
        me.show(posAbs); // show tool-tip at this pos
      } else setDivPos(posAbs); // otherwise, update position
    }

    // get mouse position relative to canvas
    function getPos(e) {
      var r = canvas.getBoundingClientRect();
      return { x: e.clientX - r.left, y: e.clientY - r.top };
    }

    // update and adjust div position if needed (anchor to a different corner etc.)
    function setDivPos(pos) {
      if (visible) {
        if (pos.x < 0) pos.x = 0;
        if (pos.y < 0) pos.y = 0;
        // other bound checks here
        div.style.left = pos.x + 15 + 'px';
        div.style.top = pos.y + 'px';
      }
    }

    // we need to use shared event handlers:
    canvas.addEventListener('mousemove', check);
    canvas.addEventListener('click', check);
  }
}
