import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css'],
})
export class ForgotpasswordComponent implements OnInit {
  @ViewChild('submitButton') submitButton: ElementRef;

  public error = '';
  public success = false;
  public isLoading = false;
  public forgotPasswordForm: FormGroup;
  public login: FormControl;

  constructor(
    public route: Router,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    if (this.authenticationService.isLoggedIn()) {
      this.route.navigate(['']);
    }
    this.createFormControls();
  }

  loginFocusChange(e): void {
    e.preventDefault();
    this.submitButton.nativeElement.focus();
  }

  emailValidator(myControl): any {
    if (myControl.value.length < 3) {
      return { emailChk: true };
    }
    if (myControl.value.indexOf('@') === -1) {
      return { emailChk: true };
    }
    if (myControl.value.indexOf('.') === -1) {
      return { emailChk: true };
    }
    if (myControl.value.indexOf(' ') !== -1) {
      return { emailChk: true };
    }
  }

  createFormControls(): void {
    this.login = new FormControl('', [
      Validators.required,
      this.emailValidator,
      Validators.minLength(3),
      Validators.maxLength(200),
      Validators.pattern('[\\w\\-\\@\\.\\_\\:\\s\\/]+'),
    ]);
    this.forgotPasswordForm = new FormGroup({
      login: this.login,
    });
  }

  submitPasswordForgot(): void {
    this.error = null;
    this.isLoading = true;
    this.success = false;
    this.authenticationService
      .resetPasswordAPI(this.login.value)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (data) => {
          this.success = true;
          this.forgotPasswordForm.reset();
          this.login.reset();
        },
        ({ error }) => {
          if (error.message) {
            this.error = error.message;
          } else {
            this.error = 'There was some unknown error or server unreachable.';
          }
        },
        () => {}
      );
  }
}
