import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { file } from '../models/speachrecognition';

@Injectable({
  providedIn: 'root',
})
export class SpeechrecognitionManagerService {
  public files: file[] = [];

  // constructor(private http: HttpClient) {
  //   this.http.get('assets/audioFile.json').subscribe((c) => {
  //     this.files = c as file[];
  //   });
  // }
  // getFiles() {
  //   return of(this.files);
  // }
}
