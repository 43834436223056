import { Component, OnInit } from '@angular/core';
declare var $: any;
import * as RecordRTC from 'recordrtc';

import { DomSanitizer } from '@angular/platform-browser';
import { AudiorecorderService } from '../../services/audiorecorder.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-audiorecorder',
  templateUrl: './audiorecorder.component.html',
  styleUrls: ['./audiorecorder.component.css'],
})
export class AudiorecorderComponent implements OnInit {
  title = 'micRecorder';
  public transcription;
  //Lets declare Record OBJ
  record;
  //Will use this flag for toggeling recording
  recording = false;
  //URL of Blob
  url;
  error;
  constructor(
    private domSanitizer: DomSanitizer,
    private audioservice: AudiorecorderService,
    private spinner: NgxSpinnerService
  ) {}
  sanitize(url: string) {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }
  /**
   * Start recording.
   */
  initiateRecording() {
    this.url = undefined;
    this.recording = true;
    let mediaConstraints = {
      video: false,
      audio: true,
    };
    navigator.mediaDevices
      .getUserMedia(mediaConstraints)
      .then(this.successCallback.bind(this), this.errorCallback.bind(this));
  }
  /**
   * Will be called automatically.
   */
  successCallback(stream) {
    var options = {
      mimeType: 'audio/wav',
      numberOfAudioChannels: 1,
      sampleRate: 48000,
    };
    //Start Actuall Recording
    var StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
    this.record = new StereoAudioRecorder(stream, options);
    this.record.record();
  }
  /**
   * Stop recording.
   */
  stopRecording() {
    this.recording = false;

    this.record.stop(this.processRecording.bind(this));
  }
  /**
   * processRecording Do what ever you want with blob
   * @param  {any} blob Blog
   */
  processRecording(blob) {
    this.url = URL.createObjectURL(blob);
    // var file = new File(blob, "sample.wav");
    this.spinner.show();
    this.audioservice.uploadaudio(blob, 'sample.wav').subscribe(
      (c) => {
        this.transcription = c['transcription'];
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  uploadFile(event: File[]) {
    if (event[0]) {
      this.spinner.show();
      this.audioservice.uploadaudio(event[0], event[0].name).subscribe(
        (c) => {
          this.transcription = c['transcription'];
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
        }
      );
    }
  }

  /**
   * Process Error.
   */
  errorCallback(error) {
    this.error = 'Can not play audio in your browser';
  }
  ngOnInit() {}
}
