import { Injectable } from '@angular/core';
import { AggregatorsService } from '../services/aggregators.service';
import { searchResponse } from '../Response/responce';
import {
  search,
  comment,
  search_result_conversation,
  cluster,
  terms,
} from '../models/aggregator';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTableDataSource } from '@angular/material/table';
import { AggregatorManagerService } from './aggregator-manager.service';

@Injectable({
  providedIn: 'root',
})
export class SearchManagerService {
  public total_search_result: number;
  public dataSource: any;
  public searchs: search[] = [];
  public flag_only = -1;
  public conversed_only = -1;
  public offset: number = 0;
  public limit: number = 15;
  public source: number = -1;
  public boolean_operator = '';
  public publish_date_from = '';
  public publish_date_to = '';
  public search_date_from = '';
  public search_date_to = '';
  public source_names = [];
  public sentiments = [];
  public orderField = '';
  public orderType = '';

  public sources: number[] = [];

  public selectedSearch: search;
  public comments: comment[] = [];
  public clusters: cluster[];
  public TreeCluster: terms[] = [];
  public comment_conversation: search_result_conversation;
  constructor(
    private aggregatorsService: AggregatorsService,
    public spinner: NgxSpinnerService,
    public aggManager: AggregatorManagerService
  ) {}

  public getSearch(history_id?) {
    this.spinner.show();
    this.aggregatorsService
      .getAggregatorSearchResults(
        this.aggManager.selectedAggregator.id,
        this.source,
        this.flag_only,
        this.conversed_only,
        this.offset,
        this.limit,
        this.boolean_operator,
        this.publish_date_from,
        this.publish_date_to,
        this.search_date_from,
        this.search_date_to,
        this.sources,
        this.source_names,
        this.sentiments,
        this.orderField,
        this.orderType
      )
      .subscribe((ele) => {
        this.total_search_result = (
          ele as searchResponse
        ).total_aggregator_search_results;
        this.searchs = (ele as searchResponse).aggregator_search_results;
        this.dataSource = new MatTableDataSource<search>(this.searchs);
        // console.log(this.searchs);

        this.spinner.hide();
      });
  }

  public getClusters() {
    this.aggregatorsService
      .getAggregatorCluster(this.aggManager.selectedAggregator.id)
      .subscribe((ele) => {
        this.clusters = ele['clusters'];
        // this.dataSource = new MatTableDataSource<search>(this.searchs);

        this.TreeCluster = [];
        this.clusters.forEach((c) => {
          let term = new terms();
          term.name = c.cluster;
          //term.id = c.cluster_id;
          c.cluster_terms.forEach((e, index) => {
            if (index == 0) {
              term.children = [];
            }
            let termchild = new terms();
            termchild.name = e.term;
            termchild.id = e.term_id;
            term.children.push(termchild);
          });
          this.TreeCluster.push(term);
        });
      });
  }

  public export(cluster_terms_ids) {
    this.spinner.show();
    this.aggregatorsService
      .exportDetails(this.aggManager.selectedAggregator.id, cluster_terms_ids)
      .subscribe((c) => {
        this.spinner.hide();
        this.downLoadFile(c, 'application/zip');
      });
  }

  downLoadFile(data: any, type: string) {
    var blob = new Blob([data], { type: type.toString() });

    var a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = this.aggManager.selectedAggregator.name + '-searchresults';
    // start download
    a.click();

    /*var url = window.URL.createObjectURL(blob);
    var pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
        alert('Please disable your Pop-up blocker and try again.');
    }*/
  }

  public SetSearchResultFlagged(
    searchResultId: number,
    flagged: boolean,
    history_id?
  ) {
    this.aggregatorsService
      .setSearchResultFlagged(searchResultId, flagged)
      .subscribe((c) => {
        this.getSearch(history_id);
      });
  }

  public RemoveAggregatorSearchResult(searchResultId: number, history_id?) {
    this.aggregatorsService
      .removeAggregatorSearchResult(searchResultId)
      .subscribe((c) => {
        this.getSearch(history_id);
      });
  }

  public getcomments(search_id, offset, limit) {
    this.aggregatorsService
      .fetchSearchResultComments(search_id, offset, limit)
      .subscribe((c) => {
        this.comments = c['search_result_comments'];
      });
  }

  public getCommentConversation(search_id) {
    this.aggregatorsService
      .fetchSearchResultConverse(search_id)
      .subscribe((c) => {
        this.comment_conversation = c['search_result_converse'];
      });
  }

  public removeComment(comment_id) {
    this.aggregatorsService
      .removeSearchResultComment(comment_id)
      .subscribe((c) => {
        this.comments.forEach((e, index) => {
          if (e.id == comment_id) {
            this.comments.splice(index, 1);
          }
        });
      });
  }

  public addComment(search_id, comment: string) {
    this.aggregatorsService
      .addSearchResultComment(search_id, comment)
      .subscribe((c) => {
        this.getcomments(search_id, 0, 3);
      });
  }
}
