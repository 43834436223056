

  

      <div class="pb-5">
        <div class="login-card p-4 bg-white">
          <div class="text-left">
            <h2 class="text-style-h2 text-color-dark mb-1 mt-3">Forgot Password</h2>
            <p class="text-style-p1 text-color-dark-light">We get it, stuff happens. Just enter your email address below and we'll send you a link to reset your password.</p>
          </div>
          <form  [formGroup]="forgotPasswordForm" (submit)="submitPasswordForgot()">

            <br *ngIf="!success && !error">
            <div class="alert alert-success text-center" role="alert" *ngIf="success"><b>Reset link sent to email. Check your email to reset password.</b></div>
            <div class="alert alert-danger text-center" role="alert" *ngIf="error"><b>{{ error }}</b></div>
            <div class="input-group">
              <input type="text"
                     (keydown.Tab)="loginFocusChange($event)"
                     aria-label="Email Address" aria-describedby="email-addon1"
                     [ngClass]="{
                        'border-danger': !login.valid && login.dirty,
                        'border-success': login.valid && login.dirty
                       }" placeholder="Email"  formControlName="login" class="form-control">
              <div *ngIf="login.value" class="input-group-append">
                <button type="button" (click)="login.reset()" class="btn btn-outline-secondary"><img class="input-icon" src="../../../assets/icons/close.png" alt="close"></button>
              </div>
            </div>

            <br>

            <div class="text-center mt-2 mb-4" *ngIf="isLoading">
              <app-loading-component  [widthSize]="'40px'"></app-loading-component>
            </div>

            <input #submitButton *ngIf="!isLoading" type="submit" [disabled]="forgotPasswordForm.invalid" value="Reset Password" class="btn btn-primary btn-block mt-2 mb-4">

          </form>
          <hr>
          <div class="text-left">
            <a class="btn btn-link text-style-p2" routerLink="/login">Back to Login</a>
          </div>
        </div>
      </div>

   

 
