import { Injectable } from '@angular/core';
import { VisualizationManagerService } from './visualization-manager.service';

@Injectable({
  providedIn: 'root',
})
export class ResetManagerService {
  constructor(public visualManager: VisualizationManagerService) {}

  resetSelectedAggregator() {
    this.visualManager.reset(true);
  }
}
