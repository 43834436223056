import { Injectable, EventEmitter } from '@angular/core';
import { VisualizationService } from '../services/visualization.service';
import { forkJoin } from 'rxjs';
import * as Fuse from 'fuse.js';
import { NgxSpinnerService } from 'ngx-spinner';
import { updateFlaggedItemsImpure } from '../services/utils';
import {
  classResult,
  cv_Pipeline,
  entity,
  general_Image,
} from '../models/dashboard';
import { Word } from '../aggregators/Visualization/cv-pipeline/cv-pipeline.component';
import { getWeekYearWithOptions } from 'date-fns/fp';
declare const $: any;

@Injectable({ providedIn: 'root' })
export class VisualizationManagerService {
  isLoading = false;
  error: any;
  errorMessage: any;
  aggregatorId: number;
  visualizationData: any;
  visualizationSearchData: any = null;
  visualizationSearchFailed: any = null;
  visualizationInfo: any;
  visualizeType: string;
  sentimentsData: any = null;
  documentText: string;
  flagDocuments = {};
  searchByVoice = false;
  searchKey: string = null;
  searchText = '';
  searchEnabled = false;
  public loadSummary = false;
  public showDocumentTextEvent = new EventEmitter<any>();
  public visualizingInit = new EventEmitter<any>();

  public loadImageonscroll = new EventEmitter<any>();

  constructor(
    private visualizationService: VisualizationService,
    private spinner: NgxSpinnerService
  ) {
    this.visualizationService.getIgnoreListFile().subscribe((c) => {
      this.ignoreList = c.toString().split(/[\r\n]+/);
    });
  }

  reset(resetSentiments): void {
    this.spinner.hide();
    this.isLoading = false;
    this.error = null;
    this.errorMessage = null;
    this.aggregatorId = null;
    this.visualizationData = null;
    this.visualizationSearchData = null;
    this.visualizationSearchFailed = null;
    this.visualizationInfo = null;
    this.visualizeType = null;
    this.documentText = null;
    this.flagDocuments = {};
    if (resetSentiments) {
      this.sentimentsData = null;
    }
  }

  showDocumentText({ id, text }): void {
    this.documentText = text;
    $('#document-text-modal').modal('show');
    this.visualizationService
      .fetchAggregatorDocumentText(id)
      .subscribe((data) => (this.documentText = data.search_result_text));
  }

  changeFlagStatus({ id, status }): void {
    this.visualizationService.setDocumentFlagged(id, status).subscribe();
    this.flagDocuments[id] = { value: status };
  }

  updateLocalDateRepo(): void {
    updateFlaggedItemsImpure(this.flagDocuments, this.visualizationData);
    updateFlaggedItemsImpure(this.flagDocuments, this.visualizationSearchData);
    this.flagDocuments = {};
  }

  searchKeyword(keyword: string): void {
    if (keyword.trim() === '') {
      return;
    }
    const options = {
      shouldSort: true,
      tokenize: true,
      matchAllTokens: false,
      threshold: 0,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 5,
      keys: ['text'],
    };

    const filteredClusters = this.visualizationData.clusters.reduce(
      (clusters, cluster) => {
        const filteredTerms = cluster.terms.reduce((terms, term) => {
          const fuse = new Fuse(term.documents, options);
          const filteredDocuments = fuse.search(keyword);
          if (filteredDocuments.length > 0) {
            return [
              ...terms,
              {
                ...term,
                docCount: filteredDocuments.length,
                documents: filteredDocuments,
              },
            ];
          } else {
            return terms;
          }
        }, []);
        if (filteredTerms.length > 0) {
          return [
            ...clusters,
            {
              ...cluster,
              termCount: filteredTerms.length,
              terms: filteredTerms,
            },
          ];
        } else {
          return clusters;
        }
      },
      []
    );
    if (filteredClusters.length > 0) {
      this.visualizationSearchData = {
        ...this.visualizationData,
        clusters: filteredClusters,
        clusterCount: filteredClusters.length,
      };
    } else {
      this.visualizationSearchFailed = keyword;
    }
    this.visualizingInit.emit({ id: this.aggregatorId });
  }
  clearSearch(): void {
    this.searchKey = null;
    this.searchText = '';
    this.visualizationSearchData = null;
    this.visualizationSearchFailed = null;
    this.visualizingInit.emit({ id: this.aggregatorId });
  }

  async assertVisualizationData(aggregatorID: number): Promise<any> {
    if (
      this.aggregatorId !== aggregatorID ||
      !this.visualizationInfo ||
      !this.visualizationData
    ) {
      // this.reset(false);
      // this.spinner.show();
      this.isLoading = true;
      this.aggregatorId = aggregatorID;
      try {
        await this.loadAggregatorVisualizationInfo();
      } catch (e) {
        this.error = e;
      }
      this.spinner.hide();
      this.isLoading = false;
      // await waitInMS(100);
      this.visualizingInit.emit({
        id: this.aggregatorId,
      });
    }
  }

  async assertSentimentsData(aggregatorID: number): Promise<any> {
    if (this.aggregatorId !== aggregatorID || !this.sentimentsData) {
      this.isLoading = true;
      this.loadSummary = true;
      this.aggregatorId = aggregatorID;
      try {
        await this.loadSentiments();
      } catch (e) {
        this.error = e;
      }
      this.isLoading = false;
      this.loadSummary = false;
      //this.spinner.hide();
      // await waitInMS(100);
      this.visualizingInit.emit({
        id: this.aggregatorId,
      });
    }
  }

  async loadAggregatorVisualizationInfo(): Promise<any> {
    const data: any = await forkJoin([
      this.visualizationService.fetchAggregatorVisualizationInfo(
        this.aggregatorId
      ),
      this.visualizationService.fetchAggregatorVisualizationData(
        this.aggregatorId
      ),
    ]).toPromise();
    this.visualizationInfo = data[0];
    this.visualizationData = data[1];
  }

  async loadSentiments(): Promise<any> {
    //glith
    this.sentimentsData = null;
    const data: any = await forkJoin(
      this.visualizationService.fetchAggregatorSentimentScore(this.aggregatorId)
    ).toPromise();
    const sentimentType = data[0].sentiment_type_record_counts;
    const sentimentCount = data[1].sentiment_value_record_counts;
    const sentimentAverage = data[2].execution_average_scores.reverse();
    const sentimentScores = data[3].execution_average_smoothed_scores.reverse();
    const sourceHistory = data[4].aggregator_statistics.sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    );
    const sentimentHistory = data[5].aggregator_sentiment_history.sort(
      (a, b) => new Date(a.time).getTime() - new Date(b.time).getTime()
    );

    let sources = [];
    let mostPositiveSource = null;
    let mostNegativeSource = null;
    let totalExecutedResults = null;
    let lastExecutedResults = null;
    let topThreeSources = null;

    if (sentimentHistory.length) {
      const sourcesMap = {};
      const assertSourceInMap = ({ source, source_id }) => {
        if (!sourcesMap[source_id]) {
          sourcesMap[source_id] = {
            id: source_id,
            source,
            sentimentAverage: 0,
            analysed: 0,
            sourceHistory: [],
            sentimentHistory: [],
          };
        }
      };

      sentimentHistory[0].sentiment_scores.reduce(
        (sourceMap, { source_id, source }) => {
          return {
            ...sourceMap,
            [source_id]: {
              id: source_id,
              source,
              sentimentAverage: 0,
              analysed: 0,
              sourceHistory: [],
              sentimentHistory: [],
            },
          };
        },
        {}
      );

      for (const sentimentHistoryItem of sentimentHistory) {
        for (const sourceSentiment of sentimentHistoryItem.sentiment_scores) {
          assertSourceInMap(sourceSentiment);
          sourcesMap[sourceSentiment.source_id].sentimentAverage +=
            sourceSentiment.average_score;
          sourcesMap[sourceSentiment.source_id].sentimentHistory.push({
            time: new Date(sentimentHistoryItem.date).getTime(),
            average: sourceSentiment.average_score,
          });
        }
      }

      let analysedSum = 0;
      for (const sourceHistoryItem of sourceHistory) {
        for (const sourceHistoryInner of sourceHistoryItem.sources_counts) {
          let sourceChange = 0;
          let accumulatedChange = 0;
          if (sourcesMap[sourceHistoryInner.source_id].sourceHistory.length) {
            sourceChange =
              sourceHistoryInner.source_count -
              sourcesMap[sourceHistoryInner.source_id].sourceHistory[
                sourcesMap[sourceHistoryInner.source_id].sourceHistory.length -
                  1
              ].sourceCount;
            accumulatedChange =
              sourceHistoryInner.source_count +
              sourcesMap[sourceHistoryInner.source_id].sourceHistory[
                sourcesMap[sourceHistoryInner.source_id].sourceHistory.length -
                  1
              ].accumulatedChange;
          }
          sourcesMap[sourceHistoryInner.source_id].sourceHistory.push({
            executionTime: new Date(sourceHistoryItem.date).getTime(),
            sourceCount: sourceHistoryInner.source_count,
            sourceChange,
            accumulatedChange,
          });
          sourcesMap[sourceHistoryInner.source_id].analysed +=
            sourceHistoryInner.source_count;
          analysedSum += sourceHistoryInner.source_count;
        }
      }

      totalExecutedResults = analysedSum;

      lastExecutedResults = 0;
      for (const id of Object.keys(sourcesMap)) {
        sourcesMap[id].sentimentAverage =
          sourcesMap[id].sentimentAverage / sentimentHistory.length;
        sourcesMap[id].lastExecuted =
          sourcesMap[id].sourceHistory[
            sourcesMap[id].sourceHistory.length - 1
          ].sourceCount;
        lastExecutedResults +=
          sourcesMap[id].sourceHistory[sourcesMap[id].sourceHistory.length - 1]
            .sourceCount;
      }

      sources = Object.values(sourcesMap).sort(
        (a: any, b: any) => a.analysed - b.analysed
      );

      const averageSorted = Object.values(sourcesMap).sort(
        (a: any, b: any) => a.sentimentAverage - b.sentimentAverage
      );

      const { positive, negative } = sources.reduce(
        (obj, source) => {
          const newPositive =
            source.sentimentAverage > obj.positive.sentimentAverage
              ? source
              : obj.positive;
          const newNegative =
            source.sentimentAverage < obj.positive.sentimentAverage
              ? source
              : obj.negative;
          return {
            positive: newPositive,
            negative: newNegative,
          };
        },
        { positive: sources[0], negative: sources[0] }
      );

      mostPositiveSource = averageSorted[averageSorted.length - 1];
      mostNegativeSource = averageSorted[0];

      sources = sources.map((source) => ({
        ...source,
        analysedPercentage: (source.analysed / analysedSum) * 100,
      }));
      const allSourcesSorted = [...sources].reverse();
      topThreeSources = allSourcesSorted.slice(0, 3);
    }

    this.sentimentsData = [
      [
        ['Positive', sentimentType.neutral_records_count],
        ['Negative', sentimentType.negative_records_count],
        ['Neutral', sentimentType.positive_records_count],
      ],
      [
        ['-9', sentimentCount.negative_9_records_count],
        ['-8', sentimentCount.negative_8_records_count],
        ['-7', sentimentCount.negative_7_records_count],
        ['-6', sentimentCount.negative_6_records_count],
        ['-5', sentimentCount.negative_5_records_count],
        ['-4', sentimentCount.negative_4_records_count],
        ['-3', sentimentCount.negative_3_records_count],
        ['-2', sentimentCount.negative_2_records_count],
        ['-1', sentimentCount.negative_1_records_count],
        ['0', sentimentCount.neutral_0_records_count],
        ['1', sentimentCount.positive_1_records_count],
        ['2', sentimentCount.positive_2_records_count],
        ['3', sentimentCount.positive_3_records_count],
        ['4', sentimentCount.positive_4_records_count],
        ['5', sentimentCount.positive_5_records_count],
        ['6', sentimentCount.positive_6_records_count],
        ['7', sentimentCount.positive_7_records_count],
        ['8', sentimentCount.positive_8_records_count],
        ['9', sentimentCount.positive_9_records_count],
      ],
      {
        columns: [
          'Average Score',
          ...sentimentAverage.map((i) => i.sentiment_score),
        ],
        categories: sentimentAverage.map(
          (i) => i.execution_date + ' ' + i.execution_time
        ),
      },
      {
        columns: [
          'Smoothened Score',
          ...sentimentScores.map((i) => i.sentiment_score),
        ],
        categories: sentimentScores.map(
          (i) => i.execution_date + ' ' + i.execution_time
        ),
      },
      {
        sources,
        topThreeSources,
        mostPositiveSource,
        mostNegativeSource,
        totalExecutedResults,
        lastExecutedResults,
      },
    ];
  }

  public classresult: classResult;
  getcvPipeline() {
    /*   this.visualizationService.GetTestCVPipeLine().subscribe(c => {


     var s = c[0] as cv_Pipeline;

    // this.classresult = s.general_Image_analysis[0]


 this.classresult.sort((a, b) => {
       if (a.count > b.count) return -1;
       if (b.count > a.count) return 1;

       return 0;
     });
   });*/
  }

  public entity: entity;
  public object_detection_entities: entity[] = [];
  public classification_entities: entity[] = [];

  public onTaxonomyLoaded: EventEmitter<any> = new EventEmitter();
  getTaxonomy(aggregatorId) {
    this.spinner.show();
    this.visualizationService.GetTestTextonomy(aggregatorId).subscribe((c) => {
      this.object_detection_entities = [];
      this.classification_entities = [];

      if (c['taxonomy'][0])
        this.object_detection_entities = c['taxonomy'][0]['object_detection'];

      if (c['taxonomy'][1])
        this.classification_entities = c['taxonomy'][1]['classification'];

      this.onTaxonomyLoaded.emit();
      setTimeout(() => {
        this.spinner.hide();
      }, 2000);
    });
  }

  public generalImages: general_Image[] = [];
  getGeneralImages(imageid: number) {
    this.visualizationService.GetGeneralImages().subscribe((c) => {
      this.generalImages = c as general_Image[];
    });
  }

  public imageAnalytics: general_Image[] = [];
  public wholetext: string = '';
  public wordfilters: Word[] = [];

  public onImagenAnylticLoaded: EventEmitter<any> = new EventEmitter();

  ignoreList: string[] = [
    'the',
    'or',
    'you',
    'are',
    'is',
    'I',
    'am',
    'they',
    'There',
    'no',
    'and',
    'of',
    'to',
    'so',
    'a',
    '-',
    '--',
    'but',
    'as',
    'it',
  ];

  getImageAnalytics(
    agreggato_id,
    faceDetection: boolean,
    textAnalysis: boolean,
    gender?: string,
    min_age?: number,
    max_age?: number,
    emotion?: string
  ) {
    this.visualizationService
      .getImageAnalytics(
        agreggato_id,
        faceDetection,
        textAnalysis,
        gender,
        min_age,
        max_age,
        emotion
      )
      .subscribe((c) => {
        this.imageAnalytics = c['images_analytics']['text_recognition'];

        this.imageAnalytics.sort(
          (a, b) =>
            Math.max.apply(
              Math,
              b.text_recognition.map(function (o) {
                return o.score;
              })
            ) -
            Math.max.apply(
              Math,
              a.text_recognition.map(function (o) {
                return o.score;
              })
            )
        );

        this.wholetext = '';
        this.imageAnalytics.forEach((c) => {
          if (c.text_recognition)
            c.text_recognition.forEach((e) => {
              this.wholetext += ' ' + e.text;
            });
        });

        this.wordfilters = [];
        let word = this.wholetext.split(' ');

        for (let i = 0; i < word.length; i++) {
          let count = 0; // reset the counter for each word

          for (let j = 0; j < word.length; j++) {
            if (word[i].toLowerCase() === word[j].toLowerCase()) {
              /* if the words are the same, but j < i, it was already calculated
               and printed earlier, so we can stop checking the current word
               and move on to another one */
              if (j < i) {
                break; // exit the inner loop, continue with the outer one
              }

              count++;
            }
          }

          if (count > 1) {
            if (word[i] != '') {
              if (
                !this.ignoreList.includes(word[i].toLowerCase()) &&
                word[i].length > 3
              ) {
                let w = new Word();
                w.freqeuncy = count;
                w.word = word[i];
                this.wordfilters.push(w);
              }
            }
          }
        }

        this.wordfilters = this.wordfilters.sort((a, b) => {
          if (a.freqeuncy > b.freqeuncy) return -1;
          if (b.freqeuncy > a.freqeuncy) return 1;

          return 0;
        });

        this.onImagenAnylticLoaded.emit();
      });
  }

  public personImageAnalytics: general_Image[] = [];
  getPersonImageAnalytics(
    agreggato_id,
    gender?: string,
    min_age?: number,
    max_age?: number,
    emotion?: string
  ) {
    this.visualizationService
      .getImageAnalytics(
        agreggato_id,
        true,
        false,
        gender,
        min_age,
        max_age,
        emotion,
        false,
        false
      )
      .subscribe((c) => {
        this.personImageAnalytics = c['images_analytics']['person_analysis'];

        this.personImageAnalytics.sort(
          (a, b) =>
            Math.max.apply(
              Math,
              b.face_detection.map(function (o) {
                return o.score;
              })
            ) -
            Math.max.apply(
              Math,
              a.face_detection.map(function (o) {
                return o.score;
              })
            )
        );

        /* setTimeout(() => {
       this.loadImageonscroll.emit();
     }, 2000); */
      });
  }

  getGeneralImageAnalytics(
    agreggato_id,
    object_detection?: boolean,
    classification?: boolean,
    class_id?: number
  ) {
    this.visualizationService
      .getImageAnalytics(
        agreggato_id,
        false,
        false,
        null,
        null,
        null,
        null,
        object_detection,
        classification,
        class_id
      )
      .subscribe((c) => {
        if (object_detection) {
          this.generalImages = c['images_analytics']['object_detection'];

          this.generalImages.sort(
            (a, b) =>
              Math.max.apply(
                Math,
                b.object_detection?.map(function (o) {
                  return o.score;
                })
              ) -
              Math.max.apply(
                Math,
                a.object_detection?.map(function (o) {
                  return o.score;
                })
              )
          );
        } else if (classification) {
          this.generalImages = c['images_analytics']['classification'];
        }
      });
  }
}
