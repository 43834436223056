import * as d3js from 'd3';

let requestID = 1;

export const getRequestId = (): number => {
  return requestID++;
};

export const waitInMS = (timeInMS) => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(true), timeInMS);
  });
};

/* tslint:disable */
export const addContextMenu = () => {
  const d3: any = d3js;

  d3.contextMenu = function(menu, openCallback) {

    // create the div element that will hold the context menu
    d3.selectAll('.d3-context-menu').data([1])
      .enter()
      .append('div')
      .attr('class', 'd3-context-menu');

    // close menu
    d3.select('body').on('click.d3-context-menu', function() {
      d3.select('.d3-context-menu').style('display', 'none');
    });

    // this gets executed when a contextmenu event occurs
    return function(data, index) {
      const elm = this;

      d3.selectAll('.d3-context-menu').html('');
      const list = d3.selectAll('.d3-context-menu').append('ul');
      list.selectAll('li').data(menu).enter()
        .append('li')
        .html(function(d: any) {
          return (typeof d.title === 'string') ? d.title : d.title(data);
        })
        .on('click', function(d: any, i) {
          d.action(elm, data, index);
          d3.select('.d3-context-menu').style('display', 'none');
        });

      // the openCallback allows an action to fire before the menu is displayed
      // an example usage would be closing a tooltip
      if (openCallback) {
        if (openCallback(data, index) === false) {
          return;
        }
      }

      // display context menu
      d3.select('.d3-context-menu')
        .style('left', (d3.event.pageX - 2) + 'px')
        .style('top', (d3.event.pageY - 2) + 'px')
        .style('display', 'block');

      d3.event.preventDefault();
      d3.event.stopPropagation();
    };
  };
  return d3;
};

export const updateFlaggedItemsImpure = (flaggedDocuments, data) => {
  if (!data || Object.keys(flaggedDocuments).length === 0)
    return;
  for (let cluster of data.clusters) {
    for (let term of cluster.terms) {
      for (let document of term.documents) {
        if (flaggedDocuments[document.id]) {
          document.flagged = flaggedDocuments[document.id].value
        }
      }
    }
  }
};

export const d3Modify = (data: any, clusterName: string) => ({
  name: clusterName,
  dataLevel: 0,
  children: data.clusters.map(i => ({
    name: i.name,
    dataLevel: 1,
    children: i.terms.map(j => ({
      name: j.name,
      dataLevel: 2,
      children: j.documents.map(k => ({
        dataLevel: 3,
        name: k.text,
        documentId: k.id,
        flagged: k.flagged,
        value: 1
      }))
    }))
  }))
});

export const d3ModifyBubble = (data: any, clusterName: string) => {
  let children = [];
  let clusters = [];
  let totalDocuments = 0;
  for (let cluster of data.clusters) {
    let clusterDensity = 0;
    for (let term of cluster.terms) {
      totalDocuments += term.documents.length;
      clusterDensity += term.documents.length;
      children.push({
        name: term.name,
        value: term.documents.length,
        group: cluster.name,
        title: `Term: \t\t ${ term.name } \n Cluster: \t\t ${ cluster.name } \n Aggregator: \t\t ${ clusterName } \n Documents Count: \t\t ${ term.documents.length }`,
        documents: term.documents
      });
    }
    clusters.push({
      group: cluster.name,
      clusterDensity
    });
  }
  return {
    children,
    totalDocuments,
    clusters
  };
};

export const staticTrue: object = { static: true };

