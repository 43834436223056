// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//
export const environment = {
  production: false,
  apiURL: '/InfosiphonWS/',
  apiURLTemp: '',
  speechURL: '',
  courpus_API: 'https://app.infosiphon.com/knowledge-graph/corpus_detail/', // production
  // courpus_API: '/knowledge-graph/corpus_detail/', // dev
  demoMode: false,
  autoLogin: false,
  devMode: false, // this mode is used for dev.infosiphon.com not for development environment
  speechAnalytics: 'https://speech.infosiphon.com/speech_analytics/',
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
