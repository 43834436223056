import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  Input,
} from '@angular/core';
import { VisualizationManagerService } from '../../../Managers/visualization-manager.service';
import { addContextMenu, d3Modify, waitInMS } from '../../../services/utils';
import { DocumentSourceService } from '../../../services/document-source.service';
import { AggregatorManagerService } from '../../../Managers/aggregator-manager.service';
import { DemoService } from '../../../services/demo.service';
import { viz_type } from '../../../models/dashboard';
import { DashboardManagerService } from '../../../Managers/dashboard-manager.service';

@Component({
  selector: 'app-round-burst-chart',
  templateUrl: './round-burst-chart.component.html',
  styleUrls: ['./round-burst-chart.component.css'],
})
export class RoundBurstChartComponent implements OnInit, OnDestroy {
  @ViewChild('roundBurstChart')
  public chartBurstContainer: ElementRef;
  public subscriptionEventListen = null;
  _serviceSubscription: any;
  @Input() height: number = null;
  constructor(
    public visualizationManagerService: VisualizationManagerService,
    public dashboardManager: DashboardManagerService,
    public aggregatorManagerService: AggregatorManagerService,
    public documentSourceService: DocumentSourceService,
    public demoService: DemoService
  ) {
    this._serviceSubscription = this.dashboardManager.onExport.subscribe(
      (c) => {
        this.export();
      }
    );
  }

  ngOnInit(): void {
    this.subscriptionEventListen =
      this.visualizationManagerService.visualizingInit.subscribe(() =>
        this.initializeChartBasics()
      );
    this.initializeChartBasics().then();

    this.dashboardManager.viz_types.forEach((c) => {
      if (c.viz_uid == this.viz_uid) {
        this.viz_type = c;
      }
    });
  }

  async initializeChartBasics(): Promise<any> {
    this.cleanChart();
    await waitInMS(30);
    this.startMakingRoundChart();
  }

  startMakingRoundChart(): void {
    if (
      !this.visualizationManagerService.visualizationInfo ||
      !this.visualizationManagerService.visualizationData
    ) {
      return;
    }
    if (this.visualizationManagerService.visualizationSearchFailed) {
      return;
    }

    const d3: any = addContextMenu();

    const roundBurstChart = this;
    const updateNodeStyle = (d, i, nodeMap) => {
      if (d.data.flagged) {
        d.data.oldColor = d3.select(nodeMap[i]).style('fill');
        d3.select(nodeMap[i]).style('fill', '#ffe00b');
      } else {
        if (d.data.oldColor) {
          d3.select(nodeMap[i]).style('fill', d.data.oldColor);
        }
      }
    };
    const menu = (nodeD: any, i: any, nodeMap: any) => [
      nodeD.data.flagged
        ? {
            title: 'Unflag',
            action: () => {
              nodeD.data.flagged = false;
              const { documentId } = nodeD.data;
              roundBurstChart.visualizationManagerService.changeFlagStatus({
                id: documentId,
                status: false,
              });
              updateNodeStyle(nodeD, i, nodeMap);
            },
          }
        : {
            title: 'Flag',
            action: () => {
              nodeD.data.flagged = true;
              const { documentId } = nodeD.data;
              roundBurstChart.visualizationManagerService.changeFlagStatus({
                id: documentId,
                status: true,
              });
              updateNodeStyle(nodeD, i, nodeMap);
            },
          },
      {
        title: 'Source',
        action: () => {
          const { documentId } = nodeD.data;
          roundBurstChart.documentSourceService.getAndOpenSource(
            { id: documentId },
            roundBurstChart.aggregatorManagerService.selectedAggregator.id
          );
        },
      },
    ];
    const onContext = (d, i, allNodes) => {
      d3.event.preventDefault();
      if (!d.data.children) {
        if (this.demoService.demoMode) {
          const { documentId } = d.data;
          roundBurstChart.documentSourceService.getAndOpenSource(
            { id: documentId },
            roundBurstChart.aggregatorManagerService.selectedAggregator.id
          );
        } else {
          d3.contextMenu(menu(d, i, allNodes))(d, i, allNodes);
        }
      }
    };

    const clonedData = JSON.parse(
      JSON.stringify(
        roundBurstChart.visualizationManagerService.visualizationSearchData ||
          roundBurstChart.visualizationManagerService.visualizationData
      )
    );
    const aggregatorName =
      roundBurstChart.visualizationManagerService.visualizationInfo
        .aggregator_visualization_info.name;

    const data = d3Modify(clonedData, aggregatorName);
    const sunburstChartElement =
      roundBurstChart.chartBurstContainer.nativeElement;
    sunburstChartElement.innerHTML = '';

    const width = sunburstChartElement.offsetWidth;
    const height = sunburstChartElement.offsetHeight;

    const radius = Math.min(width, height) / 6;

    const partition = (inData) => {
      const rootNode = d3
        .hierarchy(inData)
        .sum((d) => d.value)
        .sort((a, b) => b.value - a.value);
      return d3.partition().size([2 * Math.PI, rootNode.height + 1])(rootNode);
    };

    // let color = d3.scaleLinear()
    //   .domain([-1, 5])
    //   .range(['hsl(244.8,62%,19.6%)','hsl(241.1,74.4%,55.5%)'])
    //   .interpolate(d3.interpolateHcl);

    const color = d3
      .scaleOrdinal(data.children.map((d) => d.group))
      .range([
        '#082CAF',
        '#354FB0',
        '#1ED6BB',
        '#6214f4',
        '#d61e57',
        '#fabf1b',
        '#1464f4',
        '#0093d5',
        '#949bff',
        '#ab6dff',
        '#ff0e72',
        '#0b80b0',
        '#00ac59',
        '#488f31',
        '#00ac59',
        '#83af70',
        '#c4a0ec',
        '#98af8f',
        '#778271',
        '#8e8e8e',
        '#9f8888',
        '#f0b8b8',
        '#ec9c9d',
        '#e67f83',
        '#de6069',
        '#de425b',
        '#a47b80',
      ]);

    const format = d3.format(',d');

    const arc = d3
      .arc()
      .startAngle((d) => d.x0)
      .endAngle((d) => d.x1)
      .padAngle((d) => Math.min((d.x1 - d.x0) / 2, 0.005))
      .padRadius(radius)
      .innerRadius((d) => d.y0 * radius)
      .outerRadius((d) => Math.max(d.y0 * radius, d.y1 * radius - 1));

    const root = partition(data);

    const tooltip = d3
      .select(sunburstChartElement)
      .append('div')
      .attr('class', 'tooltip')
      .style('opacity', 0);
    const mouseOverMove = (d) => {
      if (d.data.name != null && d.data.name !== '') {
        tooltip.transition().duration(200).style('opacity', 0.9);
        tooltip
          .html(d.data.name)
          .style('left', d3.event.pageX + 'px')
          .style('top', d3.event.pageY - 28 + 'px');
      }
    };
    const mouseOut = (d) => {
      if (d.parent && d.parent.data.name) {
        tooltip
          .html(d.parent.data.name)
          .style('left', d3.event.pageX + 'px')
          .style('top', d3.event.pageY - 28 + 'px');
      } else {
        tooltip.transition().duration(500).style('opacity', 0);
      }
    };

    root.each((d) => (d.current = d));

    const svg: any = d3
      .select(sunburstChartElement)
      .append('svg')
      .attr('viewBox', [0, 0, width, height])
      .style('font', this.height ? '7px sans-serif' : '12px sans-serif');

    const g = svg
      .append('g')
      .attr('transform', `translate(${width / 2},${height / 2})`);

    const path = g
      .append('g')
      .selectAll('path')
      .data(root.descendants().slice(1))
      .join('path')
      .attr('fill', (d) => {
        let grandParent = d;
        while (grandParent.depth > 1) {
          grandParent = grandParent.parent;
        }
        d.data.oldColor = color(grandParent.data.name);
        if (d.data.flagged) {
          return '#ffe00b';
        }
        return d.data.oldColor;
      })
      .attr('fill-opacity', (d) =>
        arcVisible(d.current) ? (d.children ? 0.6 : 0.5) : 0
      )
      .attr('d', (d) => arc(d.current))
      .on('mouseover', mouseOverMove)
      .on('mouseout', mouseOut);
    path
      .filter((d) => d.depth)
      .style('cursor', 'pointer')
      .on('click', clicked)
      .on('contextmenu', onContext);

    /*path.append('title')
        .text(d => `${d.ancestors().map(inD => inD.data.name).reverse().join('\t\n')}\n${format(d.value)}`); */

    const label = g
      .append('g')
      .attr('pointer-events', 'none')
      .attr('text-anchor', 'middle')
      .style('user-select', 'none')
      .selectAll('text')
      .data(root.descendants().slice(1))
      .join('text')
      .attr('dy', '0.35em')
      .attr('fill-opacity', (d) => +labelVisible(d.current))
      .attr('transform', (d) => labelTransform(d.current))
      .text((d) => (d.data.name.length > 30 ? '' : d.data.name));

    const parent = g
      .append('circle')
      .datum(root)
      .attr('r', radius)
      .attr('fill', 'none')
      .attr('pointer-events', 'all')
      .on('click', clicked);

    function clicked(p): void {
      if (!p.data.children) {
        const { documentId, name } = p.data;
        roundBurstChart.visualizationManagerService.showDocumentText({
          id: documentId,
          text: name,
        });
        return;
      }
      // tslint:disable-next-line:curly
      if (p.depth > 2) return;
      parent.datum(p.parent || root);

      root.each(
        (d) =>
          (d.target = {
            x0:
              Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) *
              2 *
              Math.PI,
            x1:
              Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) *
              2 *
              Math.PI,
            y0: Math.max(0, d.y0 - p.depth),
            y1: Math.max(0, d.y1 - p.depth),
          })
      );

      const t = g.transition().duration(750);

      // Transition the data on all arcs, even the ones that aren’t visible,
      // so that if this transition is interrupted, entering arcs will start
      // the next transition from the desired position.
      path
        .transition(t)
        .tween('data', (d) => {
          const i = d3.interpolate(d.current, d.target);
          return (inT) => (d.current = i(inT));
        })
        .filter(function (d: any): any {
          return +this.getAttribute('fill-opacity') || arcVisible(d.target);
        })
        .attr('fill-opacity', (d) =>
          arcVisible(d.target) ? (d.children ? 0.6 : 0.4) : 0
        )
        .attrTween('d', (d) => () => arc(d.current));

      label
        .filter(function (d: any): any {
          return +this.getAttribute('fill-opacity') || labelVisible(d.target);
        })
        .transition(t)
        .attr('fill-opacity', (d) => +labelVisible(d.target))
        .attrTween('transform', (d) => () => labelTransform(d.current));
    }

    function arcVisible(d): any {
      return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
    }

    function labelVisible(d): any {
      return d.y1 <= 3 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03;
    }

    function labelTransform(d): any {
      const x = (((d.x0 + d.x1) / 2) * 180) / Math.PI;
      const y = ((d.y0 + d.y1) / 2) * radius;
      return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
    }
  }

  cleanChart(): void {
    if (this.chartBurstContainer) {
      this.chartBurstContainer.nativeElement.innerHTML = '';
    }
  }

  ngOnDestroy(): void {
    if (this._serviceSubscription) this._serviceSubscription.unsubscribe();

    this.dashboardManager.viz_types.forEach((c) => {
      if (c.viz_uid == this.viz_uid) {
        this.viz_type = c;
      }
    });
    this.subscriptionEventListen.unsubscribe();
    this.cleanChart();
  }

  viz_uid = '000000000000000000000002';

  public viz_type: viz_type;
  Add() {
    this.dashboardManager.viz_types.forEach((c) => {
      if (c.viz_uid == this.viz_uid) {
        this.viz_type = c;
      }
    });
    this.viz_type.isEnabled = true;
    this.dashboardManager.AddDashboardItem(
      this.aggregatorManagerService.selectedAggregator.id,
      this.viz_type.viz_uid,
      1
    );
  }

  export() {
    const element = document.querySelector('div#roundBurstChart svg');
    if (this.height) {
      this.dashboardManager.dashboard_items.forEach((c) => {
        if (c.viz_uid == this.viz_uid) {
          c.viz_type = this.viz_type.viz_type;
          c.viz_svg =
            '<svg xmlns="http://www.w3.org/2000/svg" width="600" height="600" viewBox="0 0 600 600" preserveAspectRatio="xMinYMin" style="display: block; margin: 0vh; background: rgb(255, 255, 255); cursor: pointer;" > <g transform="translate(50,50)">' +
            element.innerHTML +
            '</g></svg>';
        }
      });
    }
  }
}
