<div class="chart-card pl-4 pt-4 pr-2 pb-3">


  <h4 class="text-style-p1 text-color-dark font-weight-bold m-2"> Packed Layout
  </h4>
  <button class="btn2" *ngIf="!height" (click)="Add(this.viz_type?.viz_uid)" matTooltip="Add to Dashboard"
    [disabled]="this.viz_type?.isEnabled || this.visualizationManagerService.visualizationData != null && this.visualizationManagerService.visualizationData.length == 0"><i
      class="fa fa-plus"></i></button>
  <div #packContainer id="packContainer" class="w-100 chart-visualization-full-size"
    [style.height]="height ?  height+'px' :  null"></div>
  <div
    *ngIf="this.visualizationManagerService.visualizationData != null && this.visualizationManagerService.visualizationData.length == 0"
    class="empty-data">
    <i class="fa fa-solid fa-box-open"></i>
    <strong>Not available!</strong>
  </div>
</div>