export class dashboardItem
{
    aggregator_id: number;
    created_time: number;
    viz_cat_id: number;
    dashboard_item_id: number;
    viz_uid: string;
    viz_type: string;
    viz_svg:string;
    isEnabled:boolean;
}

export class viz_type
{
    viz_type: string;
    viz_uid: string;
    isEnabled: boolean;
}

export class viz_category
{
    viz_category: string;
    viz_cat_id: number;
}


export class visResponse
{
    viz_categories: viz_category[];
    viz_types: viz_type[];
}


export class vizs
{
    type:string;
    viz_svg:string;
    title: string;
    is_summary:boolean;
}


export class classResult
{
    class: string;
    count: number;
    object_detection_results:detected_object[];
}




  export class cv_Pipeline
  {
    total_classes_found:number;
    general_Image_analysis: general_Image_analysis[]; 
    text_analysis: text_analysis[];
    person_analysis:person_analysis[];
  }

  export class general_Image_analysis
  {
    class: string;
    count: number;
    subclasses: subclass[];
  }
  export class subclass
  {
    class: string;
    count: number;
    object_detection_results:detected_object[];
  }

  export class text_analysis
  {
    class: string;
    count: number;
    text_detection_results:text_detection_results[];
    image_url: string;
  }

  export class text_detection_results
  {
    image_url:string;
    texts:text[];
  }

  export class text
  {
    score: string;
    text:string;
  }
  export class person_analysis
  {
    count: number;
    person_detection_results: person_detction[];
  }
  export class person_detction
  {
    gender: string;
    face_score: number;
    startX: number; 
    endX: number;
    startY: number;
    endY: number;
    filters: personFilter[];
  }
  export class personFilter
  {
    filter: string;
    score: number;
  }


  export class entity
  {
    name: string;
    mid: string;
    id: number;
    children: entity[];
  }

  export class general_Image{

    image_url:string;
    object_detection:object_detection[];
   // bbox:detected_object[];
    text_recognition:text[];
    face_detection : face_detection[];
    
  }

  export class object_detection
  {
    score:number;
    class_id: number;
    bbox:detected_object;

  }

  export class face_detection
  {
    score:number;
    bbox:detected_object;
    facial_analysis : facial_analysis;

  }

  export class facial_analysis {
    emotion: string;
    gender: string;
    emotion_score: number;
    age_min: number;
    gender_score: number;
    age_score: number;
    age_max: number;
  }

 

  export class detected_object
{

 
            
            x1:number;
            x2: number;
            y1: number;
            y2: number;
           
  }


