import {
  Injectable,
  ViewChild,
  ViewContainerRef,
  ElementRef,
} from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  public errorContent: any;
  public Error: string = '';
  constructor(private modalService: NgbModal) {}
  showError(error: string) {
    this.Error = error;
    this.modalService.open(this.errorContent, {});
  }
}
