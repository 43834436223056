import {Component, OnDestroy, OnInit} from '@angular/core';
import {DocumentSourceService} from '../../services/document-source.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-csv-table-viewer',
  templateUrl: './csv-table-viewer.component.html',
  styleUrls: ['./csv-table-viewer.component.css']
})
export class CsvTableViewerComponent implements OnInit, OnDestroy {
  public keymapStatus = {};
  public subscriptionToDocReset: Subscription = null;
  public charactersAllowed = 200;
  constructor(public documentSourceService: DocumentSourceService) { }
  convertToKeys(sourceObject: any): string[] {
    return Object.keys(sourceObject).filter(i => sourceObject[i]);
  }
  isExpanded(keyStr: string): boolean {
    return this.keymapStatus[keyStr];
  }
  expandToggle(keyStr: string): void {
    this.keymapStatus[keyStr] = !this.keymapStatus[keyStr];
  }
  hasMoreCharacters(keyStr: string, sourceObject: any): boolean {
    if (!sourceObject[keyStr]) {
      return false;
    }
    return sourceObject[keyStr].length > this.charactersAllowed;
  }
  trimCharacters(str: string, requiresTrim: boolean): string {
    return requiresTrim ? str.substr(0, this.charactersAllowed) : str;
  }
  hasMoreCharactersInText(text: string): boolean {
    return text.length > this.charactersAllowed;
  }
  isRowAList(row: any): boolean {
    return Array.isArray(row);
  }
  ngOnInit(): void {
    this.subscriptionToDocReset = this.documentSourceService.csvDocumentReset.subscribe(() => this.keymapStatus = {});
  }
  ngOnDestroy(): void {
    if (this.subscriptionToDocReset) {
      this.subscriptionToDocReset.unsubscribe();
    }
  }
}
