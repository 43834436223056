/* tslint:disable */
import {Component, OnInit, ViewChild, ElementRef, OnDestroy, ViewEncapsulation, Input} from '@angular/core';
import {addContextMenu, d3ModifyBubble, staticTrue, waitInMS} from '../../../services/utils';
import {VisualizationManagerService} from '../../../Managers/visualization-manager.service';
import {DocumentSourceService} from '../../../services/document-source.service';
import {AggregatorManagerService} from '../../../Managers/aggregator-manager.service';
import {AuthenticationService} from '../../../services/authentication.service';
import {DemoService} from '../../../services/demo.service';
import { viz_type } from '../../../models/dashboard';
import { DashboardManagerService } from '../../../Managers/dashboard-manager.service';
const d3: any = addContextMenu();
declare const $: any;

@Component({
  selector: 'app-bubble-chart',
  templateUrl: './bubble-chart.component.html',
  styleUrls: ['./bubble-chart.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class BubbleChartComponent implements OnInit, OnDestroy {
  @ViewChild('bubbleChart', { static: true })
  private chartContainer: ElementRef;
  visualizationSubscription = null;
  selectedNode: any;
  statsData = null;
  @Input()  height:number=null;
  loaded = false;
  svg:string;
  _serviceSubscription:any;
  colorFunc = (item) => {};

  constructor(public visualizationManagerService: VisualizationManagerService,
              public aggregatorManagerService: AggregatorManagerService,
              public documentSourceService: DocumentSourceService,public dashboardManager:DashboardManagerService,
              public demoService: DemoService) {


                this._serviceSubscription = this.dashboardManager.onExport.subscribe(c=>{
                  this.export();
                   
              });
               }

  showDetails(node) {
    this.selectedNode = node.data;
    $('#bubble-chart-details-modal').modal('show');
  }

  closeDetails() {
    $('#bubble-chart-details-modal').modal('hide');
    this.selectedNode = null;
  }

  flagItem(id, status, index) {
    this.visualizationManagerService.changeFlagStatus({ id, status});
    this.selectedNode.documents[index].flagged = status;
  }

  async initChart(): Promise<any> {
    const element = this.chartContainer.nativeElement;
    element.innerHTML = '';
    //await waitInMS(30);
    this.createChart();
  }

  createChart(): void {
    if (!this.loaded) return;
    if (!this.visualizationManagerService.visualizationInfo || !this.visualizationManagerService.visualizationData) return;
    if (this.visualizationManagerService.visualizationSearchFailed) return;

    const bubbleChartComponent = this;
    const clonedData = JSON.parse(JSON.stringify(this.visualizationManagerService.visualizationSearchData || this.visualizationManagerService.visualizationData))
    const aggregatorName = this.visualizationManagerService.visualizationInfo.aggregator_visualization_info.name;
    const bubbleData = d3ModifyBubble(clonedData, aggregatorName);
    const element = bubbleChartComponent.chartContainer.nativeElement;
    var width=element.offsetWidth;;
    element.innerHTML = ''; 
    var height = element.offsetHeight;
    if(this.height === null)
    {
     height = element.offsetHeight;
    }else
    {
      height = this.height
    }
   

    this.statsData = { clusters: bubbleData.clusters, totalDocuments: bubbleData.totalDocuments, aggregatorName };

    const diameter = Math.min(height, width);
    bubbleChartComponent.colorFunc = d3.scaleOrdinal(bubbleData.children.map(d => d.group))
      .range(['#082CAF', '#354FB0', '#1ED6BB',
        '#6214f4', '#d61e57', '#fabf1b', '#1464f4',
        '#0093d5', '#949bff', '#ab6dff', '#ff0e72',
        '#0b80b0',
        '#00ac59', '#488f31', '#00ac59', '#83af70',
        '#c4a0ec',
        '#98af8f', '#778271', '#8e8e8e', '#9f8888',
        '#f0b8b8',
        '#ec9c9d', '#e67f83', '#de6069', '#de425b',
        '#a47b80']);
    const format = d3.format(',d');
    const bubble = d3.pack(bubbleData)
      .size([diameter, diameter])
      .padding(1.5);

    const svg = d3.select(element).append('svg')
      .attr('width', width)
      .attr('height', diameter)
      .attr('class', 'bubble');

    const nodes = d3.hierarchy(bubbleData)
      .sum(function(d) { return d.value; });

    const replaceX = x => x + (width * 0.15);

    let node = svg.selectAll('.node')
      .data(bubble(nodes).descendants())
      .enter()
      .filter(function(d) {
        return  !d.children;
      })
      .append('g')
      .attr('class', 'node')
      .attr('transform', function(d) {
        return 'translate(' + replaceX(d.x) + ',' + d.y + ')';
      })
      .on('click', (node) => this.showDetails(node));


    node.append('title')
      .text(function(d) {
        return `${ d.data.title }`;
      });

    node.append('circle')
      .attr('r', function(d) {
        return d.r;
      })
      .style('fill', function(d, i) {
        return bubbleChartComponent.colorFunc(d.data.group);
      });

    node.append('text')
      .attr('dy', '.2em')
      .style('text-anchor', 'middle')
      .text(function(d) {
        return d.data.name.substring(0, d.r / 3);
      })
      .attr('font-family', 'sans-serif')
      .attr('font-size', function(d) {
        return d.r / 5;
      })
      .attr('fill', 'white');


      
      

  }

  ngOnDestroy(): void {

    if(this._serviceSubscription)
    this._serviceSubscription.unsubscribe();
    this.loaded  = false;
    this.visualizationSubscription.unsubscribe();
  }

  ngOnInit(): void {

    this.dashboardManager.viz_types.forEach(c=>{
      if(c.viz_uid == this.viz_uid)
      {
        this.viz_type = c;
      }
        });

        

    this.loaded = true;
    this.visualizationSubscription = this.visualizationManagerService.visualizingInit.subscribe(() => this.initChart().then().catch(console.log));
    this.initChart().then().catch(console.log);
  }


  viz_uid = '000000000000000000000006';
  
 public viz_type: viz_type;
 Add()
 {
    this.dashboardManager.viz_types.forEach(c=>{
    if(c.viz_uid ==  this.viz_uid)
    {
      this.viz_type = c;
    } });

   
    this.viz_type.isEnabled = true;
   this.dashboardManager.AddDashboardItem(this.aggregatorManagerService.selectedAggregator.id,this.viz_type.viz_uid,1);
 }

 export()
 {
   if(this.height)
   {

    const  element = document.querySelector('div#bubbleChart svg')
  
   
    this.dashboardManager.dashboard_items.forEach(c=>{
      if(c.viz_uid ==  this.viz_uid)
      {
        c.viz_type = this.viz_type.viz_type;
        c.viz_svg=  "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"850\" height=\"800\"  viewBox=\"0 0 600 400\" preserveAspectRatio=\"xMinYMin\"   > <g transform=\"translate(-100,-40)\"  >"+ element.innerHTML+"</g></svg>";
      } });
 
     }
   }

}
