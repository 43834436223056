import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../services/authentication.service';
import { AggregatorManagerService } from '../../Managers/aggregator-manager.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(public router: Router, private authenticationService: AuthenticationService,private aggmanager:AggregatorManagerService ) { }

  ngOnInit(): void {
    if (this.authenticationService.isLoggedIn()) {

      this.aggmanager.clearAllInterval();
      this.authenticationService.logoutAPI().subscribe(data => {
        this.authenticationService.clearLoggedUser();
        this.aggmanager.reset();
        this.router.navigate(['login']);
        
      });
    } else {
      this.router.navigate(['login']);
    }
  }

}
