import { Injectable, EventEmitter, Output } from '@angular/core';
import { viz_type, dashboardItem, vizs } from '../models/dashboard';
import { VisualizationService } from '../services/visualization.service';
import { AggregatorManagerService } from './aggregator-manager.service';
import { InfoGraphManagerService } from './info-graph-manager.service';
import { AggregatorsService } from '../services/aggregators.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorService } from '../services/error.service';
import { numerics } from '../models/csvVisualizer';

@Injectable({
  providedIn: 'root',
})
export class DashboardManagerService {
  public viz_types: viz_type[] = [];
  public dashboard_items: dashboardItem[] = [];
  public summaryReport: any;
  constructor(
    private visualizationService: VisualizationService,
    private aggService: AggregatorsService,
    private spinner: NgxSpinnerService,
    private errorService: ErrorService
  ) {}

  public onExport: EventEmitter<any> = new EventEmitter();
  fetchDashboard(aggId) {
    this.visualizationService.GetSupportedViz().subscribe((v) => {
      this.viz_types = v['viz_types'];

      this.visualizationService.GetDashboardItems(aggId).subscribe((c) => {
        this.summaryReport = c['sources_result_count'];

        this.CalculatePercentage();

        this.dashboard_items = c['dashboard_items'];

        this.dashboard_items.forEach((c) => {
          this.viz_types.forEach((item) => {
            if (item.viz_uid == c.viz_uid) {
              c.viz_type = item.viz_type;

              item.isEnabled = true;
              c.isEnabled = true;
            }
          });
        });
      });
    });
  }

  private CalculatePercentage() {
    var total: number;
    this.summaryReport.forEach((c, index) => {
      if (c.source_name.toLowerCase() == 'total') {
        total = c.count;
        this.summaryReport.splice(index, 1);
      }
    });
    this.summaryReport.forEach((c) => {
      if (c.source_name.toLowerCase() != 'total') {
        c.percent = (c.count / total) * 100;
      }
    });
    return total;
  }

  removeDashboardItem(id) {
    this.dashboard_items.forEach((x, index) => {
      if (x.dashboard_item_id == id) {
        this.dashboard_items.splice(index, 1);
      }
    });
    this.visualizationService.RemoveDashboardItem(id).subscribe((c) => {});
  }

  AddDashboardItem(aggregator_id, viz_id, viz_cat_id) {
    this.visualizationService
      .AddDashboardItem(aggregator_id, viz_id, viz_cat_id)
      .subscribe((c) => {
        (c['dashboard_item'] as dashboardItem).isEnabled = true;
        this.dashboard_items.push(c['dashboard_item'] as dashboardItem);

        this.viz_types.forEach((viz) => {
          if (viz.viz_uid == viz_id) {
            viz.isEnabled = true;
          }
        });
      });
  }
  ExportPdf(aggId, aggrName, vizs: vizs[]) {
    this.spinner.show();
    this.aggService.exportDashboard(aggId, vizs).subscribe(
      (c) => {
        this.spinner.hide();
        this.downLoadFile(c, aggrName, 'application/pdf');
      },
      (error) => {
        this.spinner.hide();
        this.errorService.showError(error.error.message);
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  downLoadFile(data: any, aggrName: string, type: string) {
    var blob = new Blob([data], { type: type.toString() });

    var a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = aggrName;
    // start download
    a.click();
  }
}
