<div *ngIf="this.dashboardManager.dashboard_items.length == 0" class="empty-data">
  <i class="fa fa-solid fa-box-open"></i>
  <strong>Not available!</strong>
</div>

<button *ngIf="this.dashboardManager.dashboard_items.length > 0"
  class="button float-right secondary-color br-8 ml-2 font-size14" (click)="exportAsPDF();">
  Export PDF
</button>
<div style="    position: relative;
 display: flex;">
  <div style="display: flex;
 flex-wrap: wrap;">




    <div class="card ml-4" *ngFor="let item of  this.dashboardManager.dashboard_items; let i = index">


      <div class="close">
        <button type="button" aria-label="Close" (click)="openPopup(item)">
          <i class="fa fa-arrows-alt" aria-hidden="true"></i>
        </button>

        <button type="button" aria-label="Close" (click)="closeInvite(item,i)">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
      </div>

      <app-source-count-chart #sourcecount height="500" *ngIf="item.viz_uid == '000000000000000000000008'">
      </app-source-count-chart>

      <app-sentiment-average-score-chart #sentimentavgscorecount height="500"
        *ngIf="item.viz_uid == '000000000000000000000009'"></app-sentiment-average-score-chart>

      <app-sentimentdevision #sentimentPieChart height="500" *ngIf="item.viz_uid == '00000000000000000000000a'">
      </app-sentimentdevision>

      <app-sentiment-source-history #sentimentBySourceChart height="500"
        *ngIf="item.viz_uid == '00000000000000000000000b'"></app-sentiment-source-history>

      <app-accumulated-change #accumulatedChange height="500" *ngIf="item.viz_uid == '00000000000000000000000c'">
      </app-accumulated-change>

      <app-bubble-chart height="500" style="zoom:100%" *ngIf="item.viz_uid == '000000000000000000000006'">
      </app-bubble-chart>
      <app-force height="500" *ngIf="item.viz_uid == '000000000000000000000004'"></app-force>

      <app-indent-tree height="500" *ngIf="item.viz_uid == '000000000000000000000007'"></app-indent-tree>

      <app-pack height="500" *ngIf="item.viz_uid == '000000000000000000000001'"></app-pack>

      <app-round-burst-chart height="500" *ngIf="item.viz_uid == '000000000000000000000002'"></app-round-burst-chart>

      <app-tree height="500" *ngIf="item.viz_uid == '000000000000000000000005'"></app-tree>

      <app-tree-map height="500" *ngIf="item.viz_uid == '000000000000000000000003'"></app-tree-map>

      <app-piechart [id]="item.viz_uid" [showAddButton]="false" *ngIf="item | isNominalDashboardItem" [width]="550"
        [height]="500"></app-piechart>

      <app-barchart [id]="item.viz_uid" [showAddButton]="false" *ngIf="item | isNumericalDashboardItem" [width]="550"
        [height]="450"></app-barchart>


      <app-neo-graph-widget [id]="item.viz_uid" *ngIf="item.viz_cat_id === 3"></app-neo-graph-widget>

    </div>










  </div>

  <div class="cover" *ngIf="showPopup">

    <div class="verticalcenter">
      <button type="button" class="close" style="right: 20px;
  top: 20px;" aria-label="Close" (click)="closePopup()">
        <span aria-hidden="true">&times;</span>
      </button>


      <app-source-count-chart #sourcecount height="1000" *ngIf="selecteditem?.viz_uid == '000000000000000000000008'">
      </app-source-count-chart>

      <app-sentiment-average-score-chart #sentimentavgscorecount height="1000"
        *ngIf="selecteditem?.viz_uid == '000000000000000000000009'"></app-sentiment-average-score-chart>

      <app-sentimentdevision #sentimentPieChart height="1000"
        *ngIf="selecteditem?.viz_uid == '00000000000000000000000a'"> </app-sentimentdevision>

      <app-sentiment-source-history #sentimentBySourceChart height="1000"
        *ngIf="selecteditem?.viz_uid == '00000000000000000000000b'"></app-sentiment-source-history>

      <app-accumulated-change #accumulatedChange height="1000"
        *ngIf="selecteditem?.viz_uid == '00000000000000000000000c'"> </app-accumulated-change>

      <app-bubble-chart height="1000" style="zoom:100%" *ngIf="selecteditem?.viz_uid == '000000000000000000000006'">
      </app-bubble-chart>
      <app-force height="1000" *ngIf="selecteditem?.viz_uid == '000000000000000000000004'"></app-force>

      <app-indent-tree height="1000" *ngIf="selecteditem?.viz_uid == '000000000000000000000007'"></app-indent-tree>

      <app-pack height="1000" *ngIf="selecteditem?.viz_uid == '000000000000000000000001'"></app-pack>

      <app-round-burst-chart height="1000" *ngIf="selecteditem?.viz_uid == '000000000000000000000002'">
      </app-round-burst-chart>

      <app-tree height="1000" *ngIf="selecteditem?.viz_uid == '000000000000000000000003'"></app-tree>

      <app-tree-map height="1000" *ngIf="selecteditem?.viz_uid == '000000000000000000000005'"></app-tree-map>

      <app-piechart [id]="selecteditem?.viz_uid" [showAddButton]="false" *ngIf="selecteditem | isNominalDashboardItem"
        [width]="1000" [height]="1000"></app-piechart>

      <app-barchart [id]="selecteditem?.viz_uid" [showAddButton]="false" *ngIf="selecteditem | isNumericalDashboardItem"
        [width]="1000" [height]="1000"></app-barchart>
      <app-neo-graph-widget [id]="selecteditem?.viz_uid" [height]="900" *ngIf="selecteditem.viz_cat_id === 3">
      </app-neo-graph-widget>


    </div>

  </div>

  <div hidden>
    <div id="summaryResult">

    </div>
  </div>

</div>