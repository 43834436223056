import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  Input,
  OnDestroy,
} from '@angular/core';
import * as c3 from 'c3/c3';
import { AggregatorManagerService } from '../../../Managers/aggregator-manager.service';
import { VisualizationManagerService } from '../../../Managers/visualization-manager.service';
import { Router } from '@angular/router';
import { addContextMenu, waitInMS } from '../../../services/utils';
import { viz_type } from '../../../models/dashboard';
import { DashboardManagerService } from '../../../Managers/dashboard-manager.service';
const d3: any = addContextMenu();

@Component({
  selector: 'app-accumulated-change',
  templateUrl: './accumulated-change.component.html',
  styleUrls: ['./accumulated-change.component.css'],
})
export class AccumulatedChangeComponent implements OnInit {
  private hook = null;
  sentimentByScore: any = null;
  colorFunc = null;
  formatN = d3.format('.2s');
  uniqueId: any;
  _serviceSubscription: any;
  @Input() height: number = null;

  constructor(
    private router: Router,
    public dashboardManager: DashboardManagerService,
    public visualizationManagerService: VisualizationManagerService,
    public aggManager: AggregatorManagerService
  ) {
    this._serviceSubscription = this.dashboardManager.onExport.subscribe(
      (c) => {
        this.export();
      }
    );
  }

  ngOnInit(): void {
    this.uniqueId = 'accumulatedChange' + this.getRandomInt(1, 2000);

    //this.createChart();
  }

  viz_uid = '00000000000000000000000c';
  ngAfterViewInit(): void {
    this.dashboardManager.viz_types.forEach((c) => {
      if (c.viz_uid == this.viz_uid) {
        this.viz_type = c;
      }
    });

    this.createChart();
    this.hook = this.visualizationManagerService.visualizingInit.subscribe(
      (event) => {
        this.createChart();
      }
    );
  }

  public viz_type: viz_type;
  Add() {
    this.dashboardManager.viz_types.forEach((c) => {
      if (c.viz_uid == this.viz_uid) {
        this.viz_type = c;
      }
    });

    this.viz_type.isEnabled = true;
    this.dashboardManager.AddDashboardItem(
      this.aggManager.selectedAggregator.id,
      this.viz_type.viz_uid,
      1
    );
  }

  getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  public createChart() {
    globalThis.d3 = d3;

    if (this.visualizationManagerService.sentimentsData != undefined) {
      this.sentimentByScore =
        this.visualizationManagerService.sentimentsData[4];

      if (this.sentimentByScore.sources) {
        this.colorFunc = d3
          .scaleOrdinal(this.sentimentByScore.sources.map((d) => d.source))
          .range([
            '#354FB0',
            '#fabf1b',
            '#1ED6BB',
            '#6214f4',
            '#d61e57',
            '#1464f4',
            '#0093d5',
            '#949bff',
            '#ab6dff',
            '#ff0e72',
            '#0b80b0',
            '#00ac59',
            '#488f31',
            '#00ac59',
            '#83af70',
            '#c4a0ec',
            '#082CAF',
            '#98af8f',
            '#778271',
            '#8e8e8e',
            '#9f8888',
            '#f0b8b8',
            '#ec9c9d',
            '#e67f83',
            '#de6069',
            '#de425b',
            '#a47b80',
          ]);

        const colors = {};
        for (const source of this.visualizationManagerService.sentimentsData[4]
          .sources) {
          colors[source.source] = this.colorFunc(source.source);
        }

        const accumulatedChangeInSourceChart = c3.generate({
          bindto: '#' + this.uniqueId,
          // title: {
          //   text: 'Average Sentiment Score / History',
          //   position: 'top-right',   // top-left, top-center and top-right
          // },
          point: {
            r: 3,
          },
          zoom: {
            enabled: this.height ? false : true,
          },
          // subchart: {
          //   show: this.height ? false : true,
          //   axis: {
          //     x: {
          //       show: false,
          //     },
          //   },
          // },
          data: {
            x: 'x',
            columns: [
              [
                'x',
                ...this.visualizationManagerService.sentimentsData[4].sources[0].sourceHistory.map(
                  (i) =>
                    new Date(i.executionTime).toISOString().substring(0, 10)
                ),
              ],
              ...this.visualizationManagerService.sentimentsData[4].sources.map(
                (i) => [
                  i.source,
                  ...i.sourceHistory.map((ini) => ini.accumulatedChange),
                ]
              ),
            ],
            labels: false,
            type: 'area',
            // types: {
            //   'Smoothened Score': 'spline'
            // },
            empty: {
              label: {
                text: 'No Data',
              },
            },
            selection: {
              enabled: true,
              draggable: true,
            },
            colors,
          },
          axis: {
            x: {
              label: {
                text: 'Executions',
                position: 'inner-center',
              },
              // type: 'category',
              type: 'category',
              tick: {
                rotate: -20,
                multiline: false,
                culling: {
                  max: 20, // the number of tick texts will be adjusted to less than this value
                },
              },
              show: this.height ? '' : true,
              // categories: this.visualizationManagerService.sentimentsData[4]
              //   .sources[0].sentimentHistory.map(i => (new Date(i.time)).toISOString().substring(0, 10)),
              // tick: {
              //   count: 4
              // }
            },
            y: {
              label: {
                text: 'Accumulated Change',
                position: 'outer-middle',
              },
              tick: {
                format: d3.format('.1f'),
              },
            },

            y2: {
              show: false,
              label: 'Score',
            },
          },
          grid: {
            x: {
              show: false,
            },
            y: {
              show: false,
              lines: [{ value: 0, text: '', position: 'end' }],
            },
          },
          legend: {
            position: 'inset',
          },
          // bar: {
          //   space: 0.1,
          //   width: {
          //     ratio: 0.85 // this makes bar width 50% of length between ticks
          //   }
          // }
        });
        //if (!this.height) accumulatedChangeInSourceChart.zoom([94, 100]);
      }
    } else {
    }
  }

  ngOnDestoy() {
    if (this._serviceSubscription) this._serviceSubscription.unsubscribe();
    if (this.hook) {
      this.hook.unsubscribe();
    }
  }

  export() {
    if (this.height) {
      const element = document.querySelector('#' + this.uniqueId + ' svg');

      this.dashboardManager.dashboard_items.forEach((c) => {
        if (c.viz_uid == this.viz_uid) {
          //c.viz_svg=  "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"600\" height=\"600\" viewBox=\"0 0 600 300\" preserveAspectRatio=\"xMinYMin\" style=\"display: block; margin: 0vh; background: rgb(255, 255, 255); cursor: pointer;\" > <g transform=\"translate(50,50)\">"+ element.innerHTML.replace(/clip-path=\".*?\"/g,"")+"</g></svg>";
          c.viz_svg =
            '<svg xmlns="http://www.w3.org/2000/svg" width="850" height="800"    ><style>.c3-line{fill-opacity:0; opacity: 1; }</style> <g transform="translate(0,-50)"  >' +
            element.innerHTML.replace(/clip-path=\".*?\"/g, '') +
            '</g></svg>';

          c.viz_type = this.viz_type.viz_type;
        }
      });
    }
  }
}
