import { EventEmitter, Injectable } from '@angular/core';
import { infoGraph, infoGraphResponse, query } from '../models/infograph';
import { MatTableDataSource } from '@angular/material/table';
import { InfographService } from '../services/infograph.service';
import { ignoreElements } from 'rxjs/operators';
import { DashboardManagerService } from './dashboard-manager.service';

@Injectable({
  providedIn: 'root',
})
export class InfoGraphManagerService {
  public classifications: infoGraph[] = [];
  public searchlabel: infoGraph[] = [];
  public clusters: infoGraph[] = [];
  public relationships: infoGraph[] = [];
  public searchList: infoGraph[] = [];
  public starredQueries: infoGraphResponse[] = [];

  public query: infoGraph[] = [];
  public isClassSelected = false;
  public condition: string = 'OR';
  constructor(
    public infoService: InfographService,
    public dashbaordManager: DashboardManagerService
  ) {}

  saveFav(aggrigator_id, query: query) {
    this.infoService.saveFav(aggrigator_id, query).subscribe((c) => {});
  }

  removeFav(query_id) {
    this.infoService.removeFavList(query_id).subscribe((c) => {});
  }

  getFavList(aggrigator_id) {
    this.infoService.getFavList(aggrigator_id).subscribe((c) => {
      this.starredQueries = c['user_favorite_queries'] as infoGraphResponse[];

      this.starredQueries.forEach((c) => {
        c.query = JSON.parse(c.query.toString()) as query;
        this.dashbaordManager.dashboard_items.forEach((e) => {
          if (e.viz_uid === c.id.toString()) {
            c.isEnabled = true;
          }
        });
      });
    });
  }

  getLabelsofFavByid(id) {
    let query = '';
    let label = [];
    let condition = '';
    this.starredQueries.forEach((c) => {
      if (c.id == id) {
        condition = c.query.condition;
        c.query.query.forEach((q) => {
          if (q.type == 'label') {
            label.push(q);
          }
        });
      }
    });

    if (label.length > 0) {
      if (condition == 'OR') {
        return 'n:' + label.map((i) => i.name).join(' OR n:');
      } else {
        return 'n:' + label.map((i) => i.name).join(' AND n:');
      }
    } else {
      return null;
    }
  }

  getAlllabelFavById(id) {
    let query = '';
    let label = [];
    let condition = '';
    this.starredQueries.forEach((c) => {
      if (c.id == id) {
        condition = c.query.condition;
        c.query.query.forEach((q) => {
          if (q.type == 'label') {
            label.push(q);
          }
        });
      }
    });

    if (label.length > 0) {
      if (this.condition == 'OR') {
        return 'n:' + label.map((i) => i.name).join(' OR n:');
      } else {
        return 'n:' + label.map((i) => i.name).join(' AND n:');
      }
    } else {
      return null;
    }
  }

  getRelationshipofFavByid(id) {
    let query = '';
    let relationship = [];
    let condition = '';
    this.starredQueries.forEach((c) => {
      if (c.id == id) {
        condition = c.query.condition;
        c.query.query.forEach((q) => {
          if (q.type == 'relationship') {
            relationship.push(q);
          }
        });
      }
    });

    if (relationship.length > 0) {
      if (condition == 'OR') {
        return ':' + relationship.map((i) => i.name).join('|');
      } else {
        return ':' + relationship.map((i) => i.name).join('&');
      }
    } else {
      return null;
    }
  }

  AddToQuery(item: infoGraph): void {
    this.query.push(item);
    this.isClassSelected =
      this.classifications.filter((i) => i.isSelected).length > 0;
  }

  RemoveFromQuery(item: infoGraph): void {
    this.query.forEach((c, index) => {
      if (c.name == item.name && c.type == item.type) {
        this.query.splice(index, 1);
      }
    });

    this.searchlabel.forEach((i, index) => {
      if (item.name == i.name) {
        this.searchlabel.splice(index, 1);
      }
    });

    this.isClassSelected =
      this.classifications.filter((i) => i.isSelected).length > 0;
  }

  updatesearhlist() {
    this.classifications.forEach((e) => {
      let notfound: boolean = true;
      this.searchList.forEach((c) => {
        if (c.name == e.name && c.type == e.type) {
          notfound = false;
        }
      });

      if (notfound) {
        this.searchList.push(e);
      }
    });

    this.clusters.forEach((e) => {
      let notfound: boolean = true;
      this.searchList.forEach((c) => {
        if (c.name == e.name && c.type == e.type) {
          notfound = false;
        }
      });

      if (notfound) {
        this.searchList.push(e);
      }
    });

    this.relationships.forEach((e) => {
      let notfound: boolean = true;
      this.searchList.forEach((c) => {
        if (c.name === e.name && c.type === e.type) {
          notfound = false;
        }
      });

      if (notfound) {
        this.searchList.push(e);
      }
    });

    //this.searchList  = [].concat(this.classifications,this.clusters,this.relationships);
  }

  getLabelQuerySyntax(): string {
    let query = '';
    if (!this.classifications) {
      return null;
    }
    const selectedLabels = this.classifications.filter((i) => i.isSelected);

    if (this.searchlabel.length > 0) {
      query =
        "n.name='" +
        this.searchlabel.map((i) => i.name).join("' OR n.name='") +
        "'";
    } else if (selectedLabels.length) {
      if (this.condition == 'OR') {
        query = 'n:' + selectedLabels.map((i) => i.name).join(' OR n:');
      } else {
        query = 'n:' + selectedLabels.map((i) => i.name).join(' AND n:');
      }
    } else {
      return null;
    }
    return query;
  }

  getAllLabelQuerySyntax(): string {
    if (!this.classifications) {
      return null;
    }
    const selectedLabels = this.classifications;
    if (selectedLabels.length) {
      if (this.condition == 'OR') {
        return 'n:' + selectedLabels.map((i) => i.name).join(' OR n:');
      } else {
        return 'n:' + selectedLabels.map((i) => i.name).join(' AND n:');
      }
    } else {
      return null;
    }
  }

  getRelationshipQuerySyntax(): string {
    if (!this.relationships) {
      return null;
    }
    const selectedRelation = this.relationships.filter((i) => i.isSelected);
    if (selectedRelation.length) {
      if (this.condition == 'OR') {
        return ':' + selectedRelation.map((i) => i.name).join('|');
      } else {
        return ':' + selectedRelation.map((i) => i.name).join('&');
      }
    } else {
      return null;
    }
  }

  setquery(query: infoGraph[]) {
    this.query = query;

    this.classifications.forEach((c) => (c.isSelected = false));
    this.relationships.forEach((c) => (c.isSelected = false));
    this.clusters.forEach((c) => (c.isSelected = false));

    this.classifications.forEach((c) => {
      this.query.forEach((cc) => {
        if (cc.type == 'label') {
          if (cc.name == c.name) {
            c.isSelected = true;
          }
        }
      });
    });

    this.relationships.forEach((c) => {
      this.query.forEach((cc) => {
        if (cc.type == 'relationship') {
          if (cc.name == c.name) {
            c.isSelected = true;
          }
        }
      });
    });

    this.clusters.forEach((c) => {
      this.query.forEach((cc) => {
        if (cc.type == 'cluster') {
          if (cc.name == c.name) {
            c.isSelected = true;
          }
        }
      });
    });
  }
}
