import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AggregatorsComponent } from './aggregators/aggregators.component';
import { AggregatorListComponent } from './aggregators/aggregator-list/aggregator-list/aggregator-list.component';
import { system_analysisComponent } from './aggregators/system_analysis/system_analysis/system_analysis.component';
import { VisualizationComponent } from './aggregators/Visualization/visualization/visualization.component';
import { SettingComponent } from './aggregators/setting/setting/setting.component';
import { HistoryComponent } from './aggregators/history/history/history.component';
import { SearchComponent } from './aggregators/search/search/search.component';
import { UsersComponent } from './users/users.component';
import { UserListComponent } from './users/user-list/user-list.component';
import { EdituserComponent } from './users/edituser/edituser/edituser.component';
import { LandingDashboardComponent } from './landing-dashboard/landing-dashboard.component';
import { LoginComponent } from './login/login.component';
import { SigninComponent } from './login/signin/signin.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ForgotpasswordComponent } from './login/forgotpassword/forgotpassword.component';
import { AlreadyLoggedIn } from './gaurd/already-login';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LogoutComponent } from './login/logout/logout.component';
import { LoadingComponentComponent } from './ui-components/loading-component/loading-component.component';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MaterialModules } from './material-modules';
import { SentimentsComponent } from './aggregators/Visualization/sentiments/sentiments.component';
import { BubbleChartComponent } from './aggregators/Visualization/bubble-chart/bubble-chart.component';
import { IndentTreeComponent } from './aggregators/Visualization/indent-tree/indent-tree.component';
import { TreeMapComponent } from './aggregators/Visualization/tree-map/tree-map.component';
import { ForceComponent } from './aggregators/Visualization/force/force.component';
import { PackComponent } from './aggregators/Visualization/pack/pack.component';
import { TreeComponent } from './aggregators/Visualization/tree/tree.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { DragDropDirective } from './directive/DragDropDirective';

import { HttpConfigInterceptor } from './interceptor/httpconfig.interceptor';
import { ConfirmationDailogComponent } from './dailogs/confirmation-dailog/confirmation-dailog.component';
import { CommentsConversationComponent } from './aggregators/comments-conversation/comments-conversation.component';
import { ChangepasswordComponent } from './login/changepassword/changepassword.component';
import { RoundBurstChartComponent } from './aggregators/Visualization/round-burst-chart/round-burst-chart.component';
import { InfographComponent } from './info-graph/infograph/infograph.component';
import { Neo4jGraphModule } from 'neo4j-graph';
import { GraphTestComponent } from './graph-test/graph-test.component';
import { IsExistPipe } from './pipes/is-exist.pipe';
import { CsvTableViewerComponent } from './ui-components/csv-table-viewer/csv-table-viewer.component';
import { CsvfileVisualizerComponent } from './aggregators/Visualization/csvfile-visualizer/csvfile-visualizer.component';
import { BarchartComponent } from './aggregators/Visualization/csvfile-visualizer/barchart/barchart.component';
import { PiechartComponent } from './aggregators/Visualization/csvfile-visualizer/piechart/piechart.component';
import { map } from 'rxjs/operators';
import { TextCloudComponent } from './aggregators/Visualization/csvfile-visualizer/text-cloud/text-cloud.component';
import { TagCloudModule } from 'angular-tag-cloud-module';
import { LinechartComponent } from './aggregators/Visualization/csvfile-visualizer/linechart/linechart.component';
import { ScatterplotsComponent } from './aggregators/Visualization/csvfile-visualizer/scatterplots/scatterplots.component';
import { DashboardComponent } from './aggregators/dashboard/dashboard.component';
import { SourceCountChartComponent } from './aggregators/system_analysis/source-count-chart/source-count-chart.component';
import { SentimentAverageScoreChartComponent } from './aggregators/system_analysis/sentiment-average-score-chart/sentiment-average-score-chart.component';
import { SentimentdevisionComponent } from './aggregators/system_analysis/sentimentdevision/sentimentdevision.component';
import { SentimentSourceHistoryComponent } from './aggregators/system_analysis/sentiment-source-history/sentiment-source-history.component';
import { AccumulatedChangeComponent } from './aggregators/system_analysis/accumulated-change/accumulated-change.component';
import { IsNominalDashboardItemPipe } from './pipes/is-nominal-dashboard-item.pipe';
import { IsTagCloudDashboardItemPipe } from './pipes/is-tag-cloud-dashboard-item.pipe';
import { IsNumericalDashboardItemPipe } from './pipes/is-numerical-dashboard-item.pipe';
import { NeoGraphWidgetComponent } from './info-graph/neo-graph-widget/neo-graph-widget.component';
import { CvPipelineComponent } from './aggregators/Visualization/cv-pipeline/cv-pipeline.component';
import { CvPipelineImageComponent } from './aggregators/Visualization/cv-pipeline/cv-pipeline-image/cv-pipeline-image.component';
import { CvPiplelineTextDetectionComponent } from './aggregators/Visualization/cv-pipeline/cv-pipleline-text-detection/cv-pipleline-text-detection.component';
import { CvPiplineSideviewComponent } from './aggregators/Visualization/cv-pipeline/cv-pipline-sideview/cv-pipline-sideview.component';
import { SpeechrecognitionComponent } from './aggregators/speechrecognition/speechrecognition.component';
import { AudiopiechartComponent } from './aggregators/speechrecognition/audiopiechart/audiopiechart.component';

import { DemoNgZorroAntdModule } from './zoro-material';
import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';
import en from '@angular/common/locales/en';
import { AudiorecorderComponent } from './aggregators/audiorecorder/audiorecorder.component';

registerLocaleData(en);

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(
  (key) => antDesignIcons[key]
);

@NgModule({
  declarations: [
    AppComponent,
    DragDropDirective,
    AggregatorsComponent,
    AggregatorListComponent,
    system_analysisComponent,
    VisualizationComponent,
    SettingComponent,
    HistoryComponent,
    SearchComponent,
    UsersComponent,
    UserListComponent,
    EdituserComponent,
    LandingDashboardComponent,
    LoginComponent,
    SigninComponent,
    ForgotpasswordComponent,
    LogoutComponent,
    LoadingComponentComponent,
    SentimentsComponent,
    BubbleChartComponent,
    IndentTreeComponent,
    TreeMapComponent,
    ForceComponent,
    PackComponent,
    TreeComponent,
    PageNotFoundComponent,
    ConfirmationDailogComponent,
    CommentsConversationComponent,
    ChangepasswordComponent,
    RoundBurstChartComponent,
    InfographComponent,
    GraphTestComponent,
    IsExistPipe,
    CsvTableViewerComponent,
    CsvfileVisualizerComponent,
    BarchartComponent,
    PiechartComponent,
    TextCloudComponent,
    LinechartComponent,
    ScatterplotsComponent,
    DashboardComponent,
    SourceCountChartComponent,
    SentimentAverageScoreChartComponent,
    SentimentdevisionComponent,
    SentimentSourceHistoryComponent,
    AccumulatedChangeComponent,
    IsNominalDashboardItemPipe,
    IsTagCloudDashboardItemPipe,
    IsNumericalDashboardItemPipe,
    NeoGraphWidgetComponent,
    CvPipelineComponent,
    CvPipelineImageComponent,
    CvPiplelineTextDetectionComponent,
    CvPiplineSideviewComponent,
    SpeechrecognitionComponent,
    AudiopiechartComponent,
    AudiorecorderComponent,
  ],
  imports: [
    TagCloudModule,
    Neo4jGraphModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    MatNativeDateModule,
    MaterialModules,
    BrowserAnimationsModule,
    HttpClientModule,
    DemoNgZorroAntdModule,
  ],
  providers: [
    AlreadyLoggedIn,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'fill' },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    { provide: NZ_I18N, useValue: en_US },
    { provide: NZ_ICONS, useValue: icons },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
