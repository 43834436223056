<div class="rounded-div mt-2">
    <h1 class="d-inline-block">{{ this.searchManger.comment_conversation?.aggregator_name}}</h1>
    <div class="float-right mt-1 action-icons">
        <span class="p2 mr-2">{{this.searchManger.comment_conversation?.comment_count}}
            <i class="fa fa-solid fa-comment icon-lg"></i>
        </span>
        <button class="ml-1" matTooltip="Flagged" (click)="confirmFlagChange()">
            <i class="fa fa-solid fa-flag icon-lg"></i>

        </button>
        <button class="ml-1" matTooltip="Goto Source" (click)="navigate()">
            <i class="fa fa-solid fa-link icon-lg"></i>
        </button>
        <button class="icon-lg ml-1" matTooltip="Delete" (click)="confirmRemoveSearch()">
            <i class="fa fa-solid fa-trash icon-lg"></i>
        </button>
    </div>
    <span class="p2 d-block">
        {{this.searchManger.comment_conversation?.text}}
    </span>
    <div class=" mt-3 clearfix">
        <div class="d-iniline-grid">
            <span class="p2 dark-grey-font">Flagged by</span>
            <span class="p1">{{this.searchManger.comment_conversation?.user_name}}</span>
            <span class="p2">{{ this.searchManger.comment_conversation?.date}} {{
                this.searchManger.comment_conversation?.time}}</span>
        </div>
        <div class="d-flex float-right ">
            <div class="d-grid">
                <span class="p2">Source</span>
                <span class="p1">{{this.searchManger.comment_conversation?.source}}</span>
            </div>
            <div class="verticalline"></div>
            <div class="d-grid">
                <span class="p2">Src. Name</span>
                <span class="p1">{{this.searchManger.comment_conversation?.source_name}}</span>
            </div>
        </div>
    </div>
</div>
<div class="rounded-div mt-2" *ngFor="let comment of this.searchManger.comments">
    <div>
        <div class="d-iniline-grid">

            <span class="p1 dark-grey-font">{{comment?.user_name}}</span>
            <span class="p2">{{comment?.time}}</span>
        </div>
        <div class="d-flex float-right ">
            <button class="close-button" (click)="confirmDeleteComment(comment)">
                <mat-icon class="close-icon">close</mat-icon>
            </button>
        </div>
    </div>
    <hr>
    <span class="p1">{{comment?.comment}}</span>

</div>
<footer class="comment-footer">
    <form name="myForm" [formGroup]="PostcommentForm" (submit)="AddComment(PostcommentForm.controls['comment'].value)">
        <mat-form-field class="col-9 p-0" appearance="outline">
            <mat-label>Write Comment</mat-label>
            <input matInput placeholder="Placeholder" formControlName="comment">
            <button mat-button matSuffix mat-icon-button aria-label="Clear" type="button"
                *ngIf="PostcommentForm.controls['comment'].value"
                (click)="PostcommentForm.controls['comment'].setValue('')">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <button type="submit" [disabled]="comment.invalid" style="  vertical-align: super"
            class="button primary-color br-8 font-size14 ml-3 p-3">
            Post Comment
        </button>
    </form>
</footer>