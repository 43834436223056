/* tslint:disable */

import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  Input,
} from '@angular/core';
import { addContextMenu, d3Modify, waitInMS } from '../../../services/utils';
import { VisualizationManagerService } from '../../../Managers/visualization-manager.service';
import { DocumentSourceService } from '../../../services/document-source.service';
import { AggregatorManagerService } from '../../../Managers/aggregator-manager.service';
import { DemoService } from '../../../services/demo.service';
import { viz_type } from '../../../models/dashboard';
import { DashboardManagerService } from '../../../Managers/dashboard-manager.service';
const d3: any = addContextMenu();
@Component({
  selector: 'app-tree-map',
  templateUrl: './tree-map.component.html',
  styleUrls: ['./tree-map.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class TreeMapComponent implements OnInit, OnDestroy {
  @ViewChild('chart', { static: true })
  private chartContainer: ElementRef;
  @Input() height: number = null;
  visualizationSubscription = null;
  _serviceSubscription: any;
  loaded = false;
  constructor(
    public visualizationManagerService: VisualizationManagerService,
    public dashboardManager: DashboardManagerService,
    public aggregatorManagerService: AggregatorManagerService,
    public documentSourceService: DocumentSourceService,
    public demoService: DemoService
  ) {
    this._serviceSubscription = this.dashboardManager.onExport.subscribe(
      (c) => {
        // this.export();
      }
    );
  }

  async initChart(): Promise<any> {
    const element = this.chartContainer.nativeElement;
    element.innerHTML = '';
    // await waitInMS(30);
    this.createChart();
  }

  createChart(): void {
    if (!this.loaded) return;
    if (
      !this.visualizationManagerService.visualizationInfo ||
      !this.visualizationManagerService.visualizationData
    )
      return;
    if (this.visualizationManagerService.visualizationSearchFailed) return;

    const treeChartComponent = this;
    const updateNodeStyle = (d, i, nodeMap) => {
      nodeMap[i].parentElement.parentElement.querySelector(
        'rect.child'
      ).style.fill = d.data.flagged ? '#b55b00' : d.rectColor;
      nodeMap[i].parentElement.parentElement.querySelector(
        'rect.parent'
      ).style.fill = d.data.flagged ? '#b55b00' : d.rectColor;
    };
    const menu = (nodeD: any, i: any, nodeMap: any) => [
      nodeD.data.flagged
        ? {
            title: 'Unflag',
            action: () => {
              nodeD.data.flagged = false;
              const { documentId } = nodeD.data;
              treeChartComponent.visualizationManagerService.changeFlagStatus({
                id: documentId,
                status: false,
              });
              updateNodeStyle(nodeD, i, nodeMap);
            },
          }
        : {
            title: 'Flag',
            action: () => {
              nodeD.data.flagged = true;
              const { documentId } = nodeD.data;
              treeChartComponent.visualizationManagerService.changeFlagStatus({
                id: documentId,
                status: true,
              });
              updateNodeStyle(nodeD, i, nodeMap);
            },
          },
      {
        title: 'Source',
        action: () => {
          const { documentId } = nodeD.data;
          treeChartComponent.documentSourceService.getAndOpenSource(
            { id: documentId },
            treeChartComponent.aggregatorManagerService.selectedAggregator.id
          );
        },
      },
    ];
    const onContext = (d, i, allNodes) => {
      d3.event.preventDefault();
      if (!d.data.children) {
        if (this.demoService.demoMode) {
          const { documentId } = d.data;
          treeChartComponent.documentSourceService.getAndOpenSource(
            { id: documentId },
            treeChartComponent.aggregatorManagerService.selectedAggregator.id
          );
        } else {
          d3.contextMenu(menu(d, i, allNodes))(d, i, allNodes);
        }
      }
    };
    const onClick = (d: any) => {
      if (!d.data.children) {
        treeChartComponent.visualizationManagerService.showDocumentText({
          id: d.data.documentId,
          text: d.data.name,
        });
      }
    };
    const clonedData = JSON.parse(
      JSON.stringify(
        treeChartComponent.visualizationManagerService
          .visualizationSearchData ||
          treeChartComponent.visualizationManagerService.visualizationData
      )
    );
    const aggregatorName =
      treeChartComponent.visualizationManagerService.visualizationInfo
        .aggregator_visualization_info.name;

    const treeData = d3Modify(clonedData, aggregatorName);
    const chartElement = treeChartComponent.chartContainer.nativeElement;
    chartElement.innerHTML = '';
    const margin = { top: 30, right: 0, bottom: 20, left: 0 };
    const width = chartElement.offsetWidth - (margin.left + margin.right);
    const height = chartElement.offsetHeight - (margin.top + margin.bottom);
    const color = d3
      .scaleOrdinal(treeData.children.map((d) => d.group))
      .range([
        '#2133A0',
        '#6818F2',
        '#DE752C',
        '#3A46CD',
        '#32D0B1',
        '#CB3264',
        '#C92F96',
        '#BE4127',
        '#6818F2',
        '#ab6dff',
        '#ff0e72',
        '#0b80b0',
        '#00ac59',
        '#488f31',
        '#00ac59',
        '#83af70',
        '#c4a0ec',
        '#98af8f',
        '#778271',
        '#8e8e8e',
        '#9f8888',
        '#f0b8b8',
        '#ec9c9d',
        '#e67f83',
        '#de6069',
        '#de425b',
        '#a47b80',
      ]);

    const formatNumber = d3.format(',');
    let transitioning;
    const x = d3.scaleLinear().domain([0, width]).range([0, width]);
    const y = d3.scaleLinear().domain([0, height]).range([0, height]);
    const treemap = d3
      .treemap()
      .size([width, height])
      .paddingInner(0)
      .round(false);

    const svg: any = d3
      .select(chartElement)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.bottom + margin.top)
      .style('margin-left', -margin.left + 'px')
      .style('margin.right', -margin.right + 'px')
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
      .style('shape-rendering', 'crispEdges');

    const grandparent = svg.append('g').attr('class', 'grandparent');

    grandparent
      .append('rect')
      .attr('y', -margin.top)
      .attr('width', width)
      .attr('height', margin.top)
      .attr('fill', '#6561E7');

    grandparent
      .append('text')
      .attr('x', 6)
      .attr('y', 6 - margin.top)
      .attr('dy', '.75em');

    const root = d3.hierarchy(treeData);
    treemap(
      root
        .sum(function (d) {
          return d.value;
        })
        .sort(function (a, b) {
          return b.height - a.height || b.value - a.value;
        })
    );
    display(root);
    function display(d) {
      // write text into grandparent
      // and activate click's handler
      grandparent
        .datum(d.parent)
        .on('click', transition)
        .select('text')
        .text(name(d))
        .attr('fill', '#FFF');

      grandparent
        .datum(d.parent)
        .select('rect')
        .attr('fill', '#6561E7')
        .attr('fill-opacity', 1);
      const g1 = svg
        .insert('g', '.grandparent')
        .datum(d)
        .attr('class', 'depth');
      const g = g1.selectAll('g').data(d.children).enter().append('g');
      // add class and click handler to all g's with children
      g.filter(function (inD) {
        return inD.children;
      })
        .classed('children', true)
        .on('click', transition);
      g.selectAll('.child')
        .data(function (inD) {
          return inD.children || [inD];
        })
        .enter()
        .append('rect')
        .attr('class', 'child')
        .call(rect);
      // add title to parents
      g.append('rect')
        .attr('class', 'parent')
        .call(rect)
        .append('title')
        .text(function (inD) {
          return inD.data.name;
        });
      /* Adding a foreign object instead of a text object, allows for text wrapping */
      g.append('foreignObject')
        .call(rect)
        .attr('class', 'foreignobj')
        .append('xhtml:div')
        .attr('dy', '.75em')
        .html(function (inD) {
          if (inD.depth === 3) {
            return (
              '' + '<p class="description-inner">' + inD.data.name + '</p>'
            );
          }
          return (
            '' +
            '<p class="title">' +
            inD.data.name +
            '</p>' +
            '<p class="value">' +
            formatNumber(inD.value) +
            '</p>'
          );
        })
        .attr('class', 'textdiv')
        .on('click', onClick)
        .on('contextmenu', onContext);

      function transition(dIn) {
        if (transitioning || !dIn) {
          return;
        }
        transitioning = true;
        const g2 = display(dIn);
        const t1 = g1.transition().duration(650);
        const t2 = g2.transition().duration(650);
        // Update the domain only after entering new elements.
        x.domain([dIn.x0, dIn.x1]);
        y.domain([dIn.y0, dIn.y1]);
        // Enable anti-aliasing during the transition.
        svg.style('shape-rendering', null);
        // Draw child nodes on top of parent nodes.
        svg.selectAll('.depth').sort(function (a, b) {
          return a.depth - b.depth;
        });
        // Fade-in entering text.
        g2.selectAll('text').style('fill-opacity', 0);
        g2.selectAll('foreignObject div').style('display', 'none');
        /*added*/
        // Transition to the new view.
        t1.selectAll('text').call(text).style('fill-opacity', 0);
        t2.selectAll('text')
          .call(text)
          .attr('fill', '#FFF')
          .style('fill-opacity', 1);
        t1.selectAll('rect').call(rect);
        t2.selectAll('rect').call(rect);
        /* Foreign object */
        t1.selectAll('.textdiv').style('display', 'none');
        /* added */
        t1.selectAll('.foreignobj').call(foreign);
        /* added */
        t2.selectAll('.textdiv').style('display', 'block');
        /* added */
        t2.selectAll('.foreignobj').call(foreign);
        /* added */
        // Remove the old node when the transition is finished.
        t1.on('end.remove', function () {
          this.remove();
          transitioning = false;
        });
      }
      return g;
    }
    function text(textIn) {
      textIn
        .attr('x', function (inD) {
          return x(inD.x) + 6;
        })
        .attr('y', function (inD) {
          return y(inD.y) + 6;
        });
    }
    function rect(rectIn) {
      rectIn
        .attr('x', function (inD) {
          return x(inD.x0);
        })
        .attr('y', function (inD) {
          return y(inD.y0);
        })
        .attr('width', function (inD) {
          return x(inD.x1) - x(inD.x0);
        })
        .attr('height', function (inD) {
          return y(inD.y1) - y(inD.y0);
        })
        .attr('fill', (dIn) => {
          if (dIn.data.flagged) {
            dIn.rectColor = dIn.parent.rectColor;
            return '#b55b00';
          }
          if (dIn.depth === 1) {
            const selectedColor = color(dIn.data.name);
            dIn.rectColor = selectedColor;
            return selectedColor;
          } else {
            dIn.rectColor = dIn.parent.rectColor;
            return dIn.rectColor;
          }
        })
        .attr('fill-opacity', (dIn) => {
          switch (dIn.depth) {
            case 1:
              return 0.95;
            case 2:
              return 0.85;
            case 3:
              return 0.75;
          }
          return 0.65;
        });
    }
    function foreign(foreignIn) {
      /* added */
      foreignIn
        .attr('x', function (inD) {
          return x(inD.x0);
        })
        .attr('y', function (inD) {
          return y(inD.y0);
        })
        .attr('width', function (inD) {
          return x(inD.x1) - x(inD.x0);
        })
        .attr('height', function (inD) {
          return y(inD.y1) - y(inD.y0);
        });
    }
    function name(inD) {
      return (
        breadcrumbs(inD) +
        (inD.parent
          ? ' -  Click to zoom out'
          : ' - Click inside square to zoom in')
      );
    }
    function breadcrumbs(inD) {
      let res = '';
      const sep = ' > ';
      inD
        .ancestors()
        .reverse()
        .forEach(function (i) {
          res += i.data.name + sep;
        });
      return res
        .split(sep)
        .filter(function (i) {
          return i !== '';
        })
        .join(sep);
    }
  }

  ngOnDestroy(): void {
    if (this._serviceSubscription) this._serviceSubscription.unsubscribe();
    this.loaded = false;
    this.visualizationSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.dashboardManager.viz_types.forEach((c) => {
      if (c.viz_uid == this.viz_uid) {
        this.viz_type = c;
      }
    });
    this.loaded = true;
    this.visualizationSubscription =
      this.visualizationManagerService.visualizingInit.subscribe(() =>
        this.initChart().then().catch(console.log)
      );
    this.initChart().then().catch(console.log);
  }
  viz_uid = '000000000000000000000003';

  public viz_type: viz_type;
  Add() {
    this.dashboardManager.viz_types.forEach((c) => {
      if (c.viz_uid == this.viz_uid) {
        this.viz_type = c;
      }
    });
    this.viz_type.isEnabled = true;
    this.dashboardManager.AddDashboardItem(
      this.aggregatorManagerService.selectedAggregator.id,
      this.viz_uid,
      1
    );
  }

  export() {
    if (this.height) {
      const element = document.querySelector('div#chart svg');

      this.dashboardManager.dashboard_items.forEach((c) => {
        if (c.viz_uid == this.viz_uid) {
          c.viz_type = this.viz_type.viz_type;
          c.viz_svg =
            '<svg xmlns="http://www.w3.org/2000/svg" width="600" height="600" viewBox="0 0 600 600" preserveAspectRatio="xMinYMin" style="display: block; margin: 0vh; background: rgb(255, 255, 255); cursor: pointer;" > <g transform="translate(5,5)">' +
            element.innerHTML +
            '</g></svg>';
        }
      });
    }
  }
}
