

   
<div class="tooltip2"  (click)="onImageClick()"  >

  
  <canvas #layout width="{{width}}"    height="{{height}}">{{imgUrl}}</canvas>

   

  <div class="spinner" >
  <mat-spinner
 
  *ngIf="loading"
 
   >
</mat-spinner>
</div> 
    
  </div>