import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import { AggregatorManagerService } from '../../../Managers/aggregator-manager.service';
import { VisualizationManagerService } from '../../../Managers/visualization-manager.service';
declare const $: any;

@Component({
  selector: 'app-visualization',
  templateUrl: './visualization.component.html',
  styleUrls: ['./visualization.component.css']
})
export class VisualizationComponent implements OnInit, OnDestroy {
  documentTextSubscription = null;

  constructor(public router: Router,
              public aggManager: AggregatorManagerService,
              public visualizationManagerService: VisualizationManagerService) {}

  ngOnInit(): void {
    if (!this.aggManager.selectedAggregator) {
      this.router.navigate(['']).then();
      return;
    }

    /*
    setTimeout(async () => {
      await this.visualizationManagerService.assertSentimentsData(this.aggManager.selectedAggregator.id);
      await this.visualizationManagerService.assertVisualizationData(this.aggManager.selectedAggregator.id);
    }, 100);*/
    this.documentTextSubscription = this.visualizationManagerService.showDocumentTextEvent.subscribe(() => {
      $('#document-text-modal').modal('show');
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.visualizationManagerService.updateLocalDateRepo();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.documentTextSubscription) {
      this.documentTextSubscription.unsubscribe();
    }
  }
}
