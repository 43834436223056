import {EventEmitter, Injectable} from '@angular/core';
import { VisualizationService } from './visualization.service';
declare const $: any;

@Injectable({
  providedIn: 'root'
})
export class DocumentSourceService {
  sourceLoading = false;
  sourceObject = null;
  public csvDocumentReset = new EventEmitter<any>();
  constructor(private visualizationService: VisualizationService) { }
  showCSVTable(aggregatorID: number, link: string): void {
    const columnIndex = +link.substr(link.lastIndexOf('/') + 1);
    this.visualizationService.fetchAggregatorCSVDocument(aggregatorID, link)
      .subscribe((innerResp: any) => {
        this.sourceLoading = false;
        const selectedColumn = Object.keys(innerResp.search_result_csv_record.row).sort((a, b) => (a < b ? -1 : 1))[columnIndex];
        this.sourceObject = {
          selectedColumn,
          selectedIndex: link,
          ...innerResp
        };
      });
  }
  getAndOpenSource(source: any = null, aggregatorID: number, providedLink: string = null): void {
    this.sourceLoading = true;
    this.sourceObject = null;
    $('#source-csv-viewer-modal').modal('show');
    if (providedLink) {
      this.showCSVTable(aggregatorID, providedLink);
    } else {
      this.csvDocumentReset.emit();
      this.visualizationService.fetchAggregatorDocumentLink(source.id).subscribe(data => {
        if(data.search_result_link !== undefined)
        if (data.search_result_link.indexOf('csv://') === 0) {
          this.showCSVTable(aggregatorID, data.search_result_link);
        } else {
          setTimeout(() => {
            $('#source-csv-viewer-modal').modal('hide');
            this.sourceLoading = false;
          }, 350);
          setTimeout(() => {
            window.open(data.search_result_link, '_blank');
          }, 1000);
        }
      });
    }
  }
}
