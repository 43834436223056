/* tslint:disable */
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  Input,
} from '@angular/core';
import { addContextMenu, d3Modify, waitInMS } from '../../../services/utils';
import { VisualizationManagerService } from '../../../Managers/visualization-manager.service';
import { DocumentSourceService } from '../../../services/document-source.service';
import { AggregatorManagerService } from '../../../Managers/aggregator-manager.service';
import { DemoService } from '../../../services/demo.service';
import { viz_type } from '../../../models/dashboard';
import { DashboardManagerService } from '../../../Managers/dashboard-manager.service';
const d3: any = addContextMenu();
declare const window: any;

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.css'],
})
export class TreeComponent implements OnInit, OnDestroy {
  @ViewChild('treechart', { static: true })
  private treechartContainer: ElementRef;
  visualizationSubscription = null;
  @Input() height: number = null;
  loaded = false;
  _serviceSubscription: any;
  constructor(
    public visualizationManagerService: VisualizationManagerService,
    public dashboardManager: DashboardManagerService,
    public aggregatorManagerService: AggregatorManagerService,
    public documentSourceService: DocumentSourceService,
    public demoService: DemoService
  ) {
    this._serviceSubscription = this.dashboardManager.onExport.subscribe(
      (c) => {
        // this.export();
      }
    );
  }

  async initChart(): Promise<any> {
    const element = this.treechartContainer.nativeElement;
    element.innerHTML = '';
    //  await waitInMS(30);
    this.createChart();
  }

  createChart(): void {
    if (!this.loaded) return;
    if (
      !this.visualizationManagerService.visualizationInfo ||
      !this.visualizationManagerService.visualizationData
    )
      return;
    if (this.visualizationManagerService.visualizationSearchFailed) return;

    const thisComponent = this;
    const updateNodeStyle = (d, i, nodeMap) => {
      d3.select(nodeMap[i])
        .select('circle')
        .style('fill', d.children || d.data.flagged ? '#6463ff' : '#eff5ff');
    };
    const menu = (nodeD: any, i: any, nodeMap: any) => [
      nodeD.data.flagged
        ? {
            title: 'Unflag',
            action: () => {
              nodeD.data.flagged = false;
              const { documentId } = nodeD.data;
              thisComponent.visualizationManagerService.changeFlagStatus({
                id: documentId,
                status: false,
              });
              updateNodeStyle(nodeD, i, nodeMap);
            },
          }
        : {
            title: 'Flag',
            action: () => {
              nodeD.data.flagged = true;
              const { documentId } = nodeD.data;
              thisComponent.visualizationManagerService.changeFlagStatus({
                id: documentId,
                status: true,
              });
              updateNodeStyle(nodeD, i, nodeMap);
            },
          },
      {
        title: 'Source',
        action: () => {
          const { documentId } = nodeD.data;
          thisComponent.documentSourceService.getAndOpenSource(
            { id: documentId },
            thisComponent.aggregatorManagerService.selectedAggregator.id
          );
        },
      },
    ];
    const onContext = (d, i, allNodes) => {
      d3.event.preventDefault();
      if (!d.data.children) {
        if (thisComponent.demoService.demoMode) {
          const { documentId } = d.data;
          thisComponent.documentSourceService.getAndOpenSource(
            { id: documentId },
            thisComponent.aggregatorManagerService.selectedAggregator.id
          );
        } else {
          d3.contextMenu(menu(d, i, allNodes))(d, i, allNodes);
        }
      }
    };
    const onClick = (d: any) => {
      if (!d._children) {
        const { documentId, name } = d.data;
        thisComponent.visualizationManagerService.showDocumentText({
          id: documentId,
          text: name,
        });
      }
    };

    const clonedData = JSON.parse(
      JSON.stringify(
        thisComponent.visualizationManagerService.visualizationSearchData ||
          thisComponent.visualizationManagerService.visualizationData
      )
    );
    const aggregatorName =
      thisComponent.visualizationManagerService.visualizationInfo
        .aggregator_visualization_info.name;

    const data = d3Modify(clonedData, aggregatorName);
    const chartElement = thisComponent.treechartContainer.nativeElement;
    chartElement.innerHTML = '';

    const width = chartElement.offsetWidth;
    const margin = { top: 10, right: 120, bottom: 10, left: 100 };
    const root: any = d3.hierarchy(data);
    const dx = 20;
    const dy = width / 6;
    const tree = d3.tree().nodeSize([dx, dy]);
    const diagonal: any = d3
      .linkHorizontal()
      .x((d: any) => d.y)
      .y((d: any) => d.x);

    root.x0 = dy / 2;
    root.y0 = 0;
    root.descendants().forEach((d, i) => {
      d.id = i;
      d._children = d.children;
      if (d.depth) {
        d.children = null;
      }
    });

    const svg: any = d3
      .select(chartElement)
      .append('svg')
      .attr('viewBox', `${-margin.left} ${-margin.top} ${width} ${dx}`)
      .style('font', '10px sans-serif')
      .style('user-select', 'none');

    const gLink = svg
      .append('g')
      .attr('fill', 'none')
      .attr('stroke', '#6463ff')
      .attr('stroke-opacity', 0.4)
      .attr('stroke-width', 1.5);

    const gNode = svg
      .append('g')
      .attr('cursor', 'pointer')
      .attr('pointer-events', 'all');

    function update(source: any) {
      const duration = d3.event && d3.event.altKey ? 2500 : 250;
      const nodes = root.descendants().reverse();
      const links = root.links();

      // Compute the new tree layout.
      tree(root);

      let left = root;
      let right = root;
      root.eachBefore((cNode) => {
        // tslint:disable-next-line:curly
        if (cNode.x < left.x) left = cNode;
        // tslint:disable-next-line:curly
        if (cNode.x > right.x) right = cNode;
      });

      const height = right.x - left.x + margin.top + margin.bottom;

      const transition = svg
        .transition()
        .duration(duration)
        .attr('viewBox', [-margin.left, left.x - margin.top, width, height])
        .tween(
          'resize',
          window.ResizeObserver ? null : () => () => svg.dispatch('toggle')
        );

      // Update the nodes…
      const node = gNode.selectAll('g').data(nodes, (d) => d.id);

      // Enter any new nodes at the parent's previous position.
      const nodeEnter = node
        .enter()
        .append('g')
        .attr('transform', (d) => `translate(${source.y0},${source.x0})`)
        .attr('fill-opacity', 0)
        .attr('stroke-opacity', 0)
        .on('click', (d, i, nodeMap) => {
          if (!d._children) {
            onClick(d);
          }
          d.children = d.children ? null : d._children;
          update(d);
          updateNodeStyle(d, i, nodeMap);
        })
        .on('contextmenu', onContext);

      nodeEnter
        .append('circle')
        .attr('r', 7)
        .attr('fill', (d) =>
          d.children || d.data.flagged ? '#6463ff' : '#eff5ff'
        )
        .attr('stroke', '#6463ff')
        .attr('stroke-width', 3);

      nodeEnter
        .append('text')
        .attr('dy', '0.31em')
        .attr('x', (d) => (d._children ? -12 : 12))
        .attr('text-anchor', (d) => (d._children ? 'end' : 'start'))
        .text((d) => d.data.name)
        .clone(true)
        .lower()
        .attr('stroke-linejoin', 'round')
        .attr('stroke-width', 5)
        .attr('stroke', 'white');

      // Transition nodes to their new position.
      const nodeUpdate = node
        .merge(nodeEnter)
        .transition(transition)
        .attr('transform', (d) => `translate(${d.y},${d.x})`)
        .attr('fill-opacity', 1)
        .attr('stroke-opacity', 1);

      // Transition exiting nodes to the parent's new position.
      const nodeExit = node
        .exit()
        .transition(transition)
        .remove()
        .attr('transform', (d) => `translate(${source.y},${source.x})`)
        .attr('fill-opacity', 0)
        .attr('stroke-opacity', 0);

      // Update the links…
      const link = gLink.selectAll('path').data(links, (d) => d.target.id);

      // Enter any new links at the parent's previous position.
      const linkEnter = link
        .enter()
        .append('path')
        .attr('d', (d) => {
          const o = { x: source.x0, y: source.y0 };
          // @ts-ignore
          return diagonal({ source: o, target: o });
        });

      // Transition links to their new position.
      link.merge(linkEnter).transition(transition).attr('d', diagonal);

      // Transition exiting nodes to the parent's new position.
      link
        .exit()
        .transition(transition)
        .remove()
        .attr('d', (d) => {
          const o = { x: source.x, y: source.y };
          return diagonal({ source: o, target: o });
        });

      // Stash the old positions for transition.
      root.eachBefore((d) => {
        d.x0 = d.x;
        d.y0 = d.y;
      });
    }

    update(root);
    if (this.height) {
      this.dashboardManager.dashboard_items.forEach((c) => {
        if (c.viz_uid == this.viz_uid) {
          c.viz_svg = svg;
        }
      });
    }
    return svg.node();
  }

  ngOnDestroy(): void {
    if (this._serviceSubscription) this._serviceSubscription.unsubscribe();
    this.loaded = false;
    this.visualizationSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.dashboardManager.viz_types.forEach((c) => {
      if (c.viz_uid == this.viz_uid) {
        this.viz_type = c;
      }
    });
    this.loaded = true;
    this.visualizationSubscription =
      this.visualizationManagerService.visualizingInit.subscribe(() =>
        this.initChart().then().catch(console.log)
      );
    this.initChart().then().catch(console.log);
  }

  viz_uid = '000000000000000000000005';

  public viz_type: viz_type;
  Add() {
    this.dashboardManager.viz_types.forEach((c) => {
      if (c.viz_uid == this.viz_uid) {
        this.viz_type = c;
      }
    });
    this.viz_type.isEnabled = true;
    this.dashboardManager.AddDashboardItem(
      this.aggregatorManagerService.selectedAggregator.id,
      this.viz_type.viz_uid,
      1
    );
  }

  export() {
    if (this.height) {
      const element = document.querySelector('div#treechart svg');

      this.dashboardManager.dashboard_items.forEach((c) => {
        if (c.viz_uid == this.viz_uid) {
          c.viz_type = this.viz_type.viz_type;
          c.viz_svg =
            '<svg xmlns="http://www.w3.org/2000/svg" width="600" height="600" viewBox="0 0 600 200" preserveAspectRatio="xMinYMin" style="display: block; margin: 0vh; background: rgb(255, 255, 255); cursor: pointer;" > <g transform="translate(100,100)">' +
            element.innerHTML +
            '</g></svg>';
        }
      });
    }
  }
}
