import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { finalize } from 'rxjs/operators';
import { DemoService } from '../../services/demo.service';
import { isDevMode } from '@angular/core';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css'],
})
export class SigninComponent implements OnInit {
  @ViewChild('loginInput') loginInput: ElementRef;
  @ViewChild('passwordInput') passwordInput: ElementRef;
  @ViewChild('loginButton') loginButton: ElementRef;

  public error = '';
  showPassword = false;
  isLoading = false;
  public loginForm: FormGroup;
  public login: FormControl;
  public password: FormControl;

  constructor(
    public route: Router,
    private authenticationService: AuthenticationService,
    public demoService: DemoService
  ) {}

  ngOnInit(): void {
    if (this.authenticationService.isLoggedIn()) {
      this.route.navigate(['']);
    }
    this.createLoginFormControls();
    this.createLoginForm();
    if (this.demoService.autoLogin) {
      this.login.setValue('info@infosiphon.com');
      this.password.setValue('mDX8CEW_zk6k5A');
      this.loginUser();
    }

    //user will be auto login on devmode
    if (isDevMode()) {
      this.login.setValue('tmbahadarali@gmail.com');
      this.password.setValue('mDX8CEW_zk6k5A');
      //this.loginUser();
    }
  }

  loginFocusChange(e): void {
    e.preventDefault();
    this.passwordInput.nativeElement.focus();
  }

  passwordFocusChange(e): void {
    e.preventDefault();
    this.loginButton.nativeElement.focus();
  }

  emailValidator(myControl): any {
    if (myControl.value.length < 3) {
      return { emailChk: true };
    }
    if (myControl.value.indexOf('@') === -1) {
      return { emailChk: true };
    }
    if (myControl.value.indexOf('.') === -1) {
      return { emailChk: true };
    }
    if (myControl.value.indexOf(' ') !== -1) {
      return { emailChk: true };
    }
  }

  createLoginFormControls(): void {
    this.login = new FormControl('', [
      Validators.required,
      this.emailValidator,
      Validators.minLength(3),
      Validators.maxLength(200),
      Validators.pattern('[\\w\\-\\@\\.\\_\\:\\s\\/]+'),
    ]);
    this.password = new FormControl('', Validators.required);
  }

  createLoginForm(): void {
    this.loginForm = new FormGroup({
      login: this.login,
      password: this.password,
    });
  }

  loginUser(): void {
    this.error = null;
    this.isLoading = true;
    this.authenticationService
      .loginAPI(this.login.value, this.password.value)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (data) => {
          data.email = this.login.value;
          this.authenticationService.setLoggedUser(data);
          this.route.navigate(['']);
        },
        ({ error }) => {
          if (error.message) {
            this.error = error.message;
          } else {
            this.error = 'There was some unknown error or server unreachable.';
          }
        }
      );
  }
}
