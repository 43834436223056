import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { VisualizationManagerService } from '../../../Managers/visualization-manager.service';
import { AggregatorManagerService } from '../../../Managers/aggregator-manager.service';
import { Route, Router } from '@angular/router';
import { aggregatorSummary } from '../../../models/aggregator';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  ConfirmationDailogComponent,
  ConfirmDialogModel,
} from '../../../dailogs/confirmation-dailog/confirmation-dailog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { DemoService } from '../../../services/demo.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';

import { addContextMenu, waitInMS } from '../../../services/utils';
import { SourceCountChartComponent } from '../../system_analysis/source-count-chart/source-count-chart.component';

const d3: any = addContextMenu();

@Component({
  selector: 'app-aggregator-list',
  templateUrl: './aggregator-list.component.html',
  styleUrls: ['./aggregator-list.component.css'],
})
export class AggregatorListComponent implements OnInit, OnDestroy {
  @ViewChild('sourcecount') sourcecount: SourceCountChartComponent;

  private hook = null;
  sentimentByScore: any = null;
  colorFunc = null;
  formatN = d3.format('.2s');

  constructor(
    public router: Router,
    public visualizationManagerService: VisualizationManagerService,
    public aggManager: AggregatorManagerService,
    public dialog: MatDialog,
    public demoService: DemoService,
    private notification: NzNotificationService
  ) {}
  currentScrollPage = 1;
  rejectPagination = false;
  aggregatorList: any;
  eventlistener: any;
  AgrretatorHandle: any;
  public showAddPopup = false;

  public isTabView = true;
  public visible = false;
  public visibleSummary = false;
  public noDataFound = false;

  public displayedColumns: string[] = this.demoService.autoLogin
    ? [
        'id',
        'name',
        'state',
        'schedule_type',
        'last_executed',
        'results',
        'actions',
      ]
    : [
        'id',
        'name',
        'state',
        'schedule_type',
        'scheduled_for',
        'last_executed',
        'results',
        'flagged',
        'conversed',
        'comments',
        'actions',
      ];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public AddAggregatorForm: FormGroup;

  // Form Controls
  public name: FormControl;

  public result: any;
  public aggSummary = null;

  resolveIcon(sourceName): string {
    switch (sourceName) {
      case 'Website':
        return './assets/images/website.png';
      case 'Twitter':
        return './assets/images/twitter.png';
      case 'Google':
        return './assets/images/google.png';
      case 'YouTube':
        return './assets/images/youtube.png';
      case 'Reddit':
        return './assets/images/reddit.jpeg';
      case 'Facebook':
        return './assets/images/facebook.jpeg';
      case 'Instagram':
        return './assets/images/instagram.png';
      case 'Document':
        return './assets/images/document.png';
      case 'Confluence':
        return './assets/images/confluence.png';
      default:
        return './assets/images/default.png';
    }
  }

  async initChart(): Promise<any> {
    await waitInMS(150);
    this.createChart();
  }

  ngOnInit(): void {
    this.createAddAggregatorControls();
    this.createAddAggregator();

    this.aggManager.removeSelectedAggregator();
    if (this.aggManager.aggregators.length == 0) {
      this.aggManager.getAggregators();
    }
    // this.HookupscrollEvents();
  }
  openSummary(agg: aggregatorSummary, aggId) {
    this.aggSummary = agg;
    this.visible = true;
    if (this.visualizationManagerService.sentimentsData) {
      this.initChart().then();
    }

    this.visualizationManagerService.assertSentimentsData(aggId).then((res) => {
      // console.log('first')
    });
    this.hook = this.visualizationManagerService.visualizingInit.subscribe(
      async (event) => this.initChart().then()
    );

    this.aggManager.getAggregatorSearchTerms(aggId);
  }

  close(): void {
    this.visible = false;
    this.visibleSummary = false;
    this.noDataFound = false;
  }

  sortByAverage(sources: [any]): [any] {
    return sources.sort((a, b) => b.sentimentAverage - a.sentimentAverage);
  }

  sortByAnalysed(sources: [any]): [any] {
    return sources.sort((a, b) => b.analysed - a.analysed);
  }
  createChart(): void {
    if (this.visualizationManagerService.isLoading) {
      return;
    }

    globalThis.d3 = d3;

    this.sentimentByScore = this.visualizationManagerService.sentimentsData[4];
    if (this.sentimentByScore.sources) {
      if (this.sentimentByScore.sources.length > 0) {
        this.visibleSummary = true;
      } else {
        this.noDataFound = true;
      }

      this.colorFunc = d3
        .scaleOrdinal(this.sentimentByScore.sources.map((d) => d.source))
        .range([
          '#354FB0',
          '#fabf1b',
          '#1ED6BB',
          '#6214f4',
          '#d61e57',
          '#1464f4',
          '#0093d5',
          '#949bff',
          '#ab6dff',
          '#ff0e72',
          '#0b80b0',
          '#00ac59',
          '#488f31',
          '#00ac59',
          '#83af70',
          '#c4a0ec',
          '#082CAF',
          '#98af8f',
          '#778271',
          '#8e8e8e',
          '#9f8888',
          '#f0b8b8',
          '#ec9c9d',
          '#e67f83',
          '#de6069',
          '#de425b',
          '#a47b80',
        ]);

      const colors = {};
      for (const source of this.visualizationManagerService.sentimentsData[4]
        .sources) {
        colors[source.source] = this.colorFunc(source.source);
      }

      if (this.sourcecount) {
        this.sourcecount.createChart();
      }
    } else {
      this.visibleSummary = false;
    }
  }
  ngOnDestroy(): void {
    // this.aggregatorList.removeEventListener('scroll', this.AgrretatorHandle, true);
    if (this.hook) {
      this.hook.unsubscribe();
    }
  }

  createAddAggregatorControls() {
    this.name = new FormControl('', [Validators.required]);
  }

  changePagesize(event?: PageEvent) {
    //  this.aggManager.limit = event.pageSize;
    //this.aggManager.offset = (event.pageIndex * event.pageSize);
    // this.aggManager.getAggregators();
  }

  createAddAggregator() {
    this.AddAggregatorForm = new FormGroup({
      name: this.name,
    });
  }

  AddAggregator() {
    this.showAddPopup = false;
    this.aggManager.AddAggregator(this.AddAggregatorForm.controls.name.value);
  }
  closeInvite() {
    this.showAddPopup = false;
  }

  ShowAddPopup() {
    this.AddAggregatorForm.controls.name.setValue('');
    this.showAddPopup = true;
  }

  private HookupscrollEvents(): void {
    this.AgrretatorHandle = this.callAggregator.bind(this);
    this.aggregatorList = document.querySelector('.scrolldiv ');
    this.eventlistener = this.aggregatorList.addEventListener(
      'scroll',
      this.AgrretatorHandle,
      true
    );
  }

  callAggregator() {
    const aggrComponentTop = this.aggregatorList.scrollTop,
      aggComponentHeight =
        this.aggregatorList.scrollHeight - this.aggregatorList.clientHeight;

    if (aggrComponentTop >= aggComponentHeight) {
      this.currentScrollPage++;
      if (!this.rejectPagination) {
        this.paginateFilteredSignals();
      }
    }
  }
  omit_special_char(event) {
    let k;
    k = event.charCode; //         k = event.keyCode;  (Both can be used)
    return (
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      k == 8 ||
      k == 32 ||
      (k >= 48 && k <= 57)
    );
  }
  paginateFilteredSignals(): void {
    this.rejectPagination = true;
    if (this.aggManager.aggregators.length > 0) {
      this.aggManager.offset = this.aggManager.aggregators.length + 1;
      this.aggManager.getAggregators();
    }

    setTimeout(() => {
      // Prevent multiple paginations and pushes in the array at a the same time
      this.rejectPagination = false;
    }, 1000);
  }

  ExecuteAggregator(aggregator_id) {
    this.aggManager.ExecuteAggregator(aggregator_id);
  }

  DeleteAggregator(aggregator_id) {
    this.aggManager.DeleteAggregator(aggregator_id);
  }

  sentimentclick(agg: aggregatorSummary): void {
    this.aggManager.setSelectedAggregator(agg);
    this.router.navigate(['sentiment_analysis']);
  }

  speechclick(agg: aggregatorSummary): void {
    this.aggManager.setSelectedAggregator(agg);
    this.router.navigate(['speechrecognition']);
  }
  imageclick(agg: aggregatorSummary): void {
    this.aggManager.setSelectedAggregator(agg);
    this.router.navigate(['visualization/cvPipeline']);
  }

  detailsClick(agg: aggregatorSummary): void {
    this.aggManager.setSelectedAggregator(agg);
    this.router.navigate(['search']);
  }

  infoGraphClick(agg: aggregatorSummary): void {
    this.aggManager.setSelectedAggregator(agg);
    this.router.navigate(['infograph']);
  }

  VisualizationClick(agg: aggregatorSummary): void {
    this.aggManager.setSelectedAggregator(agg);
    this.router.navigate(['visualization/pack']);
  }

  historyclick(agg: aggregatorSummary): void {
    this.aggManager.setSelectedAggregator(agg);
    this.router.navigate(['history']);
  }

  dashboardclick(agg: aggregatorSummary): void {
    this.aggManager.setSelectedAggregator(agg);
    this.router.navigate(['dashboard']);
  }

  settingClick(agg: aggregatorSummary): void {
    this.aggManager.setSelectedAggregator(agg);
    this.router.navigate(['setting']);
  }
  confirmExecuteDialog(aggregator: aggregatorSummary): void {
    const message =
      `Are you sure you want execute aggregator '` + aggregator.name + `'`;

    const dialogData = new ConfirmDialogModel('Confirm Action', message);

    const dialogRef = this.dialog.open(ConfirmationDailogComponent, {
      maxWidth: '400px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      this.result = dialogResult;

      if (this.result) {
        this.ExecuteAggregator(aggregator);
      }
    });
  }

  confirmDeleteDialog(aggregator: aggregatorSummary): void {
    const message =
      `Are you sure you want remover aggregator '` + aggregator.name + `'`;

    const dialogData = new ConfirmDialogModel('Confirm Action', message);

    const dialogRef = this.dialog.open(ConfirmationDailogComponent, {
      maxWidth: '400px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      this.result = dialogResult;

      if (this.result) {
        this.DeleteAggregator(aggregator.id);
      }
    });
  }

  changeAlert(agg: aggregatorSummary, alert) {
    this.aggManager.SetAggregatorUserAlert(agg.id, alert);
  }

  tableView() {
    this.isTabView = false;
    this.aggregatorList.removeEventListener(
      'scroll',
      this.AgrretatorHandle,
      true
    );
  }

  tabView() {
    this.isTabView = true;
    this.HookupscrollEvents();
  }
}
