import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { getRequestId } from './utils';
import { Observable } from 'rxjs';
import { infoGraph, query } from '../models/infograph';

@Injectable({
  providedIn: 'root',
})
export class InfographService {
  constructor(private http: HttpClient) {}

  public getFavList(aggregator_id): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.get(
      environment.apiURL +
        'GetKGUserFavoriteQueries?aggregator_id=' +
        aggregator_id +
        '&request_id=' +
        getRequestId().toString(),
      options
    );
  }

  public saveFav(aggregator_id, query: query): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.get(
      environment.apiURL +
        'AddKGUserFavoriteQuery?aggregator_id=' +
        aggregator_id +
        '&query=' +
        encodeURI(JSON.stringify(query)) +
        '&request_id=' +
        getRequestId().toString(),
      options
    );
  }

  public removeFavList(kg_user_favorite_query_id): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.get(
      environment.apiURL +
        'RemoveKGUserFavoriteQuery?kg_user_favorite_query_id=' +
        kg_user_favorite_query_id +
        '&request_id=' +
        getRequestId().toString(),
      options
    );
  }
}
