import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpHeaders, HttpClient, HttpBackend } from '@angular/common/http';
import { getRequestId } from './utils';
import { Observable } from 'rxjs';

import {
  searchResponse,
  historyResponse,
  aggregatorResponse,
} from '../Response/responce';
import { vizs } from '../models/dashboard';

@Injectable({
  providedIn: 'root',
})
export class AggregatorsService {
  httpFile;
  e: HttpClient;
  constructor(
    private http: HttpClient,
    private httpBackendhandler: HttpBackend
  ) {
    this.httpFile = new HttpClient(httpBackendhandler);
  }

  public getAggregatorList(offset, limit): Observable<any> {
    const data = new URLSearchParams();
    //data.set('offset', offset);
    data.set('request_id', getRequestId().toString());
    // data.set('limit', limit);
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.get(
      environment.apiURL +
        'Aggregators?offset=' +
        -1 +
        '&limit=' +
        -1 +
        '&request_id=3',
      options
    );
    //  return this.http.get(environment.apiURL + 'Aggregators?request_id=3', options);
  }

  public getAggregatorSearchResults(
    aggregator_id,
    source,
    flagged_only,
    conversed_only,
    offset,
    limit,
    boolean_operator,
    publish_date_from,
    publish_date_to,
    search_date_from,
    search_date_to,
    sources,
    source_names,
    sentiments,
    orderField,
    orderType?
  ): Observable<any> {
    const data = new URLSearchParams();
    data.set('aggregator_id', aggregator_id);
    data.set('flagged', flagged_only);
    data.set('conversed', conversed_only);
    data.set('offset', offset);
    data.set('request_id', getRequestId().toString());
    data.set('limit', limit);
    if (boolean_operator) {
      data.set('boolean_operator', boolean_operator);
    }
    if (publish_date_from) {
      data.set('publish_date_from', publish_date_from);
    }
    if (publish_date_to) {
      data.set('publish_date_to', publish_date_to);
    }
    if (search_date_from) {
      data.set('search_date_from', search_date_from);
    }
    if (search_date_to) {
      data.set('search_date_to', search_date_to);
    }
    if (sources && sources.length > 0) {
      data.set('sources', sources.toString());
    }
    if (source_names && source_names.length > 0) {
      data.set('source_names', source_names.toString());
    }
    if (sentiments && sentiments.length > 0) {
      data.set('sentiments', sentiments.toString());
    }
    if (orderField) {
      data.set('orderField', orderField);
    }
    if (orderType) {
      data.set('orderType', orderType);
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.post<searchResponse>(
      environment.apiURL + 'FilterAggregatorSearchResults',
      data.toString(),
      options
    );
  }

  public getHistoryResults(aggregator_id, offset, limit): Observable<any> {
    const data = new URLSearchParams();
    data.set('aggregator_id', aggregator_id);

    data.set('offset', offset);
    data.set('request_id', getRequestId().toString());
    data.set('limit', limit);
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.post<historyResponse>(
      environment.apiURL + 'AggregatorHistories',
      data.toString(),
      options
    );
  }

  public exportDetails(aggregator_id, cluster_terms_ids) {
    const headers = new HttpHeaders({
      Accept: '*/*',
      'Accept-Encoding': 'gzip,deflate,br',
      responseType: 'blob',
    });
    const options = {
      headers,
      observe: 'events',
      params: {},
      responseType: 'blob',
      reportProgress: false,
      withCredentials: true,
    };
    return this.http.post(
      environment.apiURL +
        'ExportAggregatorSearchResults?request_id=' +
        getRequestId().toString() +
        '&aggregator_id=' +
        aggregator_id,
      { aggregator_id: aggregator_id, cluster_terms: cluster_terms_ids },
      { responseType: 'blob', withCredentials: true, headers: headers }
    );
  }

  public exportDashboard(aggregator_id, vizs) {
    const headers = new HttpHeaders({
      Accept: '*/*',
      'Accept-Encoding': 'gzip,deflate,br',
      responseType: 'blob',
    });
    const options = {
      responseType: 'blob',
      withCredentials: true,
      headers: headers,
    };
    /*const data:   URLSearchParams  = new URLSearchParams ();
  data.append('aggregatorId', aggregator_id.toString() );
  data.append('vizs', JSON.stringify(vizs)); */

    var data2: {};

    return this.http.post(
      environment.apiURL + 'PDFReport?request_id=' + getRequestId().toString(),
      {
        aggregatorId: aggregator_id.toString(),
        vizs: vizs,
      },
      { responseType: 'blob', withCredentials: true, headers: headers }
    );
  }

  public RemoveHistory(aggregator_history_id) {
    const data = new URLSearchParams();
    data.set('aggregator_history_id', aggregator_history_id);

    data.set('request_id', getRequestId().toString());
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.post(
      environment.apiURL + 'RemoveAggregatorHistory',
      data.toString(),
      options
    );
  }

  public ClearHistory(aggregator_id) {
    const data = new URLSearchParams();
    data.set('aggregator_id', aggregator_id);

    data.set('request_id', getRequestId().toString());
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.post(
      environment.apiURL + 'ClearAggregatorHistories',
      data.toString(),
      options
    );
  }

  public getAggregatorKGStatus(url: string): Promise<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });

    if (environment.devMode) {
      headers = headers.set('environment', 'dev');
    } else {
      headers = headers.set('environment', 'prod');
    }

    const options = { headers };

    return this.http.get<any>(url, options).toPromise();
  }

  public getAggregatorCluster(aggregator_id) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.get(
      environment.apiURL +
        'GetAggregatorClusterTerms?aggregator_id=' +
        aggregator_id +
        '&request_id=' +
        getRequestId().toString(),
      options
    );
  }

  public getAggregator(aggregator_id) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.get<aggregatorResponse>(
      environment.apiURL +
        'GetAggregator?aggregator_id=' +
        aggregator_id +
        '&request_id=' +
        getRequestId().toString(),
      options
    );
  }
  GetDashboardItems(aggid): Observable<any> {
    const headers = new HttpHeaders();
    const options = { withCredentials: true, headers };
    return this.http.post(
      environment.apiURL +
        'GetDashboardItems?request_id=' +
        getRequestId().toString() +
        '&aggregator_id=' +
        aggid,
      options
    );
  }
  public getAggregatorSpeechAnalyticsObjectIds(aggregator_id) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.get(
      environment.apiURL +
        'GetAggregatorSpeechAnalyticsObjectIds?aggregator_id=' +
        aggregator_id +
        '&request_id=' +
        getRequestId().toString(),
      options
    );
  }

  public GetAggregatorSpeechAnalytics(object_id, aggregator_id) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.get(
      environment.apiURL +
        'GetAggregatorSpeechAnalytics?request_id=' +
        getRequestId().toString() +
        '&aggregator_id=' +
        aggregator_id +
        '&object_id=' +
        object_id,
      options
    );
  }

  public update_sentence(aggregatorName, _id, type, start, innerText) {
    const formData = {
      aggregatorName: aggregatorName,
      _id: _id,
      type: type,
      start: start,
      text: innerText,
    };
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    const options = { withCredentials: false, header };
    return this.http.put(
      environment.apiURLTemp + 'speech_analytics/text',
      formData,
      options
    );
  }

  public update_speaker(aggregatorName, _id, speaker, speakerNewName) {
    const formData = {
      aggregatorName: aggregatorName,
      _id: _id,
      speaker: speaker,
      speakerNewName: speakerNewName,
    };
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    const options = { withCredentials: false, header };
    return this.http.put(
      environment.apiURLTemp + 'speech_analytics/speaker',
      formData,
      options
    );
  }

  public getOrganizationInformation() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.get(
      environment.apiURL +
        'GetOrganizationInfo?request_id=' +
        getRequestId().toString(),
      options
    );
  }

  public EditAggregator(
    aggregator_id,
    aggregator_name,
    schedule_type,
    scheduled_for_year,
    scheduled_for_month,
    scheduled_for_date
  ) {
    const data = new URLSearchParams();
    data.set('aggregator_id', aggregator_id);

    data.set('aggregator_name', aggregator_name);
    data.set('request_id', getRequestId().toString());
    data.set('schedule_type', schedule_type);
    data.set('scheduled_for_year', scheduled_for_year);
    data.set('scheduled_for_month', scheduled_for_month);
    data.set('scheduled_for_date', scheduled_for_date);
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.post<historyResponse>(
      environment.apiURL + 'EditAggregator',
      data.toString(),
      options
    );
  }

  public AddSearchTerm(aggregator_id, search_term, search_term_type) {
    const data = new URLSearchParams();
    data.set('aggregator_id', aggregator_id);

    data.set('search_term', search_term);
    data.set('request_id', getRequestId().toString());
    data.set('search_term_type', search_term_type);

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.post(
      environment.apiURL + 'AddAggregatorSearchTerm',
      data.toString(),
      options
    );
  }

  addDocumentCollection(
    documentAggregatorId: number,
    files: File[]
  ): Observable<any> {
    const data: FormData = new FormData();
    data.append('request_id', getRequestId().toString());
    data.append('document_aggregator_id', documentAggregatorId.toString());
    for (let i = 0; i < files.length; i++) {
      data.append('uploads[]', files[i], files[i].name);
    }
    //
    const headers = new HttpHeaders();
    const options = { withCredentials: true, headers };
    return this.http.post(
      environment.apiURL +
        'AddDocumentCollection?request_id=' +
        getRequestId().toString() +
        '&document_aggregator_id=' +
        documentAggregatorId,
      data,
      options
    );
  }

  removeDocument(documentId: number): Observable<any> {
    const data = new URLSearchParams();
    data.set('request_id', getRequestId().toString());
    data.set('document_collection_id', documentId.toString());
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.post(
      environment.apiURL + 'RemoveDocumentCollection',
      data.toString(),
      options
    );
  }

  public AddWebsite(website_aggregator_id, website_aggregator_url) {
    const data = new URLSearchParams();
    data.set('website_aggregator_id', website_aggregator_id);
    data.set('website_aggregator_url', website_aggregator_url);
    data.set('request_id', getRequestId().toString());
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.post(
      environment.apiURL + 'AddWebsiteAggregatorLink',
      data.toString(),
      options
    );
  }
  public AddDocumentURL(document_aggregator_id, type, document_aggregator_url) {
    const data = new URLSearchParams();
    data.set('document_aggregator_id', document_aggregator_id);
    data.set('type', type);
    data.set('document_aggregator_url', document_aggregator_url);
    data.set('request_id', getRequestId().toString());
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.post(
      environment.apiURL + 'AddDocumentAggregatorLink',
      data.toString(),
      options
    );
  }

  public AddConfulenceText(aggregator_id, confluence_text) {
    const data = new URLSearchParams();
    data.set('confluence_aggregator_id', aggregator_id);
    data.set('confluence_aggregator_space_key', confluence_text);
    data.set('request_id', getRequestId().toString());
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.post(
      environment.apiURL + 'AddConfluenceAggregatorSpace',
      data.toString(),
      options
    );
  }

  public AddFilter(aggregator_id, exclude_filter, exclude_filter_type) {
    const data = new URLSearchParams();
    data.set('aggregator_id', aggregator_id);
    data.set('exclude_filter', exclude_filter);
    data.set('exclude_filter_type', exclude_filter_type);
    data.set('request_id', getRequestId().toString());
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.post(
      environment.apiURL + 'AddAggregatorExcludeFilter',
      data.toString(),
      options
    );
  }

  public AddAggregator(aggregator_name) {
    const data = new URLSearchParams();
    data.set('aggregator_name', aggregator_name);

    data.set('request_id', getRequestId().toString());
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.post(
      environment.apiURL + 'AddAggregator',
      data.toString(),
      options
    );
  }

  public ExecuteAggregator(aggregator_id) {
    const data = new URLSearchParams();
    data.set('aggregator_id', aggregator_id);

    data.set('request_id', getRequestId().toString());
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.post(
      environment.apiURL + 'ExecuteAggregator',
      data.toString(),
      options
    );
  }

  public GetAggregatorCSVStatisic(aggregator_id) {
    const data = new URLSearchParams();
    data.set('aggregator_id', aggregator_id);

    data.set('request_id', getRequestId().toString());
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.post(
      environment.apiURL + 'AggregatorCSVStatistics',
      data.toString(),
      options
    );
  }

  fetchAggregatorInfo(id): Observable<any> {
    const data = new URLSearchParams();
    data.set('request_id', '' + getRequestId());
    data.set('id', id);
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.get(
      environment.apiURL +
        'GetAggregatorInfo?request_id=' +
        getRequestId() +
        '&aggregator_id=' +
        id,
      options
    );
  }

  public DeleteAggregator(aggregator_id) {
    const data = new URLSearchParams();
    data.set('aggregator_id', aggregator_id);

    data.set('request_id', getRequestId().toString());
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.post(
      environment.apiURL + 'RemoveAggregator',
      data.toString(),
      options
    );
  }

  public SetAggregatorUserAlert(
    aggregator_id,
    enable_aggregator_processed_alert
  ) {
    const data = new URLSearchParams();
    data.set('aggregator_id', aggregator_id);
    data.set(
      'enable_aggregator_processed_alert',
      enable_aggregator_processed_alert
    );
    data.set('request_id', getRequestId().toString());
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.post(
      environment.apiURL + 'SetAggregatorUserAlert',
      data.toString(),
      options
    );
  }

  public RemoveSearchTerm(aggregator_search_term_id) {
    const data = new URLSearchParams();
    data.set('aggregator_search_term_id', aggregator_search_term_id);
    data.set('request_id', getRequestId().toString());
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.post<historyResponse>(
      environment.apiURL + 'RemoveAggregatorSearchTerm',
      data.toString(),
      options
    );
  }

  public Removefilter(aggregator_exclude_filter_id) {
    const data = new URLSearchParams();
    data.set('aggregator_exclude_filter_id', aggregator_exclude_filter_id);
    data.set('request_id', getRequestId().toString());
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.post<historyResponse>(
      environment.apiURL + 'RemoveAggregatorExcludeFilter',
      data.toString(),
      options
    );
  }

  public Removewebsite(website_aggregator_link_id) {
    const data = new URLSearchParams();
    data.set('website_aggregator_link_id', website_aggregator_link_id);
    data.set('request_id', getRequestId().toString());
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.post<historyResponse>(
      environment.apiURL + 'RemoveWebsiteAggregatorLink',
      data.toString(),
      options
    );
  }
  public RemoveDocumentURL(document_aggregator_link_id) {
    const data = new URLSearchParams();
    data.set('document_aggregator_link_id', document_aggregator_link_id);
    data.set('request_id', getRequestId().toString());
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.post<historyResponse>(
      environment.apiURL + 'RemoveDocumentAggregatorLink',
      data.toString(),
      options
    );
  }

  public Removeconfluence(id) {
    const data = new URLSearchParams();
    data.set('confluence_aggregator_space_id', id);
    data.set('request_id', getRequestId().toString());
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.post<historyResponse>(
      environment.apiURL + 'RemoveConfluenceAggregatorSpace',
      data.toString(),
      options
    );
  }

  public edit_web_aggregator() {}

  public edit_facebook_aggregator(
    facebook_aggregator_id,
    enable_facebook_aggregator
  ) {
    const data = new URLSearchParams();
    data.set('facebook_aggregator_id', facebook_aggregator_id);
    data.set('enable_facebook_aggregator', enable_facebook_aggregator);
    data.set('request_id', getRequestId().toString());
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.post<historyResponse>(
      environment.apiURL + 'EditFacebookAggregator',
      data.toString(),
      options
    );
  }

  public edit_instagram_aggregator(
    instagram_aggregator_id,
    enable_instagram_aggregator
  ) {
    const data = new URLSearchParams();
    data.set('instagram_aggregator_id', instagram_aggregator_id);
    data.set('enable_instagram_aggregator', enable_instagram_aggregator);
    data.set('request_id', getRequestId().toString());
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.post<historyResponse>(
      environment.apiURL + 'EditInstagramAggregator',
      data.toString(),
      options
    );
  }

  public edit_reddit_aggregator(
    reddit_aggregator_id,
    enable_reddit_aggregator
  ) {
    const data = new URLSearchParams();
    data.set('reddit_aggregator_id', reddit_aggregator_id);
    data.set('enable_reddit_aggregator', enable_reddit_aggregator);
    data.set('request_id', getRequestId().toString());
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.post<historyResponse>(
      environment.apiURL + 'EditRedditAggregator',
      data.toString(),
      options
    );
  }

  public edit_twitter_aggregator(
    twitter_aggregator_id,
    enable_twitter_aggregator
  ) {
    const data = new URLSearchParams();
    data.set('twitter_aggregator_id', twitter_aggregator_id);
    data.set('enable_twitter_aggregator', enable_twitter_aggregator);
    data.set('request_id', getRequestId().toString());
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.post<historyResponse>(
      environment.apiURL + 'EditTwitterAggregator',
      data.toString(),
      options
    );
  }
  public edit_document_aggregator(
    document_aggregator_id,
    enable_document_aggregator
  ) {
    const data = new URLSearchParams();
    data.set('document_aggregator_id', document_aggregator_id);
    data.set('enable_document_aggregator', enable_document_aggregator);
    data.set('request_id', getRequestId().toString());
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.post<historyResponse>(
      environment.apiURL + 'EditDocumentAggregator',
      data.toString(),
      options
    );
  }

  public edit_youtube_aggregator(
    youtube_aggregator_id,
    enable_youtube_aggregator
  ) {
    const data = new URLSearchParams();
    data.set('youtube_aggregator_id', youtube_aggregator_id);
    data.set('enable_youtube_aggregator', enable_youtube_aggregator);
    data.set('request_id', getRequestId().toString());
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.post<historyResponse>(
      environment.apiURL + 'EditYouTubeAggregator',
      data.toString(),
      options
    );
  }

  public edit_confulance_aggregator(
    confulance_aggregator_id,
    enable_confulance_aggregator
  ) {
    const data = new URLSearchParams();
    data.set('confluence_aggregator_id', confulance_aggregator_id);
    data.set('enable_confluence_aggregator', enable_confulance_aggregator);
    data.set('request_id', getRequestId().toString());
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.post<historyResponse>(
      environment.apiURL + 'EditConfluenceAggregator',
      data.toString(),
      options
    );
  }

  public edit_fxNews_aggregator(
    fxNews_aggregator_id,
    enable_fxNews_aggregator
  ) {
    const data = new URLSearchParams();
    data.set('fxnews_aggregator_id', fxNews_aggregator_id);
    data.set('enable_fxnews_aggregator', enable_fxNews_aggregator);
    data.set('request_id', getRequestId().toString());
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.post<historyResponse>(
      environment.apiURL + 'EditFXNewsAggregator',
      data.toString(),
      options
    );
  }

  public edit_google_aggregator(
    google_aggregator_id,
    enable_google_aggregator,
    crawl_level,
    follow_external_links
  ) {
    const data = new URLSearchParams();
    data.set('google_aggregator_id', google_aggregator_id);
    data.set('enable_google_aggregator', enable_google_aggregator);
    data.set('crawl_level', crawl_level);
    data.set('follow_external_links', follow_external_links);

    data.set('request_id', getRequestId().toString());
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.post<historyResponse>(
      environment.apiURL + 'EditGoogleAggregator',
      data.toString(),
      options
    );
  }

  public edit_website_aggregator(
    website_aggregator_id,
    enable_website_aggregator,
    crawl_level,
    follow_external_links
  ) {
    const data = new URLSearchParams();
    data.set('website_aggregator_id', website_aggregator_id);
    data.set('enable_website_aggregator', enable_website_aggregator);
    data.set('crawl_level', crawl_level);
    data.set('follow_external_links', follow_external_links);

    data.set('request_id', getRequestId().toString());
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.post<historyResponse>(
      environment.apiURL + 'EditWebsiteAggregator',
      data.toString(),
      options
    );
  }

  removeAggregatorSearchResult(searchResultId: number): Observable<any> {
    const data = new URLSearchParams();
    data.set('request_id', '' + getRequestId());
    data.set('search_result_id', searchResultId + '');
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.post(
      environment.apiURL + 'RemoveAggregatorSearchResult',
      data.toString(),
      options
    );
  }

  setSearchResultFlagged(
    searchResultId: number,
    flagged: boolean
  ): Observable<any> {
    const data = new URLSearchParams();
    data.set('request_id', '' + getRequestId());
    data.set('search_result_id', searchResultId + '');
    data.set('flagged', flagged + '');
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.post(
      environment.apiURL + 'SetSearchResultFlagged',
      data.toString(),
      options
    );
  }

  addSearchResultComment(
    searchResultId: number,
    comment: string
  ): Observable<any> {
    const data = new URLSearchParams();
    data.set('request_id', '' + getRequestId());
    data.set('search_result_id', searchResultId + '');
    data.set('search_result_comment', comment);
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.post(
      environment.apiURL + 'AddSearchResultComment',
      data.toString(),
      options
    );
  }

  fetchSearchResultConverse(searchResultId: number): Observable<any> {
    const data = new URLSearchParams();
    data.set('request_id', '' + getRequestId());
    data.set('search_result_id', '' + searchResultId);
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.get(
      environment.apiURL +
        'GetSearchResultConverse?request_id=' +
        getRequestId() +
        '&search_result_id=' +
        searchResultId,
      options
    );
  }

  fetchSearchResultComments(
    searchResultId: number,
    offset: number,
    limit: number
  ): Observable<any> {
    const data = new URLSearchParams();
    data.set('request_id', '' + getRequestId());
    data.set('search_result_id', '' + searchResultId);
    data.set('offset', '' + offset);
    data.set('limit', '' + limit);
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.get(
      environment.apiURL +
        'SearchResultComments?request_id=' +
        getRequestId() +
        '&search_result_id=' +
        searchResultId +
        '&offset=' +
        offset +
        '&limit=' +
        limit,
      options
    );
  }

  removeSearchResultComment(searchResultCommentId): Observable<any> {
    const data = new URLSearchParams();
    data.set('request_id', '' + getRequestId());
    data.set('search_result_comment_id', searchResultCommentId);
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.post(
      environment.apiURL + 'RemoveSearchResultComment' + '?' + data.toString(),
      options
    );
  }
}
