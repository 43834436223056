export const infographColors = [
  ['#ec7676', '#733a3a'],
  ['#eeab77', '#79573c'],
  ['#eac976', '#6e5f37'],
  ['#ebef78', '#686933'],
  ['#e5c494', '#887558'],
  ['#a1e372', '#55773c'],
  ['#74e791', '#3c7a4c'],
  ['#75e9c8', '#3c7767'],
  ['#72bde5', '#365a6d'],
  ['#7697ea', '#37496e'],
  ['#9078ef', '#4b3d7a'],
  ['#e277ee', '#6c3973'],
  ['#db8ca5', '#734a58'],
  ['#e54a4a', '#832828'],
  ['#bab0ab', '#5f5956'],
  ['#b5c6cf', '#50565b'],
  ['#1f78b4', '#165a86'],
  ['#b2df8a', '#566d44'],
  ['#ae4949', '#5f2929'],
  ['#9663ae', '#4a3258'],
  ['#69688c', '#3f3e54'],
  ['#719994', '#445b58'],
  ['#66b58e', '#3b6751'],
  ['#44c16b', '#246b3a'],
  ['#7d8868', '#4d5440'],
  ['#867869', '#544c42'],
  ['#8f6b6b', '#543f3f'],
   ['#9078ef', '#4b3d7a'],
  ['#e277ee', '#6c3973'],
  ['#db8ca5', '#734a58'],
  ['#e54a4a', '#832828'],
  ['#bab0ab', '#5f5956'],
  ['#b5c6cf', '#50565b'],
  ['#1f78b4', '#165a86'],
  ['#b2df8a', '#566d44'],
  ['#ae4949', '#5f2929'],
  ['#9663ae', '#4a3258'],
  ['#69688c', '#3f3e54'],
  ['#719994', '#445b58'],
  ['#66b58e', '#3b6751'],
  ['#44c16b', '#246b3a'],
  ['#7d8868', '#4d5440'],
  ['#867869', '#544c42'],
  ['#8f6b6b', '#543f3f']
];
