<div *ngIf="alertMessage" class="inprogress" role="alert">
  <div class="inprogress-wrap">
    <div class="ico-inprogress">
      <span role="img" aria-label="loading" class="anticon anticon-loading anticon-spin"><svg viewBox="0 0 1024 1024"
          focusable="false" data-icon="loading" width="1em" height="1em" fill="#555" aria-hidden="true">
          <path
            d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z">
          </path>
        </svg></span>
    </div>
    <p>{{ alertMessage }}</p>
  </div>

</div>
<div *ngIf="!alertMessage" class="d-flex infograph-container flex-column align-items-stretch">
  <div style=" background-color: white; " class="pt-3 pb-3">

    <div class="d-flex mt-2 flex-row justify-content-center align-items-center">
      <!--    <form class="d-flex" [formGroup]="searchForm" (ngSubmit)="onSearchSubmit(searchForm.value)"> -->


      <!--<mat-form-field class="col-12 p-0" appearance="outline">
        <mat-label>Search</mat-label>
        <input matInput placeholder="Placeholder" formControlName="searchTerm">
        <button type="button" mat-button matSuffix mat-icon-button aria-label="Clear"
                *ngIf="searchForm.controls['searchTerm'].value"
                (click)="searchForm.controls['searchTerm'].setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>


      <mat-form-field class="pt-0 pb-0 col-10 "  appearance="outline">
        <mat-label>Search</mat-label> -->

      <div class="inputSearch mt-1 p-1">
        <mat-chip-list #chipList aria-label="Query selection">
          <!--  <mat-chip
              *ngFor="let query of this.infoGraphManager.query"
              [selectable]="selectable"
              [removable]="removable"

              (removed)="buildQuery(query)">
              {{query.name}}
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip> -->
          <i class="fa fa-search  gray mt-1 ml-2" aria-hidden="true"></i>

          <div *ngFor="let query of this.infoGraphManager.query" class="d-flex">
            <div *ngIf="query.type == 'label'" class="roundedbutton mt-1 ml-2 pt-2"
              [style.backgroundColor]="colorScale[query.name]?.color" [style.color]="colorScale[query.name]?.textColor">
              <span class="ml-1">{{query.name}}</span>
              <button class="ml-2 closebutton " (click)="buildQuery(query,false)">
                <i class="fas fa-times"></i>
              </button>
            </div>

            <div *ngIf="query.type == 'label-name'" class="recbutton mt-1 ml-2 pt-2" [style.backgroundColor]="lightblue"
              [style.color]="black">
              <span class="ml-1">{{query.name}}</span>
              <button class="ml-2 closebutton " (click)="buildQuery(query,false)">
                <i class="fas fa-times"></i>
              </button>
            </div>

            <div *ngIf="query.type != 'label' && query.type != 'label-name'"
              [style.background]="query.type == 'cluster' ? '#c3f5ed' : '#e1e1e6'" class="searchItem mt-1 ml-2 pt-1">
              <span class="ml-1">{{query.name}}</span>
              <button class="ml-2 closebutton" (click)="buildQuery(query,false)">
                <i class="fa fa-times" aria-hidden="true"></i>
              </button>
            </div>

          </div>

          <input class="b-0 mt-2" placeholder="Search" #queryInput [formControl]="queryCtrl" [matAutocomplete]="auto"
            [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="onSearchSubmit($event)">
        </mat-chip-list>
        <mat-autocomplete #auto="matAutocomplete">

          <mat-option *ngFor="let query of this.filteredSearch | async " (click)="buildQuery(query)">
            <div *ngIf="(query | isExist)">
              <div *ngIf="query.type == 'label'" class="roundedbutton mt-1 mr-1 ml-1 pt-2"
                [style.backgroundColor]="colorScale && colorScale[query.name] ? colorScale[query.name]?.color : '#FFF'"
                [style.color]="colorScale && colorScale[query.name] ? colorScale[query.name]?.textColor: '#FFF'">
                <span class="ml-1">{{query.name}}</span>

              </div>

              <div *ngIf="query.type != 'label'" [style.background]="query.type == 'cluster' ? '#c3f5ed' : '#e1e1e6'"
                class="searchItem mt-1 ml-1 mr-1  pt-1">
                <span class="ml-1">{{query.name}}</span>

              </div>

            </div>


          </mat-option>
        </mat-autocomplete>

      </div>
      <button *ngIf="!demoService.demoMode" type="button" class="searchButton mt-1" (click)="ShowAddPopup()">
        <i class="fas fa-star"></i>
      </button>


      <button type="submit" class="button primary-color mt-1  ml-2 br-8">
        <i class="fas fa-check"></i>
      </button>

      <button type="button" class="button secondary-color mt-1  ml-2 br-8" [ngClass]="{'not-allowed': isBrowse}"
        [disabled]="isBrowse" (click)="browse()">Browse</button>


      <ul class="no-deft-style  filter-list ml-5 mt-2">

        <li [ngClass]="{ active: this.infoGraphManager.condition === 'OR' }"><a (click)="UpdateCondition('OR')">OR</a>
        </li>
        <li [ngClass]="{ active: this.infoGraphManager.condition === 'AND' }"><a
            (click)="UpdateCondition('AND')">AND</a></li>
      </ul>

    </div>
  </div>

  <div style="width:100%;  background:white; padding: 8px;" class=" d-none">
    <h3 class="mt-2 ml-4" *ngIf="this.infoGraphManager.query.length >0">Selected</h3>
    <div class="ml-3 d-flex" *ngIf="!this.searchTerm">
      <div *ngFor="let query of this.infoGraphManager.query" class="d-flex">
        <div *ngIf="query.type == 'label'" class="roundedbutton mt-1 ml-2 pt-2">
          <span class="ml-1">{{query.name}}</span>
          <button class="ml-2 closebutton whitecolor" [style.backgroundColor]="colorScale[query.name]?.color"
            [style.color]="colorScale[query.name]?.textColor" (click)="buildQuery(query,true)">
            <i class="fas fa-times"></i>
          </button>
        </div>

        <div *ngIf="query.type != 'label'" class="searchItem mt-1 ml-2 pt-1">
          <span class="ml-1">{{query.name}}</span>
          <button class="ml-2 closebutton" (click)="buildQuery(query)">
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>

      </div>

    </div>

    <div *ngIf="this.searchTerm">
      <h3 class="mt-2 ml-4"> {{this.searchTerm}} </h3>
    </div>
  </div>

  <div style="position:relative;" class="flex-fill">
    <div *ngIf="this.isBrowse" class="pl-4 pr-4 infograph-left-container theme-shadow-vertical-low-tail">

      <div class="d-flex">
        <div class="mt-3">


          <button class="filter" (click)="selectEntity()" [ngClass]="{'active': this.isEntitySelected}">
            Entities
          </button>
          <div *ngIf="this.isEntitySelected" class="rectangle"></div>
        </div>
        <div class="ml-4  mt-3">
          <button class="filter " (click)="selectRelationship()"
            [ngClass]="{'active': this.isRelationshipSelected}">Relationships
          </button>
          <div *ngIf="this.isRelationshipSelected" class="rectangle"></div>
        </div>

        <div class="ml-4  mt-3">
          <button *ngIf="!demoService.demoMode" class="filter " (click)="selectStarred()"
            [ngClass]="{'active': this.isStarredSelected}">Starred
          </button>
          <div *ngIf="this.isStarredSelected" class="rectangle"></div>
        </div>

        <button class="ml-2 closebutton" style="position: absolute; right: 0px; top: 1px;" (click)="closeBrowse()"><i
            class="fas fa-times"></i></button>
      </div>
      <hr style="margin-top: 0px;">
      <div class="mt-1">

        <div *ngIf="this.isEntitySelected">
          <div>
            <button *ngFor="let cfn of this.infoGraphManager.classifications" class="roundedbutton ml-2 mt-2"
              [style.backgroundColor]="colorScale[cfn.name].color" [style.color]="colorScale[cfn.name].textColor"
              (click)="buildQuery(cfn)"> {{cfn.name}} ({{ cfn.nodeCount || '0' }}, {{ cfn.relationCount || '0' }})
              <div *ngIf="cfn.isSelected" class="ellipse"></div>
            </button>
          </div>
        </div>

        <div *ngIf="this.isRelationshipSelected">

          <mat-form-field class=" col-12 p-0" appearance="outline">
            <mat-label>Filter</mat-label>
            <input #myInput placeholder="Item name..." (input)="filterItem(myInput.value)" matInput>
            <!--<button mat-button matSuffix mat-icon-button aria-label="Clear">
              <mat-icon>close</mat-icon>
            </button> -->
          </mat-form-field>

          <h3 class="mt-1">Clusters</h3>
          <hr>

          <div>
            <button *ngFor="let clr of this.infoGraphManager.clusters" class="searchItem ml-2 mt-1"
              (click)="buildQuery(clr)"> {{clr.name}}
              <div *ngIf="clr.isSelected" class="ellipse"></div>
            </button>

          </div>

          <div style="position: relative;">
            <h3 class="mt-3">Relationships</h3>
            <mat-paginator #paginator style="    position: absolute;
         background: transparent;
         top: -30px;
         right: -12px;" [length]="totalRelations" (page)="changePagesize($event)" showFirstLastButtons></mat-paginator>
          </div>
          <hr>
          <div>
            <button
              *ngFor="let rel of this.filteredRelationships() | slice: (curPage * pageSize) - pageSize :curPage * pageSize"
              class="searchItem ml-2 mt-1" (click)="buildQuery(rel)">{{rel.name}}
              <div *ngIf="rel.isSelected" class="ellipse"></div>
            </button>


          </div>
        </div>

        <div *ngIf="this.isStarredSelected">

          <div *ngFor="let qy of this.infoGraphManager.starredQueries" class="Rectangle-22"
            (click)="selectStarredQuery(qy)">
            <span class="queryheader">{{qy.query.name}}</span>
            <button class="transparent float-right"><i class="fas fa-trash"
                (click)="ConfirmDeleteFav($event,qy.id)"></i></button>
            <button class="transparent float-right"><i class="fas fa-edit" (click)="EditFav($event,qy)"></i></button>

            <button class="transparent float-right" [disabled]="qy.isEnabled" (click)="AddtoDashboard($event,qy)"><i
                class="fas fa-plus"></i></button>

            <div class=" clearfix clear" style="margin-left: -5px;">
              <div *ngFor="let query of qy.query.query" class="queryitem">
                <div *ngIf="query.type == 'label'" class="roundedbutton mt-1 ml-2 pt-2"
                  [style.backgroundColor]="colorScale[query.name].color"
                  [style.color]="colorScale[query.name].textColor">
                  <span class="ml-1">{{query.name}}</span>

                </div>

                <div *ngIf="query.type != 'label'" [style.background]="query.type == 'cluster' ? '#c3f5ed' : '#e1e1e6'"
                  class="searchItem mt-1 ml-2 pt-1">
                  <span class="ml-1">{{query.name}}</span>

                </div>

              </div>
            </div>

          </div>

        </div>

      </div>

    </div>

    <div id="viz" class="w-100 h-100">
      <lib-neo-graph *ngIf="showQuery" (canvasMouseClick)="canvasMouseClick($event)"
        (canvasMouseOver)="canvasMouseOver($event)" (nodeMouseClick)="nodeMouseClick($event)"
        (nodeMouseOver)="nodeMouseOver($event)" (nodeMouseDoubleClick)="nodeMouseDoubleClick($event)"
        (edgeMouseClick)="edgeMouseClick($event)" (edgeMouseOver)="edgeMouseOver($event)"
        (nodesAndRelationshipsUpdate)="nodesAndRelationshipsUpdate($event)" [scaleMap]="colorScale"
        [cipherQuery]="showQuery" [cipherServerURL]="'bolt+s://' + driverURL" [cipherServerUsername]="serverUsername"
        [cipherServerPassword]="serverPassword" [cipherDatabase]="serverDatabase">
        <div class="text-center chart-visualization-full-size d-flex justify-content-center align-items-center">
          <app-loading-component></app-loading-component>
        </div>
      </lib-neo-graph>
    </div>

    <div *ngIf="showNode || showEdge" class="pl-4 pr-4 infograph-right-container theme-shadow-vertical-left-low-tail">

      <button class="ml-2 closebutton" style="position: absolute; right: 10px; top: 20px;"
        (click)="showNode = null; showEdge = null;"><i class="fas fa-times"></i></button>

      <div *ngIf="showNode">
        <h3 class="mt-2"><b>Node Details</b></h3>
        <hr>
        <table class="table table-borderless table-sm">
          <tbody>
            <tr *ngIf="showNode.labels">
              <th scope="row">Classification</th>
              <td>{{ showNode?.labels[0] }}</td>
            </tr>
            <tr *ngFor="let property of filterNodeProperties(showNode?.properties)">
              <th scope="row">{{ property?.key | titlecase }}</th>
              <td>{{ property?.value }}</td>
            </tr>
          </tbody>
        </table>

        <div *ngIf="showNode && showNode.relations">
          <hr>
          <h3 class="mt-3"><b>Relations</b></h3>
          <hr>
          <table class="table table-borderless table-sm">
            <tbody>
              <tr *ngFor="let relation of showNode.relations">
                <th scope="row">{{ relation?.leftNode?.properties.name }}
                  <small>({{ relation?.leftNode?.labels[0] }})</small>
                </th>
                <td>
                  <i class="fa fa-long-arrow-alt-left" *ngIf="!relation.isRight"></i>
                </td>
                <td class="text-center d-flex justify-content-center align-items-center">
                  <small>
                    {{ relation?.relationship.type }}
                  </small>
                </td>
                <td>
                  <i class="fa fa-long-arrow-alt-right" *ngIf="relation.isRight"></i>
                </td>
                <th scope="row" class="text-right">{{ relation?.rightNode?.properties?.name }}
                  <small>({{ relation?.rightNode?.labels[0] }})</small>
                </th>
              </tr>
            </tbody>
          </table>
        </div>

      </div>

      <div *ngIf="showEdge">
        <div class="d-flex justify-content-between">
          <h3 class="mt-2"><b>Relationship Details</b></h3>
          <div *ngIf="showEdge.infosiphon_id">
            <button (click)="getAndOpenSource(showEdge?.infosiphon_id)" class="btn btn-primary mr-3">Go to
              source</button>
          </div>
        </div>

        <hr>
        <table class="table table-borderless table-sm">
          <tbody>
            <tr *ngFor="let property of filterEdgeProperties(showEdge.properties)">
              <th scope="row">{{ property?.key | titlecase }}</th>
              <td>{{ property?.value }}</td>
            </tr>
          </tbody>
        </table>

        <div *ngIf="showEdge && showEdge.relations">
          <hr>
          <h3 class="mt-3"><b>Nodes</b></h3>
          <hr>
          <table class="table table-borderless table-sm">
            <tbody>
              <tr *ngFor="let relation of showEdge.relations">
                <th scope="row">{{ relation?.startingNode?.properties?.name }}
                  <small>({{ relation?.startingNode?.labels[0] }})</small>
                </th>
                <td>
                  <i class="fa fa-long-arrow-alt-left" *ngIf="false"></i>
                </td>
                <td class="text-center d-flex justify-content-center align-items-center">
                  <small>
                    {{ relation?.relationship?.type }}
                  </small>
                </td>
                <td>
                  <i class="fa fa-long-arrow-alt-right" *ngIf="true"></i>
                </td>
                <th scope="row" class="text-right">{{ relation?.endingNode?.properties.name }}
                  <small>({{ relation?.endingNode?.labels[0] }})</small>
                </th>
              </tr>
            </tbody>
          </table>
        </div>

        <hr>



        <div *ngIf="showEdge.documentText">
          <h4><b>CONTEXT</b></h4>
          <p>{{ showEdge.documentText }}</p>
        </div>

      </div>

    </div>

    <div *ngIf="queryResults?.nodes && queryResults?.relationships"
      class="pl-4 pr-4 d-flex flex-row align-items-center theme-shadow-all-sides"
      [class.infograph-bottom-container]="(showNode || showEdge)"
      [class.infograph-bottom-container-expanded]="!(showNode || showEdge)">

      <ng-container *ngIf="!(hoverNode || hoverEdge)">
        <small>
          <b>Showing</b> {{ queryResults?.nodes.length }}
          <b>Nodes</b> and {{ queryResults?.relationships.length }}
          <b>Relationships</b>
        </small>
      </ng-container>

      <ng-container *ngIf="hoverNode && hoverNode.properties">
        <small class="roundedbutton mr-3" [style.backgroundColor]="colorScale[hoverNode?.labels[0]].color"
          [style.color]="colorScale[hoverNode?.labels[0]].textColor">
          {{ hoverNode?.labels[0] }}
        </small>
        <small *ngFor="let property of filterNodeProperties(hoverNode?.properties)" class="mr-3 hide-extra">
          <b>{{ property?.key | titlecase }}: </b> {{ truncate(property?.value, 150) }}
        </small>
      </ng-container>

      <ng-container *ngIf="hoverEdge && hoverEdge.properties">
        <small *ngFor="let property of filterEdgeProperties(hoverEdge.properties)" class="mr-3">
          <b>{{ property?.key | titlecase }}: </b> {{ truncate(property.value, 150) }}
        </small>
      </ng-container>

    </div>

  </div>

</div>

<div *ngIf="showAddPopup"
  style="position: absolute; top:0px; left:0px; background-color: #0303039c;z-index: 99999; width: 100%; height: 100%;">
  <div style="    background-color: white;
  display: inline-block;
  width: 30%;
  top: 30%;
  LEFT: 36%;
  POSITION: absolute;">
    <form name="myForm" [formGroup]="AddFavForm" (submit)="AddFav()">
      <div class="modal-header">
        <h2 class="modal-title">Starred Result Name</h2>
        <button type="button" class="close" aria-label="Close" (click)="closeInvite()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <small *ngIf="!name.valid && name.dirty" class="form-text text-danger">Please enter name.</small>


        <input type="email" class="form-control" (keypress)="omit_special_char($event)" placeholder="Please enter  name"
          formControlName="name" [ngClass]="{
      'border-danger': !name.valid && name.dirty,
      'border-success': name.valid && name.dirty
     }">


      </div>
      <div class="modal-footer">

        <button type="submit" [disabled]="name.invalid" class="btn primary-color white-font">Save</button>

        <button type="button" class="btn  secondary-color white-font" (click)="closeInvite()">Cancel</button>
      </div>
    </form>
  </div>
</div>

<app-csv-table-viewer></app-csv-table-viewer>