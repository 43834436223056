import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { getRequestId } from './utils';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class VisualizationService {
  constructor(private http: HttpClient) {}

  setDocumentFlagged(
    searchResultId: number,
    flagged: boolean
  ): Observable<any> {
    const data = new URLSearchParams();
    data.set('request_id', '' + getRequestId().toString());
    data.set('search_result_id', searchResultId + '');
    data.set('flagged', flagged + '');
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.post(
      environment.apiURL + 'SetSearchResultFlagged',
      data.toString(),
      options
    );
  }

  fetchAggregatorDocumentText(id): Observable<any> {
    const headers = new HttpHeaders();
    const options = { withCredentials: true, headers };
    return this.http.get(
      environment.apiURL +
        'AggregatorSearchResultText?request_id=' +
        getRequestId().toString() +
        '&search_result_id=' +
        id,
      options
    );
  }

  fetchAggregatorDocumentLink(id): Observable<any> {
    const headers = new HttpHeaders();
    const options = { withCredentials: true, headers };
    return this.http.get(
      environment.apiURL +
        'AggregatorSearchResultLink?request_id=' +
        getRequestId().toString() +
        '&search_result_id=' +
        id,
      options
    );
  }

  AddDashboardItem(aggid, viz_id, viz_cat_id): Observable<any> {
    const headers = new HttpHeaders();
    const options = { withCredentials: true, headers };
    return this.http.post(
      environment.apiURL +
        'AddDashboardItem?request_id=' +
        getRequestId().toString() +
        '&aggregator_id=' +
        aggid +
        '&viz_uid=' +
        viz_id +
        '&viz_cat_id=' +
        viz_cat_id,
      options
    );
  }

  GetSupportedViz(): Observable<any> {
    const headers = new HttpHeaders();
    const options = { withCredentials: true, headers };
    return this.http.post(
      environment.apiURL +
        'SupportedVizs?request_id=' +
        getRequestId().toString(),
      options
    );
  }
  GetDashboardItems(aggid): Observable<any> {
    const headers = new HttpHeaders();
    const options = { withCredentials: true, headers };
    return this.http.post(
      environment.apiURL +
        'GetDashboardItems?request_id=' +
        getRequestId().toString() +
        '&aggregator_id=' +
        aggid,
      options
    );
  }

  RemoveDashboardItem(dashboard_item_id): Observable<any> {
    const headers = new HttpHeaders();
    const options = { withCredentials: true, headers };
    return this.http.post(
      environment.apiURL +
        'RemoveDashboardItem?request_id=' +
        getRequestId().toString() +
        '&dashboard_item_id=' +
        dashboard_item_id,
      options
    );
  }

  fetchAggregatorCSVDocument(aggregatorId, searchResultLink): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.get(
      environment.apiURL +
        'AggregatorSearchResultCSVRecord?request_id=' +
        getRequestId().toString() +
        '&aggregator_id=' +
        aggregatorId +
        '&search_result_link=' +
        searchResultLink,
      options
    );
  }

  fetchAggregatorVisualizationData(id): Observable<any> {
    const headers = new HttpHeaders();
    const options = { withCredentials: true, headers };
    return this.http.get(
      environment.apiURL +
        'AggregatorVisualizationData?request_id=' +
        getRequestId().toString() +
        '&aggregator_id=' +
        id,
      options
    );
  }

  fetchAggregatorVisualizationInfo(aggregatorId): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    });
    const options = { withCredentials: true, headers };
    return this.http.get(
      environment.apiURL +
        'AggregatorVisualizationInfo?request_id=' +
        getRequestId().toString() +
        '&aggregator_id=' +
        aggregatorId,
      options
    );
  }

  fetchAggregatorSentimentScore(aggregatorId): Observable<any>[] {
    const headers = new HttpHeaders();
    const options = { withCredentials: true, headers };
    return [
      this.http.get(
        `${
          environment.apiURL
        }AggregatorSentimentScoreData?request_id=${getRequestId().toString()}&opt=sentiment_type_record_counts&aggregator_id=${aggregatorId}&limit=100`,
        options
      ),

      this.http.get(
        `${
          environment.apiURL
        }AggregatorSentimentScoreData?request_id=${getRequestId().toString()}&opt=sentiment_value_record_counts&aggregator_id=${aggregatorId}&limit=100`,
        options
      ),

      this.http.get(
        `${
          environment.apiURL
        }AggregatorSentimentScoreData?request_id=${getRequestId().toString()}&opt=execution_average_scores&aggregator_id=${aggregatorId}&limit=100`,
        options
      ),

      this.http.get(
        `${
          environment.apiURL
        }AggregatorSentimentScoreData?request_id=${getRequestId().toString()}&opt=execution_average_smoothed_scores&aggregator_id=${aggregatorId}&limit=100`,
        options
      ),

      this.http.get(
        `${
          environment.apiURL
        }AggregatorSourceDailyHistory?request_id=${getRequestId().toString()}&aggregator_id=${aggregatorId}`,
        options
      ),

      this.http.get(
        `${
          environment.apiURL
        }AggregatorSentimentHistory?request_id=${getRequestId().toString()}&aggregator_id=${aggregatorId}`,
        options
      ),
    ];
  }

  public GetTestCVPipeLine() {
    //return this.http.get("assets/cvPipeline.json");
  }

  public GetTestTextonomy(aggid) {
    const headers = new HttpHeaders();
    const options = { withCredentials: true, headers };
    return this.http.get(
      environment.apiURL +
        'GetAggregatorImagesTaxonomy?request_id=' +
        getRequestId().toString() +
        '&aggregator_id=' +
        aggid,
      options
    );
  }

  public GetGeneralImages() {
    return this.http.get('assets/general_Image_detection.json');
  }

  getIgnoreListFile() {
    return this.http.get('assets/englishstopwords.txt', {
      responseType: 'text',
    });
  }

  getImageAnalytics(
    aggId,
    faceDetection: boolean,
    textAnalysis: boolean,
    gender?: string,
    min_age?: number,
    max_age?: number,
    emotion?: string,
    object_detection?: boolean,
    image_classification?: boolean,
    class_id?: number
  ) {
    const data = new URLSearchParams();
    data.set('request_id', '' + getRequestId());
    data.set('aggregator_id', aggId);
    data.set('text_recognition', textAnalysis.toString());
    data.set('face_detection', faceDetection.toString());
    if (gender) {
      data.set('gender', gender.toLowerCase());
    }

    if (min_age) {
      data.set('min_age', min_age.toString());
    }

    if (max_age) {
      data.set('max_age', max_age.toString());
    }

    if (emotion) {
      data.set('emotions', emotion.toLowerCase());
    }

    if (object_detection) {
      data.set('object_detection', object_detection.toString());
    }
    if (image_classification) {
      data.set('image_classification', image_classification.toString());
    }
    if (class_id) {
      data.set('class_id', class_id.toString());
    }

    const headers = new HttpHeaders();
    const options = { withCredentials: true, headers };
    return this.http.get(
      environment.apiURL + 'GetAggregatorImagesAnalytics?' + data.toString(),
      options
    );
  }
}
