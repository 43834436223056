<div  class="flip-box"   >
    <div class="flip-box-inner">
      <div class="flip-box-front">
      
        <img src="{{this.imgUrl}}" (load)="onload()" loading="lazy"  style="width:250px; height: 250px;"  >
      </div>
      <div class="flip-box-back">
        <div *ngFor="let t of this.tooltip">
          <span  >{{t.text}}</span>
     </div>
      </div>
    </div>
    <div class="spinner" >
      <mat-spinner
     
      *ngIf="loading"
     
       >
    </mat-spinner>
    </div> 
  </div>
