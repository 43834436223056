import { Injectable } from '@angular/core';
import { VisualizationManagerService } from './visualization-manager.service';
import { DashboardManagerService } from './dashboard-manager.service';
import { InfoGraphManagerService } from './info-graph-manager.service';

@Injectable({
  providedIn: 'root'
})
export class DataMangerService {

  constructor(private visualManager:VisualizationManagerService,private dashboardManager:DashboardManagerService,private infoGraphManager:InfoGraphManagerService) { }


  fetchSelectedAggregatorData(agg_id)
  { 
    this.infoGraphManager.getFavList(agg_id);
    this.visualManager.assertVisualizationData(agg_id).then();
    this.visualManager.assertSentimentsData(agg_id).then();
    this.dashboardManager.fetchDashboard(agg_id);

    setTimeout(()=>{
this.dashboardManager.dashboard_items.forEach(c=>{

  this.infoGraphManager.starredQueries.forEach(query=>{

    if(c.viz_uid == query.id.toString())
    {

      query.isEnabled = true;

    }
  })

})

    },1000);
   
  }

  resetSelectedAggregator()
  {
    this.visualManager.reset(true);
  }
}
