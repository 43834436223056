<div class="row-custom">
  <div class="col-6">
    <div class="card">
      <h3>Sources</h3>
      <mat-checkbox [checked]="this.aggManager.aggregatorSetting?.twitter_aggregator?.enabled"
        (change)="changeTwitter($event.checked)">Twitter</mat-checkbox>
      <mat-checkbox class="ml-2 d-none" [checked]="this.aggManager.aggregatorSetting?.facebook_aggregator?.enabled"
        (change)="changeFacebook($event.checked)">Instagram
      </mat-checkbox>
      <mat-checkbox class="ml-2" [checked]="this.aggManager.aggregatorSetting?.reddit_aggregator?.enabled"
        (change)="changeReddit($event.checked)">Reddit
      </mat-checkbox>
      <mat-checkbox class="ml-2" [checked]="this.aggManager.aggregatorSetting?.document_aggregator?.enabled"
        (change)="changeDocument($event.checked)">Document
      </mat-checkbox>
      <mat-checkbox class="ml-2" [checked]="this.aggManager.aggregatorSetting?.google_aggregator?.enabled"
        (change)="changeGoogle($event.checked)">Google
      </mat-checkbox>
      <mat-checkbox class="ml-2" [checked]="this.aggManager.aggregatorSetting?.website_aggregator?.enabled"
        (change)="changeWebsite($event.checked)">Website
      </mat-checkbox>
      <mat-checkbox class="ml-2" [checked]="this.aggManager.aggregatorSetting?.youtube_aggregator?.enabled"
        (change)="changeYoutube($event.checked)">Youtube
      </mat-checkbox>
      <!-- <mat-checkbox class="ml-2" [checked]="this.aggManager.aggregatorSetting?.confluence_aggregator?.enabled"
        (change)="changeConfulance($event.checked)">Confluence
      </mat-checkbox> -->
      <mat-checkbox class="ml-2" *ngIf="this.aggManager.aggregatorSetting?.fxnews_aggregator"
        [checked]="this.aggManager.aggregatorSetting?.fxnews_aggregator?.enabled"
        (change)="changeFxNews($event.checked)">Fx News
      </mat-checkbox>

    </div>

    <div class="card">
      <form [formGroup]="registerForm" (ngSubmit)="onSubmit(registerForm.value)" class="form">
        <mat-form-field style="width: 368px;" appearance="outline">
          <mat-label>Aggregator Name</mat-label>
          <input matInput placeholder="Placeholder" formControlName="name" readonly>
          <!-- <button mat-button matSuffix mat-icon-button aria-label="Clear" *ngIf="registerForm.controls['name'].value"
            (click)="registerForm.controls['name'].setValue('')">
            <mat-icon>close</mat-icon>
          </button> -->
        </mat-form-field>
        <span class="p1 mt-2 mb-1 d-block d-none"></span>
        <mat-form-field appearance="outline" style="width: 368px;">
          <mat-label>Schedule For</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="date">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <span class="p1 mt-2  d-block">Schedule Type</span>
        <mat-radio-group class="d-block mt-1" formControlName="type">
          <!--  <mat-radio-button value="Once">Once</mat-radio-button>
          <mat-radio-button class="radio-margin " value="Daily">Daily</mat-radio-button>
          <mat-radio-button class="radio-margin" value="Weekly">Weekly</mat-radio-button>-->
          <mat-radio-button class="radio-margin " *ngFor="let st of this.aggManager.schedule_types"
            value="{{st.schedule_type}}">{{st.schedule_type}}</mat-radio-button>
        </mat-radio-group>
        <div class="mt-3 d-block">
          <button type="submit" [disabled]="!registerForm.dirty"
            class="button primary-color br-8 font-size14">Save</button>
          <button type="button" [disabled]="!registerForm.dirty" class="button secondary-color br-8 font-size14 ml-4"
            (click)="Cancel()">Cancel</button>
        </div>
      </form>
    </div>
    <div class="card mt-2">
      <h3>Search Terms</h3>
      <span class="p1 mt-2 mb-1  d-block "></span>
      <form [formGroup]="searchForm" (ngSubmit)="onSearchSubmit(searchForm.value)" class="form">
        <div class="d-flex">
          <mat-form-field class="col-6 p-0" appearance="outline">
            <mat-label>Enter Term</mat-label>
            <input matInput placeholder="Placeholder" formControlName="searchTerm">
            <button mat-button matSuffix mat-icon-button aria-label="Clear"
              *ngIf="searchForm.controls['searchTerm'].value" (click)="searchForm.controls['searchTerm'].setValue('')">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <div class="col-6">
            <!-- <mat-form-field class="col-5" appearance="outline">
              <mat-label> Type</mat-label>
              <mat-select formControlName="type">
                <mat-option value="Text">Text</mat-option>
              </mat-select>
            </mat-form-field> -->
            <button type="submit" style="  vertical-align: super"
              class="button primary-color br-8 font-size14 ml-3 p-2">
              <svg viewBox="0 0 515.556 515.556" width="30" height="32">
                <path fill="white"
                  d="m0 274.226 176.549 176.886 339.007-338.672-48.67-47.997-290.337 290-128.553-128.552z" />
              </svg>
            </button>
          </div>
        </div>
      </form>
      <mat-list class="pr-4">
        <mat-list-item class="bdr-divider" *ngFor="let st of this.aggManager.searchterms"> {{st.searchTerm}} <button
            class="postion-right" (click)="removeSearch(st)" mat-button matSuffix mat-icon-button aria-label="Clear">
            <mat-icon>close</mat-icon>
          </button></mat-list-item>
      </mat-list>
    </div>
    <div class="card mt-2">
      <h3>Exclude Filters</h3>
      <span class="p1 mt-2 mb-1  d-block "></span>
      <form [formGroup]="filterForm" (ngSubmit)="onFilterSubmit(filterForm.value)" class="form">
        <div class="d-flex">
          <mat-form-field class="col-6 p-0" appearance="outline">
            <mat-label>Enter Filter</mat-label>
            <input matInput placeholder="Placeholder" formControlName="filterTerm">
            <button mat-button matSuffix mat-icon-button aria-label="Clear"
              *ngIf="filterForm.controls['filterTerm'].value" (click)="filterForm.controls['filterTerm'].setValue('')">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <div class="col-6">
            <!-- <mat-form-field class="col-5" appearance="outline">
              <mat-label> Type</mat-label>
              <mat-select formControlName="type">
                <mat-option value="Text">Text</mat-option>
              </mat-select>
            </mat-form-field> -->

            <button type="submit" style="  vertical-align: super"
              class="button primary-color br-8 font-size14 ml-3 p-2">
              <svg viewBox="0 0 515.556 515.556" width="30" height="32">
                <path fill="white"
                  d="m0 274.226 176.549 176.886 339.007-338.672-48.67-47.997-290.337 290-128.553-128.552z" />
              </svg>

            </button>
          </div>
        </div>
      </form>
      <mat-list class="pr-4">
        <mat-list-item class="bdr-divider" *ngFor="let st of this.aggManager.filters"> {{st.excludeFilter}} <button
            class="postion-right" (click)="confirmFilterDialog(st)" mat-button matSuffix mat-icon-button
            aria-label="Clear">
            <mat-icon>close</mat-icon>
          </button></mat-list-item>

      </mat-list>

    </div>
  </div>
  <div class="col-6">
    <div class="card" *ngIf="this.aggManager.aggregatorSetting?.document_aggregator?.enabled">
      <div (click)="fileInput.click()" class="uploadfilecontainer" appDragDrop (onFileDropped)="uploadFile($event)">
        <input hidden type="file" #fileInput (change)="uploadFile($event.target.files)">

        <div class="d-block"><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">

            <g id="outbox">
              <g id="Group_534" data-name="Group 534">
                <g id="Group_533" data-name="Group 533">
                  <path id="Path_65"
                    d="M144.823 17.627l4.744-5v17.907a.931.931 0 1 0 1.86 0V12.629l4.744 5a.991.991 0 0 0 1.3 0 1.051 1.051 0 0 0 0-1.407l-6.326-6.648a.947.947 0 0 0-1.3 0l-6.326 6.648a1.051 1.051 0 0 0 0 1.407.947.947 0 0 0 1.304-.002z"
                    data-name="Path 65" transform="translate(-130.498 -9.312)" />
                  <path id="Path_66"
                    d="M39.86 133.768l-5.209-11.84a1.025 1.025 0 0 0-.884-.631H29.3a.971.971 0 0 0 0 1.941h3.86l4.326 10.19h-5.3a6.084 6.084 0 0 0-5.581 3.736 4.315 4.315 0 0 1-3.907 2.572H17.3a4.315 4.315 0 0 1-3.907-2.572 6.084 6.084 0 0 0-5.581-3.736H2.6l5.116-10.19H10.7a.971.971 0 0 0 0-1.941H7.163a1.023 1.023 0 0 0-.837.582l-6.14 11.792a1.044 1.044 0 0 0-.186.485v12.131a4.679 4.679 0 0 0 4.791 4.61h30.418a4.679 4.679 0 0 0 4.791-4.61v-12.13a2.047 2.047 0 0 0-.14-.389z"
                    data-name="Path 66" transform="translate(0 -110.897)" />
                </g>
              </g>
            </g>
          </svg>
          <span class="Drag-and-Drop-Files">Drag and Drop Files</span>
        </div>
      </div>
      <div class="position-relative mt-4">
        <mat-list class="pr-4 d-flex mb-1">
          <mat-list-item class=" d-flex document-name p-0 " *ngFor="let dt of this.aggManager.documents"> {{dt.name}}
            <button (click)="deleteAttachment(dt.id)" mat-button matSuffix mat-icon-button aria-label="Clear">
              <mat-icon class="document-delete">delete</mat-icon>
            </button>
          </mat-list-item>
        </mat-list>
      </div>
      <form [formGroup]="URLForm" (ngSubmit)="onURLSubmit(URLForm.value)" class="form">
        <div class="d-flex">
          <mat-form-field class="col-6" appearance="outline">
            <mat-label>Enter URL</mat-label>
            <input matInput placeholder="Placeholder" formControlName="URLTerm">
          </mat-form-field>
          <div class="col-6">
            <mat-form-field class="col-5" appearance="outline">
              <mat-label> Type</mat-label>
              <mat-select formControlName="type">
                <mat-option value="audio">Audio</mat-option>
                <mat-option value="image">Image</mat-option>
              </mat-select>
            </mat-form-field>
            <button type="submit" style="  vertical-align: super"
              class="button primary-color br-8 font-size14 ml-3 p-2">
              <svg viewBox="0 0 515.556 515.556" width="30" height="32">
                <path fill="white"
                  d="m0 274.226 176.549 176.886 339.007-338.672-48.67-47.997-290.337 290-128.553-128.552z" />
              </svg>
            </button>
          </div>
        </div>
      </form>
      <mat-list class="pr-4">
        <mat-list-item class="bdr-divider" *ngFor="let st of this.aggManager.documentURLs">
          <strong>{{st.type+':' | titlecase}}</strong>&nbsp;{{st.url}} <button class="postion-right"
            (click)="removeDocumentURL(st)" mat-button matSuffix mat-icon-button aria-label="Clear">
            <mat-icon>close</mat-icon>
          </button>
        </mat-list-item>
      </mat-list>

      <div class="card mt-2" *ngIf="this.aggManager.aggregatorSetting?.confluence_aggregator?.enabled">
        <h3>Confluence Space</h3>
        <span class="p1 mt-2 mb-1  d-block "></span>
        <form [formGroup]="confluenceForm" (ngSubmit)="onconfluenceSubmit(confluenceForm.value)" class="form">
          <div class="d-flex">
            <mat-form-field class="col-9 p-0" appearance="outline">
              <mat-label>Leave empty to search all spaces</mat-label>
              <input matInput placeholder="" formControlName="confluenceText">
              <button mat-button matSuffix mat-icon-button aria-label="Clear"
                *ngIf="confluenceForm.controls['confluenceText'].value"
                (click)="confluenceForm.controls['confluenceText'].setValue('')">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <div class="col-2">
              <button type="submit" style="  vertical-align: super"
                class="button primary-color br-8 font-size14 ml-3 p-2">
                <svg viewBox="0 0 515.556 515.556" width="30" height="32">
                  <path fill="white"
                    d="m0 274.226 176.549 176.886 339.007-338.672-48.67-47.997-290.337 290-128.553-128.552z" />
                </svg>
              </button>
            </div>
          </div>
        </form>
        <mat-list class="pr-4">
          <mat-list-item class="bdr-divider" *ngFor="let st of this.aggManager.confluenceterms"> {{st.space_key}}
            <button class="postion-right" (click)="removeConfluence(st)" mat-button matSuffix mat-icon-button
              aria-label="Clear">
              <mat-icon>close</mat-icon>
            </button>
          </mat-list-item>
        </mat-list>
      </div>
    </div>
    <div class="card mt-2" *ngIf="this.aggManager.aggregatorSetting?.website_aggregator?.enabled">
      <h3>Websites</h3>
      <span class="p1 mt-2 mb-1  d-block "></span>
      <div>
        <mat-form-field appearance="outline">
          <mat-label> Crawl Level</mat-label>
          <mat-select [(value)]="this.aggManager.aggregatorSetting.website_aggregator.crawl_level"
            (selectionChange)="changeCrawl($event)">
            <mat-option [value]="1">1</mat-option>
            <mat-option [value]="2">2</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-checkbox class="ml-2 mr-2 mt-2 float-right"
          [checked]="this.aggManager.aggregatorSetting?.website_aggregator?.follow_external_links"
          (change)="changeExternalLink($event.checked)">Follow External Links
        </mat-checkbox>
      </div>
      <form [formGroup]="webForm" (ngSubmit)="onWebSubmit(webForm.value)" class="form">
        <div class="d-flex">
          <mat-form-field class="col-10 p-0" appearance="outline">
            <mat-label>Enter Website</mat-label>
            <input matInput placeholder="Placeholder" formControlName="webTerm">
            <button mat-button matSuffix mat-icon-button aria-label="Clear" *ngIf="webForm.controls['webTerm'].value"
              (click)="webForm.controls['webTerm'].setValue('')">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <div class="col-2">
            <button type="submit" style="  vertical-align: super"
              class="button primary-color br-8 font-size14 ml-3 p-2">
              <svg viewBox="0 0 515.556 515.556" width="30" height="32">
                <path fill="white"
                  d="m0 274.226 176.549 176.886 339.007-338.672-48.67-47.997-290.337 290-128.553-128.552z" />
              </svg>
            </button>
          </div>
        </div>
      </form>
      <mat-list class="pr-4">
        <mat-list-item class="bdr-divider" *ngFor="let st of this.aggManager.websites"> <span
            class="break-words">{{st.url}} </span><button class="postion-right" (click)="removeWebsite(st)" mat-button
            matSuffix mat-icon-button aria-label="Clear">
            <mat-icon>close</mat-icon>
          </button></mat-list-item>
      </mat-list>
    </div>
  </div>


  <ng-template #content let-modal style="z-index: 99999999999 !important;">
    <div class="modal-header">
      <h4 class="modal-title">Error</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>{{Error}}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
  </ng-template>