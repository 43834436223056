import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aggregators',
  templateUrl: './aggregators.component.html',
  styleUrls: ['./aggregators.component.css']
})
export class AggregatorsComponent implements OnInit {
  constructor() {}
  ngOnInit(): void {}

}
