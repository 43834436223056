import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DemoService {
  demoMode = environment.demoMode;
  autoLogin = environment.autoLogin;
  constructor() { }
}
