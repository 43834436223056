import { Pipe, PipeTransform } from '@angular/core';
import { dashboardItem } from '../models/dashboard';
import { AggregatorManagerService } from '../Managers/aggregator-manager.service';

@Pipe({
  name: 'isNumericalDashboardItem',
})
export class IsNumericalDashboardItemPipe implements PipeTransform {
  constructor(private aggManager: AggregatorManagerService) {}

  transform(value: dashboardItem): boolean {
    let found: boolean = false;
    if (this.aggManager.csv) {
      if (value.viz_cat_id == 2) {
        this.aggManager.csv.forEach((csv) => {
          csv.data.forEach((col) => {
            if (value.viz_uid === col.viz_uid) {
              if (col.column_marker === 'numeric') {
                found = true;
              }
            }
          });
        });
      }
    }

    return found;
  }
}
