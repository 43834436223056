import { Component, OnInit } from '@angular/core';
import { AggregatorManagerService } from '../../../Managers/aggregator-manager.service';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormGroupDirective,
  NgForm,
} from '@angular/forms';
import { first } from 'rxjs/operators';
import { ErrorStateMatcher } from '@angular/material/core';
import {
  aggregator_search_term,
  aggregator_exclude_filters,
  website_aggregator,
  document_aggregator,
} from '../../../models/aggregator';
import { throws } from 'assert';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmationDailogComponent,
  ConfirmDialogModel,
} from '../../../dailogs/confirmation-dailog/confirmation-dailog.component';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css'],
})
export class SettingComponent implements OnInit {
  public value1: string = '';

  public websites: website_aggregator[] = [];
  public documentURLs: document_aggregator[] = [];

  public searchterms: aggregator_search_term[] = [];

  public filters: aggregator_exclude_filters[] = [];

  registerForm: FormGroup;
  searchForm: FormGroup;
  filterForm: FormGroup;
  webForm: FormGroup;
  URLForm: FormGroup;
  confluenceForm: FormGroup;
  constructor(
    public router: Router,
    public aggManager: AggregatorManagerService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private notification: NzNotificationService
  ) {}

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      date: [{ value: '' }],
      type: [''],
    });

    this.searchForm = this.formBuilder.group({
      searchTerm: ['', [Validators.required]],
      // type: ['Text'],
    });

    this.filterForm = this.formBuilder.group({
      filterTerm: ['', [Validators.required]],
      // type: ['Text'],
    });

    this.confluenceForm = this.formBuilder.group({
      confluenceText: ['', [Validators.required]],
      type: ['Text'],
    });

    this.webForm = this.formBuilder.group({
      webTerm: ['', [Validators.required]],
      type: ['Text'],
    });
    // const urlReg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    this.URLForm = this.formBuilder.group({
      URLTerm: ['', [Validators.required]],
      type: ['', [Validators.required]],
    });

    if (this.aggManager.selectedAggregator == undefined) {
      this.router.navigate(['']);
    }

    this.aggManager.getAggregatorSetting();
    this.aggManager.getOrganizationInfo();

    this.initializeAggregator();
  }

  files: any = [];

  private initializeAggregator() {
    setTimeout(() => {
      if (this.aggManager.aggregatorSetting) {
        if (this.aggManager.aggregatorSetting.scheduled_for) {
          const d = new Date(
            this.aggManager.aggregatorSetting.scheduled_for.year,
            this.aggManager.aggregatorSetting.scheduled_for.month - 1,
            this.aggManager.aggregatorSetting.scheduled_for.date,
            0,
            0,
            0,
            0
          );
          this.registerForm.controls['date'].setValue(d);
        }

        this.registerForm.controls['name'].setValue(
          this.aggManager.aggregatorSetting.name
        );

        this.registerForm.controls['type'].setValue(
          this.aggManager.aggregatorSetting.schedule_type
        );
      }
    }, 4000);
  }

  uploadFile(event) {
    this.files = event;
    this.aggManager.UploadFile(
      this.aggManager.aggregatorSetting.document_aggregator.id,
      this.files
    );
  }
  deleteAttachment(id: number) {
    this.aggManager.RemoveFile(id);
  }

  onSubmit(value: any) {
    if (this.registerForm.valid) {
      let d = this.registerForm.controls['date'].value as Date;

      let name = this.registerForm.controls['name'].value;
      let type = this.registerForm.controls['type'].value;

      this.aggManager.EditAggregator(
        this.registerForm.controls['name'].value,
        this.registerForm.controls['type'].value,
        d.getFullYear(),
        d.getMonth() + 1,
        d.getDate()
      );

      this.registerForm.reset();
      this.registerForm.controls['name'].setValue(name);
      this.registerForm.controls['date'].setValue(d);
      this.registerForm.controls['type'].setValue(type);
    }
  }

  Cancel() {
    this.registerForm.reset();
    const d = new Date(
      this.aggManager.aggregatorSetting.scheduled_for.year,
      this.aggManager.aggregatorSetting.scheduled_for.month - 1,
      this.aggManager.aggregatorSetting.scheduled_for.date,
      0,
      0,
      0,
      0
    );

    this.registerForm.controls['name'].setValue(
      this.aggManager.aggregatorSetting.name
    );
    this.registerForm.controls['date'].setValue(d);
    this.registerForm.controls['type'].setValue(
      this.aggManager.aggregatorSetting.schedule_type
    );
  }

  onSearchSubmit(value: any) {
    if (this.searchForm.valid) {
      this.aggManager.AddSearchTerm(
        this.aggManager.selectedAggregator.id,
        this.searchForm.controls['searchTerm'].value,
        'Text'
      );
      this.searchForm.reset();
      this.searchForm.controls.searchTerm.setErrors(null);
    }
  }

  onFilterSubmit(value: any) {
    if (this.filterForm.valid) {
      this.aggManager.AddFilters(
        this.aggManager.selectedAggregator.id,
        this.filterForm.controls['filterTerm'].value,
        'Text'
      );
      this.filterForm.reset();
      this.filterForm.controls.filterTerm.setErrors(null);
    }
  }

  onWebSubmit(value: any) {
    if (this.webForm.valid) {
      this.aggManager.Addwebsite(
        this.aggManager.aggregatorSetting.website_aggregator.id,
        this.webForm.controls['webTerm'].value
      );

      this.webForm.controls.webTerm.setErrors(null);
    }
  }
  onURLSubmit(value: any) {
    if (this.URLForm.valid) {
      //return false;
      this.aggManager.AddDocumentURL(
        this.aggManager.aggregatorSetting.document_aggregator.id,
        this.URLForm.controls['type'].value,
        this.URLForm.controls['URLTerm'].value
      );
      this.URLForm.reset();
      this.URLForm.controls.URLTerm.setErrors(null);
    }
  }

  onconfluenceSubmit(value: any) {
    if (this.confluenceForm.valid) {
      this.aggManager.Addconfluence(
        this.aggManager.aggregatorSetting.confluence_aggregator.id,
        this.confluenceForm.controls['confluenceText'].value
      );

      this.confluenceForm.controls.confluenceText.setErrors(null);
      this.confluenceForm = this.formBuilder.group({
        confluenceText: [' ', [Validators.required]],
        type: ['Text'],
      });
    }
  }

  removeSearch(st: aggregator_search_term) {
    this.aggManager.RemoveSearchTerm(st.id);
  }

  removeFile(file) {}

  removeWebsite(st: any) {
    this.aggManager.Removewebsite(st.id);
  }
  removeDocumentURL(st: any) {
    this.aggManager.RemoveDocumentURL(st.id);
  }
  removeConfluence(st: any) {
    this.aggManager.RemoveConfluence(st.id);
  }

  removeFilter(st: aggregator_exclude_filters) {
    this.aggManager.Removefilter(st.id);
  }

  changeTwitter(checked: boolean) {
    this.aggManager.aggregatorSetting.twitter_aggregator.enabled = checked;
    this.aggManager.editTwitterAggregator();
  }
  changeFacebook(checked: boolean) {
    this.aggManager.aggregatorSetting.facebook_aggregator.enabled = checked;
    this.aggManager.editFacebookAggregator();
  }

  changeInstagram(checked: boolean) {
    this.aggManager.aggregatorSetting.instagram_aggregator.enabled = checked;
    this.aggManager.editInstagramAggregator();
  }
  changeDocument(checked: boolean) {
    this.aggManager.aggregatorSetting.document_aggregator.enabled = checked;
    this.aggManager.editDocumentAggregator();
  }
  changeReddit(checked: boolean) {
    this.aggManager.aggregatorSetting.reddit_aggregator.enabled = checked;
    this.aggManager.editRedditAggregator();
  }
  changeYoutube(checked: boolean) {
    this.aggManager.aggregatorSetting.youtube_aggregator.enabled = checked;
    this.aggManager.editYoutubeAggregator();
  }

  changeConfulance(checked: boolean) {
    this.aggManager.aggregatorSetting.confluence_aggregator.enabled = checked;
    this.aggManager.editConfulanceAggregator();
  }

  changeFxNews(checked: boolean) {
    this.aggManager.aggregatorSetting.fxnews_aggregator.enabled = checked;
    this.aggManager.editfxNewsAggregator();
  }

  changeWebsite(checked: boolean) {
    this.aggManager.aggregatorSetting.website_aggregator.enabled = checked;
    this.aggManager.editWebAggregator();
  }

  changeExternalLink(checked: boolean) {
    this.aggManager.aggregatorSetting.website_aggregator.follow_external_links =
      checked;
    this.aggManager.editWebAggregator();
  }

  changeCrawl(value: any) {
    this.aggManager.aggregatorSetting.website_aggregator.crawl_level =
      value.value;
    this.aggManager.editWebAggregator();
  }

  changeGoogle(checked: boolean) {
    this.aggManager.aggregatorSetting.google_aggregator.enabled = checked;
    this.aggManager.editGoogleAggregator();
  }

  public result: any;
  confirmFilterDialog(st): void {
    const message = `Are you sure you want delete this filter?`;

    const dialogData = new ConfirmDialogModel('Confirm Action', message);

    const dialogRef = this.dialog.open(ConfirmationDailogComponent, {
      maxWidth: '400px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      this.result = dialogResult;

      if (this.result) {
        this.removeFilter(st);
      }
    });
  }
}
