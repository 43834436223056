import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChildren,
  Renderer2,
  AfterViewChecked,
  OnDestroy,
  HostListener,
} from '@angular/core';
import { VisualizationManagerService } from '../../../Managers/visualization-manager.service';
import { classResult, entity, general_Image } from '../../../models/dashboard';
import { NestedTreeControl } from '@angular/cdk/tree';

import { MatTreeNestedDataSource, MatTreeNode } from '@angular/material/tree';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AggregatorManagerService } from '../../../Managers/aggregator-manager.service';

export class Word {
  word: string = '';
  freqeuncy: number = 0;
}

@Component({
  selector: 'app-cv-pipeline',
  templateUrl: './cv-pipeline.component.html',
  styleUrls: ['./cv-pipeline.component.css'],
})
export class CvPipelineComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  @ViewChildren(MatTreeNode, { read: ElementRef }) treeNodes: ElementRef[];

  myControl = new FormControl();

  filteredOptions: Observable<Word[]>;

  maxAge = 50;
  minAge = 20;
  gender: string[] = ['male', 'female'];
  emotions: string[] = ['0', '3', '5'];

  genders = new FormControl();
  genderlist: string[] = ['Male', 'Female'];
  selectedGender: string[] = ['Male', 'Female'];
  selectedEmotion: string[] = [
    'Angry',
    'Fearful',
    'Happy',
    'Neutral',
    'Sad',
    'Surprised',
    'Disgusted',
  ];

  typesOfEmotion: string[] = [
    'Angry',
    'Fearful',
    'Happy',
    'Neutral',
    'Sad',
    'Surprised',
    'Disgusted',
  ];

  ignoreList: string[] = [
    'the',
    'or',
    'you',
    'are',
    'is',
    'I',
    'am',
    'they',
    'There',
    'no',
    'and',
    'of',
    'to',
    'so',
    'a',
    '-',
    '--',
    'but',
    'as',
    'it',
  ];

  public treeControl = new NestedTreeControl<entity>((node) => node.children);
  public dataSource = new MatTreeNestedDataSource<entity>();

  constructor(
    public vManager: VisualizationManagerService,
    public aggManger: AggregatorManagerService,
    private renderer: Renderer2
  ) {
    //this.dataSource.data = TREE_DATA;
  }

  hasChild = (_: number, node: entity) =>
    !!node.children && node.children.length > 0;

  public Analysis_selected: string = 'Image Analysis';
  public wholetext: string = '';

  public selectedClass: classResult;
  public general_images: general_Image[];
  public filterImgaes: general_Image[];
  public entity: entity;
  public isFullscreen: boolean = false;

  imageLoadSubscriber;
  taxnomysubscriber;

  @HostListener('scroll', ['$event'])
  scrollHandler(event) {
    this.vManager.loadImageonscroll.emit();
  }

  ngOnInit(): void {
    this.vManager.getImageAnalytics(
      this.aggManger.selectedAggregator.id,
      false,
      true
    );

    this.imageLoadSubscriber = this.vManager.onImagenAnylticLoaded.subscribe(
      (c) => {
        this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith(''),
          map((value) => this._filter(value))
        );

        if (this.vManager.wordfilters)
          if (this.vManager.wordfilters[0])
            this.filterText(this.vManager.wordfilters[0].word);
      }
    );

    this.vManager.getPersonImageAnalytics(
      this.aggManger.selectedAggregator.id,
      'all',
      20,
      50,
      'all'
    );

    this.vManager.getTaxonomy(this.aggManger.selectedAggregator.id);
    this.dataSource.data = [];
    this.taxnomysubscriber = this.vManager.onTaxonomyLoaded.subscribe((c) => {
      this.dataSource.data = this.vManager.object_detection_entities;

      this.vManager.getGeneralImageAnalytics(
        this.aggManger.selectedAggregator.id,
        this.isObjectTextonmoy,
        !this.isObjectTextonmoy,
        this.vManager.object_detection_entities[0]?.id
      );
      this.activeNode = this.vManager.object_detection_entities[0];
      //this.general_images = this.vManager.generalImages;
    });

    //this.vManager.getGeneralImages(111);
  }

  AnalysisChange() {
    /* this.vManager.loadImageonscroll.emit();*/
    this.selectedImage = undefined;
  }

  ngOnDestroy() {
    this.imageLoadSubscriber.unsubscribe();
    this.taxnomysubscriber.unsubscribe();
  }

  setAllEmotion(checked: boolean) {
    if (checked) {
      this.selectedEmotion = this.typesOfEmotion;

      setTimeout(() => {
        this.selectedEmotion = this.typesOfEmotion;
      }, 1000);
    } else {
      this.selectedEmotion = [];

      setTimeout(() => {
        this.selectedEmotion = [];
      }, 1000);
    }

    this.ApplyFilter();
  }

  selectedImage: general_Image;
  SelectImage(image: general_Image) {
    this.selectedImage = image;
  }

  closeDetail() {
    this.selectedImage = undefined;
  }

  private _filter(value: string): Word[] {
    const filterValue = value.toLowerCase();

    return this.vManager.wordfilters.filter(
      (option) => option.word.toLocaleLowerCase().indexOf(filterValue) == 0
    );
  }

  hasListener: any[] = [];
  oldHighlight: ElementRef;

  updateHighlight = (newHighlight: ElementRef) => {
    this.oldHighlight &&
      this.renderer.removeClass(
        this.oldHighlight.nativeElement,
        'background-highlight'
      );

    this.renderer.addClass(newHighlight.nativeElement, 'background-highlight');
    this.oldHighlight = newHighlight;
  };

  ngAfterViewChecked() {
    /* this.treeNodes.forEach((reference) => {
       if (!this.hasListener.includes(reference.nativeElement)) {
 
 
         this.renderer.listen(reference.nativeElement, 'click', () => {
           this.updateHighlight(reference);
         });
         this.renderer.listen(reference.nativeElement.children.item(0), 'click', () => {
           this.updateHighlight(reference);
         });
 
         this.hasListener = this.hasListener.concat([reference.nativeElement]);
       }
     });
 
     this.hasListener = this.hasListener.filter((element) => document.contains(element)); */
  }

  isObjectTextonmoy: boolean = true;
  selectTexonomy(isObject_detection_selected) {
    this.isObjectTextonmoy = isObject_detection_selected;
    if (isObject_detection_selected) {
      this.dataSource.data = this.vManager.object_detection_entities;
      this.onClick(this.vManager.object_detection_entities[0]);
    } else {
      this.dataSource.data = this.vManager.classification_entities;
      this.onClick(this.vManager.classification_entities[0]);
    }
  }

  activeNode;
  onClick(node) {
    this.activeNode = node;
    this.vManager.getGeneralImageAnalytics(
      this.aggManger.selectedAggregator.id,
      this.isObjectTextonmoy,
      !this.isObjectTextonmoy,
      node.id
    );

    /* setTimeout(() => {
      this.vManager.loadImageonscroll.emit();
    }, 2000); */
  }

  ApplyFilter() {
    let gender = 'all';
    if (this.selectedGender.length < 2 && this.selectedGender.length > 0) {
      gender = this.selectedGender.toString().toLowerCase();
    } else if (this.selectedGender.length == 0) {
      gender = undefined;
    }

    this.vManager.getPersonImageAnalytics(
      this.aggManger.selectedAggregator.id,
      gender,
      this.minAge,
      this.maxAge,
      this.selectedEmotion.toString().toLowerCase()
    );

    /*setTimeout(() => {
      this.vManager.loadImageonscroll.emit();
    }, 2000); */
  }

  selectedword: string;
  filterText(value) {
    this.selectedword = value;
    if (value == '') {
      this.filterImgaes = this.vManager.imageAnalytics;
    } else {
      this.filterImgaes = [];

      this.vManager.imageAnalytics.forEach((c) => {
        if (c.text_recognition) {
          c.text_recognition.forEach((e) => {
            if (e.text.toLowerCase().includes(value.toLowerCase())) {
              this.filterImgaes.push(c);
            }
          });
        }
      });
    }
    /*
        this.filterImgaes =  this.general_images.filter(c=> { 
          
          let isfound = false
          if(c.texts){
          c.texts.forEach(t=>{
    
            if(!t.text.includes(value))
            {
              isfound = true;
            } 
          })
    
       
        }
    
       return isfound;
      })
       */
  }
}
