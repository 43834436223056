import { Injectable } from '@angular/core';
import { AggregatorsService } from '../services/aggregators.service';
import { historyResponse } from '../Response/responce';
import { history } from '../models/aggregator';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTableDataSource } from '@angular/material/table';


@Injectable({
  providedIn: 'root'
})
export class HistoryManagerService {
  public total_history_result:number;
  public  dataSource : any;
  offset:number;
  limit:number;
  aggregator_id:number;

  public histories:history[]=[];

  constructor(private aggregatorsService: AggregatorsService, private spinner:NgxSpinnerService) { }


  public getHistory(aggregator_id,offset, limit)
  {
    this.aggregator_id = aggregator_id;
    this.offset = offset;
    this.limit = limit;

    this.spinner.show();
   this.aggregatorsService.getHistoryResults(aggregator_id,offset, limit).subscribe(ele=>{
     this.total_history_result = (ele as historyResponse).total_aggregator_histories;
     this.histories =  (ele as historyResponse).aggregator_histories;

     this.dataSource = new MatTableDataSource<history>(this.histories);
     
     this.spinner.hide();
    });
    
  }


  public RemoveAggregatorHistory(aggregator_history_id)
  {
    this.spinner.show();
    this.aggregatorsService.RemoveHistory(aggregator_history_id).subscribe(c=>{
      this.getHistory(this.aggregator_id,this.offset,this.limit);
      this.spinner.hide();
    });
  }


  public clearHistory(aggregator_id)
  {
    this.spinner.show();
    this.aggregatorsService.ClearHistory(aggregator_id).subscribe(c=>{
      this.histories=[];
      this.dataSource = new MatTableDataSource<history>(this.histories);
      this.spinner.hide();
    });

  }
}
