import { Injectable } from '@angular/core';
import { UsersService } from '../services/users.service';
import { userResponse } from '../Response/responce';
import { user } from '../models/user';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UserManagerService {
  public total_user_result: number;
  public dataSource: any;
  public selectedUser: user;
  public users: user[] = [];

  constructor(
    private userService: UsersService,
    public spinner: NgxSpinnerService,
    private route: Router
  ) {}
  getUsers(offset, limit) {
    this.spinner.show();

    this.userService.getUsers(offset, limit).subscribe((ele) => {
      this.total_user_result = (ele as userResponse).total_users;
      this.users = (ele as userResponse).users;

      this.dataSource = new MatTableDataSource<user>(this.users);
      this.spinner.hide();
    });
  }

  updateUsers() {
    this.spinner.show();
    this.userService
      .updateUser(
        this.selectedUser.id,
        this.selectedUser.name,
        this.selectedUser.type,
        this.selectedUser.state
      )
      .subscribe((c) => {
        this.spinner.hide();
      });
  }

  AddUsers(name, email, type, state) {
    this.spinner.show();

    this.userService.addUser(name, email, type).subscribe((c) => {
      // this.selectedUser =c['user'];

      this.users.push(c['user']);
      this.route.navigate(['users']);
    });
  }

  removeUser(userid, offset, limit) {
    this.spinner.show();

    this.userService.removeUser(userid).subscribe((c) => {
      this.getUsers(offset, limit);
      this.spinner.hide();
    });
  }

  resetUser(email: string, password: string) {
    this.spinner.show();
    this.userService.resetPasswordByAdmin(email, password).subscribe((c) => {
      this.spinner.hide();
    });
  }
}
