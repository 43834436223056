import { Component, OnInit, OnDestroy } from '@angular/core';
import { StreamState } from '../../interface/stream-state';
import { AudioService } from '../../services/audio.service';
import { SpeechrecognitionManagerService } from '../../Managers/speechrecognition-manager.service';
import { AggregatorManagerService } from '../../Managers/aggregator-manager.service';
import { file, speaker } from '../../models/speachrecognition';
import { ActivatedRoute } from '@angular/router';

import { NzMarks } from 'ng-zorro-antd/slider';
import * as D3 from 'd3';
import { PieArcDatum, VoronoiCell } from 'd3';
import { AudiorecorderService } from '../../services/audiorecorder.service';
import {
  FormControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-speechrecognition',
  templateUrl: './speechrecognition.component.html',
  styleUrls: ['./speechrecognition.component.css'],
})
export class SpeechrecognitionComponent implements OnDestroy {
  favoriteSeason: string;
  isEdit: boolean = true;
  isEditSpeaker: boolean = true;
  public value1 = 10;
  public isEditValue = null;
  public isEditSpeakerValue = null;

  private hostutternace: D3.Selection<any, any, any, any>;
  private svgutternace: D3.Selection<any, any, any, any>;

  private hostword: D3.Selection<any, any, any, any>;
  private svgword: D3.Selection<any, any, any, any>;

  private hosttime: D3.Selection<any, any, any, any>;
  private svgtime: D3.Selection<any, any, any, any>;

  private pieData: speaker[];

  files: file[] = [];

  state: StreamState = undefined;
  currentFile: any = {};
  public isLoaded: boolean = false;
  showUploadPopup: boolean = false;
  public marks: NzMarks = {};
  public urlFragment: any;
  public urlStartTime: any;

  changeMarks(): void {}

  constructor(
    public audioService: AudioService,
    public srManager: SpeechrecognitionManagerService,
    public aggManager: AggregatorManagerService,
    private audioservice: AudiorecorderService,
    private route: ActivatedRoute
  ) {
    // listen to stream state
    this.audioService.getState().subscribe((state) => {
      this.state = state;
    });
  }

  getFileAnalytics(object_id) {
    this.files = [];
    this.aggManager.filesSpeech = [];
    this.aggManager.GetAggregatorSpeechAnalytics(object_id);
    setTimeout(() => {
      this.files = this.aggManager.filesSpeech;
      if (this.files.length > 0) {
        this.openFile(this.files[0], 0);
      }
    }, 3000);
  }

  ngOnInit(): void {
    this.files = [];
    this.aggManager.filesSpeech = [];
    this.aggManager.AggregatorSpeechAnalyticsObjectIds();

    this.route.queryParams.subscribe((params) => {
      if (params.object_id) {
        this.urlFragment = params.start_time;
        this.getFileAnalytics(params.object_id);
      } else {
        //console.log('no params');
        setTimeout(() => {
          // this.aggManager.selectedObjectId;
          if (this.aggManager.selectedObjectId) {
            this.getFileAnalytics(this.aggManager.selectedObjectId);
          }
        }, 3000);
      }
    });
  }

  youtubeFormControl = new FormControl('', [Validators.required]);

  playStream(url) {
    this.audioService.playStream(url).subscribe((events) => {});
  }

  speakers;

  selectedSpeaker: string = '';
  selectSpeaker(speaker: string) {
    this.selectedSpeaker = speaker;
    this.marks = {};
    this.currentFile.file.conversation.utterances
      .filter((c) => c.speaker == speaker)
      .forEach((element, index) => {
        this.marks[element.start_time] = '';
      });
  }
  editSentence(i) {
    this.isEdit = false;
    this.isEditValue = i;
  }
  editCancelSentence() {
    this.isEdit = true;
  }

  editSpeaker(i) {
    this.isEditSpeaker = false;
    this.isEditSpeakerValue = i;
  }
  editCancelSpeaker() {
    this.isEditSpeaker = true;
  }

  onSenteceSubmit(value: any) {
    console.log(value);
  }
  updateSentence(_id, sentence, start, innerText, type) {
    let sentenceParsed = sentence.replace(/\s/g, '');
    let innerTextParsed = innerText.replace(/\s/g, '');
    if (sentenceParsed !== innerTextParsed) {
      let aggregatorName = this.aggManager.selectedAggregator.name;
      this.aggManager.update_sentence(
        aggregatorName,
        _id,
        type,
        start,
        innerText
      );
      setTimeout(() => {
        this.aggManager.selectedObjectId;
        if (this.aggManager.selectedObjectId) {
          this.getFileAnalytics(this.aggManager.selectedObjectId);
        }
      }, 3000);
      this.isEdit = true;
    } else {
      console.log('no change');
    }
  }

  updateSpeaker(_id, speaker, speakerNewName) {
    let speakerParsed = speaker.replace(/\s/g, '');
    let speakerNewNameParsed = speakerNewName.replace(/\s/g, '');
    if (speakerParsed !== speakerNewNameParsed) {
      let aggregatorName = this.aggManager.selectedAggregator.name;
      this.aggManager.update_speaker(
        aggregatorName,
        _id,
        speakerParsed,
        speakerNewNameParsed
      );

      this.ngOnInit();
      this.isEditSpeaker = true;
    } else {
      console.log('no change');
    }
  }

  openFile(file: file, index) {
    this.speakers = [];
    this.marks = {};
    setTimeout(() => {
      this.pieData = file.conversation.info.speakers;
      this.DrawPieCharts();
      this.isLoaded = true;
      this.currentFile = { index, file };
      this.audioService.stop();
      this.playStream(file.source.audio_file);
      if (file.conversation.utterances) {
        this.speakers = file.conversation.utterances
          .map((item) => item.speaker)
          .filter((value, index, self) => self.indexOf(value) === index);
      }
    }, 1000);

    this.changeMarks();
  }

  uploadYoutubeLink() {
    this.audioservice
      .uploadYoutubeLink(this.youtubeFormControl.value, 'youtube')
      .subscribe(
        (c) => {
          this.files.push(c as file);

          this.showUploadPopup = false;

          this.youtubeFormControl.setValue('');
        },
        (error) => {
          this.showUploadPopup = false;
        }
      );
  }
  uploadFile(event: File[]) {
    if (event[0]) {
      this.audioservice.uploadaudio(event[0], event[0].name).subscribe(
        (c) => {
          this.files.push(c as file);
          // this.transcription = c['transcription'];
          this.showUploadPopup = false;
        },
        (error) => {
          this.showUploadPopup = false;
        }
      );
    }
  }

  ngOnDestroy() {
    this.stop();
  }
  pause() {
    this.audioService.pause();
  }

  play() {
    this.audioService.play();
  }

  stop() {
    this.audioService.stop();
  }

  next() {
    const index = this.currentFile.index + 1;
    const file = this.files[index];
    this.openFile(file, index);
  }

  previous() {
    const index = this.currentFile.index - 1;
    const file = this.files[index];
    this.openFile(file, index);
  }

  isFirstPlaying() {
    return this.currentFile.index === 0;
  }

  isLastPlaying() {
    return this.currentFile.index === this.files.length - 1;
  }

  onSliderChangeEnd(change) {
    console.log('change', change);
    this.audioService.seekTo(change);
  }

  private margin = 50;
  width: number = 475;
  height: number = 350;
  // The radius of the pie chart is half the smallest side
  private radius = Math.min(this.width, this.height) / 2 - this.margin;

  DrawPieCharts() {
    this.hostutternace = D3.select('figure#utterances');

    this.hostword = D3.select('figure#word');
    this.hosttime = D3.select('figure#time');

    this.buildUtternaceSVG();
    this.buildwordsSVG();
    this.buildtimeSVG();

    this.createtimeColors();
    this.createUtteranceColors();
    this.createwordColors();
    this.drawTimeChart();
    this.drawUtternaceChart();
    this.drawWordChart();
  }

  private buildUtternaceSVG(): void {
    this.hostutternace.html('');
    this.svgutternace = this.hostutternace
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height)
      .append('g')
      .attr(
        'transform',
        'translate(' + this.width / 2.9 + ',' + this.height / 2 + ')'
      );
  }

  private colors;
  private createUtteranceColors(): void {
    this.colors = D3.scaleOrdinal()
      .domain(this.pieData.map((d) => d.total_utterances.toString()))
      .range(D3.schemeSet3);
  }

  private drawUtternaceChart(): void {
    const pie = D3.pie<any>().value((d: any) => Number(d.total_utterances));

    // Build the pie chart
    this.svgutternace
      .selectAll('pieces')
      .data(pie(this.pieData))
      .enter()
      .append('path')
      .attr(
        'd',
        D3.arc<PieArcDatum<any>>().innerRadius(0).outerRadius(this.radius)
      )
      .attr('fill', (d, i) => this.colors(i))
      .attr('stroke', '#9e9e9e')
      .style('stroke-width', '0.5px');

    // Add labels
    const labelLocation = D3.arc().innerRadius(100).outerRadius(this.radius);

    var legendG = this.svgutternace
      .selectAll('.legend')
      .data(pie(this.pieData))
      .enter()
      .append('g')
      .attr('transform', function (d, i) {
        return 'translate(' + 180 + ',' + (i * 20 - 150) + ')';
      })
      .attr('class', 'legend');

    legendG
      .append('rect')
      .attr('width', 15)
      .attr('height', 15)
      .attr('fill', (d, i) => this.colors(i));

    legendG
      .append('text')
      .text(function (d) {
        return d.data.total_utterances + ' ' + d.data.speaker.substring(0, 10);
      })
      .style('font-size', 12)
      .attr('y', 12)
      .attr('x', 20);
  }

  private buildwordsSVG(): void {
    this.hostword.html('');
    this.svgword = this.hostword
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height)
      .append('g')
      .attr(
        'transform',
        'translate(' + this.width / 2.9 + ',' + this.height / 2 + ')'
      );
  }

  private colorsword;
  private createwordColors(): void {
    this.colorsword = D3.scaleOrdinal()
      .domain(this.pieData.map((d) => d.total_words.toString()))
      .range(D3.schemeSet3);
  }

  private drawWordChart(): void {
    const pie = D3.pie<any>().value((d: any) => Number(d.total_words));

    // Build the pie chart
    this.svgword
      .selectAll('pieces')
      .data(pie(this.pieData))
      .enter()
      .append('path')
      .attr(
        'd',
        D3.arc<PieArcDatum<any>>().innerRadius(0).outerRadius(this.radius)
      )
      .attr('fill', (d, i) => this.colorsword(i))
      .attr('stroke', '#9e9e9e')
      .style('stroke-width', '0.5px');

    // Add labels
    const labelLocation = D3.arc().innerRadius(100).outerRadius(this.radius);

    var legendG = this.svgword
      .selectAll('.legend')
      .data(pie(this.pieData))
      .enter()
      .append('g')
      .attr('transform', function (d, i) {
        return 'translate(' + 180 + ',' + (i * 20 - 150) + ')';
      })
      .attr('class', 'legend');

    legendG
      .append('rect')
      .attr('width', 15)
      .attr('height', 15)
      .attr('fill', (d, i) => this.colorsword(i));

    legendG
      .append('text')
      .text(function (d) {
        return d.data.total_words + ' ' + d.data.speaker.substring(0, 10);
      })
      .style('font-size', 12)
      .attr('y', 12)
      .attr('x', 20);
  }

  private buildtimeSVG(): void {
    this.hosttime.html('');
    this.svgtime = this.hosttime
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height)
      .append('g')
      .attr(
        'transform',
        'translate(' + this.width / 2.9 + ',' + this.height / 2 + ')'
      );
  }

  private timeword;
  private createtimeColors(): void {
    this.timeword = D3.scaleOrdinal()
      .domain(this.pieData.map((d) => d.total_time.toString()))
      .range(D3.schemeSet3);
  }

  private drawTimeChart(): void {
    const pie = D3.pie<any>().value((d: any) => Number(d.total_time));

    // Build the pie chart
    this.svgtime
      .selectAll('pieces')
      .data(pie(this.pieData))
      .enter()
      .append('path')
      .attr(
        'd',
        D3.arc<PieArcDatum<any>>().innerRadius(0).outerRadius(this.radius)
      )
      .attr('fill', (d, i) => this.timeword(i))
      .attr('stroke', '#9e9e9e')
      .style('stroke-width', '0.5px');

    // Add labels
    const labelLocation = D3.arc().innerRadius(100).outerRadius(this.radius);

    var legendG = this.svgtime
      .selectAll('.legend')
      .data(pie(this.pieData))
      .enter()
      .append('g')
      .attr('transform', function (d, i) {
        return 'translate(' + 180 + ',' + (i * 20 - 150) + ')';
      })
      .attr('class', 'legend');

    legendG
      .append('rect')
      .attr('width', 15)
      .attr('height', 15)
      .attr('fill', (d, i) => this.timeword(i));

    legendG
      .append('text')
      .text(function (d) {
        return d.data.total_time + ' ' + d.data.speaker.substring(0, 10);
      })
      .style('font-size', 12)
      .attr('y', 12)
      .attr('x', 20);
  }

  formatter(value: number): string {
    return `${value}`;
  }
}
