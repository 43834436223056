
  <div class="chart-card pl-4 pt-4 pr-2 pb-3"    >
    <h4 class="text-style-p1 text-color-dark font-weight-bold m-2">{{this.columnName}}</h4>
<button class="btn2" *ngIf="showAddButton"   (click)="Add(this.dashboardItem?.viz_uid)" matTooltip="Add to Dashboard"  [disabled]="this.dashboardItem?.isEnabled"><i class="fa fa-plus"></i></button>
<div style="display: inline-flex;">
<figure id="{{uniqueId}}"></figure>
<div class="mt-5" style="display: inline-block; position: absolute;
right: 5px;
 "  >
 <div *ngFor="let st of this.statistics">
   <div><b>{{st.name}}</b> : {{st.value | number}}</div>
 </div>
</div>
</div>
  </div>