<form [formGroup]="registerForm" (ngSubmit)="onSubmit(registerForm.value)" class="form">
<div class="mat-elevation-z8 " style="background-color: white; border-radius: 16px; padding: 20px;">
    <div class="d-flow-root">
        <h2 style="display: contents;">{{isEdit ? "Edit User":"Add User"}}</h2>
        <div  class="float-right" >
            <button class="button primary-color br-8 font-size14" type="submit">Save</button>
            <button class="button secondary-color br-8 font-size14 ml-2" (click)="cancel()">Cancel</button>
        </div>
    </div>
 
    <div class="mt-3">

        <div>
            <mat-form-field style="width: 368px;" appearance="outline">
                <mat-label>Name</mat-label>
                <input matInput placeholder="Placeholder"  formControlName="name" >
               <button mat-button   matSuffix mat-icon-button aria-label="Clear" *ngIf="registerForm.controls['name'].value" (click)="registerForm.controls['name'].setValue('')" >
                <mat-icon>close</mat-icon>
              </button>
               
              </mat-form-field>


             

              <mat-form-field class="ml-5 col-2 p-0" appearance="outline">
                <mat-label>User State</mat-label>
                <mat-select formControlName="state">
                  <mat-option value="Active">Active</mat-option>
                  <mat-option value="Inactive">Inactive</mat-option>
                  <mat-option value="Unverified">Unverified</mat-option>
                </mat-select>
              </mat-form-field>
        </div>
        <div>
            <mat-form-field  style="width: 368px;"  appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput   placeholder="Placeholder" formControlName="email"   >
               <button mat-button [disabled]="isEdit" matSuffix mat-icon-button aria-label="Clear" *ngIf="registerForm.controls['email'].value" (click)="registerForm.controls['email'].setValue('')"  >
                <mat-icon>close</mat-icon>
              </button>
            
              </mat-form-field>

              <mat-form-field class="ml-5 col-2 p-0" appearance="outline">
                <mat-label>User Type</mat-label>
                <mat-select formControlName="type" >
                  <mat-option value="Administrator">Analyst</mat-option>
                  <mat-option value="Regular">Viewer</mat-option>
                </mat-select>
              </mat-form-field>
        </div>
    </div>

</div>
    </form>