



      <div class="pb-5">
        <div class="login-card p-4 bg-white">
          <div class="text-left">
            <h2 class="text-style-h2 text-color-dark mb-1 mt-3">Login</h2>
            <p class="text-style-p1 text-color-dark-light">Welcome back! Please <br> Login to continue</p>
          </div>
          <form  [formGroup]="loginForm" (submit)="loginUser()">

            <br>
            <small *ngIf="error" class="form-text text-danger">{{ error }}</small>
            <div class="input-group">
              <input type="text"
                     #loginInput
                     (keydown.Tab)="loginFocusChange($event)"
                     aria-label="Email Address" aria-describedby="email-addon1"
                     [ngClass]="{
                        'border-danger': !login.valid && login.dirty,
                        'border-success': login.valid && login.dirty
                       }" placeholder="Email"  formControlName="login" class="form-control">
              <div *ngIf="login.value" class="input-group-append">
                <button type="button" (click)="login.reset()" class="btn btn-outline-secondary"><img class="input-icon" src="./assets/icons/close.png" alt="close"></button>
              </div>
            </div>

            <br>

            <div class="input-group">
              <input [type]="showPassword? 'text': 'password' "
                     #passwordInput
                     (keydown.Tab)="passwordFocusChange($event)"
                     aria-label="Password" aria-describedby="password-addon1"
                     [ngClass]="{
                        'border-danger': !password.valid && password.dirty,
                        'border-success': password.valid && password.dirty
                       }" placeholder="Password"  formControlName="password" class="form-control">
              <div class="input-group-append">
                <button type="button" (click)="showPassword = !showPassword" class="btn btn-outline-secondary">
                  <img class="input-icon" [src]="showPassword? './assets/icons/hide.png' : './assets/icons/eye.png' " alt="close"></button>
              </div>
            </div>


            <br>

            <div class="text-center mt-2 mb-4" *ngIf="isLoading">
              <app-loading-component  [widthSize]="'40px'"></app-loading-component>
            </div>

            <input #loginButton *ngIf="!isLoading" type="submit" [disabled]="loginForm.invalid" value="Login" class="btn btn-primary btn-block mt-2 mb-4">

          </form>
          <hr>
          <div class="text-left">
            <a class="btn btn-link text-style-p2" routerLink="/login/forgot-password">Forgot Password?</a>
          </div>
        </div>
      </div>




